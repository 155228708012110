import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import AccessLevel from '~/core/models/enums/AccessLevel';
import {BrokerageFactory} from '../models/submodels/Brokerage';
import {BrokerageUserFactory} from '../models/submodels/BrokerageUser';

export default class BrokerService {
	/**
	 * Get the Brokerage List (Level 2)
	 */
	static async getBrokers() {
		const response = await request
			.get(`${BASE_URL.broker}/v2/portal/workersInsurance/policies/me/brokers`)
			.use(requestBearer)
			.use(authBearer);

		if (!response.body || !response.body.data || !response.body.data.attributes) {
			return [];
		}

		return response.body.data.attributes;
	}

	/**
	 * Get the Brokerage List (Level 3) - These will show access levels
	 */
	static async getBrokerages() {
		const response = await request
			.get(`${BASE_URL.brokerageGet}/v2/portal/workersInsurance/me/brokerages`)
			.use(requestBearer)
			.use(authBearer);

		if (!response.body.data.attributes) {
			return [];
		}

		return response.body.data.attributes.map(BrokerageFactory.createFromObject);
	}

	/**
	 * API call to Add new Brokerage
	 *
	 * @param {object} data for added brokerage
	 * @param {object} user - User Model
	 */
	static async addBrokerage(data, user) {
		const payload = {
			data: {
				type: 'Brokerage',
				attributes: {
					addSelfToGroup: true,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					registrationCode: data.regNo,
					userType: 'Broker',
					mobilePhone: user.contactNumber,
					status: true,
					brokerUserGroups: [
						// Newly added Brokerage
						{
							groupName: '',
							groupId: data.brokerCode,
							accessLevel: AccessLevel.ADMIN,
						},
					],
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.brokerageAdd}/v2/portal/workersInsurance/me/brokerages`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return BrokerageFactory.createFromObject(response.body.data.attributes);
	}

	/**
	 * Get the Brokerage users list
	 */
	static async searchBrokerageUsers(brokerageName) {
		const payload = {
			data: {
				type: 'BrokerageUsersSearch',
				attributes: {
					criteria: {
						groupName: brokerageName,
					},
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.brokerageUserSearch}/v2/portal/workersInsurance/me/brokerages/users/search`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data.attributes.map(BrokerageUserFactory.createFromObject);
	}

	/**
	 * Add users in brokerage users
	 */
	static async addUsersToBrokerages(brokerageUser) {
		const payload = {
			data: {
				type: 'BrokerageUser',
				attributes: brokerageUser.toServiceLayer,
			},
		};

		const response = await request
			.post(`${BASE_URL.brokerageUserAdd}/v2/portal/workersInsurance/me/brokerages/users`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response;
	}

	/**
	 * Get user in brokerage users
	 */
	static async getUserToBrokerageUsers(identityId) {
		const response = await request
			.get(`${BASE_URL.brokerageUserGet}/v2/portal/workersInsurance/me/users/${identityId}/brokerages`)
			.use(requestBearer)
			.use(authBearer);

		return BrokerageUserFactory.createObjectFromResponse(response.body.data.attributes);
	}

	/**
	 * Edit users in brokerage users
	 */
	static async editUsersToBrokerage(brokerageId, brokerageUser, identityId) {
		const payload = {
			data: {
				type: 'BrokerageUser',
				id: identityId,
				attributes: brokerageUser.toServiceLayer,
			},
		};

		const response = await request
			.patch(
				`${BASE_URL.brokerageUserUpdate}/v2/portal/workersInsurance/me/brokerages/${brokerageId}/users/${identityId}`,
			)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data.attributes;
	}

	/**
	 * Reset password in brokerage users
	 */
	static async resetPassword(brokerageUser, identityId) {
		const payload = {
			data: {
				type: 'BrokerageUserPasswordReset',
				attributes: {
					email: brokerageUser.email,
					firstName: brokerageUser.firstName,
					lastName: brokerageUser.lastName,
					delegatedUserId: identityId,
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.brokerageUserResetPassword}/v2/portal/workersInsurance/me/users/${identityId}/resetPassword`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response;
	}
}
