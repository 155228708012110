import ActionTypes from '~/core/models/enums/ActionType';

/**
 *
 * @param {ActionType} string
 *
 * get the Action Type for
 * the Policy Details Page
 */
export const getActionLabel = type => {
	switch (type.toUpperCase()) {
		case ActionTypes.CANCELLED:
			return 'Cancelled';
		case ActionTypes.PAYMENT_DUE:
			return 'Payment due';
		case ActionTypes.PAYMENT_OVERDUE:
			return 'Payment due';
		case ActionTypes.RENEWAL_DUE:
			return 'Renewal due';
		case ActionTypes.CREDIT_CARD_EXPIRES:
			return 'Card expiring';
		case ActionTypes.CANCELLATION_DATE:
			return 'Cancelled';
		case ActionTypes.PREMIUM_PENDING:
			return 'Premium pending';
		case ActionTypes.CANCELLATION:
			return 'Cancelled';
		case ActionTypes.HINDSIGHTS_DUE:
			return 'Actual wages due';
		default:
			return null;
	}
};

export default getActionLabel;
