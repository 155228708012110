import {observable, action} from 'mobx';
import uuidv4 from 'uuid/v4';
import {PolicyLocationAddressFactory} from './PolicyLocationAddress';

export default class PolicyLocation {
	
	@observable id = uuidv4();

	@observable publicID = null;

	@observable locationAddress = [];

  
	/**
	 * Set the business type
	 * @param {string} value - The business type
	 */
	@action setpublicId(value) {
		this.publicID = value || null;
	}
	/**
	 * Sets the authorisations
	 *
	 * @param {?import('./PolicyLocationAddress').default} value
	 */
	@action
	setlocationAddress(value) {
		this.locationAddress = value;
	}
}

export class PolicyLocationFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param location
	 * @returns {?PolicyLocation}
	 */
	static createFromServiceObject(location) {
		if (!location) {
			return null;
		}

		const policyLocation = new PolicyLocation();
	 
		policyLocation.setpublicId(location.publicID);
		let locationAddress = null;
		if (location.accountLocation && location.accountLocation.locationAddress) {
			locationAddress = PolicyLocationAddressFactory.createFromServiceObject(location.accountLocation.locationAddress);
		}
		policyLocation.setlocationAddress(locationAddress);
		return policyLocation;
	}
}
