import {observable, computed, action} from 'mobx';
import moment from 'moment';
import {PaymentOptions} from '~/core/constants/ApplicationConstants';
import DirectDebitMethod from '~/core/models/enums/DirectDebitMethod';
import {PaymentPlanFactory} from './PaymentPlan';

export class Payment {
	/** @type {?string} */
	@observable paymentMethod = null;
	/** @type {?string} */
	@observable directDebitMethod = null;

	/** @type {?number} */
	@observable annualPremiumAmount = null;
	/** @type {?string} */
	@observable selectedPaymentPlan = null;
	/** @type {?string} */
	@observable installmentAmount = null;
	/** @type {?string} */
	@observable bPayReference = null;
	/** @type {?string} */
	@observable paymentReference = null;
	/** @type {?import('moment').Moment} */
	@observable rateAsOfDate = null;
	/** @type {?string} */
	@observable chosenQuote = null;
	/** @type {Array<import('../submodels/PaymentPlan').default>} */
	@observable paymentPlans = [];

	/** @type {?string} */
	@observable bankName = null;
	/** @type {?string} */
	@observable bankAccountName = null;
	/** @type {?string} */
	@observable Bsb = null;
	/** @type {?string} */
	@observable accountNumber = null;
	/** @type {?string} */
	@observable branchName = null;

	/** @type {?string} */
	@observable bankNameDisbursement = null;
	/** @type {?string} */
	@observable bankAccountNameDisbursement = null;
	/** @type {?string} */
	@observable BsbDisbursement = null;
	/** @type {?string} */
	@observable accountNumberDisbursement = null;
	/** @type {?string} */
	@observable branchNameDisbursement = null;
	/** @type {?string} */
	@observable accountTypeDisbursement = null;

	/** @type {?string} */
	@observable cardNumber = null;
	/** @type {?string} */
	@observable cardHolderName = null;
	/** @type {?string} */
	@observable creditCardType = null;
	/** @type {?string} */
	@observable creditCardExpiryMonth = null;
	/** @type {?number} */
	@observable creditCardExpiryYear = null;

	@action
	setPaymentMethod(value) {
		this.paymentMethod = value || null;
	}
	@action
	setDirectDebitMethod(value) {
		this.directDebitMethod = value || null;
	}
	@action
	setBankAccountName(value) {
		this.bankAccountName = value || null;
	}
	@action
	setBsb(value) {
		this.Bsb = value || null;
	}
	@action
	setBranchName(value) {
		this.branchName = value || null;
	}
	@action
	setBankName(value) {
		this.bankName = value || null;
	}
	@action
	setAccountNumber(value) {
		this.accountNumber = value || null;
	}
	@action
	setBankAccountNameDisbursement(value) {
		this.bankAccountNameDisbursement = value || null;
	}
	@action
	setBsbDisbursement(value) {
		this.BsbDisbursement = value || null;
	}
	@action
	setBranchNameDisbursement(value) {
		this.branchNameDisbursement = value || null;
	}
	@action
	setBankNameDisbursement(value) {
		this.bankNameDisbursement = value || null;
	}
	@action
	setAccountNumberDisbursement(value) {
		this.accountNumberDisbursement = value || null;
	}
	@action
	setAccountTypeDisbursement(value) {
		this.accountTypeDisbursement = value || null;
	}
	@action
	setAnnualPremiumAmount(value) {
		this.annualPremiumAmount = value || null;
	}
	@action
	setSelectedPaymentPlan(value) {
		this.selectedPaymentPlan = value || null;
	}
	@action
	setInstallmentAmount(value) {
		this.installmentAmount = value || null;
	}
	@action
	setBPayReference(value) {
		this.bPayReference = value || null;
	}
	@action
	setPaymentReference(value) {
		this.paymentReference = value || null;
	}
	@action
	setChosenQuote(value) {
		this.chosenQuote = value || null;
	}

	/**
	 * @param {?Array<import('../submodels/PaymentPlan').default>} value
	 */
	@action
	setPaymentPlans(value) {
		this.paymentPlans = value || [];
	}

	@action
	setCardNumber(value) {
		this.cardNumber = value || null;
	}
	@action
	setCardHolderName(value) {
		this.cardHolderName = value || null;
	}
	@action
	setCreditCardType(value) {
		this.creditCardType = value || null;
	}
	@action
	setCreditCardExpiryMonth(value) {
		this.creditCardExpiryMonth = value || null;
	}
	@action
	setCreditCardExpiryYear(value) {
		this.creditCardExpiryYear = value || null;
	}

	/**
	 * Set the rate as of date
	 * @param {?moment.Moment} value
	 */
	@action
	setRateAsOfDate(value) {
		if (!value) {
			this.rateAsOfDate = null;
		} else if (value instanceof moment) {
			this.rateAsOfDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	@computed
	get toServiceLayer() {
		return {
			bPayReference: this.bPayReference,
			paymentMethod: this.paymentMethod,
			paymentReference: this.paymentReference,
			selectedPaymentPlan: this.selectedPaymentPlan,
			paymentDetails: {
				bankAccountData: {
					bankAccountNumber: this.accountNumber,
					bankName: this.bankName,
				},
			},
		};
	}

	toServiceLayerSetupDirectDebit(policyNumber, isDirectDebitMethodFromBank) {
		return isDirectDebitMethodFromBank
			? {
					data: {
						type: 'DirectDebitRequest',
						attributes: {
							policyNumber,
							directDebitDetails: {
								bankDetails: {
									branchName: this.branchName,
									bankAccountName: this.bankAccountName,
									bankAccountNumber: this.accountNumber,
									bsb: this.Bsb,
									bankName: this.bankName,
								},
							},
							disbursementDetails: {
								bankDetails: {
									branchName: this.branchNameDisbursement,
									bankAccountName: this.bankAccountNameDisbursement,
									bankAccountNumber: this.accountNumberDisbursement,
									bsb: this.BsbDisbursement,
									bankName: this.branchNameDisbursement,
								},
							},
						},
					},
			  }
			: {
					data: {
						type: 'DirectDebitRequest',
						attributes: {
							policyNumber,
							disbursementDetails: {
								bankDetails: {
									branchName: this.branchNameDisbursement,
									bankAccountName: this.bankAccountNameDisbursement,
									bankAccountNumber: this.accountNumberDisbursement,
									bsb: this.BsbDisbursement,
									bankName: this.branchNameDisbursement,
								},
							},
						},
					},
			  };
	}

	@computed
	get toServiceLayerPolicyEdit() {
		return {
			bPayReference: this.bPayReference,
			paymentMethod: this.paymentMethod,
			paymentReference: this.paymentReference,
			selectedPaymentPlan: this.selectedPaymentPlan,
			paymentDetails: {
				bankAccountData: {
					bankAccountNumber: this.accountNumber,
					bankName: this.bankName,
				},
				disbursementBankAccountData: {
					bsb: this.BsbDisbursement,
					bankAccountNumber: this.accountNumberDisbursement,
					bankAccountName: this.bankAccountNameDisbursement,
					bankAccountType: this.accountTypeDisbursement,
					bankName: this.bankNameDisbursement,
				},
			},
			paymentPlans: this.paymentPlans ? this.paymentPlans.map(plan => plan.toServiceLayerBind) : [],
			chosenQuote: this.chosenQuote,
		};
	}

	/**
	 * Checks if payment has debit details
	 *
	 * @returns {boolean}
	 */
	@computed
	get hasDebitDetails() {
		return !!(this.accountNumber || this.cardNumber);
	}

	/**
	 * Checks if payment has bank details
	 *
	 * @returns {boolean}
	 */
	@computed
	get hasBankDetails() {
		return !!(this.bankAccountName && this.Bsb && this.accountNumber);
	}

	@computed
	get isBankDetailsIdenticalToDisbursement() {
		return (
			this.bankAccountName === this.bankAccountNameDisbursement &&
			this.Bsb === this.BsbDisbursement &&
			this.accountNumber === this.accountNumberDisbursement
		);
	}

	@computed
	get getAmountDue() {
		if (!this.selectedPaymentPlan || !this.paymentPlans) {
			return 0;
		}

		const paymentPlan = this.paymentPlans.find(
			paymentPlansDetails => paymentPlansDetails.billingId === this.selectedPaymentPlan,
		);

		return paymentPlan && paymentPlan.downPayment ? paymentPlan.downPayment.amount : 0;
	}

	@computed
	get isAnnual() {
		return this.selectedPaymentPlan === PaymentOptions.annual;
	}
}

export class PaymentFactory {
	static createFromPaymentDetails(o) {
		if (!o) {
			return null;
		}

		const p = new Payment();
		if (o.directDebitDetails && o.directDebitDetails.creditCardDetails) {
			const {creditCardDetails} = o.directDebitDetails;
			p.setCardNumber(creditCardDetails.cardNumber);
			p.setCardHolderName(creditCardDetails.cardHolderName);
			p.setCreditCardType(creditCardDetails.creditCardType);
			p.setCreditCardExpiryMonth(creditCardDetails.expiryMonth);
			p.setCreditCardExpiryYear(creditCardDetails.expiryYear);
		}

		if (o.directDebitDetails && o.directDebitDetails.bankDetails) {
			const {bankDetails} = o.directDebitDetails;
			p.setBranchName(bankDetails.branchName);
			p.setAccountNumber(bankDetails.bankAccountNumber);
			p.setBsb(bankDetails.bsb);
			p.setBankName(bankDetails.bankName);
			p.setBankAccountName(bankDetails.bankAccountName);
		}

		if (o.disbursementDetails && o.disbursementDetails.bankDetails) {
			const {bankDetails} = o.disbursementDetails;
			p.setBranchNameDisbursement(bankDetails.branchName);
			p.setAccountNumberDisbursement(bankDetails.bankAccountNumber);
			p.setBsbDisbursement(bankDetails.bsb);
			p.setBankNameDisbursement(bankDetails.bankName);
			p.setBankAccountNameDisbursement(bankDetails.bankAccountName);
		}
		return p;
	}

	static createPaymentFromResponseObject(o) {
		if (!o || !o.bindData || !o.quoteData) {
			return null;
		}

		const {bindData, quoteData, draftData} = o;

		const p = new Payment();
		p.setSelectedPaymentPlan(bindData.selectedPaymentPlan);
		if (bindData.paymentPlans && bindData.paymentPlans.length) {
			// Set downpayment for annual/quarterly based on business discussion FLPAU-5554
			p.setInstallmentAmount(bindData.paymentPlans[0].installment.amount);
			p.setPaymentPlans(bindData.paymentPlans.map(plan => PaymentPlanFactory.createFromObject(plan)));
		}
		p.setPaymentReference(bindData.paymentReference);
		p.setBPayReference(bindData.bPayReference);
		// Note: Setting the value 'Direct Debit' here when bank account or credit card details is returned
		// This is used in the renewal confirmation page and quote application quote confirmation page
		// This implementation will eventually need to be updated to include 'BPAY' and 'Debit/Credit Card'
		// See FLPAU-5453
		// if (bindData.paymentDetails) {
		// 	if ((bindData.paymentDetails.bankAccountData && bindData.paymentDetails.bankAccountData.bankAccountNumber) ||
		// 		(bindData.paymentDetails.creditCardDetails && bindData.paymentDetails.creditCardDetails.creditCardNumber)) {
		// 		p.setPaymentMethod(PaymentMethod.DIRECT_DEBIT);
		// 	}
		// }q
		if (bindData.paymentPlans && bindData.paymentPlans.length) {
			p.setAnnualPremiumAmount(bindData.paymentPlans[0].total.amount);
		}
		if (draftData && draftData.lobs && draftData.lobs.wcLine && draftData.lobs.wcLine.policyPeriod) {
			p.setRateAsOfDate(moment(draftData.lobs.wcLine.policyPeriod.rateAsOfDate));
		}
		p.setChosenQuote(bindData.chosenQuote);
		p.setPaymentMethod(
			bindData.paymentMethod || (bindData.paymentDetails ? bindData.paymentDetails.paymentMethod : null),
		);

		if (bindData.paymentDetails && bindData.paymentDetails.disbursementBankAccountData) {
			const {disbursementBankAccountData} = bindData.paymentDetails;
			p.setAccountNumberDisbursement(disbursementBankAccountData.bankAccountNumber);
			p.setBsbDisbursement(disbursementBankAccountData.bsb);
			p.setBankNameDisbursement(disbursementBankAccountData.bankName);
			p.setBankAccountNameDisbursement(disbursementBankAccountData.bankAccountName);
			p.setAccountTypeDisbursement(disbursementBankAccountData.bankAccountType);
		}
		return p;
	}

	static createPaymentFromDebitForm(o) {
		if (!o || !o.debitDetails) {
			return null;
		}
		const {debitDetails} = o;
		const p = new Payment();
		p.setBankAccountName(debitDetails.nameOfAccount);
		p.setAccountNumber(debitDetails.accountNumber);
		p.setBsb(debitDetails.bsb);

		if (o.debitDetails.directDebitMethod === DirectDebitMethod.FROM_BANK) {
			if (debitDetails.useBankAccount) {
				p.setBankAccountNameDisbursement(debitDetails.nameOfAccount);
				p.setAccountNumberDisbursement(debitDetails.accountNumber);
				p.setBsbDisbursement(debitDetails.bsb);
			} else if (!debitDetails.useBankAccount && debitDetails.useBankAccountDetails) {
				p.setBankAccountNameDisbursement(debitDetails.receiveNameOfAccount);
				p.setAccountNumberDisbursement(debitDetails.receiveAccountNumber);
				p.setBsbDisbursement(debitDetails.receiveBsb);
			}
		} else if (
			o.debitDetails.directDebitMethod === DirectDebitMethod.FROM_DEBIT_CREDIT &&
			debitDetails.useBankAccountDetails
		) {
			p.setBankAccountNameDisbursement(debitDetails.receiveNameOfAccount);
			p.setAccountNumberDisbursement(debitDetails.receiveAccountNumber);
			p.setBsbDisbursement(debitDetails.receiveBsb);
		}

		return p;
	}
}

export default Payment;
