import request from 'superagent';
import uuidv4 from 'uuid/v4';
import {BASE_API_URL} from '~/config/index';

/**
 * PolicyWagePrelimInfoService
 *
 */
export default class PolicyWagePrelimInfoService {
	
	static async submitRequest(prelimInfo, token, clientid, clientSecret) {
		const data = prelimInfo.toServiceLayerPrelimInitialInfo;
		const uniqueCode = prelimInfo.policyUniqueCode;
		const recaptchaToken = token;
		const payload = {
			data,
		};
		
		const url = `${BASE_API_URL}/v2/portal/workersInsurance/tokenAuth/policies/validate`;
		return request
			.post(`${url}`)
			.set({
				Accept: '*/*',
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/vnd.api+json',
				'X-TrackingID': `portalRequest-${uuidv4()}`,
				'X-Client-ID': `${clientid}`,
				'X-Client-Secret': `${clientSecret}`,
				uniquePolicyCode: `${uniqueCode}`,
			})
			.set('X-Token', recaptchaToken)
			.send(payload);
	}
}
