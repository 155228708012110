import {observable, computed, action} from 'mobx';
import CommunicationPreference from '~/core/models/enums/CommunicationPreference';
import PhoneType from '~/core/models/enums/PhoneType';

export class ContactDetails {
	@observable firstName = null; /** @property {?string} */
	@observable lastName = null; /** @property {?string} */
	@observable communicationPreference = null; /** @property {?string} */
	@observable smsNotifications = null; /** @property {?boolean} */
	@observable primaryPhoneType = null; /** @property {?string} */
	@observable mobileNumber = null; /** @property {?string} */
	@observable workNumber = null; /** @property {?string} */
	@observable homeNumber = null; /** @property {?string} */
	@observable email = null; /** @property {?string} */
	@observable publicId = null; /** @property {?string} */

	@action
	setFirstName(value) {
		this.firstName = value || null;
	}

	@action
	setLastName(value) {
		this.lastName = value || null;
	}

	@action
	setPrimaryContactNumber(value, type) {
		if (value) {
			switch (type) {
				case PhoneType.MOBILE:
					this.mobileNumber = value;
					this.workNumber = null;
					this.homeNumber = null;
					break;
				case PhoneType.WORK:
					this.workNumber = value;
					this.mobileNumber = null;
					this.homeNumber = null;
					break;
				case PhoneType.HOME:
					this.homeNumber = value;
					this.mobileNumber = null;
					this.workNumber = null;
					break;
			}
		}
	}

	@action
	setCommunicationPreference(value) {
		if (value && !Object.values(CommunicationPreference).find(n => n === value)) {
			throw new TypeError();
		}
		this.communicationPreference = value;
	}

	@action
	setSmsNotifications(value) {
		if (typeof value === 'string') {
			this.smsNotifications = value.toUpperCase() === 'YES';
		} else if (typeof value === 'boolean') {
			this.smsNotifications = value;
		} else if (!value) {
			this.smsNotifications = null;
		} else {
			throw new TypeError();
		}
	}

	@action
	setEmail(value) {
		this.email = value || null;
	}

	@action
	setPrimaryPhoneType(value) {
		if (value && !Object.values(PhoneType).find(n => n === value)) {
			throw new TypeError();
		}
		this.primaryPhoneType = value || null;
	}

	@action
	setMobileNumber(value) {
		this.mobileNumber = value || null;
	}

	@action
	setWorkNumber(value) {
		this.workNumber = value || null;
	}

	@action
	setHomeNumber(value) {
		this.homeNumber = value || null;
	}

	@action
	setPublicId(value) {
		this.publicId = value || null;
	}

	@computed
	get bestContactNo() {
		if (!this.primaryPhoneType) {
			return null;
		}
		switch (this.primaryPhoneType.toUpperCase()) {
			case 'MOBILE':
				return this.mobileNumber;
			case 'HOME':
				return this.homeNumber;
			case 'WORK':
				return this.workNumber;
			default:
				return this.mobileNumber; // TODO: To verified
		}
	}

	@computed
	get toServiceLayer() {
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			workNumber: this.workNumber,
			smsNotifications: this.smsNotifications,
			primaryPhoneType: this.primaryPhoneType, //enum
			mobileNumber: this.mobileNumber,
			homeNumber: this.homeNumber,
			emailAddress1: this.email,
			displayName: `${this.firstName} ${this.lastName}`,
			communicationPreference: this.communicationPreference, //enum
			publicId: this.publicId,
		};
	}
}

export class ContactDetailsFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const c = new ContactDetails();
		c.setFirstName(o.firstName);
		c.setLastName(o.lastName);
		c.setCommunicationPreference(o.communicationPreference);
		c.setSmsNotifications(o.smsNotifications);
		c.setPrimaryPhoneType(o.primaryPhoneType || o.phoneType);
		c.setMobileNumber(o.mobileNumber);
		c.setWorkNumber(o.workNumber);
		c.setHomeNumber(o.homeNumber);
		c.setEmail(o.email || o.emailAddress1);
		c.setPrimaryContactNumber(o.bestContactNo || o.contactNumber, o.primaryPhoneType || o.phoneType);
		if (o.publicId) {
			c.setPublicId(o.publicId);
		}

		return c;
	}
}

export default ContactDetails;
