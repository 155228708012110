import {observable, computed, action} from 'mobx';
/**
 * Asbestos
 */
export class Asbestos {
	// region Properties

	/**
	 * Work description
	 * @type {?string}
	 */
	@observable workDescription = null;

	/**
	 * Gross wages
	 * @type {?number}
	 */
	@observable grossWages = null;

	/**
	 * Number of workers exposed
	 * @type {?number}
	 */
	@observable numberOfWorkersExposed = null;

	// endregion

	// region Actions

	/**
	 * Set the work description
	 * @param {?string} value - The description or null
	 */
	@action
	setWorkDescription(value) {
		this.workDescription = value || null;
	}

	@action
	setGrossWages(value) {
		this.grossWages = value;
	}

	@action
	setNumberOfWorkersExposed(value) {
		if (value !== null && typeof value !== 'number') {
			throw new TypeError();
		}
		this.numberOfWorkersExposed = value;
	}

	// endregion

	// region Computed properties

	@computed
	get toServiceLayer() {
		return {
			workDescription: this.workDescription,
			descriptionOfWork: this.workDescription,
			numberOfWorkersExposed: this.numberOfWorkersExposed,
			grossWages: this.grossWages,
			grossWagesForAsbestosHandling: this.grossWages,
		};
	}

	// endregion
}

/**
 * The Asbestos Factory
 */
export class AsbestosFactory {
	/**
	 * Creates an Asbestos from a server response object
	 * @param {object} o - The object
	 * @returns {?Asbestos}
	 */
	static createFromResponseObject(o) {
		const a = new Asbestos();
		a.setWorkDescription(o.descriptionOfWork);
		a.setGrossWages(o.grossWages || o.grossWagesForAsbestosHandling);
		a.setNumberOfWorkersExposed(o.numberOfWorkersExposed);
		return a;
	}

	/**
	 * Creates an Asbestos from a form object
	 * @param {object} o - The object
	 * @returns {?Asbestos}
	 */
	static createFromFormObject(o) {
		if (!o) {
			return null;
		}
		const a = new Asbestos();
		a.setWorkDescription(o.descriptionOfWork);
		if (o.grossWages) {
			a.setGrossWages(parseFloat(o.grossWages.replace(/[$,]/g, '')));
		}
		if (o.numberOfWorkers) {
			a.setNumberOfWorkersExposed(parseInt(o.numberOfWorkers));
		}
		return a;
	}
}

export default Asbestos;
