import uuidv4 from 'uuid/v4';
import {BASE_REQ_HEADERS} from '~/config/endpoints';

/**
 * SuperAgent plugin
 * Will automatically add request headers to request and generate unique request id
 *
 * @param request
 * @returns {request}
 * @example
 *
 * import request from 'superagent';
 * import requestBearer from '~/core/utils/requestBearer';
 *
 * request.get('/url').use(requestBearer).end();
 */
export default function (request) {
	request.set(BASE_REQ_HEADERS).set({'X-TrackingID': `portalRequest-${uuidv4()}`});

	return request;
}
