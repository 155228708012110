import {action} from 'mobx';

export class ReferenceData {
	/**
	 * @type {object[]}
	 */
	businessType = [];
	/**
	 * @type {string[]}
	 */
	trusteeType = [];
	/**
	 * @type {string[]}
	 */
	labourHire = [];
	/**
	 * @type {string[]}
	 */
	contractorWorkType = [];
	/**
	 * @type {object[]}
	 */
	cancelReason = [];
	/**
	 * @type {string[]}
	 */
	schemeAgent = [];
	/**
	 * @type {object[]}
	 */
	perCapitaWic = [];
	/**
	 * @type {object[]}
	 */
	icareTermTypeRationalised = [];
	/**
	 * @type {object[]}
	 */
	securityQuestion = [];
	/**
	 * @type {object[]}
	 */
	forgotRegistrationCode = [];
	/**
	 * @type {object[]}
	 */
	downloadDocTemplates = [];

	/**
	 * @type {number[]}
	 */
	itcEntitlementsWics = [];

	/**
	 * @type {object[]}
	 */
	uploadDocTemplates = [];

	@action setBusinessType(value) {
		this.businessType = value || [];
	}

	@action setTrusteeType(value) {
		this.trusteeType = value || [];
	}

	@action setLabourHire(value) {
		this.labourHire = value || [];
	}

	@action setContractorWorkType(value) {
		this.contractorWorkType = value || [];
	}

	@action setCancelReason(value) {
		this.cancelReason = value || null;
	}

	@action setPerCapitaWic(value) {
		this.perCapitaWic = value || [];
	}

	@action setIcareTermTypeRationalised(value) {
		this.icareTermTypeRationalised = value || [];
	}

	@action setSchemeAgent(value) {
		this.schemeAgent = value || [];
	}

	@action setSecurityQuestion(value) {
		this.securityQuestion = value || [];
	}

	@action setForgotRegistrationCode(value) {
		this.forgotRegistrationCode = value || [];
	}

	@action setDownloadDocTemplates(value) {
		this.downloadDocTemplates = value || [];
	}

	@action setItcEntitlementsWics(value) {
		this.itcEntitlementsWics = value || [];
	}

	@action setUploadDocTemplates(value) {
		this.uploadDocTemplates = value || [];
	}
}

export class ReferenceDataFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const rd = new ReferenceData();
		rd.setBusinessType(o.businessType);
		rd.setTrusteeType(o.trusteeType);
		rd.setLabourHire(o.labourHire);
		rd.setContractorWorkType(o.contractorWorkType);
		rd.setCancelReason(o.cancelReason);
		rd.setPerCapitaWic(o.perCapitaWic);
		rd.setIcareTermTypeRationalised(o.icareTermTypeRationalised);
		rd.setSchemeAgent(o.schemeAgent);
		rd.setSecurityQuestion(o.securityQuestion);
		rd.setForgotRegistrationCode(o.forgotRegistrationCode);
		rd.setDownloadDocTemplates(o.downloadDocTemplates);
		rd.setItcEntitlementsWics(o.itcEntitlementsWics);
		rd.setUploadDocTemplates(o.uploadDocTemplates);

		return rd;
	}
}

export default ReferenceData;
