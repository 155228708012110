/**
 *
 * @param {data} data object
 *
 * get Type of Premium
 * Indicative/Confirmed premium
 */
export const getTypeOfPremium = data => {
	if (
		(data.account.abn && !data.account.isVedaValidated) ||
		data.account.groupNumber ||
		data.premium.computedAnnual > 30000 ||
		data.primaryPremise.wics.length > 1
	) {
		return 'Indicative premium';
	} else {
		return 'Confirmed premium';
	}
};

export default getTypeOfPremium;
