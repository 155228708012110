import {observable, computed, action} from 'mobx';
import moment from 'moment';

/**
 * Taxi Plate
 */
export default class Taxi {
	// region Properties

	/**
	 * Taxi plate number
	 * @type {?string}
	 */
	@observable taxiPlate = null;

	/**
	 * Start date
	 * @type {?moment}
	 */
	@observable startDate = null;

	/**
	 * End date
	 * @type {?moment}
	 */
	@observable endDate = null;

	// endregion

	// region Actions

	/**
	 * Set the taxi plate
	 * @param {?string} value - The taxi plate or null
	 */
	@action setTaxiPlate(value) {
		this.taxiPlate = value || null;
	}

	/**
	 * Set start date
	 * @param {?moment} value - The start date or null
	 */
	@action setStartDate(value) {
		if (value !== null && !(value instanceof moment)) {
			throw new TypeError();
		}
		this.startDate = value;
	}

	/**
	 * Set the end date
	 * @param {?moment} value - The end date or null
	 */
	@action setEndDate(value) {
		if (value !== null && !(value instanceof moment)) {
			throw new TypeError();
		}
		this.endDate = value;
	}

	// endregion

	// region Computed properties

	@computed
	get toServiceLayer() {
		return {
			taxiPlate: this.taxiPlate,
			startDate: this.startDate ? this.startDate.toISOString() : null,
			endDate: this.endDate ? this.endDate.toISOString() : null,
		};
	}

	// endregion
}

/**
 * The Taxi Factory
 */
export class TaxiFactory {
	/**
	 * Create Taxi from server response object
	 * @param {object} o - The response object
	 * @returns {?Taxi}
	 */
	static createFromResponseObject(o) {
		const t = new Taxi();
		t.setTaxiPlate(o.taxiPlate);

		if (o.startDate) {
			t.setStartDate(moment(o.startDate));
		}

		if (o.endDate) {
			t.setEndDate(moment(o.endDate));
		}

		return t;
	}

	/**
	 * Create Taxi from form object
	 * @param {object} o - The form object
	 * @returns {?Taxi}
	 */
	static createFromFormObject(o) {
		const t = new Taxi();
		t.setTaxiPlate(o.taxiPlate);

		if (o.startDate) {
			t.setStartDate(moment(o.startDate, 'DD/MM/YYYY'));
		}

		if (o.endDate) {
			t.setEndDate(moment(o.endDate, 'DD/MM/YYYY'));
		}

		return t;
	}

	/**
	 * Create default value for taxiPlate
	 */
	static createDefaultValues() {
		return {
			taxiPlate: '',
		};
	}
}
