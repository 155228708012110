import {observable, computed, action} from 'mobx';

export default class WicActivity {
	// region Properties
	/** @type {?string} */
	@observable industry = null;

	/** @type {?string} */
	@observable code = null;

	/** @type {?string} */
	@observable wicCode = null;

	/** @type {?string} */
	@observable wicDescription = null;

	/** @type {?number} */
	@observable wicRate = null;

	/** @type {?string} */
	@observable pacCode = null;

	/** @type {?string} */
	@observable pacDescription = null;

	/** @type {?number} */
	@observable ddlContribution = null;

	/** @type {boolean} */
	@observable perCapitaFlag = false;

	// endregion

	/**
	 * Update WicActivity
	 * @param {WicActivity} w
	 */
	@action
	updateWicActivity(w) {
		this.industry = (w && w.industry) || null;
		this.wicCode = (w && w.wicCode) || null;
		this.wicDescription = (w && w.wicDescription) || null;
		this.pacDescription = (w && w.pacDescription) || null;
		this.pacCode = (w && w.pacCode) || null;
		this.code = (w && w.code) || null;
		this.wicRate = (w && w.wicRate) || null;
		this.ddlContribution = (w && w.ddlContribution) || null;
		this.perCapitaFlag = (w && w.perCapitaFlag) || false;
	}

	@computed
	get toServiceLayer() {
		return {
			perCapitaFlag: !!this.perCapitaFlag,
			code: this.code,
			wicRate: parseFloat(this.wicRate),
			wicDescription: this.wicDescription,
			wicCode: this.wicCode,
			pacDescription: this.pacDescription,
			pacCode: this.pacCode.toString(),
			ddlContribution: parseFloat(this.ddlContribution),
		};
	}
}

export class WicActivityFactory {
	static createFromFormObjects(wic, pac, perCapitaFlag = false) {
		if (!wic && !pac) {
			return null;
		}

		const w = new WicActivity();

		const wicDesc = wic.wicCode.split(/-(.+)/)[1];

		w.updateWicActivity({
			industry: wic.industry,
			wicCode: `${wic.code} - ${wicDesc}`,
			wicDescription: wicDesc,
			pacDescription: pac.pacDescription,
			pacCode: pac.pacCode,
			code: wic.code,
			wicRate: wic.wicRate,
			ddlContribution: wic.ddlContribution,
			perCapitaFlag: perCapitaFlag,
		});

		return w;
	}

	static createFromResponseObject(o) {
		const w = new WicActivity();

		w.updateWicActivity({
			wicCode: `${o.code} - ${o.wicDescription}`,
			wicDescription: o.wicDescription,
			pacDescription: o.pacDescription,
			pacCode: o.pacCode,
			code: o.code,
			wicRate: o.wicRate,
			ddlContribution: o.ddlContribution,
			perCapitaFlag: o.perCapitaFlag,
		});

		return w;
	}
}
