const CommunicationPreference = Object.freeze({
	POST: 'post',
	EMAIL: 'email',
});

// Cast user comms pref to endpoint
export const UserCommPrefOut = {
	post: 'Post',
	email: 'Email',
};

export const UserCommPrefIn = {
	Post: 'post',
	Email: 'email',
};

export default CommunicationPreference;
