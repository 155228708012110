import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import QuoteFactory from '~/core/factories/QuoteFactory';
import {QuoteSummaryFactory} from '~/pods/dashboard/models/QuoteSummary';

/**
 * Quote Services
 *
 */
export default class QuoteServices {
	/**
	 * Returns the quote of a workers Insurance Policy for Unauthenticated user.
	 * @param quote
	 * @param isAuth (optional)
	 * @returns {Promise<*>}
	 */
	static async quickQuote(quote, isAuth = false, isQuickQuoteFlow = false) {
		const attr = quote.toServiceLayerQuickQuote;
		if (isQuickQuoteFlow) {
			attr.periodStartDate = null;
			attr.periodEndDate = null;
		}

		const payload = {
			data: {
				type: 'Quote',
				attributes: [attr],
			},
		};

		const url = isAuth ? '/v2/portal/workersInsurance/quotes/me' : '/v2/portal/workersInsurance/quotes';

		const response = await request
			.post(`${BASE_URL.quoteCreate}${url}`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return QuoteFactory.createQuoteFromQuickQuoteResponse(response.body.data, quote);
	}

	// Full Quote / Update Quote
	static async updateQuote(quote, isAuth = false) {
		const payload = {
			data: {
				type: 'Quote',
				id: quote.id,
				attributes: quote.toServiceLayerQuote,
			},
		};

		const url = isAuth
			? `/v2/portal/workersInsurance/quotes/me/${quote.id}`
			: `/v2/portal/workersInsurance/quotes/${quote.id}`;

		const response = await request
			.patch(`${BASE_URL.quoteUpdate}${url}`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return QuoteFactory.createQuoteFromObject(response.body.data, quote);
	}

	// Save Quote
	static async saveQuote(quote, isAuth = false) {
		const payload = {
			data: {
				type: 'Quote',
				id: quote.id,
				attributes: quote.toServiceLayerQuote,
			},
		};

		const url = isAuth
			? `/v2/portal/workersInsurance/me/quotes/${quote.id}/save`
			: `/v2/portal/workersInsurance/quotes/${quote.id}/save`;

		await request.post(`${BASE_URL.quoteSave}${url}`).send(payload).use(requestBearer).use(authBearer);

		return quote;
	}

	// Retrieve Quote / Get Quote
	static async getQuote(quoteId, isAuth = false, email = null) {
		const url = isAuth
			? `/v2/portal/workersInsurance/me/quotes/${quoteId}`
			: `/v2/portal/workersInsurance/quotes/${quoteId}`;

		const response = await request
			.get(`${BASE_URL.quoteGet}${url}`)
			.query(isAuth ? null : {email})
			.use(requestBearer)
			.use(authBearer);

		return QuoteFactory.createQuoteFromObject(response.body.data);
	}

	static async removeQuote(quoteId) {
		const response = await request
			.del(`${BASE_URL.quoteDelete}/v2/portal/workersInsurance/quotes/me/${quoteId}`)
			.send({}) // Required for DELETE request to avoid 'invalid_json' error response from MuleSoft
			.use(requestBearer)
			.use(authBearer);

		return response.statusCode === 204;
	}

	static async searchQuote(payload) {
		const response = await request
			.post(`${BASE_URL.quoteSearch}/v2/portal/workersInsurance/quotes/me/summaries/search`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return QuoteSummaryFactory.createQuotesFromSummary(response.body);
	}
}
