/**
 * Recursively does a deep copy for arrays, objects with arrays and objects with primitive types.
 * Also converts mobx observable arrays into js arrays.
 *
 * Note: This util has not been tested to copy 'complex objects' such as Function, Boolean, String etc.
 * Behaviour may be undefined.
 * @param {*} value
 */
export default function deepCopy(value) {
	if (value instanceof Array) {
		return value.reduce((acc, k) => {
			acc.push(deepCopy(k));
			return acc;
		}, []);
	} else if (value instanceof Object) {
		return Object.keys(value).reduce((acc, k) => {
			acc[k] = deepCopy(value[k]);
			return acc;
		}, {});
	}
	return value;
}
