/* @@GENERATOR:STORE:IMPORT */
import WageDeclarationStore from '~/pods/wage-declaration/stores/WageDeclarationStore';
import PolicyCancelStore from '~/pods/policy-cancel/stores/PolicyCancelStore';
import AuthStore from '~/core/stores/AuthStore';
import {ContentStore} from '~/core/stores/ContentStore';
import ReferenceDataStore from '~/core/stores/ReferenceDataStore';
import QuoteStore from '~/core/stores/QuoteStore';
import PolicyStore from '~/core/stores/PolicyStore';
import RenewPolicyStore from '~/core/stores/RenewPolicyStore';
import EditPolicyStore from '~/core/stores/EditPolicyStore';
import PolicyHistoryStore from '~/core/stores/PolicyHistoryStore';
import BrokerStore from '~/core/stores/BrokerStore';
import BrokerUserStore from '~/core/stores/BrokerUserStore';
import PaymentStore from '~/core/stores/PaymentStore';
import AnalyticsStore from '~/core/stores/AnalyticsStore';
import EmployeeUserStore from '~/core/stores/EmployeeUserStore';
import PolicyHindsightStore from '~/core/stores/PolicyHindsightStore';
import PolicyWageDeclarationStore from '~/pods/policy-wage-declaration/stores/policyWageDeclarationStore';
import FallbackLocationStore from '~/core/stores/FallbackLocationStore';

const stores = {
	/* @@GENERATOR:STORE:ASSIGN */
	wageDeclarationStore: new WageDeclarationStore(),
	policyCancelStore: new PolicyCancelStore(),
	contentStore: ContentStore.create(),
	authStore: new AuthStore(),
	referenceDataStore: new ReferenceDataStore(),
	quoteStore: new QuoteStore(),
	policyStore: new PolicyStore(),
	policyHistoryStore: new PolicyHistoryStore(),
	renewPolicyStore: new RenewPolicyStore(),
	editPolicyStore: new EditPolicyStore(),
	brokerStore: new BrokerStore(),
	brokerUserStore: new BrokerUserStore(),
	paymentStore: new PaymentStore(),
	analyticsStore: new AnalyticsStore(),
	employeeUserStore: new EmployeeUserStore(),
	policyHindsightStore: new PolicyHindsightStore(),
	policyWageDeclarationStore: new PolicyWageDeclarationStore(),
	fallbackLocationStore: new FallbackLocationStore(),
};

export default stores;
