import {css} from '@emotion/core';

const tagsContainer = css`
	display: flex;
	align-items: center;
`;

const topLeft = css`
	.btn-group {
		.cta.is-secondary.has-icon {
			.cta-icon.btn-icon-file-pdf {
				height: 25px;
			}
		}
	}
`;

const actionTags = css`
	.actions-list {
		display: inline;
	}
`;

export default {
	topLeft,
	actionTags,
	tagsContainer,
};
