import ObjectUtils from '@icare/core/lib/utils/ObjectUtils';

export default class AnalyticsUtils {
	// Load GA Tag in DOM
	static initiateLoadOfGATag() {
		if (typeof window.loadGATag === 'function') {
			window.loadGATag();
		}
	}

	// Wrapper function for calling gaTrackingEvent
	static callGaTrackingEvent(analyticsData) {
		ObjectUtils.gaTrackingEvent(analyticsData);
	}
}
