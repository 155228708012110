import {observable, computed, action, toJS} from 'mobx';

/**
 * Payment type (installment, total, downPayment)
 * @typedef {Object} PaymentType
 * @property {number} amount
 * @property {string} amountCurrency
 */

/**
 * Payment plan type (response)
 * @typedef {Object} PaymentPlanObject
 * @property {string} billingId
 * @property {PaymentType} downPayment
 * @property {PaymentType} installment
 * @property {PaymentType} total
 * @property {string} name
 */

class PaymentPlan {
	/** @type {?string} */
	@observable billingId = null;

	/** @type {?PaymentType} */
	@observable downPayment = null;

	/** @type {?PaymentType} */
	@observable installment = null;

	/** @type {?PaymentType} */
	@observable total = null;

	/** @type {?string} */
	@observable name = null;

	/** @returns {?string} */
	@computed get id() {
		return this.billingId;
	}

	/** @returns {?number} */
	@computed get amount() {
		return this.installment?.amount || null;
	}

	/** @returns {?string} */
	@computed get amountCurrency() {
		return this.downPayment ? this.downPayment.amountCurrency : null;
	}

	/** @param {string} id */
	@action setBillingId(id) {
		this.billingId = id;
	}

	/** @param {PaymentType} downPayment */
	@action setDownPayment(downPayment) {
		this.downPayment = downPayment;
	}

	/** @param {PaymentType} installment */
	@action setInstallment(installment) {
		this.installment = installment;
	}

	/** @param {PaymentType} total */
	@action setTotal(total) {
		this.total = total;
	}

	/** @param {string} name */
	@action setName(name) {
		this.name = name;
	}

	/** @returns {PaymentPlanObject} */
	@computed
	get toServiceLayerBind() {
		return {
			billingId: this.billingId,
			downPayment: toJS(this.downPayment),
			installment: toJS(this.installment),
			total: toJS(this.total),
			name: this.name,
		};
	}
}

export class PaymentPlanFactory {
	/**
	 * Creates a payment plan from an object
	 * @param {?PaymentPlanObject} o
	 * @returns {?PaymentPlan}
	 */
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const paymentPlan = new PaymentPlan();

		if (o.billingId) {
			paymentPlan.setBillingId(o.billingId);
		}
		if (o.downPayment) {
			paymentPlan.setDownPayment(o.downPayment);
		}
		if (o.installment) {
			paymentPlan.setInstallment(o.installment);
		}
		if (o.total) {
			paymentPlan.setTotal(o.total);
		}
		if (o.name) {
			paymentPlan.setName(o.name);
		}

		return paymentPlan;
	}
}

export default PaymentPlan;
