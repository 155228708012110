import request from 'superagent';
import uuidv4 from 'uuid/v4';
import {BASE_API_URL_WAGE_DECLARATION} from '~/config/index';

/**
 * PolicyCancelService
 *
 */
export default class WageDeclarationService {
	/**
	 * Returns the quote of a workers Insurance Policy for Unauthenticated user.
	 * @param data
	 * @returns {Promise<*>}
	 */
	static async submitRequest(data) {
		const payload = {
			data,
		};

		// @TODO
		const url = BASE_API_URL_WAGE_DECLARATION || '/api/UnauthenticatedWagesDeclaration';

		return request
			.post(`${url}`)
			.send(payload)
			.type('form')
			.set({
				'Accept': 'application/json',
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json',
				'X-TrackingID': `portalRequest-${uuidv4()}`
			});

	}
}
