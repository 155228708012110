import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';

/**
 * Auth Services
 *
 */
export default class AuthServices {
	static async getUserDetails() {
		const response = await request
			.get(`${BASE_URL.user}/v2/portal/workersInsurance/me`)
			.use(requestBearer)
			.use(authBearer);
		return response.body.data;
	}

	static async patchUserDetails(payload) {
		const response = await request
			.patch(`${BASE_URL.user}/v2/portal/workersInsurance/me`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.status === 200;
	}
}
