import { observable, computed, action } from 'mobx';
import uuidv4 from 'uuid/v4';
import policyTransactionType from "../constants/policyTransactionType";

export default class PolicyAuth {

	@observable id = uuidv4();

	@observable policyTransactionType = null;

	@observable policyNumber = null;

	@observable policyExpiryDate = null;

	@observable policyEstimatedWageEndDate = null;

	@observable policyCancellationEndDate = null;

	@observable policyUniqueCode = null;

	@observable policyPostCode = null;

	/**
	 * Set the wage type
	 * @param {string} value - wage type
	 */
	@action setPolicyTransactionType(value) {
		this.policyTransactionType = value || null;
	}

	/**
	 * Set the policy number
	 * @param {string} value - The policy number
	 */
	@action setPolicyNumber(value) {
		this.policyNumber = value || null;
	}

	/**
	 * Set the policy expiry date
	 * @param {?string} value - The policy expiry date
	 */
	@action setPolicyExpiryDate(value) {
		this.policyExpiryDate = value || null;
	}

	/**
	 * Set the estimated wages policy end date
	 * @param {?string} value - The policy end date
	 */
	@action setPolicyEstimatedWageEndDate(value) {
		this.policyEstimatedWageEndDate = value || null;
	}

	/**
	 * Set the ecancellation policy end date
	 * @param {?string} value - The policy end date
	 */
	@action setPolicyCancellationEndDate(value) {
		this.policyCancellationEndDate = value || null;
	}
	

	/**
	 * Set the policy unique code
	 * @param {?string} value - The policy unique code
	 */
	@action setPolicyUniqueCode(value) {
		this.policyUniqueCode = value || null;
	}

	/**
	 * Set the policy post code
	 * @param {?string} value - The policy post code
	 */
	@action setPolicyPostCode(value) {
		this.policyPostCode = value || null;
	}

	@computed
	get toServiceLayerPrelimInfo() {
		let expDate = null;
		if (this.policyTransactionType === policyTransactionType.ACTUAL_WAGES) {
			expDate = this.policyExpiryDate;
		} else if (this.policyTransactionType === policyTransactionType.ESTIMATED_WAGES) {
			expDate = this.policyEstimatedWageEndDate;
		} else if (this.policyTransactionType === policyTransactionType.CUSTOMER_SERVICE_PROVIDER) {
			//expDate = moment(new Date()).format('MM/DD/YYYY');
			expDate = null;
		} else if (this.policyTransactionType === policyTransactionType.CANCEL_POLICY) {
			expDate = this.policyCancellationEndDate;
		}
		return {
			type: 'PolicySearch',
			id: this.id,
			attributes: {
				criteria: {
					policyNumber: this.policyNumber || null,
					policyExpiryDate: expDate,
					policyPostcode: this.policyPostCode || null,
					transactionType: this.policyTransactionType,
				},
			},
		};
	}

	@computed
	get toServiceLayerPrelimInitialInfo() {
		return {
			type: 'policyValidation',
			id: this.id,
			attributes: {
				criteria: {
					policyNumber: this.policyNumber || null,
					policyPostcode: this.policyPostCode || null,
				},
			},
		};
	}
}

export class PrelimInfoFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param prelimInfo
	 * @returns {?Wage}
	 */
	static createFromFormObject(prelimInfo) {
		if (!prelimInfo) {
			return null;
		}
		const policyAuth = new PolicyAuth();

		policyAuth.setPolicyNumber(prelimInfo.policyNumber);
		policyAuth.setPolicyExpiryDate(prelimInfo.policyExpiryDate);
		policyAuth.setPolicyUniqueCode(prelimInfo.policyUniqueCode);
		policyAuth.setPolicyPostCode(prelimInfo.policyPostCode);
		policyAuth.setPolicyTransactionType(prelimInfo.policyTransactionType);
		policyAuth.setPolicyEstimatedWageEndDate(prelimInfo.policyEstimatedWageEndDate);
		policyAuth.setPolicyCancellationEndDate(prelimInfo.policyCancellationEndDate);
		return policyAuth;
	}
}
