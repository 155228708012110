import Classification from '~/core/models/enums/Classification';

export default class PerCapitaWicUtils {
	/**
	 * This function checks if the perCapitaWic is a Non-Taxi
	 * @param {perCapitaWic} perCapitaWic array
	 * @param {code} code string
	 * @returns {boolean}
	 */
	static isNonTaxi(perCapitaWic, code) {
		if (!perCapitaWic || !code) {
			return false;
		}

		const perCapitaWicObj = perCapitaWic.find(o => o.code === code);
		if (!perCapitaWicObj) {
			return false;
		}

		return perCapitaWicObj.classification !== Classification.TAXI;
	}

	static taxiPlatesHaveUniqueValues(array) {
		const unique = new Set(array);

		if (unique.size !== array.length) {
			return false;
		}

		return true;
	}
}
