export default Object.freeze({
	PAYMENT_OVERDUE: 'PAYMENTOVERDUE',
	PAYMENT_DUE: 'PAYMENTDUE',
	RENEWAL_DUE: 'RENEWALDUE',
	CREDIT_CARD_EXPIRES: 'CREDITCARDEXPIRES',
	CANCELLATION: 'CANCELLATION',
	CANCELLED: 'CANCELLED',
	PREMIUM_PENDING: 'PREMIUMPENDING',
	CANCELLATION_DATE: 'CANCELLATIONDATE',
	HINDSIGHTS_DUE: 'HINDSIGHTSDUE',
});
