import {observable, computed, action} from 'mobx';
import AccessLevel from '~/core/models/enums/AccessLevel';
import Role from '~/core/models/enums/Role';
import {BrokerageFactory} from './Brokerage';

class BrokerageUser {
	@observable identityId = null; /**  @property {?string} */
	@observable accessLevel = null; /**  @property {?string} */
	@observable firstName = null; /**  @property {?string} */
	@observable lastName = null; /**  @property {?string} */
	@observable email = null; /**  @property {?string} */
	@observable contactNumber = null; /**  @property {?string} */
	@observable phoneType = null; /**  @property {?string} */
	@observable brokerUserGroups = null; /**  @property {?Brokerage[]} */

	// action region
	/**
	 * Set the Broker user group
	 * @param {?array} value - Broker user group list
	 */
	@action
	setBrokerUserGroups(value) {
		this.brokerUserGroups = value || null;
	}

	@computed
	get toServiceLayer() {
		return {
			userType: Role.BROKER,
			contactInfo: {
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				phone: {
					contactNumber: this.contactNumber,
					phoneType: 'Mobile', // Default Phone type
				},
			},
			brokerUserGroups: this.brokerUserGroups.map(u => u.toServiceLayer),
			activationLink: null,
		};
	}

	// end region
}

/**
 * The Authorisation Factory
 */
export class BrokerageUserFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const bu = new BrokerageUser();

		if (o.accessLevel) {
			bu.accessLevel = o.accessLevel.toLowerCase() === AccessLevel.ADMIN ? AccessLevel.ADMIN : AccessLevel.GENERAL;
		}

		if (o.contactInfo) {
			bu.firstName = o.contactInfo.firstName;
			bu.lastName = o.contactInfo.lastName;
			bu.email = o.contactInfo.email;
		}

		if (o.contactInfo && o.contactInfo.phone) {
			bu.contactNumber = o.contactInfo.phone.contactNumber;
			bu.phoneType = o.contactInfo.phone.phoneType;
		}

		if (o.identityId) {
			bu.identityId = o.identityId;
		}

		return bu;
	}

	static createObjectFromResponse(o) {
		if (!o) {
			return null;
		}

		const bu = new BrokerageUser();

		if (o.contactInformation) {
			bu.firstName = o.contactInformation.firstName;
			bu.lastName = o.contactInformation.lastName;
			bu.email = o.contactInformation.email;
		}

		if (o.contactInformation && o.contactInformation.phone) {
			bu.contactNumber = o.contactInformation.phone.contactNumber;
			bu.phoneType = o.contactInformation.phone.phoneType;
		} else if (o.contactInformation && o.contactInformation.mobilePhone) {
			bu.contactNumber = o.contactInformation.mobilePhone;
		}

		if (o.brokerUserGroups && o.brokerUserGroups.length) {
			bu.brokerUserGroups = o.brokerUserGroups.map(BrokerageFactory.createFromObject);
		}

		return bu;
	}

	static createObjectFromRequest(o) {
		if (!o) {
			return null;
		}

		const bu = new BrokerageUser();

		bu.firstName = o.firstName;
		bu.lastName = o.lastName;
		bu.email = o.email;
		bu.contactNumber = o.mobile;

		if (o.brokerUserGroups && o.brokerUserGroups.length) {
			bu.brokerUserGroups = o.brokerUserGroups.map(BrokerageFactory.createFromObject);
		} else {
			bu.brokerUserGroups = null;
		}

		return bu;
	}
}
export default BrokerageUser;
