import { observable, computed, action } from 'mobx';
import moment from 'moment';
import Account, { AccountFactory } from '~/core/models/submodels/Account';
import Contact, { ContactFactory } from '~/core/models/submodels/Contact';
import Premise, { PremiseFactory } from '~/core/models/submodels/Premise';
import Premium, { PremiumFactory } from '~/core/models/submodels/Premium';
import Payment, { PaymentFactory } from '~/core/models/submodels/Payment';
import Cancellation, { CancellationFactory } from '~/core/models/submodels/Cancellation';
import DATE_FORMAT from '~/core/constants/DateFormat';
import { isLeapDayInPeriod } from '~/core/utils/DateUtils';
import PerCapitaWicUtils from '~/core/utils/PerCapitaWicUtils';
import Broker from '~/core/models/enums/Broker';
import deepCopy from '../utils/DataStructureUtils';

export default class Policy {
	/**
	 * @property {?string}
	 */
	@observable id;
	/**
	 * @property {?Organisation}
	 */
	@observable account = new Account();
	/**
	 * @property {?Premise}
	 */
	@observable primaryPremise = new Premise();
	/**
	 * @property {Business[]}
	 */
	@observable premises = [];
	/**
	 * @property {?Contact}
	 */
	@observable primaryContact = new Contact();
	/**
	 * @property {Contact[]}
	 */
	@observable contacts = [];
	/**
	 * @property {?Premium}
	 */
	@observable premium = new Premium();
	/**
	 * @property {?Payment}
	 */
	@observable payment = new Payment();
	/**
	 * @property {?Cancellation}
	 */
	@observable cancellation = new Cancellation();
	/**
	 * @property {?string}
	 */
	@observable status = null;

	/** @property {?date} */
	@observable periodStartDate = null;

	/** @property {?date} */
	@observable periodEndDate = null;

	/** @property {?string} */
	@observable policyNumber = null;

	/** @property {?string} */
	@observable labourHireCode = null;

	/** @property {?boolean} */
	@observable policyPending = null;
	/**
	 * @property {Action[]}
	 */
	@observable actions = [];
	/**
	 * @property {?string}
	 */
	@observable brokerGroupId = null;
	/**
	 * @property {?string}
	 * */
	@observable reasonForLowWages = null;
	/**
	 * @property {?string}
	 */
	@observable brokerOrganization = null;
	/**
	 * @property {?string}
	 */
	@observable branch = null;
	/**
	 * @property {?string}
	 */
	@observable groupName = null;
	/**
	 * @property {?string}
	 */
	@observable quoteId;
	/**
	 * @property {?string}
	 */
	@observable accountId;
	/**
	 * @property {?boolean}
	 */
	@observable isUwIssueAvailable = false;
	/**
	 * @property {?string}
	 */
	@observable registrationCode;
	/**
	 * @property {?string}
	 */
	@observable schemeAgent;
	/**
	 * @property {?Object}
	 */
	@observable policyLobs;
	/**
	 * @property {?boolean}
	 */
	@observable hadPolicylast12Months = false;
	/**
	 * @property {?string}
	 */
	@observable policyChangeReason;
	/**
	 * @property {?string}
	 */
	@observable policyChangeType;
	/**
	 * @property {?string}
	 */
	@observable productCode;
	/**
	 * @property {?Array}
	 */
	@observable preQualQuestionSets;
	/**
	 * @property {?string}
	 */
	@observable offering;
	/**
	 * @property {?string}
	 */
	@observable termNumber;
	/**
	 * Certificate of currency
	 * @type string?
	 */
	@observable certificateOfCurrencyUrl = null;
	/** @property {?string} */
	@observable previousSchemeAgent = null;
	/** @property {?string} */
	@observable previousPolicyNumber = null;
	/** @property {?boolean} */
	@observable isBusinessInsured = null;
	/**
	 * @property {?string}
	 */
	@observable srpTermNumber = null;

	/**
	 * Set Policy id
	 * @param value
	 */
	@action
	setId(value) {
		this.id = value || null;
	}

	/**
	 * Set Policy id
	 * @param value
	 */
	@action
	setPolicyNumber(value) {
		this.policyNumber = value || null;
	}

	/**
	 * Set Uw Issue
	 * @param value
	 */
	@action
	setUwIssueAvailable(value) {
		this.isUwIssueAvailable = value || false;
	}

	/**
	 * Set Uw Issue
	 * @param value
	 */
	@action
	setPolicyChangeReason(value) {
		this.policyChangeReason = value || null;
	}

	/**
	 * Set Policy Change Type Value
	 * @param value
	 */
	@action
	setPolicyChangeType(value) {
		this.policyChangeType = value || null;
	}

	/**
	 * Set Policy id
	 * @param value
	 */
	@action
	setPolicyPending(value) {
		if (typeof value !== 'boolean' && !value) {
			this.policyPending = null;
		} else {
			this.policyPending = value;
		}
	}

	/**
	 * Set policy status
	 */
	@action
	setStatus(value) {
		this.status = value || null;
	}

	/**
	 * Set Account
	 * @param value
	 */
	@action
	setAccount(value) {
		if (!value) {
			this.account = null;
		} else if (value instanceof Account) {
			this.account = value;
		} else if (typeof value === 'object') {
			this.account = AccountFactory.createFromResponseObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set policy contacts
	 * @param value
	 */
	@action
	setContacts(value) {
		if (!Array.isArray(value)) {
			throw new TypeError('Invalid value', value);
		}

		this.contacts = value;
	}

	/**
	 * Set the Policy primary business
	 * @param value
	 */
	@action
	setPrimaryPremise(value) {
		if (!value) {
			this.primaryPremise = null;
		} else if (value instanceof Premise) {
			this.primaryPremise = value;
		} else if (typeof value === 'object') {
			this.primaryPremise = PremiseFactory.createFromResponseObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set the Policy other premises
	 * @param value
	 */
	@action
	setPremises(value) {
		if (!value) {
			this.premises = [];
		} else if (Array.isArray(value)) {
			this.premises = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Add additional Premise
	 */
	@action
	addPremise(premise) {
		this.premises = [...this.premises, premise];
	}

	/**
	 * Remove additional Premise
	 */
	@action
	removePremise(premise) {
		this.premises = this.premises.filter(w => {
			return w !== premise;
		});
	}

	/**
	 * Set the Primary Contact
	 */
	@action
	setPrimaryContact(value) {
		if (!value) {
			this.primaryContact = null;
		} else if (value instanceof Contact) {
			this.primaryContact = value;
		} else if (typeof value === 'object') {
			this.primaryContact = ContactFactory.createFromObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Add additional Contact
	 */
	@action
	addContact(contact) {
		this.contacts = [...this.contacts, contact];
	}

	/**
	 * Set the policy premium
	 * @param value
	 */
	@action
	setPremium(value) {
		if (!value) {
			this.premium = null;
		} else if (value instanceof Premium) {
			this.premium = value;
		} else if (typeof value === 'object') {
			this.premium = PremiumFactory.createFromObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set the period start date
	 * @param value
	 */
	@action
	setPeriodStartDate(value) {
		if (!value) {
			this.periodStartDate = null;
		} else if (value instanceof moment) {
			this.periodStartDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the period end date
	 * @param value
	 */
	@action
	setPeriodEndDate(value) {
		if (!value) {
			this.periodEndDate = null;
		} else if (value instanceof moment) {
			this.periodEndDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the payment
	 * @param value
	 */
	@action
	setPayment(value) {
		if (!value) {
			this.payment = null;
		} else if (value instanceof Payment) {
			this.payment = value;
		} else if (typeof value === 'object') {
			this.payment = PaymentFactory.createPaymentFromResponseObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set Reason for low wages
	 * @param value
	 */
	@action
	setReasonForLowWages(value) {
		this.reasonForLowWages = value;
	}

	/**
	 * Set the payment
	 * @param value
	 */
	@action
	setActions(value) {
		if (!value) {
			this.actions = [];
		} else if (Array.isArray(value)) {
			this.actions = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Set the labour hire code
	 * @param value
	 */
	@action
	setLabourHireCode(value) {
		this.labourHireCode = value || null;
	}

	/**
	 *
	 * @param {*} value
	 */
	@action
	setCancellation(value) {
		this.cancellation = CancellationFactory.createCancellationFromFormObject(value);
	}

	/**
	 * Set the broker organisation
	 * @param value
	 */
	@action
	setBrokerOrganization(value) {
		this.brokerOrganization = value || null;
	}

	/**
	 * Set the branch
	 * @param value
	 */
	@action
	setBranch(value) {
		this.branch = value || null;
	}

	/**
	 * Set the Broker Group ID
	 * @param {?string} value - The broker group ID
	 */
	@action
	setBrokerGroupId(value) {
		this.brokerGroupId = value || null;
	}

	/**
	 * Set Group Name
	 * @param value
	 */
	@action
	setGroupName(value) {
		this.groupName = value || null;
	}

	/**
	 * Set Quote id
	 * @param value
	 */
	@action
	setQuoteId(value) {
		this.quoteId = value || null;
	}

	/**
	 * Set Account id
	 * @param value
	 */
	@action
	setAccountId(value) {
		this.accountId = value || null;
	}

	/**
	 * Set Registration Code
	 * @param value
	 */
	@action
	setRegistrationCode(value) {
		this.registrationCode = value || null;
	}

	/**
	 * Set Policy Lobs
	 * @param value
	 */
	@action
	setPolicyLobs(value) {
		if (!value) {
			this.policyLobs = null;
		} else if (typeof value === 'object') {
			this.policyLobs = value;
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set PreQualQuestionSets
	 * @param value
	 */
	@action
	setPreQualQuestionSets(value) {
		if (!value) {
			this.preQualQuestionSets = null;
		} else if (typeof value === 'object') {
			this.preQualQuestionSets = value;
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set HadPolicylast12Months
	 * @param value
	 */
	@action
	setHadPolicyLast12Months(value) {
		if (typeof value === 'boolean') {
			this.hadPolicylast12Months = value;
		} else if (typeof value === 'string') {
			this.hadPolicylast12Months = value.toUpperCase() === 'YES';
		}
	}

	/**
	 * Set Product Code
	 * @param value
	 */
	@action
	setProductCode(value) {
		this.productCode = value || null;
	}

	/**
	 * Set Offering
	 * @param value
	 */
	@action
	setOffering(value) {
		this.offering = value || null;
	}

	@computed
	get hasRenewal() {
		return this.status === 'Quoted';
	}

	/**
	 * Set Scheme Agent
	 * @param value
	 */
	@action
	setSchemeAgent(value) {
		this.schemeAgent = value || null;
	}

	/**
	 * Set Term Number
	 * @param value
	 */
	@action
	setTermNumber(value) {
		this.termNumber = value || null;
	}

	/**
	 * Set the previous scheme agent
	 * @param {?string} value
	 */
	@action
	setPreviousSchemeAgent(value) {
		this.previousSchemeAgent = value || null;
	}

	@action
	setPreviousPolicyNumber(value) {
		this.previousPolicyNumber = value || null;
	}

	/**
	 * Set if the business has been insured
	 * @param {boolean} value
	 */
	@action
	setIsBusinessInsured(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError('value must be boolean.');
		}
		this.isBusinessInsured = value;
	}

	/**
	 * Set certificate of currency url
	 */
	@action
	setCertificateOfCurrencyUrl(url) {
		this.certificateOfCurrencyUrl = url;
	}

	/**
	 * Set SRP Term Number
	 * @param {int} value
	 */
	@action
	setSrpTermNumber(value) {
		this.srpTermNumber = value || null;
	}

	/**
	 * Compute if the policy has a broker or not
	 * @return {boolean}
	 */
	@computed
	get isBrokerPresent() {
		return (
			(this.brokerGroupId &&
				this.brokerGroupId !== Broker.DEFAULT_BROKER_ID &&
				this.brokerOrganization &&
				this.brokerOrganization !== Broker.DEFAULT_BROKER_ORG) ||
			(this.branch && this.branch !== Broker.DEFAULT_BRANCH)
		);
	}

	/**
	 * Use business start trading date
	 * @return {?moment}
	 * */
	@computed
	get effectiveDate() {
		// Only set effective date if there is a previous policy
		if (this.previousPolicyNumber && this.previousSchemeAgent) {
			return this.account && this.account.businessStartTrading ? moment(this.account.businessStartTrading) : moment();
		}
		return null;
	}

	/**
	 * 12 months from effective date
	 * @return {?moment}
	 * */
	@computed
	get expirationDate() {
		// Only set effective date if there is a previous policy
		if (this.previousPolicyNumber && this.previousSchemeAgent) {
			return moment(this.effectiveDate).add(12, 'months');
		}
		return null;
	}

	@computed
	get toServiceLayerCancelPolicy() {
		const dataFromStore = {
			data: {
				type: 'Policy',
				id: this.id,
				attributes: this.cancellation.toServiceLayer,
			},
		};

		return dataFromStore;
	}

	/**
	 * All wics
	 * @return {?[wics]}
	 * */
	@computed
	get wics() {
		const premisesWics = this.premises.map(premise => [...premise.wics]).flat();
		return [...this.primaryPremise.wics, ...premisesWics];
	}

	@computed
	get toServiceLayerPolicy() {
		return {
			policyChangeType: this.policyChangeType,
			policyChangeReason: this.policyChangeReason,
			srpTermNumber: this.srpTermNumber,
			accountId: this.accountId,
			quoteId: this.quoteId,
			status: this.status,
			hadPolicylast12Months: this.hadPolicylast12Months,
			draftData: {
				productCode: this.productCode,
				offering: this.offering,
				account: {
					...this.account.toServiceLayerPolicyEdit,
					communicationPreference: this.primaryContact.details.communicationPreference,
					emailAddress1: this.primaryContact.details.email,
				},
				policyAddress: this.primaryPremise.address
					? {
						...this.primaryPremise.address.toServiceLayer,
						addressType: 'business', //MANDATORY DEFAULT
					}
					: null,
				periodStartDate: this.periodStartDate ? this.periodStartDate.toISOString() : null,
				periodEndDate: this.periodEndDate ? this.periodEndDate.toISOString() : null,
				lobs: {
					wcLine: {
						policyPeriod: {
							reasonLowWages: this.reasonForLowWages,
							hasBroker: !!this.branch,
							itcEntitlement: `${this.account.itcEntitlement}`,
							policy: {
								primaryLanguage: 'en_US', //DEFAULT
								priorPolicy: [
									{
										wcCarrier: this.previousSchemeAgent,
										policyNumber: this.previousPolicyNumber,
										effectiveDate: this.effectiveDate,
										expirationDate: this.expirationDate,
									},
								],
							},
							brokerOrganisation: this.brokerOrganization,
							brokerName: this.branch,
							brokerId: this.brokerGroupId,
							// Using deepCopy to convert mobx observable arrays to js arrays
							policy: deepCopy(this.policyLobs),
							schemeAgent: this.schemeAgent,
							groupNumber: this.account.groupNumber,
							groupName: this.groupName,
							termNumber: this.termNumber,
							policyLocation: [
								{
									...this.primaryPremise.toServiceLayerPolicyEdit,
									primaryLocation: true,
								},
								...this.premises.map(n => n.toServiceLayerPolicyEdit),
							],
							gstRegistration: this.account.gstRegistration,
						},
					},
				},
				accountContact: [
					this.primaryContact.toServiceLayerPolicyEdit,
					...this.contacts.map(n => n.toServiceLayerPolicyEdit),
				],
				// Using deepCopy to convert mobx observable arrays to js arrays
				preQualQuestionSets: deepCopy(this.preQualQuestionSets),
			},
			bindData: {
				...this.payment.toServiceLayerPolicyEdit,
				registrationCode: this.registrationCode,
				isUwIssueAvailable: this.isUwIssueAvailable,
			},
			quoteData: {
				offeredQuotes: [
					{
						annualPremium: {
							// Added this to layer when using Westpac Web Service
							amount: this.payment.annualPremiumAmount,
							amountCurrency: 'aud',
						},
						previousAnnualPremium: {
							amount: this.premium.previousAnnualPremium,
							amountCurrency: 'aud',
						},
					},
				],
			},
		};
	}

	@computed
	get toServiceLayerPolicyStorage() {
		// TODO: add remaining items

		return {
			data: {
				type: 'Policy',
				id: this.id,
				attributes: this.toServiceLayerPolicy,
			},
		};
	}

	@computed
	get paymentDue() {
		let paymentDue = '';
		const endDay = moment(this.periodStartDate).endOf('month').format(DATE_FORMAT.fullDate);
		const periodStartDate = moment(this.periodStartDate).format(DATE_FORMAT.fullDate);

		if (!this.periodStartDate) {
			return null;
		}

		if (endDay === periodStartDate) {
			paymentDue = moment(this.periodStartDate).add(1, 'M').endOf('month').format(DATE_FORMAT.fullDate);
		} else {
			paymentDue = moment(this.periodStartDate).add(1, 'M').format(DATE_FORMAT.fullDate);
		}

		return paymentDue;
	}
	/**
	 * Whether Reason for low wages is required
	 * @returns {boolean}
	 */
	@computed get isReasonForLowWages() {
		let employeeCount = this.primaryPremise.totalNumberOfEmployees;
		let wageCount = this.primaryPremise.totalWagesFloat;

		if (this.premises.length !== 0) {
			this.premises.map(premise => {
				employeeCount += premise.totalNumberOfEmployees;
				wageCount += premise.totalWagesFloat;
			});
		}

		return wageCount / employeeCount < 40000;
	}

	/**
	 * Get total annual wages
	 */
	@computed
	get totalAnnualWages() {
		const days = this.periodEndDate.diff(this.periodStartDate, 'days');
		let wageCount = this.primaryPremise.totalWagesFloat;

		if (this.premises.length !== 0) {
			this.premises.map(premise => {
				wageCount += premise.totalWagesFloat;
			});
		}

		return isLeapDayInPeriod(this.periodStartDate, this.periodEndDate)
			? (wageCount / days) * 366
			: (wageCount / days) * 365;
	}

	/**
	 * To validate if policy has a perCapitaWic
	 */
	@computed
	get hasPerCapitaWic() {
		if (!this.primaryPremise.hasPerCapitaWic) {
			const _hasPerCapitaWic = this.premises.filter(premise => premise.hasPerCapitaWic);
			if (!_hasPerCapitaWic || _hasPerCapitaWic.length === 0) {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if policy has a WIC that has apprentice details
	 */
	@computed
	get hasApprentices() {
		if (!this.primaryPremise.hasApprentices) {
			const _hasApprentices = this.premises.filter(premise => premise.hasApprentices);
			if (!_hasApprentices || _hasApprentices.length === 0) {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if policy is valid to be created if the total wages is less than $7,500
	 */
	@computed
	get isPolicyValidBaseOnWage() {
		if (this.totalAnnualWages < 7500) {
			if ((this.account && this.account.memberOfGroupCompanies) || this.hasPerCapitaWic || this.hasApprentices) {
				return true;
			} else {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if policy has a WIC that has unique taxi plates
	 */
	@computed
	get hasUniqueTaxiPlates() {
		const taxiPlates = this.primaryPremise.taxiPlateList;

		if (this.premises && this.premises.length > 0) {
			this.premises.map(premise => {
				taxiPlates.push(...premise.taxiPlateList);
			});
		}

		return PerCapitaWicUtils.taxiPlatesHaveUniqueValues(taxiPlates);
	}

	/**
	 * Merge all the addresses
	 */
	@computed
	get reservedAddresses() {
		return [this.primaryPremise.address.unstructured, ...this.premises.map(premise => premise.address.unstructured)];
	}

	/**
	 * Total wages across premises
	 */
	@computed
	get totalWagesAcrossPremises() {
		return this.premises.reduce((acc, val) => {
			return acc + val.totalWagesFloat;
		}, this.primaryPremise.totalWagesFloat);
	}
}
