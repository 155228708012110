import {observable, computed, action} from 'mobx';
import moment from 'moment';

export default class Cancellation {
	/** @type {?string} */
	@observable cancellationSource = null;

	/** @type {?string} */
	@observable cancelReasonCode = null;

	/** @type {?string} */
	@observable cancellationDescription = null;

	/** @type {?moment.Moment} */
	@observable effectiveDate = null;

	/** @type {?string} */
	@observable errorCode = null;

	/**
	 * Sets the cancellation source
	 *
	 * @param {string} value
	 */
	@action setCancellationSource(value) {
		this.cancellationSource = value || null;
	}

	/**
	 * Sets the reason code for cancellation
	 *
	 * @param {?string} value
	 */
	@action setCancelReasonCode(value) {
		this.cancelReasonCode = value || null;
	}

	/**
	 * Sets the cancellation description
	 *
	 * @param {?string} value
	 */
	@action setCancellationDescription(value) {
		this.cancellationDescription = value || null;
	}

	/**
	 * Sets the error code
	 *
	 * @param {?string} value
	 */
	@action setErrorCode(value) {
		this.errorCode = value || null;
	}

	@action setEffectiveDate(value) {
		if (!value) {
			this.effectiveDate = null;
		} else if (value instanceof moment) {
			this.effectiveDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	@computed
	get toServiceLayer() {
		return {
			cancellationSource: this.cancellationSource,
			cancelReasonCode: this.cancelReasonCode,
			cancellationDescription: this.cancellationDescription,
			effectiveDate: this.effectiveDate && this.effectiveDate.add(1, 'minutes').toISOString(),
		};
	}
}

export class CancellationFactory {
	static createCancellationFromFormObject(data) {
		if (!data) {
			return null;
		}

		const cancellation = new Cancellation();
		cancellation.setCancellationSource(data.cancellationSource);
		cancellation.setCancelReasonCode(data.cancelReasonCode);
		cancellation.setCancellationDescription(data.cancellationDescription);
		cancellation.setEffectiveDate(moment(data.effectiveDate, 'DD/MM/YYYY'));

		return cancellation;
	}
}
