import {observable, action} from 'mobx';
import PolicyCancelService from '~/core/services/PolicyCancelService';
import AsyncStatus from '~/core/models/AsyncStatus';
import {parseJSON, parseErrorJSONToString} from '~/core/utils/JsonUtility';
import {deleteArrayById} from '~/core/utils/ArrayUtils';

export default class PolicyCancelStore {
	//region Properties

	/**
	 * Data item
	 * @type {?type}
	 */
	@observable details = null;
	@observable wageFlag = null;
	@observable workerWages = [];
	@observable referenceNumber = null;

	/**
	 * Sync status of retrieving the reference data via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();
	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PolicyCancelService) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * Action
	 */
	@action
	submitRequest() {
		this.asyncStatus.isBusy = true;
		const {details, wageFlag, workerWages} = this;

		return PolicyCancelService.submitRequest({
			...details,
			...wageFlag,
			workerWages,
		})
			.then(response => {
				// Convert response to JSON
				const resultsJSON = parseJSON(response);

				// Valid JSON will always have status. If no status than parsing failed
				// Throw error to above layers
				if (!resultsJSON.status) {
					this._setErrorMessage('Invalid response format. Failed to understand response from server.');
					return;
				}

				// All good
				this._setSuccessMessage(resultsJSON);
			})
			.catch(error => {
				this._setErrorMessage(parseErrorJSONToString(error));
			});
	}

	@action
	setDetails(details = null) {
		this.details = details;
	}

	@action
	getDetails() {
		return this.details;
	}

	@action
	setWageFlag(wageFlag = null) {
		this.wageFlag = wageFlag;
	}

	@action
	getWageFlag() {
		return this.wageFlag;
	}

	@action
	getWorkerWages() {
		return this.workerWages || [];
	}

	@action
	setWorkerWages(workerWages = []) {
		this.workerWages = workerWages;
	}

	@action
	deleteWorkerWage(wage) {
		this.workerWages = deleteArrayById(this.workerWages, wage.id);
		return this.workerWages;
	}

	@action
	_setErrorMessage = msg => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = false;
		this.asyncStatus.error = typeof msg === 'string' ? msg : msg.toString();
		console.error(msg);
	};

	@action
	_setSuccessMessage = jsonResponse => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = true;
		this.asyncStatus.error = '';
		this.referenceNumber = jsonResponse.id;
	};
	//endregion
}
