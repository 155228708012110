import fieldTypes from '@icare/forms/lib/constants/ConfigFormTypes';
import fieldSizes from '@icare/forms/lib/constants/ConfigFormSizes';
import validators from '@icare/forms/lib/constants/ConfigFormValidators';

export const submitterTypes = Object.freeze({
	EMPLOYED: 'employed',
	ON_BEHALF: 'on_behalf',
});

export const ChangeClaimsProviderForm = {
	id: 'idForm',
	sections: [
		{
			id: 'formSection',
			fields: [
				{
					id: 'isCSPEditable',
					type: fieldTypes.HIDDEN,
				},
				{
					id: 'policyCurrentCSP',
					label: 'Your current Claims Service Provider',
					type: fieldTypes.READ_ONLY,
					data: {
						width: fieldSizes.LARGE,
					},
				},
				{
					id: 'policyCurrentGeneralistCSP',
					label: 'Your current Generalist Claims Service Provider',
					type: fieldTypes.READ_ONLY,
					data: {
						width: fieldSizes.LARGE,
					},
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__isCSPEditable',
								value: true,
							},
						},
					],
				},
				{
					id: 'policyCurrentSpecialistCSP',
					label: 'Specialist Claims Service Provider',
					type: fieldTypes.READ_ONLY,
					data: {
						width: fieldSizes.LARGE,
					},
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__isCSPEditable',
								value: true,
							},
						},
					],
				},
				{
					id: 'policyCSPlist',
					label: 'Please choose a CSP from one of those listed below',
					type: fieldTypes.SELECT,
					placeholder: "Please select",
					data: {
						width: fieldSizes.LARGE,
					},
					required: true,
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__isCSPEditable',
								value: false,
							},
						},
					],
				},
				{
					id: 'policyGeneralCSPs',
					label: 'Select a Generalist Claims Service Provider',
					type: fieldTypes.SELECT,
					placeholder: "Please select",
					data: {
						width: fieldSizes.LARGE,
					},
					required: true,
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__isCSPEditable',
								value: true,
							},
						},
					],
				},
				{
					id: 'policySpecialistCSPs',
					label: 'Select a Specialist Claims Service Provider',
					type: fieldTypes.SELECT,
					data: {
						width: fieldSizes.LARGE,
					},
					placeholder: "Please select",
					required: true,
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__isCSPEditable',
								value: true,
							},
						},
					],
				},
				{
					id: 'submitterTypeCSP',
					type: fieldTypes.RADIO,
					required: true,
					label: 'Who is selecting the Claims service provider?',
					options: [
						{
							id: 'business',
							label: 'The business',
							value: submitterTypes.EMPLOYED,
						},
						{
							id: 'representative',
							label: 'Representative on behalf of the business (eg. broker, accountant)',
							value: submitterTypes.ON_BEHALF,
						},
					]
				},
				{
					id: 'firstName',
					type: fieldTypes.TEXT,
					required: true,
					requiredMessage: 'This field is required',
					label: 'First name',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.EMPLOYED,
									},
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
					validators: [
						{
							type: validators.IS_LENGTH,
							data: {
								message: 'Please enter no more than 20 characters.',
								options: {
									max: 20,
								},
							},
						},
					],

				},
				{
					id: 'lastName',
					type: fieldTypes.TEXT,
					required: true,
					requiredMessage: 'This field is required',
					label: 'Last name',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.EMPLOYED,
									},
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
					validators: [
						{
							type: validators.IS_LENGTH,
							data: {
								message: 'Please enter no more than 20 characters.',
								options: {
									max: 20,
								},
							},
						},
					],
				},
				{
					id: 'position',
					type: fieldTypes.TEXT,
					label: 'Position',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.EMPLOYED,
									},
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
				},
				{
					id: 'companyName',
					type: fieldTypes.TEXT,
					requiredMessage: 'This field is required',
					label: 'Company name',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
				},
				{
					id: 'contactNumber',
					type: fieldTypes.TEXT,
					required: true,
					requiredMessage: 'This field is required',
					label: 'Contact number',
					placeholder: 'E.g 02 9999 1111',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.EMPLOYED,
									},
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
					validators: [
						{
							type: validators.IS_LENGTH,
							data: {
								message: 'Your phone number should be between 8 and 15 characters',
								options: {
									min: 6,
								},
							},
						},
						{
							type: validators.IS_LENGTH,
							data: {
								message: 'Your phone number should be between 8 and 15 characters',
								options: {
									max: 15,
								},
							},
						},
						{
							type: validators.IS_PHONE,
							data: {
								message: 'Please enter only numbers 0-9 and special characters ()+',
							},
						},
					],
				},
				{
					id: 'emailAddress',
					type: fieldTypes.EMAIL,
					required: true,
					requiredMessage: 'This field is required',
					label: 'Email address',
					width: 's',
					rules: [
						{
							type: 'show-when',
							data: {
								or: [
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.EMPLOYED,
									},
									{
										id: 'formSection__submitterTypeCSP',
										value: submitterTypes.ON_BEHALF,
									},
								]
							}
						},
					],
					validators: [
						{
							type: validators.IS_LENGTH,
							data: {
								message: 'Please enter no more than 60 characters.',
								options: {
									max: 60,
								},
							},
						},
						{
							type: validators.IS_EMAIL,
							data: {
								message: 'Please enter a valid email address.',
							},
						},
					],
				},
				{
					id: 'declarationCheckEmployed',
					type: fieldTypes.CHECKBOX_GROUP,
					label: 'Your declaration and undertaking',
					required: true,
					requiredMessage: 'This field is required',
					options: [
						{
							id: '_declarationCheckEmployed',
							label: 'xI agree to the terms and conditions',
						},
					],
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__submitterTypeCSP',
								value: submitterTypes.EMPLOYED,
							},
						},
						{
							type: 'mandatory-when',
							data: {
								id: 'formSection__submitterTypeCSP',
								value: submitterTypes.EMPLOYED,
							},
						},
					],
				},
				{
					id: 'declarationCheckBehalf',
					label: 'Your declaration and undertaking',
					type: fieldTypes.CHECKBOX_GROUP,
					required: true,
					requiredMessage: 'This field is required',
					rules: [
						{
							type: 'show-when',
							data: {
								id: 'formSection__submitterTypeCSP',
								value: submitterTypes.ON_BEHALF,
							},
						},
						{
							type: 'mandatory-when',
							data: {
								id: 'formSection__submitterTypeCSP',
								value: submitterTypes.ON_BEHALF,
							},
						},
					],
				},
				{
					id: 'agreeTerms',
					type: fieldTypes.CHECKBOX_GROUP,
					label: 'Your declaration and undertaking',
					required: true,
					requiredMessage: 'This field is required',
					options: [
						{
							id: '_agreeTerms',
							label: "I, agree I am authorised to make this choice on behalf of the employer.Penalties may apply for providing false, misleading or incomplete information.",
						},
					],
				},
			],
		},
	],
};


export default ChangeClaimsProviderForm;
