import { observable, computed, action } from 'mobx';
import { PaymentOptions, LavyNameMap } from '~/core/constants/ApplicationConstants';
import { PaymentPlanFactory } from './PaymentPlan';

/**
 * @typedef {import('./PaymentPlan').default} PaymentPlan
 */

class Premium {
	// region Properties

	/** @type {?string} */
	@observable id = null;

	/** @type {Array<PaymentPlan>} */
	@observable paymentPlans = [];

	/** @property {?string} */
	@observable selectedPaymentPlan = null;

	/** @property {?string} */
	@observable discountPercentage = null;
	/**
	 * @property {?boolean}
	 */
	@observable isCspSelected;
	/**
	 * @property {?string}
	 */
	@observable generalistCspName;
	/**
	 * @property {?string}
	 */
	@observable specialistCspName;
	/**
	 * @type {?string}
	 */
	@observable annual = null;
	/**
	 * @type {?string}
	 */
	@observable quarterly = null;
	/**
	 * @type {?string}
	 */
	@observable monthly = null;

	/** @property {?string} */
	@observable typeOfPremium = null;

	@observable breakdown = {}; /** @property {?object} */

	@observable revisedAnnualPremium = null; /** @property {null} */

	@observable previousAnnualPremium = null; /** @property {null} */

	@observable transactionCost = null; /** @property {null} */

	// endregion

	// region Actions
	/**
	 * Set id
	 */
	@action
	setId(value) {
		this.id = value || null;
	}

	/**
	 * Set Policy isCspSelected
	 * @param value
	 */
	@action
	setIsCspSelected(value) {
		if (typeof value !== 'boolean' && !value) {
			this.isCspSelected = null;
		} else {
			this.isCspSelected = value;
		}
	}

	/**
	 * Set Policy generalistCspName
	 * @param value
	 */
	@action
	setGeneralistCspName(value) {
		this.generalistCspName = value || null
	}

	/**
	 * Set Policy specialistCspName
	 * @param value
	 */
	@action
	setSpecialistCspName(value) {
		this.specialistCspName = value || null
	}

	/**
	 * Set discount percentage from response
	 */
	@action
	setDiscountPercentage(data) {
		this.discountPercentage = data || null;
	}

	/**
	 * Set montlhy premium from response
	 */
	@action
	setMonthly(data) {
		this.monthly = data || null;
	}

	/**
	 * Set quarterly premium from response
	 */
	@action
	setQuarterly(data) {
		this.quarterly = data || null;
	}

	/**
	 * Set annual premium from response
	 */
	@action
	setAnnual(value) {
		this.annual = value || null;
	}

	/**
	 * Set Type of Premium from response
	 */
	@action
	setTypeOfPremium(data) {
		this.typeOfPremium = data || null;
	}

	/**
	 * Set the payment plans
	 * @param {Array<PaymentPlan>} value
	 */
	@action setPaymentPlans(value) {
		this.paymentPlans = value || [];
	}

	/**
	 * Set the selected payment plan
	 * @param value
	 */
	@action setSelectedPaymentPlan(value) {
		this.selectedPaymentPlan = value || null;
	}

	/**
	 * Set the selected payment plan
	 * @param value
	 */
	@action setPremiumBreakdown(value) {
		this.breakdown = value || {};
	}

	/**
	 * Set revised annual premium from response
	 */
	@action
	setRevisedAnnualPremium(value) {
		this.revisedAnnualPremium = value || 0;
	}

	/**
	 * Set previous annual premium from response
	 */
	@action
	setPreviousAnnualPremium(value) {
		this.previousAnnualPremium = value || 0;
	}

	/**
	 * Set transaction cost from response
	 */
	@action
	setTransactionCost(value) {
		this.transactionCost = value || 0;
	}

	// endregion

	// region Computed properties

	@computed
	get changeInCost() {
		if (!this.revisedAnnualPremium && !this.previousAnnualPremium) {
			return 0;
		} else {
			return parseFloat(this.revisedAnnualPremium) - parseFloat(this.previousAnnualPremium);
		}
	}

	@computed
	get toServiceLayerBind() {
		return {
			paymentPlans: this.paymentPlans
				.filter(item => item.id === this.selectedPaymentPlan)
				.map(item => item.toServiceLayerBind),
			chosenQuote: this.id,
			selectedPaymentPlan: this.selectedPaymentPlan,
			isCspSelected: this.isCspSelected,
			generalistCspName: this.generalistCspName,
			specialistCspName: this.specialistCspName,
		};
	}

	@computed
	get computedAnnual() {
		if (this.annual) {
			return this.annual;
		} else if (this.quarterly) {
			return this.quarterly * 4;
		} else if (this.monthly) {
			return this.monthly * 12;
		} else {
			return 0;
		}
	}

	// endregion
}

// TODO: Refactor this and policy factory so that policy factory does not need to get draft data and quote data from the to service layer call
// if draft data and quote data are not sent back, and just update current premium rather than overriding
export class PremiumFactory {
	static createFromObject(o) {
		if (!o || !o.bindData || !o.quoteData || !o.policyLocation) {
			return null;
		}

		const p = new Premium();

		if (o.quoteData.offeredQuotes && o.quoteData.offeredQuotes.length > 0 && o.quoteData.offeredQuotes[0].publicId) {
			p.setId(o.quoteData.offeredQuotes[0].publicId);
		}

		if (o.bindData.paymentPlans && o.bindData.paymentPlans.length) {
			const annual = o.bindData.paymentPlans.find(n => n.billingId === PaymentOptions.annual);
			const quarterly = o.bindData.paymentPlans.find(n => n.billingId === PaymentOptions.quarterly);
			const monthly = o.bindData.paymentPlans.find(n => n.billingId === PaymentOptions.monthly);

			if (annual) {
				p.setAnnual(annual?.total?.amount);
			}
			if (quarterly) {
				p.setQuarterly(quarterly?.installment?.amount);
			}
			if (monthly) {
				p.setMonthly(monthly?.installment?.amount);
			}

			p.setPaymentPlans(o.bindData.paymentPlans.map(n => PaymentPlanFactory.createFromObject(n)));
		}

		if (o.bindData.selectedPaymentPlan) {
			p.setSelectedPaymentPlan(o.bindData.selectedPaymentPlan);
		}

		const fullPayDiscountPercentage = o.bindData.fullPayDiscountPercentage || o.bindData.fullPayDisPercentage;
		if (fullPayDiscountPercentage) {
			p.setDiscountPercentage(fullPayDiscountPercentage);
		}

		// !!
		// TODO: THIS NEEDS REFACTORING ASAP. IT BREAKS THE TESTS.
		// !!
		if (o.quoteData && o.quoteData.offeredQuotes && o.quoteData.offeredQuotes.length) {
			p.setPremiumBreakdown(this.createPremiumBreakdownFromResponse(o.quoteData.offeredQuotes[0], o.policyLocation));
			if (o.quoteData.offeredQuotes[0].annualPremium) {
				p.setRevisedAnnualPremium(o.quoteData.offeredQuotes[0].annualPremium.amount);
			}
			if (o.quoteData.offeredQuotes[0].previousAnnualPremium) {
				p.setPreviousAnnualPremium(o.quoteData.offeredQuotes[0].previousAnnualPremium.amount);
			}
			if (o.quoteData.offeredQuotes[0].transactionCost) {
				p.setTransactionCost(o.quoteData.offeredQuotes[0].transactionCost.amount);
			}
		}

		return p;
	}

	static _createAppTableFromResponse(o, policyLocation) {
		if (
			!o.lobs ||
			!o.lobs.wcLine ||
			!o.lobs.wcLine.wageDetails ||
			!o.lobs.wcLine.wageDetails.wagePremium ||
			!o.lobs.wcLine.wageDetails.wagePremium.length ||
			!policyLocation.length === 0
		) {
			return null;
		}

		const wagePremiums = o.lobs.wcLine.wageDetails.wagePremium;
		const costCenters = policyLocation.reduce((acc, val) => acc.concat(val.costCenters), []);
		const wics = costCenters.reduce((acc, val) => acc.concat(val.directWages), []);

		return wagePremiums.map(premium => ({
			wic: premium.wic,
			isPerCapitaWic: !!wics.find(wic => wic.directWageId.code === premium.wic && wic.directWageId.perCapitaFlag),
			rate: premium.rate,
			totalAnnualWages: {
				amount: premium.totalAnnualWages.amount,
				amountCurrency: premium.totalAnnualWages.amountCurrency,
			},
			averagePerformancePremium: {
				amount: premium.averagePerformancePremium.amount,
				amountCurrency: premium.averagePerformancePremium.amountCurrency,
			},
		}));
	}

	static _createEsiTableFromResponse(o) {
		if (!o.lobs || !o.lobs.wcLine || !o.lobs.wcLine.esiPremium) {
			return null;
		}

		return {
			rate: o.lobs.wcLine.esiPremium.esiRate,
			yourEsi: {
				amount: o.lobs.wcLine.esiPremium.esi.amount,
				amountCurrency: o.lobs.wcLine.esiPremium.esi.amountCurrency,
			},
			averagePerformancePremium: {
				amount: o.lobs.wcLine.esiPremium.averagePerformancePremium.amount,
				amountCurrency: o.lobs.wcLine.esiPremium.averagePerformancePremium.amountCurrency,
			},
		};
	}

	static _createLavyTableFromResponse(o, isHindsight = false) {
		if (!o.lobs || !o.lobs.wcLine) {
			return null;
		}

		const { apprenticeDiscount, mineSafetyCost, totalDdl } = o.lobs.wcLine;

		const lavyArray = [];

		if (isHindsight) {
			const { esr, performanceDiscount, rtwi } = o.lobs.wcLine;

			if (rtwi && rtwi.amount) {
				lavyArray.push({ label: LavyNameMap.rtwi, value: rtwi.amount });
			}
			if (esr && esr.amount) {
				lavyArray.push({ label: LavyNameMap.esr, value: esr.amount });
			}
			lavyArray.push({ label: LavyNameMap.pd, value: performanceDiscount.amount });
		}
		lavyArray.push({ label: LavyNameMap.ad, value: apprenticeDiscount.amount });
		lavyArray.push({ label: LavyNameMap.ddl, value: totalDdl.amount });
		lavyArray.push({ label: LavyNameMap.msc, value: mineSafetyCost.amount });

		return lavyArray;
	}

	static createPremiumBreakdownFromResponse(o, policyLocation) {
		if (!o || !o.lobs || !o.lobs.wcLine || !o.annualPremium || !o.lobs.wcLine.esiPremium) {
			return null;
		}

		return {
			totalAveragePerformancePremium: o.lobs.wcLine.wageDetails.totalAveragePerformancePremium.amount,
			totalAveragePerformancePremiumCurrency: o.lobs.wcLine.wageDetails.totalAveragePerformancePremium.amountCurrency,
			yourPremiumValue: o.annualPremium.amount,
			yourPremiumValueCurrency: o.annualPremium.amountCurrency,
			esiAmount: o.lobs.wcLine.esiPremium.esi.amount,
			esiAmountCurrency: o.lobs.wcLine.esiPremium.esi.amountCurrency,
			appTable: this._createAppTableFromResponse(o, policyLocation),
			esiTable: [this._createEsiTableFromResponse(o)],
			discountLavyData: this._createLavyTableFromResponse(o),
			hindsightDiscountLavyData: this._createLavyTableFromResponse(o, true),
		};
	}
}

export default Premium;
