import {observable, action, runInAction} from 'mobx';
import Auth from '@icare/auth-okta/lib/Auth';
import AsyncStatus from '~/core/models/AsyncStatus';
import Policy from '~/core/models/Policy';
import PolicyServices from '~/core/services/PolicyServices';
import {ActionFactory} from '~/core/models/submodels/Action';
import ActionType from '~/core/models/enums/ActionType';
import {SHOW_PREMIUM_PROCEED_ERRORS} from '~/config';
import {ASYNC_ERROR_422} from '../constants/AsyncErrors';

/**
 * The Quote Store
 */
export default class PolicyStore {
	//region Properties

	/**
	 * Main Policy instance
	 * @type {Policy}
	 */
	@observable policy = null;

	/**
	 * Sync status of retrieving the Policy via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PolicyServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * New Quote Store object
	 */
	@action
	initPolicy(policy = null) {
		this.policy = policy || new Policy();
	}

	/**
	 * Set policy
	 */
	@action
	setPolicy(policy) {
		this.policy = policy;
	}

	/**
	 * Set policy
	 */
	@action
	clearPolicy() {
		this.policy = null;
	}

	// TODO
	// get Policy
	// get Policy Details
	// get Policy Search

	/**
	 * Bind Policy (Takeout Policy)
	 */
	@action
	async bindPolicy(quote) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const auth = Auth.sharedInstance;

			const resp = await this.api.bindPolicy(quote, auth && auth.isAuthenticated);
			runInAction(() => {
				this.policy = resp;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Get Policy Details
	 * @param {string|number} policyId
	 * @param {number} termNumber
	 */
	@action
	async getPolicy(policyId, termNumber) {
		try {
			if ((SHOW_PREMIUM_PROCEED_ERRORS && !policyId) || isNaN(termNumber)) {
				throw ASYNC_ERROR_422;
			}

			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const [policy, actionsAndPolicyPending] = await Promise.all([
				this.api.getPolicy(policyId, termNumber),
				this.api.getPolicyActions(policyId, termNumber),
			]);

			const [actions, policyPending] = actionsAndPolicyPending;
			if (policyPending) {
				actions.push(ActionFactory.createFromObj({actionType: ActionType.PREMIUM_PENDING}));
			}

			runInAction(() => {
				this.setPolicy(policy);

				this.policy.setPolicyPending(policyPending);
				this.policy.setActions(actions);

				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Cancel Policy (Cancel Policy)
	 */
	@action
	async cancelPolicy() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			await this.api.cancelPolicy(this.policy);
			runInAction(() => {
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	//endregion
}
