import {observable, computed} from 'mobx';
import moment from 'moment';
import {formatDateFromISO, formatCurrency} from '@icare/core/lib/utils/FormattingUtils';

export class QuoteSummary {
	@observable id = null;
	@observable abn = null;
	@observable amount = null;
	@observable expiryDate = null;
	@observable policyNumber = null;
	@observable entityName = null;

	@computed
	get expiringSoon() {
		const today = moment();
		const pastDate = moment(this.expiryDate).subtract(7, 'days');
		return today.isBetween(pastDate, this.expiryDate);
	}

	@computed
	get displayAmount() {
		return formatCurrency(this.amount);
	}

	@computed
	get displayExpiryDate() {
		return formatDateFromISO(this.expiryDate);
	}
}

export class QuoteSummaryFactory {
	static createQuotesFromSummary(summary) {
		const quotes = summary.data.attributes.entries
			.filter(entry => entry.quoteNumber)
			.map(entry => {
				const quoteSummary = new QuoteSummary();
				quoteSummary.abn = entry.abn;
				quoteSummary.amount = entry.totalPremium.amount;
				quoteSummary.expiryDate = moment(entry.quoteExpiryDate);
				quoteSummary.policyNumber = entry.policyNumber;
				quoteSummary.id = entry.quoteNumber;
				quoteSummary.entityName = entry.entityName;
				return quoteSummary;
			});

		return {
			totalResults: quotes.length,
			totalPages: summary.meta.totalPages,
			currentPage: summary.meta.currentPage,
			quotes: quotes,
		};
	}

	static createQuoteSearchRequestPayload(page, pageSize, searchTerm, searchCategory, sortCriteria) {
		const criteria = {};

		// Add search items to request
		const searchTerms = ['quoteNumber', 'entityName', 'abn', 'acn'];
		searchTerms.forEach(term => {
			if (searchCategory === term) {
				criteria[term] = searchTerm;
			}
		});

		return {
			data: {
				type: 'QuotesSearch',
				attributes: {
					params: {
						page,
						pageSize,
						orderBy: [
							{
								fieldName: sortCriteria,
							},
						],
					},
					criteria,
				},
			},
		};
	}
}
