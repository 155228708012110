import request from 'superagent';
import moment from 'moment';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import {DocumentSummaryFactory} from '~/pods/policy-details/models/DocumentSummary';

export default class DocumentServices {
	/**
	 * Return a signed AWS S3 URL for document upload and delete.
	 * @param {*} policyId
	 * @param {*} fileName
	 * @param {*} documentType
	 * @param {string} mimeType
	 */
	static async stagingLocations(policyId, fileName, documentType, mimeType) {
		const payload = {
			data: {
				type: 'StagingLocation',
				attributes: {
					fileName: fileName,
					documentType: documentType,
					mime: mimeType,
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.stagingLocations}/v2/portal/workersInsurance/policies/me/${policyId}/stagingLocations`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body;
	}

	/**
	 * Archives the documents from AWS S3 to the Document Management solution.
	 * @param {*} policy
	 */
	static async archive(policyId, documents) {
		const payload = {
			data: {
				type: 'DocumentArchive',
				id: {policyId},
				attributes: {
					documents: documents,
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.archive}/v2/portal/workersInsurance/policies/me/${policyId}/documents/archive`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body;
	}

	/**
	 * Retrieves the documents associated to this policy for the logged in user.
	 * @param {*} policyId
	 * @param {*} periodStartDate
	 * @param {*} periodEndDate
	 * @param {*} page
	 * @param {*} pageSize
	 */
	static async getDocuments(policyId, periodStartDate, periodEndDate, page, pageSize, templateId) {
		// We need to correctly format the periodStartDate and periodEndDate queryParams to 'YYYY-MM-DD'
		// Assumption is that both periodStartDate and periodEndDate come formatted as 'DD/MM/YYYY'
		periodStartDate = periodStartDate ? moment(periodStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
		periodEndDate = periodEndDate ? moment(periodEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

		const response = await request
			.get(`${BASE_URL.documents}/v2/portal/workersInsurance/policies/me/${policyId}/documents`)
			.query({
				...(periodStartDate ? {periodStartDate} : null),
				...(periodEndDate ? {periodEndDate} : null),
				...(templateId ? {templateId} : null),
				page,
				pageSize,
			})
			.use(requestBearer)
			.use(authBearer);

		return DocumentSummaryFactory.createDocumentSummaryFromResponse(response.body);
	}
}
