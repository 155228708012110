(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Mobx"), require("React"), require("Superagent"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["Mobx", "React", "Superagent", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["icWidgetPolicy"] = factory(require("Mobx"), require("React"), require("Superagent"), require("ReactDOM"));
	else
		root["icWidgetPolicy"] = factory(root["Mobx"], root["React"], root["Superagent"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__91__) {
return 