import { observable, computed, action } from 'mobx';
import moment from 'moment-timezone';
//Submodels
import Account, { AccountFactory } from '~/core/models/submodels/Account';
import Contact, { ContactFactory } from '~/core/models/submodels/Contact';
import Premise, { PremiseFactory } from '~/core/models/submodels/Premise';
import Premium, { PremiumFactory } from '~/core/models/submodels/Premium';
import QuoteStatus from '~/core/models/enums/QuoteStatus';
//other
import { isLeapDayInPeriod } from '~/core/utils/DateUtils';
import PerCapitaWicUtils from '~/core/utils/PerCapitaWicUtils';
import Broker from '~/core/models/enums/Broker';

// We do not care which timezone user is in right now,
// but the date they entered should be treated as Sydney timezone date
// Backend service also expects Sydney Midnight + 1 minute time of that day
export const dateToService = m => moment.tz(moment(m).format('YYYY-MM-DD'), 'Australia/Sydney').add(1, 'minute');

export class Quote {
	//region Properties

	/** @type {?string} */
	@observable id;

	/** @type {?string} */
	@observable accountId;

	/** @type {?boolean} */
	@observable cspToggle;

	/** @type {?boolean} */
	@observable isCspEditable;

	/** @type {?Array[string]} */
	@observable generalistCsps = [];

	/** @type {?Array[string]} */
	@observable specialistCsps = [];

	/** @type {?Account} */
	@observable account = new Account();

	/** @type {?Premise} */
	@observable primaryPremise = new Premise();

	/** @type {Premise[]} */
	@observable premises = [];

	/** @type {?Contact} */
	@observable primaryContact = new Contact();

	/** @type {Contact[]} */
	@observable contacts = [];

	/** @type {?Premium} */
	@observable premium = null;

	/** @type {?string} */
	@observable status = null;

	/** @type {?date} */
	@observable periodStartDate = moment();

	/** @type {?date} */
	@observable periodEndDate = moment().add(12, 'months');

	/** @type {?string} */
	@observable labourHireCode = null;

	/** @type {?string} */
	@observable brokerOrganization = null;

	/** @type {?string} */
	@observable branch = null;

	/** @type {?string} */
	@observable hasBroker = null;

	/** @type {?string} */
	@observable brokerGroupId = null;

	/** @type {?string} */
	@observable reasonForLowWages = null;

	/** @type {?string} */
	@observable isDateRationalised = null;

	/** @type {?string} */
	@observable previousSchemeAgent = null;

	/** @type {?string} */
	@observable previousPolicyNumber = null;

	/** @type {?boolean} */
	@observable isBusinessInsured = null;

	//endregion

	//region Actions

	/**
	 * Set Quote id
	 * @param value
	 */
	@action
	setId(value) {
		this.id = value || null;
	}
	/**
	 * Set Account id
	 * @param value
	 */
	@action
	setAccountId(value) {
		this.accountId = value || null;
	}
	/**
	 * Set csp toggle
	 * @param {boolean} value
	 */
	@action
	setCspToggle(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError('value must be boolean.');
		}
		this.cspToggle = value;
	}

	/**
	 * Set csp editable
	 * @param {boolean} value
	 */
	@action
	setIsCspEditable(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError('value must be boolean.');
		}
		this.isCspEditable = value;
	}

	/**
	 * Set Generalist csps
	 * @param {boolean} value
	 */
	@action
	setGeneralistCsps(value) {
		this.generalistCsps = value || null
	}

	/**
	 * Set Specialist csps
	 * @param {boolean} value
	 */
	@action
	setSpecialistCsps(value) {
		this.specialistCsps = value || null;
	}
	/**
	 * Set status for the indicative/confirmed premium
	 */
	@action
	setStatus(value) {
		this.status = value || QuoteStatus.DRAFT;
	}

	/**
	 * Set Reason for low wages
	 * @param value
	 */
	@action
	setReasonForLowWages(value) {
		this.reasonForLowWages = value;
	}

	/**
	 * Set Account
	 * @param value
	 */
	@action
	setAccount(value) {
		if (!value) {
			this.account = null;
		} else if (value instanceof Account) {
			this.account = value;
		} else if (typeof value === 'object') {
			this.account = AccountFactory.createFromResponseObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set the Quote primary business
	 * @param {?Premise} value
	 */
	@action
	setPrimaryPremise(value) {
		if (!value) {
			this.primaryPremise = null;
		} else if (value instanceof Premise) {
			this.primaryPremise = value;
		} else if (typeof value === 'object') {
			this.primaryPremise = PremiseFactory.createFromResponseObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set the Quote's other premises
	 * @param value
	 */
	@action
	setPremises(value) {
		if (!value) {
			this.premises = [];
		} else if (Array.isArray(value)) {
			this.premises = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Add additional Premise
	 */
	@action
	addPremise(premise) {
		this.premises = [...this.premises, premise];
	}

	/**
	 * Remove additional Premise
	 */
	@action
	removePremise(premise) {
		this.premises = this.premises.filter(w => {
			return w !== premise;
		});
	}

	/**
	 * Set the Primary Contact
	 */
	@action
	setPrimaryContact(value) {
		if (!value) {
			this.primaryContact = null;
		} else if (value instanceof Contact) {
			this.primaryContact = value;
		} else if (typeof value === 'object') {
			this.primaryContact = ContactFactory.createFromObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Add additional Contact
	 */
	@action
	addContact(contact) {
		this.contacts = [...this.contacts, contact];
	}

	/**
	 * Remove additional Contact
	 */
	@action
	removeContact(contact) {
		this.contacts = this.contacts.filter(w => {
			return w !== contact;
		});
	}

	/**
	 * Set the quote premium
	 * @param value
	 */
	@action
	setPremium(value) {
		if (!value) {
			this.premium = null;
		} else if (value instanceof Premium) {
			this.premium = value;
		} else if (typeof value === 'object') {
			this.premium = PremiumFactory.createFromObject(value);
		} else {
			throw new TypeError('Invalid value', value);
		}
	}

	/**
	 * Set the period start date
	 * @param value
	 */
	@action
	setPeriodStartDate(value) {
		if (!value) {
			this.periodStartDate = null;
		} else if (value instanceof moment) {
			this.periodStartDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the period end date
	 * @param value
	 */
	@action
	setPeriodEndDate(value) {
		if (!value) {
			this.periodEndDate = null;
		} else if (value instanceof moment) {
			this.periodEndDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the labour hire code
	 * @param value
	 */
	@action
	setLabourHireCode(value) {
		this.labourHireCode = value || null;
	}

	/**
	 * Set if quote has a broker
	 * @param {string} value
	 */
	@action
	setHasBroker(value) {
		this.hasBroker = value;
	}

	/**
	 * Set the broker organisation
	 * @param value
	 */
	@action
	setBrokerOrganization(value) {
		this.brokerOrganization = value || null;
	}

	/**
	 * Set the branch
	 * @param value
	 */
	@action
	setBranch(value) {
		this.branch = value || null;
	}

	/**
	 * Set the Broker Group ID
	 * @param {?string} value - The broker group ID
	 */
	@action
	setBrokerGroupId(value) {
		this.brokerGroupId = value || null;
	}

	/**
	 * Set if the date is Rationalised
	 * @param {?string} value
	 */
	@action
	setIsDateRationalised(value) {
		this.isDateRationalised = value || null;
	}

	/**
	 * Set the previous scheme agent
	 * @param {?string} value
	 */
	@action
	setPreviousSchemeAgent(value) {
		this.previousSchemeAgent = value || null;
	}

	@action
	setPreviousPolicyNumber(value) {
		this.previousPolicyNumber = value || null;
	}

	/**
	 * Set if the business has been insured
	 * @param {boolean} value
	 */
	@action
	setIsBusinessInsured(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError('value must be boolean.');
		}
		this.isBusinessInsured = value;
	}

	//endregion

	// region Computed
	/**
	 * Whether Reason for low wages is required
	 * @returns {boolean}
	 */
	@computed
	get isReasonForLowWages() {
		let employeeCount = this.primaryPremise.totalNumberOfEmployees;
		let wageCount = this.primaryPremise.totalWagesFloat;

		if (this.premises.length !== 0) {
			this.premises.map(premise => {
				employeeCount += premise?.totalNumberOfEmployees || 0;
				wageCount += premise?.totalWagesFloat || 0;
			});
		}

		return wageCount / employeeCount < 40000;
	}

	/**
	 * Get total annual wages
	 */
	@computed
	get totalAnnualWages() {
		const days = this.periodEndDate.diff(this.periodStartDate, 'days');
		let wageCount = this.primaryPremise.totalWagesFloat;

		if (this.premises.length !== 0) {
			this.premises.map(premise => {
				wageCount += premise?.totalWagesFloat || 0;
			});
		}

		return isLeapDayInPeriod(this.periodStartDate, this.periodEndDate)
			? (wageCount / days) * 366
			: (wageCount / days) * 365;
	}

	/**
	 * To validate if quote has a perCapitaWic
	 */
	@computed
	get hasPerCapitaWic() {
		if (!this.primaryPremise.hasPerCapitaWic) {
			const _hasPerCapitaWic = this.premises.filter(premise => premise.hasPerCapitaWic);
			if (!_hasPerCapitaWic || _hasPerCapitaWic.length === 0) {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if quote has a WIC that has apprentice details
	 */
	@computed
	get hasApprentices() {
		if (!this.primaryPremise.hasApprentices) {
			const _hasApprentices = this.premises.filter(premise => premise.hasApprentices);
			if (!_hasApprentices || _hasApprentices.length === 0) {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if quote is valid to be created if the total wages is less than $7,500
	 */
	@computed
	get isPolicyValidBaseOnWage() {
		if (this.totalAnnualWages < 7500) {
			if ((this.account && this.account.memberOfGroupCompanies) || this.hasPerCapitaWic || this.hasApprentices) {
				return true;
			} else {
				return false;
			}
		}

		return true;
	}

	/**
	 * To validate if quote has a WIC that has unique taxi plates
	 */
	@computed
	get hasUniqueTaxiPlates() {
		const taxiPlates = this.primaryPremise.taxiPlateList;

		if (this.premises && this.premises.length > 0) {
			this.premises.map(premise => {
				taxiPlates.push(...premise.taxiPlateList);
			});
		}

		return PerCapitaWicUtils.taxiPlatesHaveUniqueValues(taxiPlates);
	}

	/**
	 * Compute if the quote has a broker or not
	 * @return {boolean}
	 */
	@computed
	get isBrokerPresent() {
		return (
			(this.brokerGroupId && this.brokerGroupId !== Broker.DEFAULT_BROKER_ID) ||
			(this.branch && this.branch !== Broker.DEFAULT_BRANCH)
		);
	}

	/**
	 * Use business start trading date
	 * @return {?moment}
	 * */
	@computed
	get effectiveDate() {
		// Only set effective date if there is a previous policy
		if (this.previousPolicyNumber && this.previousSchemeAgent) {
			return this.account && this.account.businessStartTrading ? moment(this.account.businessStartTrading) : moment();
		}
		return null;
	}

	/**
	 * 12 months from effective date
	 * @return {?moment}
	 * */
	@computed
	get expirationDate() {
		// Only set effective date if there is a previous policy
		if (this.previousPolicyNumber && this.previousSchemeAgent) {
			return moment(this.effectiveDate).add(12, 'months');
		}
		return null;
	}

	/**
	 * All wics
	 * @return {?[import('./submodels/Wic').default]}
	 * */
	@computed
	get wics() {
		const premisesWics = this.premises.map(premise => [...premise.wics]).flat();
		return [...this.primaryPremise.wics, ...premisesWics];
	}

	/**
	 * Quick Quote / Create Quote Service Request Object
	 */
	@computed
	get toServiceLayerQuickQuote() {
		let commencementDate;
		if (this.account && this.account.businessStartTrading) {
			commencementDate = this.account.businessStartTrading.toISOString();
		} else {
			commencementDate = moment().toISOString();
		}

		return {
			costCenters: [
				{
					directWages: this.primaryPremise.toServiceLayerWics,
				},
			],
			commencementDate: commencementDate,
			preQualQuestionSets: [
				{
					code: 'WCNIPreQual_icare',
					answers: {
						annualGrossWages: true,
						employeeApprenticeTrainee: false,
						employeePerCapita: null,
						employeeNSW: true,
						groupOfCompanies: !!this.account.isMemberGroupOfCompanies,
					},
				},
			],
			periodStartDate: this.periodStartDate ? dateToService(this.periodStartDate).toISOString() : null,
			periodEndDate: this.periodEndDate ? dateToService(this.periodEndDate).toISOString() : null,
		};
	}

	/**
	 * Full Quote / Update Quote Service Request Object
	 */
	@computed
	get toServiceLayerQuote() {
		return {
			accountId: this.accountId,
			draftData: {
				productCode: 'WC_ICARE', //MANDATORY DEFAULT
				offering: 'WCNI_icare', //MANDATORY DEFAULT
				account: {
					...this.account.toServiceLayer,
					communicationPreference: this.primaryContact.details.communicationPreference,
					emailAddress1: this.primaryContact.details.email,
				},
				policyAddress: this.primaryPremise.address
					? {
						...this.primaryPremise.address.toServiceLayer,
						addressType: 'business', //MANDATORY DEFAULT
					}
					: null,
				periodStartDate: this.periodStartDate ? dateToService(this.periodStartDate).toISOString() : null,
				periodEndDate: this.periodEndDate ? dateToService(this.periodEndDate).toISOString() : null,
				lobs: {
					wcLine: {
						policyPeriod: {
							wcLabourHireCode: this.labourHireCode,
							reasonLowWages: this.reasonForLowWages,
							hasBroker: !!this.branch,
							itcEntitlement: `${this.account.itcEntitlement}`,
							brokerOrganisation: this.brokerOrganization,
							brokerName: this.branch,
							brokerId: this.brokerGroupId,
							groupNumber: this.account.groupNumber,
							policy: {
								primaryLanguage: 'en_US', //DEFAULT
								priorPolicy: [
									{
										wcCarrier: this.previousSchemeAgent,
										policyNumber: this.previousPolicyNumber,
										effectiveDate: this.effectiveDate,
										expirationDate: this.expirationDate,
									},
								],
							},
							claim: [
								{
									policyForce: false, //DEFAULT
								},
							],
							policyLocation: [
								{
									...this.primaryPremise.toServiceLayer,
									primaryLocation: true,
								},
								...this.premises.map(n => n.toServiceLayer),
							],
						},
					},
				},
				accountContact: [this.primaryContact.toServiceLayer, ...this.contacts.map(n => n.toServiceLayer)],
				preQualQuestionSets: [
					{
						code: 'WCNIPreQual_icare',
						answers: {
							annualGrossWages: true,
							employeeApprenticeTrainee: false,
							employeePerCapita: null,
							employeeNSW: true,
							groupOfCompanies: !!this.account.isMemberGroupOfCompanies,
						},
					},
				],
			},
			bindData: {
				...this.premium.toServiceLayerBind,
			},
			quoteData: {
				offeredQuotes: [],
			},
		};
	}

	/**
	 * Bind Policy Service Request Object
	 */
	@computed
	get toServiceLayerBind() {
		const data = this.toServiceLayerQuote;

		data.quoteId = this.id;
		data.bindData = {
			...this.premium.toServiceLayerBind,
			billingAddress: this.primaryPremise.address.toServiceLayer,
		};

		return data;
	}

	/**
	 * Merge all the addresses
	 */
	@computed
	get reservedAddresses() {
		return [this.primaryPremise.address.unstructured, ...this.premises.map(premise => premise.address.unstructured)];
	}

	//endregion
}

export default Quote;
