import {css} from '@emotion/core';

const listReset = css`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export default {
	listReset,
};
