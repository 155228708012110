import {policyId, policyTermNumber} from '~/paths';

/**
 * Replaces policy path parameters
 * @param {string} pattern
 * @param {string|number} id
 * @param {number} termNumber
 *
 * @returns {string}
 */
export function replacePolicyPathParams(pattern, id, termNumber) {
	return pattern.replace(policyId, id).replace(policyTermNumber, termNumber);
}

export default replacePolicyPathParams;
