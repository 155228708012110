import { observable, action, computed } from 'mobx';
import { submitterTypes } from '../forms/CancelPolicyForm'

export default class CancelPolicyModel {

    @observable cancelPolicyReason = null;

    @observable cancelPolicyReasonLabel = null;

    @observable effectiveDate = null;

    @observable submitterTypeCancel = null;

    @observable firstName = null;

    @observable lastName = null;

    @observable position = null;

    @observable companyName = null;

    @observable contactNumber = null;

    @observable emailAddress = null;

    /**
     * Set cancel Policy reason
     * @param {string} -- Cancel reason code
     */
    @action setCancelPolicyReason(value) {
        this.cancelPolicyReason = value || null;
    }

     /**
     * Set cancel Policy reason Label
     * @param {string} -- Cancel reason code
     */
     @action setCancelPolicyReasonLabel(value) {
        this.cancelPolicyReasonLabel = value || null;
    }


    /**
     * Set cancel policy effective date
    * @param {string} -- effective date
     */
    @action setEffectiveDate(value) {
        this.effectiveDate = value || null;
    }

    
    /**
     * Set Submitter Type
    * @param {string} -- Submitter type
     */
    @action setSubmitterType(value) {
        this.submitterTypeCancel = value || null;
    }

    /**
     * Set Fist name
    * @param {string} -- first Name
     */
    @action setFirstName(value) {
        this.firstName = value || null;
    }

    /**
     * Set last name
    * @param {string} -- last Name
     */
    @action setLastName(value) {
        this.lastName = value || null;
    }

    /**
     * Set position
    * @param {string} -- position
     */
    @action setPosition(value) {
        this.position = value || null;
    }

    /**
     * Set Company name
    * @param {string} -- company Name
     */
    @action setCompanyName(value) {
        this.companyName = value || null;
    }

    /**
     * Set contact number
    * @param {string} -- contact Number
     */
    @action setContactNumber(value) {
        this.contactNumber = value || null;
    }

    /**
     * Set email address
    * @param {string} -- email Address
     */
    @action setEmailAddress(value) {
        this.emailAddress = value || null;
    }

    @computed
    get toServiceLayerCancelPolicy() {
        const payload = {
            data: {
                attributes: {
                    cancellationSource: "external_icare",
                    cancelReasonCode: this.cancelPolicyReason,
                    cancelReasonLabel: this.cancelPolicyReasonLabel,
                    effectiveDate: this.effectiveDate,
                    submitter: {
                        isEmployee: this.submitterTypeCancel === submitterTypes.EMPLOYED ? true : false,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        position: this.position,
                        company: this.companyName,
                        email: this.emailAddress,
                        contactNumber: this.contactNumber
                    }
                }
            }
        }
        return payload;
    }
}

export class CancelPolicyFactory {
    /**
     * Create an Wage from a plain old object
     * @param cancelPolicyInfo
     * @returns {?newCancel}
     */
    static createFromFormObject(newCancelPolicy, reasonForCancellationLabel) {
        if (!newCancelPolicy) {
            return null;
        }
        const cancelPolicyModel = new CancelPolicyModel();

        cancelPolicyModel.setCancelPolicyReason(newCancelPolicy.reasonForCancellation);
        cancelPolicyModel.setCancelPolicyReasonLabel(reasonForCancellationLabel);
        cancelPolicyModel.setEffectiveDate(newCancelPolicy.policyCancellationEffectiveDate);
        cancelPolicyModel.setSubmitterType(newCancelPolicy.submitterTypeCancel);
        cancelPolicyModel.setFirstName(newCancelPolicy.firstName);
        cancelPolicyModel.setLastName(newCancelPolicy.lastName);
        cancelPolicyModel.setPosition(newCancelPolicy.position);
        cancelPolicyModel.setCompanyName(newCancelPolicy.companyName);
        cancelPolicyModel.setContactNumber(newCancelPolicy.contactNumber);
        cancelPolicyModel.setEmailAddress(newCancelPolicy.emailAddress);
        return cancelPolicyModel;
    }
}
