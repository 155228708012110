import React from 'react';
import T from 'prop-types';
import request from 'superagent';

import Spinner from '@icare/components/lib/Spinner';

let _config = null;

export const getConfig = () => {
	return _config;
};

export default class ConfigProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isBusy: true,
			appComponent: null,
		};
	}

	static propTypes = {
		siteName: T.string.isRequired,
		portalApiBaseUrl: T.string.isRequired,
		children: T.oneOfType([T.node, T.func]).isRequired,
	};

	async componentDidMount() {
		const {portalApiBaseUrl, siteName: site, children} = this.props;

		try {
			const response = await request
				.get(`${portalApiBaseUrl}/api/spaconfig`)
				.set('Content-Type', 'application/json; charset=utf-8')
				.query({site});
			_config = response.body;
		} catch (err) {
			console.error(err);
			console.log(err.stack);
		}

		let appComponent = null;

		if (typeof children === 'function') {
			appComponent = await children().then(cmp => cmp.default);
		} else {
			appComponent = children;
		}

		this.setState({
			isBusy: false,
			appComponent,
		});
	}

	render() {
		const {isBusy, appComponent: App} = this.state;
		return <Spinner active={isBusy}>{App && <App />}</Spinner>;
	}
}
