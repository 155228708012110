import {observable, computed, action} from 'mobx';
import moment from 'moment';

import {getActionLabel} from '~/core/utils/PolicyActionTypeUtils';
import ActionTypes from '../enums/ActionType';

export default class Action {
	/** @type {?string} */
	@observable actionType = null;

	/** @type {?moment.Moment} */
	@observable actionDueDate = null;

	/**
	 * Set action type
	 *
	 * @param {?string} value
	 */
	@action
	setActionType(value) {
		this.actionType = value || null;
	}

	/**
	 * Set action due date
	 *
	 * @param {?moment.Moment} value
	 */
	@action
	setActionDueDate(value) {
		if (typeof value === 'undefined' || value === null) {
			this.actionDueDate = null;
		} else if (value instanceof moment) {
			this.actionDueDate = value;
		} else {
			throw new TypeError();
		}
	}

	@computed
	get isRenewalDue() {
		return this.actionType.toUpperCase() === ActionTypes.RENEWAL_DUE;
	}

	@computed
	get isPaymentDue() {
		return this.actionType.toUpperCase() === ActionTypes.PAYMENT_DUE;
	}

	@computed
	get isCardExpiring() {
		return this.actionType.toUpperCase() === ActionTypes.CREDIT_CARD_EXPIRES;
	}

	@computed
	get isPaymentOverdue() {
		return this.actionType.toUpperCase() === ActionTypes.PAYMENT_OVERDUE;
	}

	@computed
	get isCancelled() {
		const actionType = this.actionType.toUpperCase();
		return (
			actionType === ActionTypes.CANCELLED ||
			actionType === ActionTypes.CANCELLATION ||
			actionType === ActionTypes.CANCELLATION_DATE
		);
	}

	@computed
	get isHindsightsDue() {
		return this.actionType.toUpperCase() === ActionTypes.HINDSIGHTS_DUE;
	}

	@computed
	get isPremiumPending() {
		return this.actionType.toUpperCase() === ActionTypes.PREMIUM_PENDING;
	}

	/**
	 * @returns {?string}
	 */
	@computed
	get actionLabel() {
		return getActionLabel(this.actionType);
	}
}

export class ActionFactory {
	static createFromResponseObject(o) {
		if (!o || !o.data || !o.data.attributes) {
			return null;
		}

		const {entries} = o.data.attributes;

		const actionArray = [];
		if (entries && entries.length) {
			const actions = entries[0].actions;
			actions.forEach(action => {
				const a = new Action();
				a.setActionType(action.actionType);
				a.setActionDueDate(moment(action.actionDueDate));
				actionArray.push(a);
			});
		}

		return actionArray;
	}

	static createFromObj(o) {
		const a = new Action();
		a.setActionType(o.actionType);
		a.setActionDueDate(moment(o.actionDueDate));
		return a;
	}
}
