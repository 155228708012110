import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import {EmployeeUserFactory} from '~/core/models/submodels/EmployeeUser';

export default class EmployeeUserServices {
	static async searchUsers(page, pageSize, criteria = {}) {
		const payload = {
			data: {
				type: 'DelegatedEmployerSearch',
				attributes: {
					criteria,
					params: {
						page,
						pageSize,
					},
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.employerUserSearch}/v2/portal/workersInsurance/me/processes/searchDelegatedEmployer`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data;
	}

	static async getUser(identityId) {
		const response = await request
			.get(`${BASE_URL.employerUserGet}/v2/portal/workersInsurance/me/users/${identityId}`)
			.use(requestBearer)
			.use(authBearer);

		return EmployeeUserFactory.createFromObject(response.body.data);
	}

	static async registerUser(employeeUser) {
		const payload = {
			data: {
				type: 'DelegatedEmployerRegistration',
				attributes: employeeUser.toServiceLayerRegisterUser,
			},
		};

		const response = await request
			.post(`${BASE_URL.employerUserRegister}/v2/portal/workersInsurance/me/processes/registerDelegatedEmployer`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data;
	}

	static async updateUser(employeeUser) {
		const payload = {
			data: {
				type: 'PolicyAuthorisationBatch',
				attributes: employeeUser.toServiceLayerUpdateUserAuthorisations,
			},
		};

		const response = await request
			.post(`${BASE_URL.employerUserUpdate}/v2/portal/workersInsurance/me/policyAuthorisations/batch`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data;
	}

	/**
	 * Reset password for employer users
	 */
	static async resetPassword(employeeUser) {
		const payload = {
			data: {
				type: 'UserPasswordReset',
				attributes: employeeUser.toServiceLayerResetPassword,
			},
		};

		const response = await request
			.post(
				`${BASE_URL.employerUserResetPassword}/v2/portal/workersInsurance/me/users/${employeeUser.id}/resetPassword`,
			)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response;
	}
}
