import request from 'superagent';
import uuidv4 from 'uuid/v4';
import { BASE_API_URL } from '~/config/index';
import { successResponse } from './mocks/PolicyWageDeclarationServiceMock';

/**
 * PolicyWageDeclarationService
 *
 */
export default class PolicyWageDeclarationService {
	static async submitRequest(policyModel, clientId, clientSecret, token) {
		const data = policyModel.toServiceLayerWageDeclaration;
		const policyId = policyModel.policyNumber;
		const policyPeriodId = policyModel.policyPeriodId;
		const payload = {
			data,
		};
		const url = `${BASE_API_URL}/v2/portal/workersInsurance/policies/${policyId}/policyPeriod/${policyPeriodId}/wage/submit`;
		if (process.env.NODE_ENV === 'development') {
			const apiResponse = successResponse;
			if (apiResponse?.data) {
				return {text: JSON.stringify(apiResponse)};
			} else throw apiResponse;
		} else {
			return request
				.post(`${url}`)
				.type('form')
				.set({
					Accept: 'application/vnd.api+json',
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/vnd.api+json',
					'X-TrackingID': `portalRequest-${uuidv4()}`,
					'X-InitialSystem': 'WIPolicyPortal',
					'X-Client-ID': `${clientId}`,
					'X-Client-Secret': `${clientSecret}`,
				})
				.set('X-Token', token)
				.send(payload);
		}
	}
}
