import {observable} from 'mobx';

/**
 * Async status
 */
export class AsyncStatus {
	/**
	 * True if network activity in progress
	 * @property {?boolean}
	 */
	@observable isBusy = false;

	/**
	 * True if network activity was succesful
	 * @property {?boolean}
	 */
	@observable isSuccess = false;

	/**
	 * Error of the last network attempt
	 * @property {?object}
	 */
	@observable error = null;

	/**
	 * API Error Description
	 * @property {?object}
	 */
	@observable errDesc = null;
}

export default AsyncStatus;
