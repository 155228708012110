// Enums
import YesNoUnknown from '~/core/models/enums/YesNoUnknown';

/**
 * This function converts an address object into a string. Format returned is [address address2 address3 locality province postalCode country]
 * @param {object}
 * @returns {string}
 */
export function addressToString(obj) {
	if (!obj) {
		return '';
	}

	let str = '';

	// Make an array of indexes. This array will be used to build address in a particular order
	const addressIndexes = [
		'addressLine1',
		'addressLine2',
		'addressLine3',
		'locality',
		'city',
		'suburb',
		'province',
		'state',
		'postalCode',
		'postCode',
		'country',
	];

	// Create a map of address for easy searching
	const addressMap = new Map(Object.entries(obj));

	// Loop through all the addresses
	for (const addressIndex of addressIndexes) {
		if (addressMap.get(addressIndex) && addressMap.get(addressIndex) !== '') {
			str = str + (str === '' ? '' : ', ') + addressMap.get(addressIndex);
		}
	}

	// Return
	return str;
}

/**
 * Adds Char at index in string
 * @param {string} str string (whole string)
 * @param {string} sub string (value to insert)
 * @param {number} position string (where we want to add a character)
 * @returns
 */
export const addCharacterAt = (str, sub, position) => `${str.slice(0, position)}${sub}${str.slice(position)}`;

/**
 * Calculate GST Registration value
 * @param {boolean} val = gstRegistration
 * @returns {string} for the display value of 'Are you registered for GST?'
 */
export const setGstRegistrationValue = val => {
	return val ? YesNoUnknown.YES.label : YesNoUnknown.NO.label;
};

export default addressToString;
