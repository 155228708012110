import PaymentMethod from '~/core/models/enums/PaymentMethod';
import {SHOW_PAYMENT_METHOD_BPAY, SHOW_PAYMENT_METHOD_DIRECTDEBIT, SHOW_PAYMENT_METHOD_DEBITCREDITCARD} from '~/config';

export default Object.freeze([
	...(SHOW_PAYMENT_METHOD_DIRECTDEBIT
		? [
				{
					id: PaymentMethod.DIRECT_DEBIT,
					label: 'Direct Debit',
					value: PaymentMethod.DIRECT_DEBIT,
				},
		  ]
		: []),
	...(SHOW_PAYMENT_METHOD_BPAY
		? [
				{
					id: PaymentMethod.BPAY,
					label: 'BPAY',
					value: PaymentMethod.BPAY,
				},
		  ]
		: []),
	...(SHOW_PAYMENT_METHOD_DEBITCREDITCARD
		? [
				{
					id: PaymentMethod.DEBIT_CREDIT_CARD,
					label: 'Debit/Credit Card',
					value: PaymentMethod.DEBIT_CREDIT_CARD,
				},
		  ]
		: []),
]);
