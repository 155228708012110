import fieldTypes from '@icare/forms/lib/constants/ConfigFormTypes';
import fieldSizes from '@icare/forms/lib/constants/ConfigFormSizes';
import moment from 'moment';
import validators from '@icare/forms/lib/constants/ConfigFormValidators';

export const submitterTypes = Object.freeze({
	EMPLOYED: 'employed',
	ON_BEHALF: 'on_behalf',
});


export function cancelPolicyForm(policyContent,policyStartDate,policyEndDate,isRenewalPolicy = false) {
	const CancelPolicyForm = {
		id: 'idForm',
		sections: [
			{
				id: 'formSection',
				fields: [
					{
						id: 'reasonForCancellation',
						label: 'Reason for cancellation',
						type: fieldTypes.SELECT,
						placeholder: "Please select",
						data: {
							width: fieldSizes.LARGE,
						},
						options: [
							{
								id: 'ceasedtotrade_icare',
								label: 'Ceased trading in NSW',
								value: 'ceasedtotrade_icare',
							},
							{
								id: 'businesssold_icare',
								label: 'Business has been sold',
								value: 'businesssold_icare',
							},
							{
								id: 'ceasedtoemploy_icare',
								label: 'Ceased to employ',
								value: 'ceasedtoemploy_icare',
							},
							{
								id: 'insuringelsewhere_icare',
								label: 'Insured Elsewhere',
								value: 'insuringelsewhere_icare',
							},
							{
								id: 'sold_plates',
								label: 'Sold plates',
								value: 'sold_plates',
							},
							{
								id: 'exemptemployer_icare',
								label: 'Exempt employer',
								value: 'exemptemployer_icare',
							},
						],
						required: true,
					},
					{
						id: 'policyStartDate',
						label: 'Policy Start Date',
						type: fieldTypes.HIDDEN,
						default:moment(policyStartDate,'DD MMM YYYY').format('DD/MM/YYYY'),
						data: {
							width: fieldSizes.LARGE,
						},
						validators: [],
						required: true,
					},
					{
						id: 'policyEndDate',
						label: 'Policy End Date',
						type: fieldTypes.HIDDEN,
						default:moment(policyEndDate,'DD MMM YYYY').format('DD/MM/YYYY'),
						data: {
							width: fieldSizes.LARGE,
						},
						validators: [],
						required: true,
					},
					{
						id: 'isRenewalPolicy',
						label: 'Policy Renewal',
						type: fieldTypes.HIDDEN,
						default:isRenewalPolicy,
						data: {
							width: fieldSizes.LARGE,
						},
						validators: [],
						required: true,
					},
					{
						id: 'policyCancellationEffectiveDate',
						label: 'Cancel my policy from this date',
						type: fieldTypes.DATE_PICKER,
						data: {
							width: fieldSizes.LARGE,
							minDate: moment(policyStartDate,'DD MMM YYYY'),
							maxDate: moment(policyEndDate,'DD MMM YYYY'),
						},
						validators: [{
							type: 'isValidDate',
							data: {
								message: 'Please enter a valid date',
							},
						},
						{
							type: 'isDateEqualToOrAfter',
							data: {
								message: policyContent.isDateEqualToOrAfterCancellationError,
								fieldID:'formSection__policyStartDate'
							},
						},
						{
							type: 'isDateEqualToOrBefore',
							data: {
								message: policyContent.isDateEqualToOrBeforeCancellationError,
								fieldID: 'formSection__policyEndDate' 
							},
						},
						{
							type: 'isDateEqual',
							data: {
								message: policyContent.isCeasedToEmployError,
								fieldID: 'formSection__policyEndDate' ,
								condition : [
									{
										fieldID : 'formSection__reasonForCancellation',
										value : 'ceasedtoemploy_icare'
									},
									{
										fieldID : 'formSection__isRenewalPolicy',
										value : false
									},
								]
							},
						},
						{
							type: 'isDateEqual',
							data: {
								message: policyContent.isRenewalPolicyDateError,
								fieldID: 'formSection__policyStartDate' ,
								condition : [
									{
										fieldID : 'formSection__isRenewalPolicy',
										value : true
									},
								]
							},
						},
					],
						required: true,
					},
					{
						id: 'submitterTypeCancel',
						type: fieldTypes.RADIO,
						required: true,
						label: 'Who is submitting Cancellation?',
						options: [
							{
								id: 'business',
								label: 'The business',
								value: submitterTypes.EMPLOYED,
							},
							{
								id: 'representative',
								label: 'Representative on behalf of the business (eg. broker, accountant)',
								value: submitterTypes.ON_BEHALF,
							},
						]
					},
					{
						id: 'firstName',
						type: fieldTypes.TEXT,
						required: true,
						requiredMessage: 'This field is required',
						label: 'First name',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.EMPLOYED,
										},
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
						validators: [
							{
								type: validators.IS_LENGTH,
								data: {
									message: 'Please enter no more than 20 characters.',
									options: {
										max: 20,
									},
								},
							},
						],

					},
					{
						id: 'lastName',
						type: fieldTypes.TEXT,
						required: true,
						requiredMessage: 'This field is required',
						label: 'Last name',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.EMPLOYED,
										},
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
						validators: [
							{
								type: validators.IS_LENGTH,
								data: {
									message: 'Please enter no more than 20 characters.',
									options: {
										max: 20,
									},
								},
							},
						],
					},
					{
						id: 'position',
						type: fieldTypes.TEXT,
						label: 'Position',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.EMPLOYED,
										},
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
					},
					{
						id: 'companyName',
						type: fieldTypes.TEXT,
						requiredMessage: 'This field is required',
						label: 'Company name',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
					},
					{
						id: 'contactNumber',
						type: fieldTypes.TEXT,
						required: true,
						requiredMessage: 'This field is required',
						label: 'Contact number',
						placeholder: 'E.g 02 9999 1111',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.EMPLOYED,
										},
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
						validators: [
							{
								type: validators.IS_LENGTH,
								data: {
									message: 'Your phone number should be between 8 and 15 characters',
									options: {
										min: 6,
									},
								},
							},
							{
								type: validators.IS_LENGTH,
								data: {
									message: 'Your phone number should be between 8 and 15 characters',
									options: {
										max: 15,
									},
								},
							},
							{
								type: validators.IS_PHONE,
								data: {
									message: 'Please enter only numbers 0-9 and special characters ()+',
								},
							},
						],
					},
					{
						id: 'emailAddress',
						type: fieldTypes.EMAIL,
						required: true,
						requiredMessage: 'This field is required',
						label: 'Email address',
						width: 's',
						rules: [
							{
								type: 'show-when',
								data: {
									or: [
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.EMPLOYED,
										},
										{
											id: 'formSection__submitterTypeCancel',
											value: submitterTypes.ON_BEHALF,
										},
									]
								}
							},
						],
						validators: [
							{
								type: validators.IS_LENGTH,
								data: {
									message: 'Please enter no more than 60 characters.',
									options: {
										max: 60,
									},
								},
							},
							{
								type: validators.IS_EMAIL,
								data: {
									message: 'Please enter a valid email address.',
								},
							},
						],
					},
					{
						id: 'declarationCheckEmployed',
						type: fieldTypes.CHECKBOX_GROUP,
						label: 'Your declaration and undertaking',
						required: true,
						requiredMessage: 'This field is required',
						options: [
							{
								id: '_declarationCheckEmployed',
								label: 'xI agree to the terms and conditions',
							},
						],
						rules: [
							{
								type: 'show-when',
								data: {
									id: 'formSection__submitterTypeCancel',
									value: submitterTypes.EMPLOYED,
								},
							},
							{
								type: 'mandatory-when',
								data: {
									id: 'formSection__submitterTypeCancel',
									value: submitterTypes.EMPLOYED,
								},
							},
						],
					},
					{
						id: 'declarationCheckBehalf',
						label: 'Your declaration and undertaking',
						type: fieldTypes.CHECKBOX_GROUP,
						required: true,
						requiredMessage: 'This field is required',
						rules: [
							{
								type: 'show-when',
								data: {
									id: 'formSection__submitterTypeCancel',
									value: submitterTypes.ON_BEHALF,
								},
							},
							{
								type: 'mandatory-when',
								data: {
									id: 'formSection__submitterTypeCancel',
									value: submitterTypes.ON_BEHALF,
								},
							},
						],
					},
					{
						id: 'agreeTerms',
						type: fieldTypes.CHECKBOX_GROUP,
						label: 'Your declaration and undertaking',
						required: true,
						requiredMessage: 'This field is required',
						options: [
							{
								id: '_agreeTerms',
								label: "I, agree I am authorised to make this choice on behalf of the employer.Penalties may apply for providing false, misleading or incomplete information.",
							},
						],
					},
				],
			},
		],
	}
	return CancelPolicyForm;
};


export default cancelPolicyForm;
