import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import PolicyFactory from '~/core/factories/PolicyFactory';
import {PolicyHistoryFactory} from '~/core/models/PolicyHistory';
import {PolicySummaryFactory} from '~/pods/dashboard/models/PolicySummary';
import {SHOW_PREMIUM_PROCEED_ERRORS} from '~/config';
import {ContactDetailsFactory} from '../models/submodels/ContactDetails';

export default class PolicyServices {
	// Bind Policy
	static async bindPolicy(quote, isAuth = false) {
		const payload = {
			data: {
				type: 'Policy',
				attributes: quote.toServiceLayerBind,
			},
		};

		const url = isAuth
			? '/v2/portal/workersInsurance/policies/me/policy'
			: '/v2/portal/workersInsurance/policies/policy';

		let response;
		if (SHOW_PREMIUM_PROCEED_ERRORS) {
			response = await request
				.post(`${BASE_URL.policyBind}${url}`)
				.send(payload)
				.use(requestBearer)
				.use(authBearer)
				.catch(err => {
					err.isError = true;
					return err;
				});

			if (response.isError) {
				throw response;
			}
		} else {
			// original code prior to feature flag
			response = await request.post(`${BASE_URL.policyBind}${url}`).send(payload).use(requestBearer).use(authBearer);
		}

		return PolicyFactory.createPolicyFromObject(response.body);
	}

	// Update Policy
	static async updatePolicy(policy) {
		const payload = {
			data: {
				type: 'Policy',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};
		const response = await request
			.patch(`${BASE_URL.policyUpdate}/v2/portal/workersInsurance/policies/me/${policy.id}`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);
		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	/**
	 * Get policy details
	 * @param {string|number} policyId
	 * @param {number} termNumber
	 */
	static async getPolicy(policyId, termNumber) {
		const response = await request
			.get(`${BASE_URL.policyGet}/v2/portal/workersInsurance/policies/me/${policyId}`)
			.query({termNumber})
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body);
	}

	// Policy Search by policy number
	static async getPolicyActions(policyNumber, termNumber) {
		const payload = {
			data: {
				type: 'PoliciesSearch',
				attributes: {
					params: {
						page: 1,
						pageSize: 10,
						orderBy: [
							{
								fieldName: '',
							},
						],
					},
					criteria: {
						policyNumber,
					},
				},
			},
		};
		const response = await request
			.post(`${BASE_URL.policySearch}/v2/portal/workersInsurance/policies/me/summaries/search`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		if (response.body.data.attributes.entries[0].termNumber !== termNumber) {
			throw Error('Term number does not match');
		}

		const policyPending = response.body.data.attributes.entries[0].policyPending;

		return [PolicyFactory.createPolicyActionFromResponse(response.body), policyPending];
	}

	// Get Policy History
	static async getPolicyHistory(policyId) {
		const response = await request
			.get(`${BASE_URL.policyBind}/v2/portal/workersInsurance/policies/me/${policyId}/history`)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data.attributes.map(n => PolicyHistoryFactory.createFromObject(n));
	}

	// Search function for all policies
	static async searchPolicy(payload) {
		const response = await request
			.post(`${BASE_URL.policySearch}/v2/portal/workersInsurance/policies/me/summaries/search`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicySummaryFactory.createPolicySummaryFromResponse(response.body);
	}

	// Cancel Policy
	static async cancelPolicy(policy) {
		const payload = policy.toServiceLayerCancelPolicy;
		const response = await request
			.post(`${BASE_URL.policyCancel}/v2/portal/workersInsurance/policies/me/cancel`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);
		return response;
	}

	// Rebind Policy
	static async rebindPolicy(newQuoteId, policy) {
		const payload = {
			data: {
				type: 'UpdatePolicy',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};
		const response = await request
			.post(`${BASE_URL.policyRebind}/v2/portal/workersInsurance/quotes/me/${newQuoteId}/issueChangePolicy`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	// Policy Renewal
	static async getPolicyRenewal(policyId) {
		const response = await request
			.get(`${BASE_URL.policyRenewalGet}/v2/portal/workersInsurance/policies/me/${policyId}/renewals`)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body);
	}

	// Policy Renewal Update
	static async updatePolicyRenewal(policy) {
		const payload = {
			data: {
				type: 'PolicyRenewal',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};

		const response = await request
			.post(`${BASE_URL.policyRenewalUpdate}/v2/portal/workersInsurance/policies/me/${policy.id}/renewals`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	// Bind Renewal Submission
	static async bindRenewal(policy) {
		const payload = {
			data: {
				type: 'PolicyRenewal',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};

		const response = await request
			.post(`${BASE_URL.policyBindRenewal}/v2/portal/workersInsurance/policies/me/${policy.id}/issueRenewalPolicy`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	// Bind SRP Submission
	static async bindSRP(policy) {
		const payload = {
			data: {
				type: 'PolicyRenewal',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};

		const response = await request
			.post(`${BASE_URL.policyBindSRP}/v2/portal/workersInsurance/policies/me/${policy.id}/issueRenewalChange`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	static async getPolicyStatistics() {
		const response = await request
			.get(`${BASE_URL.policyStatistics}/v2/portal/workersInsurance/me/policyStatistics`)
			.use(requestBearer)
			.use(authBearer);

		return response.body.data.attributes;
	}

	// Update Policy Hindsight
	static async updatePolicyHindsight(policy) {
		const payload = {
			data: {
				type: 'PolicyHindsight',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};
		const response = await request
			.patch(`${BASE_URL.hindsightUpdate}/v2/portal/workersInsurance/policies/me/${policy.id}`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);
		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	// Rebind Hindsight
	static async rebindPolicyHindsight(policy) {
		const payload = {
			data: {
				type: 'PolicyHindsight',
				id: policy.id,
				attributes: policy.toServiceLayerPolicy,
			},
		};

		const response = await request
			.post(`${BASE_URL.hindsightRebind}/v2/portal/workersInsurance/policies/me/${policy.id}/issueRenewalChange`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return PolicyFactory.createPolicyFromObject(response.body, policy);
	}

	/**
	 * Update policy primary contact details
	 *
	 * @param {import('../models/Policy').default} policy
	 * @param {import('../models/submodels/ContactDetails').default} contactDetails
	 */
	static async updatePolicyPrimaryContactDetails(policy, contactDetails) {
		const payload = {
			data: {
				type: 'PolicyPrimaryContactDetails',
				attributes: {
					...policy.primaryContact.toServiceLayer,
					personContact: contactDetails.toServiceLayer,
				},
			},
		};

		const response = await request
			.post(`${BASE_URL.policyUpdate}/v2/portal/workersInsurance/policies/me/${policy.id}/updateContactDetails`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return ContactDetailsFactory.createFromObject(response.body.data.attributes.accountContact.personContact);
	}
}
