import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import Policy from '~/core/models/Policy';
import PolicyServices from '~/core/services/PolicyServices';

/**
 * The Policy Renewal Store
 */
export default class RenewPolicyStore {
	//region Properties

	/**
	 * Main Policy instance
	 * @type {Policy}
	 */
	@observable policy = null;

	/**
	 * Is SRP flow
	 * @type {boolean}
	 */
	@observable isSrpFlow = false;

	/**
	 * Sync status of retrieving the Policy via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PolicyServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * New Quote Store object
	 */
	@action
	initPolicy(policy = null) {
		this.policy = policy || new Policy();
	}

	/**
	 * Set policy
	 */
	@action
	setPolicy(policy) {
		this.policy = policy;
	}

	/**
	 * Set SRP flow
	 */
	@action
	setIsSrpFlow(value) {
		this.isSrpFlow = !!value;
	}

	/**
	 * Set policy
	 */
	@action
	clearPolicy() {
		this.policy = null;
		this.isSrpFlow = false;
	}

	/**
	 * Get Policy Renewal Details
	 */
	@action
	async getPolicyRenewal(policyId, termNumber = null) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const policy =
				this.isSrpFlow && termNumber
					? await this.api.getPolicy(policyId, termNumber)
					: await this.api.getPolicyRenewal(policyId);
			runInAction(() => {
				this.setPolicy(policy);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Update Policy Renewal
	 */
	@action
	async updatePolicyRenewal(policy) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const p = this.isSrpFlow ? await this.api.updatePolicy(policy) : await this.api.updatePolicyRenewal(policy);
			runInAction(() => {
				this.setPolicy(p);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Bind Renewal Submission
	 */
	@action
	async bindRenewal(policy) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const p = this.isSrpFlow ? await this.api.bindSRP(policy) : await this.api.bindRenewal(policy);
			runInAction(() => {
				this.setPolicy(p);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	//endregion
}
