import { observable, action, runInAction, computed } from 'mobx';
import PolicyWagePrelimInfoService from '~/core/services/PolicyWagePrelimInfoService';
import PolicyWageDeclarationService from '~/core/services/PolicyWageDeclarationService';
import PolicyWageAdditionalInfoService from '~/core/services/PolicyWageAdditionalInfoService';
import ChangeClaimsProviderServices from '~/core/services/ChangeClaimsProviderServices';
import CancelPolicyServices from '~/core/services/CancelPolicyServices';
import { parseJSON } from '~/core/utils/JsonUtility';
import AsyncStatus from '~/core/models/AsyncStatus';
import workerTypes from '~/core/models/enums/WorkerTypes';
import { PrelimInfoFactory } from '../Models/PolicyAuth';
import { PolicyFactory } from '../Models/PolicyModel';
import { AsbestosFactory } from '../Models/Asbestos';
import { ContractorWagesFactory } from '../Models/ContractorWages';
import { TaxiFactory } from '../Models/TaxiModel';
import { SubmitterFactory } from '../Models/SubmitterInfo';
import { CSPFactory } from '../Models/CSPModel';
import { CancelPolicyFactory } from '../Models/CancelPolicyModel';
import policyTransactionType from '../constants/policyTransactionType';
import { employerCategoryType } from '../constants/policyConstants';

export default class PolicyWageDeclarationStore {
	//region Properties

	/**
	 * Data item
	 * @type {?type}
	 */
	@observable preliminaryInfo = null;
	@observable submitterInfo = {
		companyName: null,
		contactNumber: null,
		emailAddress: null,
		firstName: null,
		lastName: null,
		position: null,
		submitterType: null,
		transactionType: null,
		reasonForChange: null,
		policyStartDate: null,
		policyEndDate: null,
		totalNoOfEmployees: null,
		totalWages: null,
		totalNoOfUnits: null,
		employerName: null,
	};
	@observable preliminaryModel = null;
	@observable policyModel = null;
	@observable wageDeclarationActivities = [];
	@observable premiumInfo = {
		previousPremium: 0,
		revisedPremium: 0,
		changeInCost: 0,
		error: false,
	};
	@observable retryAttempts = 0;
	@observable isRedirectWithError = true;
	@observable hasUnderWriterError = false;
	@observable hasDuplicateTransaction = false;
	@observable formObjs = [];
	@observable userAction = {
		ADD: 0,
		EDIT: 0,
	};
	@observable cspModel = null;

	@observable cancelPolicyModel = null;
	/**
	 * Sync status of retrieving the reference data via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();
	@observable isEmployerEligibleForCSPChoice = false;
	@observable policyType = 'Standard';
	@observable showSection = 'default';

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 */
	constructor(rootStore = null) {
		this.rootStore = rootStore;
	}

	@computed
	get totalWages() {
		return this.policyModel && this.policyModel.totalWages;
	}

	@computed
	get totalEmployees() {
		return this.policyModel && this.policyModel.totalEmployees;
	}

	getUnitsorPlates(wagesItem) {
		let Units = 0;

		if (!wagesItem.isTaxi) {
			Units =
				wagesItem.unitCount != null && wagesItem !== undefined && wagesItem.unitCount != null
					? parseInt(wagesItem.unitCount, 10)
					: 0;
		} else {
			Units =
				wagesItem.platesCount != null && wagesItem !== undefined && wagesItem.platesCount != null
					? parseInt(wagesItem.platesCount, 10)
					: 0;
		}

		return Units;
	}

	@computed
	get totalUnits() {
		return this.wageDeclarationActivities
			.filter(wagesItem => wagesItem.isPercapita)
			.map(wagesItem => this.getUnitsorPlates(wagesItem))
			.reduce((a, b) => a + b, 0);
	}

	@action
	setSection(value) {
		this.showSection = value;
	}

	@action
	deletewWageActivity(wageID) {
		this.policyModel.wics.find(o => {
			if (o.id === wageID) {
				o.isRemoved = true;
			}
		});
		if (this.policyModel && this.policyModel.wics.length) {
			const removedPolicy = this.policyModel.wics.filter(policy => policy.isRemoved);
			this.policyModel.submitWics.push(removedPolicy[0]);

			this.policyModel.wics = this.policyModel.wics.filter(policy => !policy.isRemoved);
			this.getWageActivities();
		}
	}

	@action
	pushNewWageActivity(wage) {
		if (this.policyModel && wage) {
			this.policyModel.wics.push(wage);
			this.getWageActivities();
		}
	}

	@action
	replaceWageActivity(wage, index) {
		if (this.policyModel && wage) {
			this.policyModel.wics[index] = wage;
			this.getWageActivities();
		}
	}

	@action
	updateNewPercapitaWageActivity(wage, formData) {
		for (const key in formData) {
			if (formData.hasOwnProperty(key)) {
				const fieldValue = formData[key];
				if (key.includes('formSection')) {
					wage.setBusinessDescription(fieldValue.businessDescription);
					if (wage.isTaxi) {
						if (fieldValue.plateNumberBlock) {
							const plates = fieldValue.plateNumberBlock.map(TaxiFactory.createFromFormObject);
							wage.setTaxiModel(plates);
						}
					} else {
						wage.setUnits(fieldValue.unitCount);
					}
					break;
				}
			}
		}
	}

	@action
	updateNewWageActivity(wage, formData) {
		const formSectionKey = Object.keys(formData).find(key => key.includes('formSection'));
		if (formSectionKey) {
			const fieldValue = formData[formSectionKey];
			wage.setBusinessDescription(fieldValue.businessDescription);
			wage.setEmployees(fieldValue.employees);
			wage.setWages(fieldValue.wages);
			wage.setHasApprentices(fieldValue.workers.includes(workerTypes.APPRENTICES));
			wage.setHasAsbestos(fieldValue.workers.includes(workerTypes.ASBESTOS_WORKER));
			wage.setHasContractors(fieldValue.workers.includes(workerTypes.CONTRACTORS));
		}

		for (const key in formData) {
			if (formData.hasOwnProperty(key)) {
				const fieldValue = formData[key];
				if (key.includes('apprentice') && formData && fieldValue) {
					if (wage.hasApprentices) {
						wage.setApprenticeNumber(fieldValue.apprenticeNumber);
						wage.setApprenticeWages(fieldValue.apprenticeWages);
						wage.setNationalQualificationCode(fieldValue.nationalQualificationCode);
					} else {
						wage.setApprenticeNumber(null);
						wage.setApprenticeWages(null);
						wage.setNationalQualificationCode(null);
					}
				} else if (key.includes('asbestos') && formData && fieldValue) {
					if (wage.hasAsbestos) {
						wage.setAsbestosObject([AsbestosFactory.createFromServiceObject(fieldValue)]);
					} else {
						wage.setAsbestosObject([]);
					}
				} else if (key.includes('contractor')) {
					if (wage.hasContractors) {
						wage.setContractorWages(
							fieldValue.contractorWage.map(value => ContractorWagesFactory.createFromServiceObject(value)),
						);
					} else {
						wage.setContractorWages([]);
					}
				}
			}
		}
	}

	/**
	 * Action
	 */
	@action
	getWageActivities() {
		this.wageDeclarationActivities = this.policyModel?.wics?.filter(policy => !policy?.isRemoved);
		return this.wageDeclarationActivities;
	}

	@action
	setPreliminaryInfo(preliminaryInfo = null) {
		if (preliminaryInfo?.policyTransactionType !== undefined && preliminaryInfo?.policyTransactionType !== null) {
			// Update the details with exiting policy info for back button changes
			this.preliminaryInfo = { ...this.preliminaryInfo, ...preliminaryInfo };
		} else {
			this.preliminaryInfo = preliminaryInfo;
		}
		this.preliminaryModel = PrelimInfoFactory.createFromFormObject(this.preliminaryInfo);
	}

	@action
	setNewCSPs(newCsps = null) {
		if (!!newCsps) {
			this.cspModel = CSPFactory.createFromFormObject(newCsps);
		}
	}

	@action
	setNewCancelPolicy(newCancelPolicy = null, reasonForCancellationLabel = null) {
		if (!!newCancelPolicy) {
			this.cancelPolicyModel = CancelPolicyFactory.createFromFormObject(newCancelPolicy, reasonForCancellationLabel);
		}
	}

	@action
	setClearErrorStatus() {
		this.asyncStatus.error = null;
	}

	getPremiumRenewalOrNot(employerCategory) {
		return employerCategory === employerCategoryType.small || employerCategory === employerCategoryType.experience;
	}

	setEstimatedPolicyForRenewal(attributes) {
		const { transactionType, isRenewalPolicy, employerCategory } = attributes;
		if (transactionType === policyTransactionType.ESTIMATED_WAGES) {
			const isEmployerCategory = this.getPremiumRenewalOrNot(employerCategory);
			this.policyModel.employerCategory = employerCategory;
			this.policyModel.premiumForRenewal = !!(isRenewalPolicy && isEmployerCategory);
		}
	}

	@action
	async submitinitialRequest(token, clientid, clientSecret) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const resp = await PolicyWagePrelimInfoService.submitRequest(
				this.preliminaryModel,
				token,
				clientid,
				clientSecret,
			);
			runInAction(() => {
				this.setEmployerCSPFlag(parseJSON(resp));
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = true;
				this.asyncStatus.error = null;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = this.validateError(e);
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = false;
				this.policyModel = null;
				this.destroyPrelimInfoPolicy();
			});
		}
	}

	@action
	async submitRequest(token, clientid, clientSecret) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const resp = await PolicyWageAdditionalInfoService.submitRequest(
				this.preliminaryModel,
				token,
				clientid,
				clientSecret,
			);

			runInAction(() => {
				this.setPolicy(parseJSON(resp));
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = true;
				this.asyncStatus.error = null;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = this.validateError(e);
				this.asyncStatus.errDesc = e?.response?.body?.errors[0]?.detail;
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = false;
				this.policyModel = null;
			});
		}
	}

	@action
	async submitWageRequest(clientID, clientSecret, token) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			this.isRedirectWithError = false;
			const resp = await PolicyWageDeclarationService.submitRequest(this.policyModel, clientID, clientSecret, token);
			runInAction(() => {
				this.validateUnderwritingError(parseJSON(resp));
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = true;
				this.asyncStatus.error = null;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = this.validateError(e);
				this.validateAndRedirect(e);
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = e.response.body.errors[0].code === '1501';
			});
		}
	}

	@action
	async submitCancelPolicyRequest(clientID, clientSecret, token) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			this.isRedirectWithError = false;
			this.hasUnderWriterError = false;
			const resp = await CancelPolicyServices.submitCancelPolicyRequest(this.policyModel, this.cancelPolicyModel, clientID, clientSecret, token);
			runInAction(() => {
				const response = parseJSON(resp);
				if (response.data?.attributes && (response.data?.attributes?.IsUnderWriterError || response.data?.attributes?.hasValidationErrors)) {
					this.hasUnderWriterError = true;
				}
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = true;
				this.asyncStatus.error = null;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = this.validateError(e);
				this.validateAndRedirect(e);
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = false;
			});
		}
	}

	@action
	async submitChangeCSPRequest(clientID, clientSecret, token) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			this.isRedirectWithError = false;
			const resp = await ChangeClaimsProviderServices.submitChangeCSPRequest(this.policyModel, this.cspModel, clientID, clientSecret, token);
			runInAction(() => {
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = true;
				this.asyncStatus.error = null;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = this.validateError(e);
				this.validateAndRedirect(e);
				this.asyncStatus.isBusy = false;
				this.asyncStatus.isSuccess = false;
			});
		}
	}
	// @action
	validateUnderwritingError(response) {
		if (response?.data?.attributes) {
			if (response.data.attributes?.IsUnderWriterError) {
				this.premiumInfo.previousPremium = 0;
				this.premiumInfo.revisedPremium = 0;
				this.premiumInfo.changeInCost = 0;
				this.premiumInfo.error = true;
			} else if (response.data.attributes?.premiumDetails && !response.data.attributes?.IsUnderWriterError) {
				this.premiumInfo.previousPremium = response.data.attributes.premiumDetails?.priorPremium;
				this.premiumInfo.revisedPremium = response.data.attributes.premiumDetails?.hindsightPremium;
				this.premiumInfo.changeInCost = response.data.attributes.premiumDetails?.changeInCost;
				this.premiumInfo.error = false;
				this.setEstimatedPolicyForRenewal(response.data.attributes);
			}
			if (response?.data?.attributes?.hasValidationErrors) {
				this.premiumInfo.previousPremium = 0;
				this.premiumInfo.revisedPremium = 0;
				this.premiumInfo.changeInCost = 0;
				this.premiumInfo.error = true;
				this.hasDuplicateTransaction = true;
			}
		} else {
			this.premiumInfo.previousPremium = 0;
			this.premiumInfo.revisedPremium = 0;
			this.premiumInfo.changeInCost = 0;
			this.premiumInfo.error = true;
		}
	}

	// @action
	validateError(responseError) {
		const isEstimatedWages = this.preliminaryModel?.policyTransactionType === policyTransactionType.ESTIMATED_WAGES;
		const isActualWages = this.preliminaryModel?.policyTransactionType === policyTransactionType.ACTUAL_WAGES;
		const isCSP = this.preliminaryModel?.policyTransactionType === policyTransactionType.CUSTOMER_SERVICE_PROVIDER;
		const isCancelPolicy = this.preliminaryModel?.policyTransactionType === policyTransactionType.CANCEL_POLICY;
		
		if (responseError?.response?.body?.errors?.length > 0) {
			const { errors } = responseError.response.body;
			if (errors[0]?.status === '404' || errors[0]?.code === '1350') {
				this.retryAttempts = this.retryAttempts + 1;
				if (this.retryAttempts <= 3) {
					responseError.response.body.errors[0].code = 'retry_attempt_not_exceeded';
				} else if (this.retryAttempts > 3) {
					responseError.response.body.errors[0].code = 'retry_attempt_exceeded';
				}
			} else if ((isEstimatedWages || isCancelPolicy || isActualWages) && errors[0]?.code === '1019') {
				if (isEstimatedWages && errors[0]?.detail === 'Transaction outstanding') {
					responseError.response.body.errors[0].code = 'CSS_EST_WAGES_TRANSACTION_OUTSTANDING';
				} else if (isCancelPolicy && errors[0]?.detail === 'Transaction outstanding') {
					responseError.response.body.errors[0].code = 'CSS_CANCEL_POLICY_TRANSACTION_OUTSTANDING';
				} else if (errors[0]?.detail === 'Cancelled Policy') {
					responseError.response.body.errors[0].code = 'CSS_CANCELLED_POLICY';
				} else if (isCancelPolicy && errors[0]?.detail === 'Other policy type') {
					responseError.response.body.errors[0].code = 'CSS_NON_STANDARD_POLICY';
				} else if(isActualWages) {
					responseError.response.body.errors[0].code = 'ICARE_POLICY_CHANGE_NOT_ALLOWED';
				} else {
					responseError.response.body.errors[0].code = 'ICARE_POLICY_CHANGE_NOT_ALLOWED_ESTIMATED_WAGES';
				}
			} else if ((isEstimatedWages || isCancelPolicy ) && errors[0]?.code === '1502') {
				if (isCancelPolicy) {
					responseError.response.body.errors[0].code = 'ICARE_CSS_CANCELLATION_NOT_ALLOWED';
				} else {
					responseError.response.body.errors[0].code = 'ICARE_CSS_ESTIMATED_WAGES_NOT_ALLOWED';
				}
			} else if ((isActualWages || isCancelPolicy) && errors[0]?.code === '1500') {
				if(isActualWages) responseError.response.body.errors[0].code = 'CSS_ACTUAL_WAGES_CANCELLED';
				else responseError.response.body.errors[0].code = 'CSS_POLICY_CANCELLED';
			} else if (isCSP && errors[0]?.code === '1504') {
				responseError.response.body.errors[0].code = 'PENDING_CSP';
			} else if (isCSP && errors[0]?.code === '1505') {
				responseError.response.body.errors[0].code = 'PENDING_CSPS';
			} else if (isCancelPolicy && errors[0]?.code === '30001') {
				responseError.response.body.errors[0].code = 'NO_PERCAPTIA_WIC';
			} else if (errors[0]?.code === '1024' && errors[0]?.status === '409') {
				responseError.response.body.errors[0].code = '1024';
			}
		}
		return responseError;
	}

	//@action
	validateAndRedirect(responseError) {
		if (
			responseError &&
			responseError.response &&
			responseError.response.body &&
			responseError.response.body.errors &&
			responseError.response.body.errors.length > 0
		) {
			this.isRedirectWithError = false;
			this.hasDuplicateTransaction = false;
			if (responseError.response.body.errors[0].code === '600' || responseError.response.body.errors[0].code === '605' || responseError.response.body.errors[0].code === '1503') {
				this.isRedirectWithError = true;
				this.hasDuplicateTransaction = false;
			}
			if (responseError.response.body.errors[0].code === '1501') {
				this.hasDuplicateTransaction = true;
				this.isRedirectWithError = false;
			}
		}
	}

	@action
	setEmployerCSPFlag(resp) {
		if (resp && resp.data) {
			this.isEmployerEligibleForCSPChoice = resp.data?.attributes?.isEmployerEligibleForCSPChoice || false;
			this.policyType = resp.data?.attributes?.policyType || 'Standard';
		}
	}

	@action
	setPolicy(resp) {
		if (resp && resp.data) {
			this.policyModel = PolicyFactory.createFromService(resp.data);
			if (this.policyModel) {
				this.policyModel.wics = this.policyModel.wics.filter(policy => !policy.isRemoved);
				this.getWageActivities();
			}
		}
	}

	@action
	destroyPrelimInfoPolicy() {
		this.preliminaryModel = null;
	}

	@action
	destroyManagePolicyInfo() {
		const updatedFormValue = {
			policyTransactionType: '',
			policyEstimatedWageEndDate: '',
			policyExpiryDate: '',
			policyCancellationEndDate: '',
		};
		this.setPreliminaryInfo(updatedFormValue);
	}

	@action
	destroyPolicy() {
		this.policyModel = null;
		this.preliminaryModel = null;
		this.wageDeclarationActivities = [];
		this.premiumInfo = null;
		this.retryAttempts = 0;
		this.formObjs = [];
	}

	@action
	getPreliminaryInfo() {
		return this.preliminaryInfo;
	}

	@action
	_setErrorMessage = msg => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = false;
		this.asyncStatus.error = typeof msg === 'string' ? msg : msg.toString();
	};

	@action
	_setSuccessMessage = jsonResponse => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = true;
		this.asyncStatus.error = '';
		this.referenceNumber = jsonResponse.id;
	};

	@action
	assignFormObjects(id, form) {
		if (this.policyModel && this.policyModel.wics) {
			this.policyModel.wics.find((o, i) => {
				if (o.id === id) {
					this.policyModel.wics[i].FormObject = form;
				}
			});
		}
	}

	@action
	getPolicyModelWics() {
		if (this.policyModel && this.policyModel.wics) {
			this.policyModel.wics = this.policyModel.wics.filter(policy => !policy.isRemoved);
			return this.policyModel.wics;
		}
		return null;
	}

	@action
	setSubmitterInfo(info) {
		this.submitterInfo.companyName = info.companyName;
		this.submitterInfo.contactNumber = info.contactNumber;
		this.submitterInfo.emailAddress = info.emailAddress;
		this.submitterInfo.firstName = info.firstName;
		this.submitterInfo.lastName = info.lastName;
		this.submitterInfo.position = info.position;
		this.submitterInfo.submitterType = info.submitterType === 'on_behalf' ? false : true;
		this.submitterInfo.transactionType = info.transactionType;
		this.submitterInfo.reasonForChange = info.reasonForChange;
		this.submitterInfo.policyStartDate = info.policyStartDate;
		this.submitterInfo.policyEndDate = info.policyEndDate;
		this.submitterInfo.totalNoOfEmployees = info.totalNoOfEmployees;
		this.submitterInfo.totalWages = info.totalWages;
		this.submitterInfo.totalNoOfUnits = info.totalNoOfUnits;
		this.submitterInfo.employerName = info.employerName;
		if (info.transactionType === policyTransactionType.ESTIMATED_WAGES) {
			this.submitterInfo.isRenewalPolicy = info.isRenewalPolicy;
		}
		const submitterInformation = SubmitterFactory.createFromService(this.submitterInfo);
		this.policyModel.SubmitterModel = submitterInformation;
	}
	//endregion
}
