import {observable, action, runInAction} from 'mobx';
import Auth from '@icare/auth-okta/lib/Auth';
import User, {UserFactory} from '~/core/models/User';
import AuthServices from '~/core/services/AuthServices';
import AsyncStatus from '~/core/models/AsyncStatus';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';

/**
 * The Auth Store
 */

export default class AuthStore {
	//region Properties

	/**
	 * User
	 * @type {User}
	 */
	@observable user = new User();
	/**
	 * Sync status of retrieving the User History via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = AuthServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * Set the Roles
	 * @param value
	 */
	@action
	setUser(value) {
		if (!value) {
			this.user = null;
		} else {
			this.user = value;
		}
	}

	/**
	 * Get the user
	 * if the Role is Employer set user Details
	 */
	@action
	updateSession = async (user, isSync) => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const response = await AuthServices.getUserDetails();

			// Analytics
			const auth = await Auth.sharedInstance.getInstance();
			if (!auth.isAuthenticated) {
				// If not logged-in remove login success flag
				AnalyticsHandler.removeUserLoginSuccessFlag();
			} else {
				const loginSuccessFlag = AnalyticsHandler.readUserLoginSuccessFlag();
				// If flag equals 0, it means we haven't triggered the 'login success' Analytics event yet
				if (loginSuccessFlag === 0) {
					// trigger the 'login success' Analytics event
					AnalyticsHandler.loginSuccess();
				}
				// Increment the flag only until its value is 1 to prevent unnecessarily setting of the localStorage item
				if (loginSuccessFlag < 1) {
					AnalyticsHandler.setUserLoginSuccessFlag();
				}
			}

			runInAction(() => {
				this.setUser(
					UserFactory.createFromObject({
						[isSync ? 'model' : 'userOkta']: user,
						getUserResponse: response,
					}),
				);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			console.error(e);
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	};

	/**
	 * Patch user details
	 */
	@action
	patchUserDetails = async formInputs => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const user = UserFactory.createFromObject({
				formInputs,
			});
			const isSuccess = await AuthServices.patchUserDetails(user.toServiceLayer);
			runInAction(() => {
				this.setUser(
					UserFactory.createFromObject({
						formInputs,
						model: this.user,
					}),
				);
				this.asyncStatus.isBusy = false;
			});
			return isSuccess;
		} catch (e) {
			console.error(e);
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	};

	//endregion
}
