import {observable, computed, action} from 'mobx';
import Address, {AddressFactory} from '~/core/models/submodels/Address';
import Wic, {WicFactory} from '~/core/models/submodels/Wic';
import {formatCurrency} from '@icare/core/lib/utils/FormattingUtils';

/**
 * Premise
 */
class Premise {
	// region Properties

	/** Premise public id
	 * @type {?string}
	 */
	@observable id = null;

	/**
	 * The premise location
	 * @type {import('../submodels/Address').default}
	 */
	@observable address = null;

	/**
	 * The Premise wics
	 * @type {Array<import('../submodels/Wic').default>}
	 */
	@observable wics = [];

	/** locationRetired
	 * @type {boolean}
	 */
	@observable locationRetired = false;

	/** primaryLocation
	 * @type {boolean}
	 */
	@observable primaryLocation = false;

	/** isRatedLocation
	 * @type {boolean}
	 */
	@observable isRatedLocation = true;

	/** publicId
	 * @type {?string}
	 */
	@observable publicId = null;

	/** publicId under costCenters[0]
	 * @type {?string}
	 */
	@observable costCenterPublicId = null;

	/** fullName
	 * @type {?string}
	 * */
	@observable fullName = null;

	/** name
	 * @type {?string}
	 * */
	@observable name = null;

	// endregion

	// region Actions

	/**
	 * Set the premise id
	 * @param {string} value
	 */
	@action setId(value) {
		this.id = value || null;
	}

	/**
	 * Add a new wic to the business
	 * @param {import('./Wic').default} wic
	 */
	@action
	addWic(wic) {
		if (!(wic instanceof Wic)) {
			throw new TypeError();
		}

		this.wics = [...this.wics, wic];
	}

	/**
	 * Remove a wic from the business
	 * @param wic
	 */
	@action
	removeWic(wic) {
		this.wics = this.wics.filter(w => {
			return w.wicActivity.code !== wic.wicActivity.code;
		});
	}

	/**
	 * Set the address
	 * @param {?Address} value - The address
	 */
	@action
	setAddress(value) {
		if (value instanceof Address) {
			this.address = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Replace a wic
	 * @deprecated Wics should manage updates themselves through Wic model actions
	 * @param {Array<import('../submodels/Wic').default>} oldWics
	 * @param {import('../submodels/Wic').default} newWic
	 */
	@action
	replaceWic(oldWics, newWic) {
		if (!(newWic instanceof Wic)) {
			throw new TypeError('Unexpected instance of Wic, did you use the factory?');
		}

		this.wics = oldWics.map(w => {
			if (w.wicActivity.code === newWic.wicActivity.code) {
				return newWic;
			}
			return w;
		});
	}

	/**
	 * Set isRatedLocation
	 * @param {boolean} value
	 */
	@action setIsRatedLocation(value) {
		if (typeof value === 'boolean') {
			this.isRatedLocation = value;
		}
	}

	/**
	 * Set locationRetired
	 * @param {boolean} value
	 */
	@action setLocationRetired(value) {
		if (typeof value === 'boolean') {
			this.locationRetired = value;
		}
	}

	/**
	 * Set primaryLocation
	 * @param {boolean} value
	 */
	@action setPrimaryLocation(value) {
		if (typeof value === 'boolean') {
			this.primaryLocation = value;
		}
	}

	/**
	 * Set publicId
	 * @param {?string} value
	 */
	@action setPublicId(value) {
		this.publicId = value || null;
	}

	/**
	 * Set publicId under costCenters[0]
	 * @param {?string} value
	 */
	@action setCostCenterPublicId(value) {
		this.costCenterPublicId = value || null;
	}

	/**
	 * Set fullname
	 * @param {?string} value
	 */
	@action setFullName(value) {
		this.fullName = value || null;
	}

	/**
	 * Set name
	 * @param {?string} value
	 */
	@action setName(value) {
		this.name = value || null;
	}

	// endregion

	// region Computed properties

	@computed
	get totalNumberOfEmployees() {
		let totalEmployees = 0;

		if (this.wics && this.wics.length) {
			totalEmployees = this.wics.reduce(
				(totalEmployeeCount, wic) => totalEmployeeCount + parseInt(wic.totalNumberOfEmployees),
				0,
			);
		}

		return totalEmployees;
	}

	/**
	 * Note: This returns a string, not a integer/float
	 */
	@computed
	get totalWages() {
		return formatCurrency(this.totalWagesFloat);
	}

	@computed
	get totalWagesFloat() {
		let totalWages = 0;

		if (this.wics && this.wics.length) {
			totalWages = this.wics.reduce((totalEmpWage, wic) => totalEmpWage + wic.totalEmployeeWages, 0);
		}

		return totalWages;
	}

	@computed
	get totalNumberOfUnits() {
		let totalUnits = 0;

		if (this.wics && this.wics.length) {
			totalUnits = this.wics.reduce(
				(totalUnitCount, wic) => totalUnitCount + parseInt(wic.numberOfUnits - wic.numberOfPlates),
				0,
			);
		}

		return totalUnits;
	}

	@computed
	get totalNumberOfPlates() {
		let totalPlates = 0;

		if (this.wics && this.wics.length) {
			totalPlates = this.wics.reduce((totalPlateCount, wic) => totalPlateCount + parseInt(wic.numberOfPlates), 0);
		}

		return totalPlates;
	}

	@computed
	get taxiPlateList() {
		const taxiPlates = [];

		if (this.wics && this.wics.length) {
			this.wics.map(wic => {
				taxiPlates.push(...wic.taxiPlateList);
			});
		}

		return taxiPlates;
	}

	@computed
	get hasPerCapitaWic() {
		const perCapitaWics = this.wics.filter(wic => wic.wicActivity.perCapitaFlag);
		return perCapitaWics && perCapitaWics.length > 0;
	}

	@computed
	get hasApprentices() {
		let totalNumberOfApprentice = 0;

		if (this.wics && this.wics.length) {
			totalNumberOfApprentice = this.wics.reduce(
				(totalApprenticeCount, wic) => totalApprenticeCount + parseInt(wic.numberOfApprentices),
				0,
			);
		}

		return totalNumberOfApprentice;
	}

	@computed
	get toServiceLayer() {
		return {
			locationRetired: false,
			primaryLocation: false,
			isRatedLocation: true,
			costCenters: [
				{
					directWages: this.toServiceLayerWics,
				},
			],
			accountLocation: {
				nonSpecific: true, //DEFAULT
				address: this.address ? this.address.toServiceLayer : null,
			},
		};
	}

	@computed
	get toServiceLayerPolicyEdit() {
		return {
			locationRetired: this.locationRetired,
			primaryLocation: this.primaryLocation,
			isRatedLocation: this.isRatedLocation,
			publicId: this.publicId,
			costCenters: [
				{
					directWages: this.toServiceLayerWics,
					publicId: this.costCenterPublicId,
					fullName: this.fullName,
					name: this.name,
				},
			],
			accountLocation: {
				nonSpecific: true, //DEFAULT
				address: this.address ? this.address.toServiceLayer : null,
			},
		};
	}

	@computed
	get toServiceLayerWics() {
		return this.wics.map(wic => wic.toServiceLayer);
	}

	// endregion
}

/**
 * Premise factory
 */
export class PremiseFactory {
	/**
	 * Creates a premise from a js object
	 * @param {object} o - The object
	 * @returns {?Premise}
	 */
	static createFromResponseObject(o) {
		if (!o) {
			return null;
		}

		const p = new Premise();
		p.id = o.id || null;

		if (o.policyAddress || o.address || o.accountLocation) {
			p.setAddress(AddressFactory.createAddressFromObject(o.policyAddress || o.address || o.accountLocation));
		}

		if (o.directWages) {
			o.directWages.map(wic => p.addWic(WicFactory.createWicFromResponseObject(wic)));
		} else if (o.wics) {
			o.wics.map(wic => p.addWic(WicFactory.createWicFromResponseObject(wic)));
		} else if (o.costCenters) {
			o.costCenters[0].directWages.map(wic => p.addWic(WicFactory.createWicFromResponseObject(wic)));
		}

		p.setIsRatedLocation(typeof o.isRatedLocation === 'boolean' ? o.isRatedLocation : true);
		p.setLocationRetired(typeof o.locationRetired === 'boolean' ? o.locationRetired : false);
		p.setPrimaryLocation(typeof o.primaryLocation === 'boolean' ? o.primaryLocation : false);

		if (o.publicId) {
			p.setPublicId(o.publicId);
		}

		if (o.costCenterPublicId) {
			p.setCostCenterPublicId(o.costCenterPublicId);
		}

		if (o.fullName) {
			p.setFullName(o.fullName);
		}

		if (o.name) {
			p.setName(o.name);
		}

		return p;
	}
}

export default Premise;
