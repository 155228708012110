import {observable, action, runInAction} from 'mobx';
import BrokerService from '~/core/services/BrokerService';
import AsyncStatus from '~/core/models/AsyncStatus';

/**
 * The Broker Store
 */
export default class BrokerStore {
	//region Properties

	/**
	 * Level 3 Brokers w Access levels
	 * for current authenticated user
	 * @type {Array}
	 */
	@observable brokerages = null;

	/**
	 * Level 2 Brokers
	 * for current authenticated user
	 * @type {Array}
	 */
	@observable brokers = null;

	/**
	 * Sync status of retrieving the User History via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	/**
	 * CRUD Status for brokers
	 * @type {AsyncStatus}
	 */
	@observable crudStatus = new AsyncStatus();

	//endregion

	//region Actions

	/**
	 * Update the Brokerage List
	 * if the Role is BROKER set Brokerage List
	 */
	@action
	syncBrokers = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const brokers = await BrokerService.getBrokers();
			runInAction(() => {
				this.brokers = brokers || [];
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	/**
	 * Get the Brokerage List
	 * if the Role is BROKER set Brokerage List
	 */
	@action
	syncBrokerages = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const brokerages = await BrokerService.getBrokerages();
			runInAction(() => {
				this.brokerages = brokerages;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	};

	@action
	addBrokerage = async (broker, user) => {
		try {
			this.crudStatus.isBusy = true;
			this.crudStatus.error = null;
			await BrokerService.addBrokerage(broker, user);
			runInAction(() => {
				this.syncBrokerages();
				this.crudStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.crudStatus.error = e;
				this.crudStatus.isBusy = false;
			});
		}
	};

	//endregion
}
