export const quoteId = ':quoteId';
export const policyId = ':policyId';
export const brokerageId = ':brokerageId';
export const brokerUserId = ':brokerUserId';
export const policyTermNumber = ':policyTermNumber';
export const id = ':id';

export const dashboardPaths = {
	listingPage: '/policies',
	downloadCOCPage: '/download-coc',
};

export const sitePaths = {
	accessibilityPage: '/accessibility-page',
	fatalErrorPage: '/fatal-error',
	unauthorisedPage: '/unauthorised',
	contactUsPage: '/contact-us',
	maintenancePage: '/maintenance',
	disabledAuthPage: '/disabled-auth-page',
	homePage: '/',
	notFoundPage: '*',
};

if (process.env.NODE_ENV === 'development') {
	sitePaths.siteMap = '/sitemap';
	dashboardPaths.widget = '/widget';
}

export const accountPaths = {
	detailsPage: '/profile',
	manageUsersPage: '/profile/employees',
	addUser: '/profile/employees/add',
	editUserPattern: `/profile/employees/edit/${id}`,
	manageBrokerage: '/profile/manage/brokerage',
	manageBrokerUsersPattern: `/profile/manage/brokerage/${brokerageId}/users`,
	addBrokerageUserDetailsPagePattern: `/profile/manage/brokerage/users/add`,
	editBrokerageUserDetailsPagePattern: `/profile/manage/brokerage/${brokerageId}/users/edit/${brokerUserId}`,
};

export const authenticationPaths = {
	changePassword: '/change-password/',
	forgotPassword: '/forgot-password/',
	forgotRegistrationCode: '/forgot-registration-code/',
	login: '/login/',
	logout: '/logout/',
	resetPassword: '/profile/reset-password/',
	registerAccount: '/register-account',
	registerAccountBroker: '/register-account/broker',
	setPasswordAndSecurity: '/set-password-and-security-question/',
	registerAccountThankYouPage: '/register-account-thank-you-page/',
	expiredRegistrationPage: '/expired-registration/',
};

export const quotePaths = {
	quoteLandingPage: '/quote/apply',
	eligibilityTestPage: '/quote/eligibility-test',
	// Full Quote from new
	fqBusinessPage: '/quote/full-quote/business-details',
	fqWagesPage: '/quote/full-quote/wages',
	fqContactPage: '/quote/full-quote/contact-details',
	// Full Quote from Quick Quote
	fqBusinessPagePattern: `/quote/full-quote/${quoteId}/business-details`,
	fqWagesPagePattern: `/quote/full-quote/${quoteId}/wages`,
	fqContactPagePattern: `/quote/full-quote/${quoteId}/contact-details`,
	// Full Quote continued
	fqPremiumPagePattern: `/quote/full-quote/${quoteId}/premium`,
	fqPaymentPagePattern: `/quote/full-quote/${policyId}/payment`,
	fqConfirmationPagePattern: `/quote/full-quote/${policyId}/confirmation`,
	// Save and Retrieve
	retrieveQuotePage: '/quote/retrieve-quote',
	saveQuotePagePattern: `/quote/save/${quoteId}`,
};

export const policyPaths = {
	// Policy Details
	policyDetailsPagePattern: `/policies/${policyId}/${policyTermNumber}`,
	// Cancel Policy
	cancelPolicyPagePattern: `/policies/cancel/${policyId}/${policyTermNumber}`,
	// Policy History
	policyHistoryPagePattern: `/policies/${policyId}/${policyTermNumber}/history`,
	// Policy Contacts
	policyContactsPagePattern: `/policies/${policyId}/${policyTermNumber}/contacts`,
	// Documents
	documentsPagePattern: `/policies/${policyId}/${policyTermNumber}/documents`,
	documentUploadPagePattern: `/policies/${policyId}/${policyTermNumber}/documents/document-upload`,
	documentClaimsUploadPage: `/policies/${policyId}/${policyTermNumber}/documents/claims-document-upload`,
	// Payments
	policyPaymentsPagePattern: `/policies/${policyId}/${policyTermNumber}/payments`,
	policyDirectDebitPagePattern: `/policies/${policyId}/${policyTermNumber}/direct-debit`,
	policyPaymentPage: '/make-a-payment',
	policyPaymentReceivedPage: '/make-a-payment/received',
	// Policy Edit
	policyEditBusinessPagePattern: `/policies/edit/${policyId}/${policyTermNumber}/business-details`,
	policyEditWagesPagePattern: `/policies/edit/${policyId}/${policyTermNumber}/wages`,
	policyEditPremiumPagePattern: `/policies/edit/${policyId}/${policyTermNumber}/premium`,
	policyEditChangePagePattern: `/policies/edit/${policyId}/${policyTermNumber}/confirmation`,
	// Policy Renew
	policyRenewPagePattern: `/policies/renew/${policyId}/${policyTermNumber}`,
	policySRPPagePattern: `/policies/srp/${policyId}/${policyTermNumber}`,
	policyRenewBusinessDetailsPagePattern: `/policies/renew/${policyId}/${policyTermNumber}/business-details`,
	policyRenewWagesPagePattern: `/policies/renew/${policyId}/${policyTermNumber}/wages`,
	policyRenewPremiumPagePattern: `/policies/renew/${policyId}/${policyTermNumber}/premium`,
	policyRenewPaymentPagePattern: `/policies/renew/${policyId}/${policyTermNumber}/payment`,
	policyRenewConfirmationPagePattern: `/policies/renew/${policyId}/${policyTermNumber}/confirmation`,
	// Policy Hindsight
	policyHindsightBusinessDetailsPagePattern: `/policies/actualwages/${policyId}/${policyTermNumber}/business-details`,
	policyHindsightWagesPagePattern: `/policies/actualwages/${policyId}/${policyTermNumber}/wages`,
	policyHindsightPremiumPagePattern: `/policies/actualwages/${policyId}/${policyTermNumber}/premium`,
	policyHindsightConfirmationPagePattern: `/policies/actualwages/${policyId}/${policyTermNumber}/confirmation`,
};

export const cancelPolicyPaths = {
	detail: '/cancel',
	wages: '/cancel/wages',
	review: '/cancel/review',
	thankYou: '/cancel/thank-you',
};

export const wagesDeclarationPaths = {
	preliminaryInfo: '/wages-declaration',
	businessDetails: '/wages-declaration/business-details',
	wages: '/wages-declaration/wages',
	review: '/wages-declaration/review',
	thankYou: '/wages-declaration/thank-you',
};

export const policyWagesDeclarationPaths = {
	policyWageDeclarationPath: '/policy-wage-declaration',
	preliminaryInfo: '/tell-us-about-your-policy',
	policyAdditionalInfo: '/manage-your-policy',
	businessDetails: '/manage-your-policy/wage-declaration-page',
	thankYou: '/manage-your-policy/thank-you',
	thankYouCSP: '/manage-your-policy/csp/thank-you',
	thankYouCancel: '/manage-your-policy/cancel/thank-you',
};

export const cocPaths = {
	confirmationPath: '/coc-confirmation',
	cocFormPage: '/manage-reporting-email-list',
};

export const sbhPaths = {
	confirmationPath: '/sb-confirmation',
	cocFormPage: '/sb-manage-reporting-email-list',
};
