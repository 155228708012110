import {observable, computed, action} from 'mobx';
import AccessLevel from '~/core/models/enums/AccessLevel';

export const authorisationType = 'PolicyAuthorisation';

class Authorisation {
	/**
	 * generic name for group name or entity name
	 * @type {?string}
	 */
	@observable name = null;
	/**
	 * generic name for broker code or policy number
	 * @type {?string}
	 */
	@observable code = null;

	/**
	 * @type {?string}
	 */
	@observable role = null;

	/**
	 * @type {?string}
	 */
	@observable newRole = null;

	/**
	 * @type {?string}
	 */
	@observable status = null;
	/**
	 * identityId is used for adding new authorisations
	 * @type {?string}
	 */
	@observable identityId = null;
	/**
	 * authorisationRowId is used for updating authorisations
	 * @type {?string}
	 */
	@observable authorisationRowId = null;

	/**
	 * Sets the name
	 * @param {?string} value
	 */
	@action
	setName(value) {
		this.name = value;
	}

	/**
	 * Sets the code
	 * @param {?string} value
	 */
	@action
	setCode(value) {
		this.code = value;
	}

	/**
	 * Sets the role
	 * @param {?string} value
	 */
	@action
	setRole(value) {
		this.role = value;
	}

	/**
	 * Sets the new role
	 * @param {?string} value
	 */
	@action
	setNewRole(value) {
		this.newRole = value;
	}

	/**
	 * Sets the status
	 * @param {?string} value
	 */
	@action
	setStatus(value) {
		this.status = value;
	}

	/**
	 * Sets the identity id
	 * @param {?string} value
	 */
	@action
	setIdentityId(value) {
		this.identityId = value;
	}

	/**
	 * Sets the authorisation row id
	 * @param {?string} value
	 */
	@action
	setAuthorisationRowId(value) {
		this.authorisationRowId = value;
	}

	@computed
	get toServiceLayerAdd() {
		return {
			accessType: this.newRole,
			policyNumber: this.code,
			status: this.status || 'active',
		};
	}

	@computed
	get toServiceLayerRemoveAuthorisation() {
		return {
			authorisationRowId: this.authorisationRowId,
			status: 'inactive',
			identityId: this.identityId,
		};
	}

	@computed
	get toServiceLayerAddAuthorisation() {
		return {
			policyNumber: this.code,
			identityId: this.identityId,
			assertions: [
				{
					role: `${this.newRole.charAt(0).toUpperCase()}${this.newRole.slice(1)}`,
					scope: ['ALL'],
				},
			],
		};
	}
}

/**
 * The Authorisation Factory
 */
export class AuthorisationFactory {
	static createFromObject(o, identityId) {
		const a = new Authorisation();

		if (o.role) {
			const formatRole = o.role.toLowerCase();
			let role = '';

			if (formatRole === AccessLevel.ADMIN) {
				role = AccessLevel.ADMIN;
			} else if (formatRole === AccessLevel.GENERAL) {
				role = AccessLevel.GENERAL;
			} else if (formatRole === AccessLevel.NO_ACCESS) {
				role = AccessLevel.NO_ACCESS;
			} else {
				return null;
			}

			a.setRole(role);
		}

		if (o.status === 'inactive') {
			return null;
		} else {
			a.setStatus(o.status);
		}

		a.setName(o.employerName);
		a.setCode(o.policyNumber);
		a.setNewRole(o.newRole || null);

		if (o.authorisationRowId) {
			a.setAuthorisationRowId(o.authorisationRowId);
		}

		if (identityId) {
			a.setIdentityId(identityId);
		}

		return a;
	}
}
export default Authorisation;
