import React from 'react';
import T from 'prop-types';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import RichText from '@icare/components/lib/RichText';
import Layout from '@icare/layouts/lib/Layout';
import ContentHeader from '@icare/components/lib/ContentHeader';

@injectContent(['disabledAuthPage'])
export default class DisabledAuthPage extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
	};

	render() {
		const {disabledAuthPage} = this.props.content;
		return (
			<Layout type={Layout.Type.ONE_COLUMN}>
				<ContentHeader heading={disabledAuthPage.c1} />
				<RichText content={disabledAuthPage.c2} />
			</Layout>
		);
	}
}
