/**
 * Parse JSON
 * @param response
 * @private
 */
export const parseJSON = response => {
	if (!response) {
		return 'Error occurred while processing response. Received no response.';
	}

	if (!response.text) {
		return 'Error occurred while processing response. Received malformed response.';
	}

	// Process response
	return JSON.parse(response.text);
};

/**
 * Parse Error JSON
 * @param response
 * @private
 */
export const parseErrorJSONToString = error => {
	if (!error) {
		return 'Error occurred while processing error response. Received no error.';
	}

	// Process response
	if (error.response && error.response.text) {
		try {
			const result = JSON.parse(error.response.text);
			return result.message || result;
		} catch (e) {
			return `Error occurred while parsing error response. ${e.toString()}`;
		}
	}

	return error.response ? error.response.toString() : error.toString();
};
