import {HIDE_HINDSIGHTS} from '~/config/index.js';

export const filterConfig = [
	{
		id: 'policyStatus',
		label: 'Show policies with',
		type: 'radio',
		options: [
			{
				id: 'renewalDue',
				label: 'Renewal Due',
				value: 'renewalDue',
			},
			{
				id: 'paymentDue',
				label: 'Payment Due',
				value: 'paymentDue',
			},
			{
				id: 'cardExpiry',
				label: 'Card Expiry',
				value: 'cardExpiry',
			},
			...(HIDE_HINDSIGHTS
				? []
				: [
						{
							id: 'hindsightsDue',
							label: 'Actual Wages Due',
							value: 'hindsightsDue',
						},
				  ]),
		],
	},
];

export const sortConfig = {
	id: 'policySortBy',
	label: 'Sort by',
	options: [
		{
			id: 'totalPremiumDesc',
			label: 'Premium high to low',
			value: 'totalPremiumDesc',
			fieldName: 'totalPremiumDesc',
			direction: 'descending',
		},
		{
			id: 'totalPremiumAsc',
			label: 'Premium low to high',
			value: 'totalPremiumAsc',
			fieldName: 'totalPremiumAsc',
			direction: 'ascending',
		},
		{
			id: 'entityNameAsc',
			label: 'Entity name A-Z',
			value: 'entityNameAsc',
			fieldName: 'entityNameAsc',
			direction: 'ascending',
		},
		{
			id: 'entityNameDesc',
			label: 'Entity name Z-A',
			value: 'entityNameDesc',
			fieldName: 'entityNameDesc',
			direction: 'descending',
		},
		{
			id: 'policyStartDateAsc',
			label: 'Policy start date',
			value: 'policyStartDateAsc',
			fieldName: 'policyStartDateAsc',
			direction: 'ascending',
		},
		{
			id: 'actionDueDateAsc',
			label: 'Due date',
			value: 'actionDueDateAsc',
			fieldName: 'actionDueDateAsc',
			direction: 'ascending',
			isDefault: true,
		},
	],
};
