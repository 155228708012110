import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import Policy from '~/core/models/Policy';
import PolicyServices from '~/core/services/PolicyServices';

/**
 * The Policy Edit Store
 */
export default class EditPolicyStore {
	//region Properties

	/**
	 * Main Policy instance
	 * @type {Policy}
	 */
	@observable policy = null;

	/**
	 * Sync status of retrieving the Policy via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PolicyServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * New Quote Store object
	 */
	@action
	initPolicy(policy = null) {
		this.policy = policy || new Policy();
	}

	/**
	 * Set policy
	 */
	@action
	setPolicy(policy) {
		this.policy = policy;
	}

	/**
	 * Set policy
	 */
	@action
	clearPolicy() {
		this.policy = null;
	}

	/**
	 * Get Policy Details
	 * @param {string|number} policyId
	 * @param {number} termNumber
	 */
	@action
	async getPolicy(policyId, termNumber = 1) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const policy = await this.api.getPolicy(policyId, termNumber);

			runInAction(() => {
				this.setPolicy(policy);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Update Policy
	 */
	@action
	async updatePolicy(policy) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const resp = await this.api.updatePolicy(policy);
			runInAction(() => {
				this.policy = resp;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Rebind Policy (Edit Policy Premium page)
	 */
	@action
	async rebindPolicy(quoteId, policy) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const resp = await this.api.rebindPolicy(quoteId, policy);
			runInAction(() => {
				this.policy = resp;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	//endregion
}
