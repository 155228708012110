import {observable, action} from 'mobx';
import ActionTypes from '~/core/models/enums/ActionType';
import {filterConfig} from '../constants/PolicySortConstants';

export default class PolicySummary {
	@observable policyNumber = null; /** @property {?number} */
	@observable termNumber = null; /** @property {?number} */
	@observable entityName = null; /** @property {?string} */
	@observable actions = []; /** @property {?array} */
	@observable totalPremium = null; /** @property {?object} */
	@observable policyStartDate = null; /** @property {?date} */
	@observable policyEndDate = null; /** @property {?date} */
	@observable abn = null; /** @property {?string} */
	@observable acn = null; /** @property {?string} */
	@observable policyPending = null; /** @property {?boolean} */

	@action
	setPolicyNumber(value) {
		this.policyNumber = value || null;
	}

	@action
	setTermNumber(value) {
		this.termNumber = value || null;
	}

	@action
	setEntityName(value) {
		this.entityName = value || null;
	}

	@action
	setActions(value, isPremiumPending = false) {
		const copy = [...value];

		if (isPremiumPending) {
			copy.push({
				actionType: ActionTypes.PREMIUM_PENDING,
			});
		}

		this.actions = copy;
	}

	@action
	setTotalPremium(value) {
		this.totalPremium = value || null;
	}

	@action
	setPolicyStartDate(value) {
		this.policyStartDate = value || null;
	}

	@action
	setPolicyEndDate(value) {
		this.policyEndDate = value || null;
	}

	@action
	setAbn(value) {
		this.abn = value || null;
	}

	@action
	setAcn(value) {
		this.acn = value || null;
	}

	@action
	setPolicyPending(value) {
		this.policyPending = !!value;
	}
}

export class PolicySummaryFactory {
	static createPolicySummaryFromResponse(o) {
		if (!o) {
			return null;
		}

		return {
			currentPage: o.meta.currentPage,
			totalPages: o.meta.totalPages,
			totalRecords: o.meta.totalRecords,
			entries: o.data.attributes.entries.map(x => {
				const ps = new PolicySummary();

				ps.setPolicyNumber(x.policyNumber);
				ps.setTermNumber(x.termNumber);
				ps.setEntityName(x.entityName);
				ps.setActions(x.actions, x.policyPending);
				ps.setTotalPremium(x.totalPremium.amount);
				ps.setPolicyStartDate(x.policyStartDate);
				ps.setPolicyEndDate(x.policyEndDate);
				ps.setAbn(x.abn);
				ps.setAcn(x.acn);
				ps.setPolicyPending(x.policyPending);

				return ps;
			}),
		};
	}

	static createPolicySearchRequestPayload(page, pageSize, searchTerm, searchCategory, policyStatus, sortCriteria) {
		const criteria = {};

		// Add search items to request
		const searchTerms = ['policyNumber', 'entityName', 'abn', 'acn', 'groupId'];
		searchTerms.forEach(term => {
			if (searchCategory === term) {
				criteria[term] = searchTerm;
			}
		});

		// Add filter items to request
		const actionsItems = filterConfig[0].options.map(item => {
			return item.value;
		});
		actionsItems.map(item => {
			if (policyStatus === item) {
				criteria[item] = true;
			}
		});

		return {
			data: {
				type: 'PoliciesSearch',
				attributes: {
					params: {
						page: page,
						pageSize: pageSize,
						orderBy: [
							{
								fieldName: sortCriteria,
							},
						],
					},
					criteria: criteria,
				},
			},
		};
	}
}
