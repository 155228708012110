import {action} from 'mobx';
import {POLICY_BASE_NAME} from '~/config';

export default class FallbackLocationStore {
	/**
	 * Constructor
	 * @param rootStore
	 */
	constructor(rootStore = null) {
		this.rootStore = rootStore;
		this.fallbackLocation = window.sessionStorage.getItem("fbhome") || `/${POLICY_BASE_NAME}`;
	}

	/**
	 * Set the fallback location for page errors
	 * @param value
	 */
	@action
	setFallbackLocation(value) {
		this.fallbackLocation = value || `/${POLICY_BASE_NAME}`;
		window.sessionStorage.setItem("fbhome", this.fallbackLocation)
	}
}

