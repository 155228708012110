/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import T from 'prop-types';

import Tag from '@icare/components/lib/Tag';

import {HIDE_HINDSIGHTS} from '~/config';

import style from './PolicyActionTag.style';

export default class PolicyActionTag extends React.Component {
	static propTypes = {
		actions: T.array.isRequired,
	};

	render() {
		const {actions} = this.props;

		const listActions = actions
			.filter(action => !action.isPaymentDue)
			.filter(action => !(HIDE_HINDSIGHTS && action.isHindsightsDue))
			.map((action, i) => {
				return (
					<li css={style.actionListItem} key={i}>
						<Tag color={Tag.color.PURPLE}>{action.actionLabel}</Tag>
					</li>
				);
			});

		return (
			<React.Fragment>
				<ul css={style.actionList}>{listActions}</ul>
			</React.Fragment>
		);
	}
}
