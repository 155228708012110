import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import PolicyServices from '../services/PolicyServices';

/**
 * The Policy History Store
 */
export default class PolicyHistoryStore {
	//region Properties

	/**
	 * The policy id
	 * @type {null}
	 */
	@observable policyId = null;

	/**
	 * Policy History
	 * @type {Policy}
	 */
	@observable policyHistory = [];

	/**
	 * Sync status of retrieving the Policy History via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Actions

	/**
	 * Get Policy history
	 */
	@action
	async getPolicyHistory(policyId) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const resp = await PolicyServices.getPolicyHistory(policyId);
			runInAction(() => {
				this.policyId = policyId;
				this.policyHistory = resp;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}
	//endregion
}
