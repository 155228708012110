import React from 'react';
import {MAIN_SITE_ROOT, POLICY_BASE_NAME} from '~/config';

import ContextualLink from '@icare/components/lib/ContextualLink';

export default class PolicyLink extends React.Component {
	render() {
		return <ContextualLink {...this.props} baseName={POLICY_BASE_NAME} siteRoot={MAIN_SITE_ROOT} />;
	}
}
