/**
 * This function takes in 2 moment dates and checks if Feb 29 is in between the 2 dates
 * Note: Inclusive of start date, exclusive of end date
 * Note: Period can only cover 1 year // TODO: Allow multiple year as range
 * @param {moment} startDate
 * @param {moment} endDate
 * @returns {boolean}
 */
export function isLeapDayInPeriod(startDate, endDate) {
	if (startDate.isLeapYear() && endDate.isLeapYear()) {
		// Period is within the same leap year
		const leapDay = startDate.clone().set({month: 1, date: 29}); // Clone to get year
		return leapDay.isBetween(startDate, endDate, null, '[)'); // Include start date, exclude end date
	} else if (startDate.isLeapYear()) {
		// Start date is leap year, end date is not leap year
		const leapDay = startDate.clone().set({month: 1, date: 29});
		return leapDay.isSameOrAfter(startDate);
	} else if (endDate.isLeapYear()) {
		// End date is leap year, start date is not
		const leapDay = endDate.clone().set({month: 1, date: 29});
		return leapDay.isSameOrBefore(endDate);
	} else {
		return false;
	}
}

export default isLeapDayInPeriod;
