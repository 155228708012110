import {observable, action} from 'mobx';
import uuidv4 from 'uuid/v4';
import FieldUtils from '@icare/forms/lib/utils/FieldUtils';

export default class ContractorWages {
	
	@observable id = uuidv4();

	@observable contractorWageFixedID = null;

	@observable contractType = null;

	@observable noOfContractWorkers = null;

	@observable totalContractValue = null;

	@observable labourCost = null;

	@observable contractorWageDescription = null;

	@observable contractorWagePercentage = null;

	/**
	 * Set the contractorWageFixedID
	 * @param {string} value - The contractorWageFixedID
	 */

	convertContractWageIntoNumber(value) {
		let newValue;
		if (value) {
			newValue = typeof value !== 'number' ? FieldUtils.getNumberFromField(value).toFixed(2) : value.toFixed(2); 
		} 
		return newValue;
	};

	@action setContractorWageFixedId(value) {
		this.contractorWageFixedID = value || null;
	}

	/**
	 * Set the contractType
	 * @param {string} value - The contractType
	 */
	@action setContractType(value) {
		this.contractType = value || null;
	}

	/**
	 * Set the noOfContractWorkers
	 * @param {?number} value - The noOfContractWorkers
	 */
	@action setNoOfContractWorkers(value) {
		this.noOfContractWorkers = value || 0;
	}

	/**
	 * Set the totalContractValue
	 * @param {string} value - The totalContractValue
	 */
	@action setTotalContractValue(value) {
		const contractValue = this.convertContractWageIntoNumber(value);
		this.totalContractValue = contractValue || 0;
	}

	/**
	 * Set the labourCost
	 * @param {string} value - The labourCost
	 */
	@action setLabourCost(value) {
		const labourCost = this.convertContractWageIntoNumber(value);
		this.labourCost = labourCost || 0;
	}

	/**
	 * Set the contractorWageDescription
	 * @param {string} value - The contractorWageDescription
	 */
	@action setContractorWageDescription(value) {
		this.contractorWageDescription = value || '';
	}

	/**
	 * Set the contractorWagePercentage
	 * @param {string} value - The contractorWagePercentage
	 */
	@action setContractorWagePercentage(value) {
		this.contractorWagePercentage = value || 0;
	}
}

export class ContractorWagesFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param contractor
	 * @returns {?ContractorWages}
	 */
	static createFromServiceObject(contractor) {
		if (!contractor) {
			return null;
		}
		const contractorWages = new ContractorWages();
	 
		contractorWages.setContractorWageFixedId(contractor.contractorWageFixedID);
		contractorWages.setContractType(contractor.contractType);
		contractorWages.setNoOfContractWorkers(contractor.noOfContractWorkers);
		contractorWages.setTotalContractValue(contractor.totalContractValue);
		contractorWages.setLabourCost(contractor.labourCost);
		contractorWages.setContractorWageDescription(contractor.contractorWageDescription);
		contractorWages.setContractorWagePercentage(contractor.contractorWagePercentage);
		return contractorWages;
	}
}
