import {observable, action} from 'mobx';

export default class PolicyLocationAddress {
	
	@observable addressLine1 = null;

	@observable addressLine2 = null;

	@observable addressLine3 = null;

	@observable city = null;

	@observable postcode = null;

	@observable country = null;

	@observable state = null;

	/**
	 * Set the addressLine1 type
	 * @param {string} value - The addressLine1 type
	 */
	@action setaddressLine1(value) {
		this.addressLine1 = value || null;
	}

	/**
	 * Set the addressLine2
	 * @param {?string} value - The addressLine2
	 */
	@action setaddressLine2(value) {
		this.addressLine2 = value || null;
	}

	/**
	 * Set the addressLine3
	 * @param {?string} value - The addressLine3
	 */
	@action setaddressLine3(value) {
		this.addressLine3 = value || null;
	}

	/**
	 * Set the city name
	 * @param {?string} value - The city name
	 */
	@action setcity(value) {
		this.city = value || null;
	}

	/**
	 * Set the postcode name
	 * @param {?string} value - The postcode name
	 */
	@action setpostcode(value) {
		this.postcode = value || null;
	}

	/**
	 * Set the country name
	 * @param {?string} value - The country name
	 */
	@action setcountry(value) {
		this.country = value || null;
	}

	/**
	 * Set the state name
	 * @param {?string} value - The state name
	 */
	@action setstate(value) {
		this.state = value || null;
	}
}

export class PolicyLocationAddressFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param address
	 * @returns {?PolicyLocationAddress}
	 */
	static createFromServiceObject(address) {
		if (!address) {
			return null;
		}
		const policyLocationAddress = new PolicyLocationAddress();
		policyLocationAddress.setaddressLine1(address.addressLine1);
		policyLocationAddress.setaddressLine2(address.addressLine2);
		policyLocationAddress.setaddressLine3(address.addressLine3);
		policyLocationAddress.setcity(address.city);
		policyLocationAddress.setpostcode(address.postcode);
		policyLocationAddress.setcountry(address.country);
		policyLocationAddress.setstate(address.state);
		return policyLocationAddress;
	}
}
