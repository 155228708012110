import {observable, action, computed} from 'mobx';

/**
 * Analytics Store
 */
export default class AnalyticsStore {
	//Property Region

	/**
	 * URL Location Prev
	 */
	@observable locationPrevious = null;

	/**
	 * URL Location Current
	 */
	@observable locationCurrent = null;

	/**
	 * Tracking the order of field visited pers workflow section (page/pod) after CTA clicked.
	 * Generally used at the end of the entire workflow to push entire field sequence to GA.
	 */
	@observable masterFieldSequenceDetails = [];

	//Property Region End

	//region Actions

	/**
	 * set setPreviousLocation
	 */
	@action
	setPreviousLocation(value) {
		this.locationPrevious = value || null;
	}

	/**
	 * Set setCurrentLocation
	 */
	@action
	setCurrentLocation(value) {
		this.locationCurrent = value || null;
	}

	/**
	 * clear locations properties
	 */
	@action
	clearLocations() {
		this.locationPrevious = null;
		this.locationCurrent = null;
	}

	/**
	 * append field sequences to master list.
	 * Generally used after each workflow pages CTA has been invoked.
	 */
	@action
	appendToMasterFieldSeq(fieldSequenceOneLineValue  = '') {
		this.masterFieldSequenceDetails.push(fieldSequenceOneLineValue);
	}

	/**
	 * clear master field sequence
	 */
	@action
	clearMasterFieldSeq() {
		this.masterFieldSequenceDetails = [];
	}

	@computed
	get getMasterFieldSequenceDetails() {
		return this.masterFieldSequenceDetails;
	}
}
