import React from 'react';
import T from 'prop-types';

import Spinner from '@icare/components/lib/Spinner';
import MaintenanceService from '@icare/sitecore-content/lib/MaintenanceService';

import PolicyServices from '~/core/services/PolicyServices';
import {PORTAL_API_BASE, MAINTENANCE_MODE_PAGE_API_BASE, DISABLE_AUTHENTICATED_PAGES} from '~/config';
import injectAuth from '~/core/decorators/injectAuth';

import MaintenanceDashboard from '../components/MaintenanceDashboard';
import NoPolicyDashboard from '../components/NoPolicyDashboard';
import SinglePolicyDashboard from '../components/SinglePolicyDashboard';
import MultiplePoliciesDashboard from '../components/MultiplePoliciesDashboard';
import {PolicySummaryFactory} from '../models/PolicySummary';
import DisabledAuthPage from '../../site/pages/DisabledAuthPage';

@injectAuth
export default class DashboardPage extends React.Component {
	static propTypes = {
		auth: T.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			policy: null,
			policyStatistics: null,
			asyncStatus: {
				isBusy: true,
				error: null,
			},
			maintenanceMode: false,
		};
	}

	async componentDidMount() {
		try {
			const resp = await new MaintenanceService(PORTAL_API_BASE, MAINTENANCE_MODE_PAGE_API_BASE).isMaintenanceMode();

			if (!resp) {
				const [policy, policyStatistics] = await Promise.all([
					PolicyServices.searchPolicy(
						PolicySummaryFactory.createPolicySearchRequestPayload(1, 10, null, null, null, 'actionDueDateAsc'),
					),
					PolicyServices.getPolicyStatistics(),
				]);
				this.setState({
					asyncStatus: {
						isBusy: false,
						error: null,
					},
					policy: policy.entries.length ? policy.entries[0] : null,
					policyStatistics,
				});
			} else {
				this.setState({
					maintenanceMode: true,
					asyncStatus: {
						isBusy: false,
						error: null,
					},
				});
			}
		} catch (e) {
			this.setState({
				asyncStatus: {
					isBusy: false,
					error: e,
				},
			});
		}
	}

	render() {
		const {policyStatistics, policy, asyncStatus, maintenanceMode} = this.state;
		const {auth} = this.props;

		return (
			<Spinner active={asyncStatus.isBusy} isStatic>
				{(() => {
					if (maintenanceMode) return <MaintenanceDashboard />;
					if (auth.isAuthenticated && DISABLE_AUTHENTICATED_PAGES) return <DisabledAuthPage />;
					return (
						<React.Fragment>
							{policyStatistics && policyStatistics.totalNumberOfPolicies === 0 && <NoPolicyDashboard />}
							{policyStatistics && policyStatistics.totalNumberOfPolicies === 1 && policy && (
								<SinglePolicyDashboard policy={policy} />
							)}
							{policyStatistics && policyStatistics.totalNumberOfPolicies > 1 && (
								<MultiplePoliciesDashboard policyStatistics={policyStatistics} />
							)}
						</React.Fragment>
					);
				})()}
			</Spinner>
		);
	}
}
