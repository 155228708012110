import moment from 'moment';
import { getTypeOfPremium } from '~/core/utils/QuoteUtils';
import QuoteStatus from '~/core/models/enums/QuoteStatus';
import Quote from '~/core/models/Quote';
import { PremiseFactory } from '~/core/models/submodels/Premise';
import { AccountFactory } from '~/core/models/submodels/Account';
import { ContactFactory } from '~/core/models/submodels/Contact';

// Service stores dates in Sydney Timezone
// It does not matter which timezone user is in right now - they should see same Sydney date
// because policy start/ends in Sydney Timezone only
export const dateFromService = m => moment(moment.tz(m, 'Australia/Sydney').format('YYYY-MM-DD'));

export default class QuoteFactory {
	/**
	 * Create a Quote from a quick quote response
	 * @param {object} o - The server response
	 * @param {Quote} quote - existing Quote instance
	 * @returns {Quote}
	 */
	static createQuoteFromQuickQuoteResponse(o, quote) {
		if (!o) {
			return null;
		}

		const q = quote || new Quote();

		q.setId(o.id);
		q.setAccountId(o.attributes.accountId || o.attributes.accountID);
		q.setStatus(QuoteStatus.DRAFT);

		/*
			Do not overwrite periodStartDate and periodEndDate if it has values
			since existing data usually comes from user input.
		*/
		if (o.attributes.periodStartDate && (!quote || (quote && !quote.periodStartDate))) {
			q.setPeriodStartDate(dateFromService(o.attributes.periodStartDate));
		}
		if (o.attributes.periodEndDate && (!quote || (quote && !quote.periodEndDate))) {
			q.setPeriodEndDate(dateFromService(o.attributes.periodEndDate));
		}

		if (o.attributes.paymentPlans && o.attributes.paymentPlans.length) {
			q.setPremium({
				policyLocation: {},
				bindData: {
					paymentPlans: o.attributes.paymentPlans,
				},
				quoteData: {},
			});
		}

		return q;
	}

	/**
	 * Create a Quote from a full quote/retrieve quote response
	 * @param {object} o - The server response
	 * @returns {?Quote}
	 */
	static createQuoteFromObject(o, quote) {
		try {
			if (!o) {
				return null;
			}

			const q = quote || new Quote();
			q.setId(o.id);
			q.setAccountId(o.attributes.accountId);

			q.setStatus(o.attributes.status); // TODO: this is a computed value
			q.setReasonForLowWages(o.attributes.draftData.lobs.wcLine.policyPeriod.reasonLowWages);

			if (o.attributes.draftData.icareTermType) {
				q.setIsDateRationalised(o.attributes.draftData.icareTermType);
			}

			if (o.attributes.draftData.periodStartDate) {
				q.setPeriodStartDate(dateFromService(o.attributes.draftData.periodStartDate));
			}

			if (o.attributes.draftData.periodEndDate) {
				q.setPeriodEndDate(dateFromService(o.attributes.draftData.periodEndDate));
			}

			if (o.attributes.cspToggle) {
				q.setCspToggle(o.attributes.cspToggle);
			}

			if (o.attributes.isCspEditable) {
				q.setIsCspEditable(o.attributes.isCspEditable);
			}

			if (o.attributes.generalistCsps) {
				q.setGeneralistCsps(o.attributes.generalistCsps);
			}

			if (o.attributes.specialistCsps) {
				q.setSpecialistCsps(o.attributes.specialistCsps);
			}

			/**
			 * Map Account
			 */
			q.setAccount(AccountFactory.createFromResponseObject(o.attributes.draftData.account));
			if (o.attributes.draftData.lobs.wcLine.policyPeriod.groupNumber) {
				q.account.setGroupNumber(o.attributes.draftData.lobs.wcLine.policyPeriod.groupNumber);
			} else {
				q.account.setGroupNumber(null);
			}

			if (o.attributes.draftData.lobs.wcLine.policyPeriod.brokerName) {
				q.setBranch(o.attributes.draftData.lobs.wcLine.policyPeriod.brokerName);
			}

			if (o.attributes.draftData.lobs.wcLine.policyPeriod.brokerId) {
				q.setBrokerOrganization(o.attributes.draftData.lobs.wcLine.policyPeriod.brokerOrganisation);
				q.setBrokerGroupId(o.attributes.draftData.lobs.wcLine.policyPeriod.brokerId);
			}

			if (typeof o.attributes.draftData.lobs.wcLine.policyPeriod.wcLabourHireCode === 'string') {
				q.setLabourHireCode(o.attributes.draftData.lobs.wcLine.policyPeriod.wcLabourHireCode);
			}

			// Premise Locations
			/**
			 * Mapping Premises
			 */
			if (
				o.attributes.draftData.lobs.wcLine.policyPeriod.policyLocation &&
				o.attributes.draftData.lobs.wcLine.policyPeriod.policyLocation.length
			) {
				// Primary location
				const primaryLocation = o.attributes.draftData.lobs.wcLine.policyPeriod.policyLocation.find(
					n => n.primaryLocation,
				);
				if (primaryLocation) {
					q.setPrimaryPremise(
						PremiseFactory.createFromResponseObject({
							address: primaryLocation.accountLocation.address,
							wics: primaryLocation.costCenters[0].directWages,
						}),
					);
				}

				// Other locations
				const otherLocations = o.attributes.draftData.lobs.wcLine.policyPeriod.policyLocation.filter(
					n => !n.primaryLocation,
				);
				if (otherLocations && otherLocations.length) {
					q.setPremises(
						otherLocations.map(n =>
							PremiseFactory.createFromResponseObject({
								address: n.accountLocation.address,
								wics: n?.costCenters[0]?.directWages || undefined,
							}),
						),
					);
				}
			}

			/**
			 * Mapping Contacts
			 */
			o.attributes.draftData.accountContact.map((contact, index) => {
				if (index === 0) {
					const primaryContact = ContactFactory.createFromObject(contact);
					if (quote && quote.primaryContact) {
						primaryContact.setUserPrimaryContact(quote.primaryContact.isUserPrimaryContact);
					}
					q.setPrimaryContact(primaryContact);
				} else {
					q.addContact();
				}
			});

			// Payment plans
			if (o.attributes.bindData && o.attributes.quoteData) {
				q.setPremium({
					policyLocation: o.attributes.draftData.lobs.wcLine.policyPeriod.policyLocation,
					bindData: o.attributes.bindData,
					quoteData: o.attributes.quoteData,
				});
			}

			/**
			 * Mapping Type of Premium
			 */
			if (q.primaryPremise && q.premium) {
				q.premium.setTypeOfPremium(getTypeOfPremium(q));
			}

			const policyPeriod = o.attributes.draftData.lobs.wcLine.policyPeriod;
			if (policyPeriod.policy && policyPeriod.policy.priorPolicy && policyPeriod.policy.priorPolicy.length > 0) {
				q.setPreviousSchemeAgent(policyPeriod.policy.priorPolicy[0].wcCarrier);
				q.setPreviousPolicyNumber(policyPeriod.policy.priorPolicy[0].policyNumber);

				if (q.previousPolicyNumber !== null && q.previousSchemeAgent !== null) {
					q.setIsBusinessInsured(true);
				} else {
					q.setIsBusinessInsured(false);
				}
			} else {
				q.setIsBusinessInsured(false);
			}

			return q;
		} catch (e) {
			console.error('Missing required data');
			throw e;
		}
	}
}
