/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import T from 'prop-types';

// iCare components
import Button from '@icare/components/lib/Button';
import LabeledData from '@icare/components/lib/LabeledData';
import LabeledDataCollection from '@icare/components/lib/LabeledDataCollection';
import {Sublayouts, Layout, Aside} from '@icare/layouts';
import IconLinkTile from '@icare/components/lib/IconLinkTile';
import BadgeLinkTile from '@icare/components/lib/IconLinkTile/BadgeLinkTile';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import icon from '@icare/core/statics/icons/icon-miscellaneous-white.svg';
import {HIDE_INSIGHTS, POLICY_BASE_NAME, HIDE_HINDSIGHTS} from '~/config';

import injectAuth from '~/core/decorators/injectAuth';
import {dashboardPaths, quotePaths} from '~/paths';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';

import style from './MultiplePoliciesDashboard.style';

@injectAuth
@injectContent(['mpdb'])
export default class MultiplePoliciesDashboard extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
		user: T.object.isRequired,
		policyStatistics: T.shape({
			totalNumberOfPolicies: T.number,
			totalNumberOfQuotes: T.number,
			paymentDuePolicies: T.number,
			renewalDuePolicies: T.number,
			paymentOverDuePolicies: T.number,
			wagesDeclarationPendingPolicies: T.number,
			cardExpiringPolicies: T.number,
			cancellationDatePolicies: T.number,
		}).isRequired,
		authStore: T.object.isRequired,
		isAuthenticated: T.bool.isRequired,
	};

	/**
	 * Turns a number to a string. If number > 100, return 99+
	 *
	 * @param {number} value
	 * @returns {string}
	 */
	_toIconText(value) {
		return value > 99 ? '99+' : `${value}`;
	}

	/**
	 * Returns a policy listing link with a policyStatus parameter
	 *
	 * @param {string} filterValue Filter value
	 * @returns {string}
	 */
	_toPolicyListingLink(filterValue) {
		return `/${POLICY_BASE_NAME}${dashboardPaths.listingPage}?policyStatus=${filterValue}&currentTab=policyTab`;
	}

	// Analytics
	_triggerAnalytics = title => {
		const {mpdb} = this.props.content;
		if (title === mpdb.f6) {
			AnalyticsHandler.insightsTag(mpdb.f6, this.props.isAuthenticated, this.props.authStore.user.isBroker);
		}
		// Common Analytics event for all tiles under the 'Manage policies' tab
		AnalyticsHandler.multiPolicyTabTileSelection('Manage policies', title);
	};

	render() {
		const {user, policyStatistics, content} = this.props;
		const {mpdb} = content;

		return (
			<React.Fragment>
				<Layout
					type={Layout.Type.TWO_COLUMN_RIGHT}
					aside={
						<Aside>
							<div css={style.totalPane}>
								<div css={style.paneItem}>
									<LabeledDataCollection type={LabeledDataCollection.HORIZONTAL} rows={LabeledDataCollection.Rows.ONE}>
										<LabeledData term={mpdb.d1} definition={policyStatistics.totalNumberOfPolicies} />
									</LabeledDataCollection>
									<Button
										tag="a"
										href={`/${POLICY_BASE_NAME}${dashboardPaths.listingPage}?currentTab=policyTab`}
										variant="tertiary"
										iconName="arrow-right"
									>
										{mpdb.d2}
									</Button>
								</div>
								{user.isBroker && (
									<div css={style.paneItem}>
										<LabeledDataCollection
											type={LabeledDataCollection.HORIZONTAL}
											rows={LabeledDataCollection.Rows.ONE}
										>
											<LabeledData term={mpdb.d3} definition={policyStatistics.totalNumberOfQuotes} />
										</LabeledDataCollection>
										{policyStatistics && policyStatistics.totalNumberOfQuotes > 0 ? (
											<Button
												tag="a"
												href={`/${POLICY_BASE_NAME}${dashboardPaths.listingPage}?currentTab=quoteTab`}
												variant="tertiary"
												iconName="arrow-right"
											>
												{mpdb.d4}
											</Button>
										) : null}
									</div>
								)}
							</div>
						</Aside>
					}
				>
					<div css={style.descriptionPane}>{mpdb.b2}</div>
				</Layout>

				<Layout type={Layout.Type.ONE_COLUMN} css={style.statistics}>
					<Sublayouts
						col1={
							<React.Fragment>
								<h3>{mpdb.e1}</h3>
								<BadgeLinkTile
									iconText={this._toIconText(policyStatistics.paymentDuePolicies)}
									href={this._toPolicyListingLink('paymentDue')}
									title={mpdb.e2}
									text={
										policyStatistics.paymentDuePolicies === 1
											? mpdb.e3_1
											: mpdb.e3_2.replace('${PAYMENT_DUE}', policyStatistics.paymentDuePolicies)
									}
									onClick={() => this._triggerAnalytics(mpdb.e2)}
								/>
								{!HIDE_HINDSIGHTS && (
									<BadgeLinkTile
										iconText={this._toIconText(policyStatistics.wagesDeclarationPendingPolicies)}
										href={this._toPolicyListingLink('hindsightsDue')}
										title={mpdb.e4}
										text={mpdb.e5}
										onClick={() => this._triggerAnalytics(mpdb.e4)}
									/>
								)}
								<BadgeLinkTile
									iconText={this._toIconText(policyStatistics.renewalDuePolicies)}
									href={this._toPolicyListingLink('renewalDue')}
									title={mpdb.e6}
									text={policyStatistics.renewalDuePolicies === 1 ? mpdb.e7_1 : mpdb.e7_2}
									onClick={() => this._triggerAnalytics(mpdb.e6)}
								/>
								{!!policyStatistics.cardExpiringPolicies && (
									<BadgeLinkTile
										iconText={this._toIconText(policyStatistics.cardExpiringPolicies)}
										href={this._toPolicyListingLink('cardExpiry')}
										title={mpdb.e8}
										text={mpdb.e9}
										onClick={() => this._triggerAnalytics(mpdb.e8)}
									/>
								)}
							</React.Fragment>
						}
						col2={
							<React.Fragment>
								<h3>{mpdb.f1}</h3>
								<IconLinkTile
									icon={icon}
									link={`/${POLICY_BASE_NAME}${dashboardPaths.downloadCOCPage}`}
									title={mpdb.f2}
									text={mpdb.f3}
									onClick={() => this._triggerAnalytics(mpdb.f2)}
								/>
								<IconLinkTile
									icon={icon}
									link={`/${POLICY_BASE_NAME}${quotePaths.quoteLandingPage}`}
									title={mpdb.f4}
									text={mpdb.f5}
									onClick={() => this._triggerAnalytics(mpdb.f4)}
								/>
								{!HIDE_INSIGHTS && (
									<IconLinkTile
										icon={icon}
										link={mpdb.f8} /* Removed POLICY_BASE_NAME as current value for this is an external website page. */
										title={mpdb.f6}
										text={mpdb.f7}
										onClick={() => this._triggerAnalytics(mpdb.f6)}
									/>
								)}
							</React.Fragment>
						}
					/>
				</Layout>
			</React.Fragment>
		);
	}
}
