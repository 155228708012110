import Auth from '@icare/auth-okta/lib/Auth';
import {API_SANDBOX_MODE} from '~/config';

/**
 * SuperAgent auth plugin
 * Will automatically add the authrorisation header if the user is authenticated, else will ignore
 * Will add request variables for user verification in the Sandbox Mock API
 *
 * @param req
 * @returns req
 * @example
 *
 * import request from 'superagent';
 * import authBearer from '@icare/auth-okta/lib/authBearer';
 *
 * request.get('/url').use(authBearer).end();
 */
function authBearerSandbox(req) {
	if (req._icareEnd) {
		return req;
	}
	req._icareEnd = true;

	// console.log(`ASYNC authBearer request override`, req);
	const oldEnd = req.end;
	req.end = async function (...args) {
		const auth = await Auth.sharedInstance.getInstance();
		if (auth.isAuthenticated) {
			req.set({Authorization: `Bearer ${auth.getIdToken()}`});
		}

		/**
		 * Setting API_SANDBOX_MODE=true as runtime env variable will add base64 encoded user information the request
		 * Doing this will allow any endpoint (e.g. Mock Sandbox) to use the user details without the need to decode the JWT token
		 */
		if (API_SANDBOX_MODE) {
			const [email, groups] = await Promise.all([auth.getUserEmail(), auth.getUserGroups()]);
			req.set({
				'X-Token-ID1': window.btoa(email),
				'X-Token-ID2': window.btoa(groups.join(',')),
			});
		}

		oldEnd.call(this, ...args);
	};

	return req;
}

export default authBearerSandbox;
