import {observable, action} from 'mobx';
import moment from 'moment';

export default class DocumentSummary {
	@observable name = null; /** @property {?string} */
	@observable type = null; /** @property {?string} */
	@observable source = null; /** @property {?string} */
	@observable policyPeriodStart = null; /** @property {?date} */
	@observable policyPeriodEnd = null; /** @property {?date} */
	@observable uploadDate = null; /** @property {?date} */
	@observable documentUrl = null; /** @property {?string} */
	@observable mime = null; /** @property {?string} */

	/**
	 * Set document name
	 * @param value
	 */
	@action setName(value) {
		this.name = value || null;
	}

	/**
	 * Set document type
	 * @param value
	 */
	@action setType(value) {
		this.type = value || null;
	}

	/**
	 * Set document source
	 * @param value
	 */
	@action setSource(value) {
		this.source = value || null;
	}

	/**
	 * Set document url
	 * @param value
	 */
	@action setDocumentUrl(value) {
		this.documentUrl = value || null;
	}

	/**
	 * Set document mime type
	 * @param value
	 */
	@action setMime(value) {
		this.mime = value || null;
	}

	/**
	 * Set the policy period start date
	 * @param value
	 */
	@action setPeriodStartDate(value) {
		if (!value) {
			this.policyPeriodStart = null;
		} else if (value instanceof moment) {
			this.policyPeriodStart = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the policy period end date
	 * @param value
	 */
	@action setPeriodEndDate(value) {
		if (!value) {
			this.policyPeriodEnd = null;
		} else if (value instanceof moment) {
			this.policyPeriodEnd = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}

	/**
	 * Set the document upload date
	 * @param value
	 */
	@action setUploadDate(value) {
		if (!value) {
			this.uploadDate = null;
		} else if (value instanceof moment) {
			this.uploadDate = value;
		} else {
			throw new TypeError('value must be a moment instance');
		}
	}
}

export class DocumentSummaryFactory {
	static createDocumentSummaryFromResponse(o) {
		if (!o) {
			return null;
		}

		return {
			totalRecords: o.meta.totalRecords,
			totalPages: o.meta.totalPages,
			currentPage: o.meta.currentPage,
			documents: o.data.attributes.documents.map(x => {
				const ds = new DocumentSummary();
				ds.setName(x.metadata.name);
				ds.setType(x.metadata.type);
				ds.setSource(x.metadata.source);
				ds.setDocumentUrl(x.metadata.documentUrl);
				ds.setPeriodStartDate(moment(x.metadata.policyPeriodStart));
				ds.setPeriodEndDate(moment(x.metadata.policyPeriodEnd));
				ds.setUploadDate(moment(x.metadata.uploadDate));
				ds.setMime(x.mime);

				return ds;
			}),
		};
	}
}
