import {observable, action, runInAction} from 'mobx';
import BrokerService from '~/core/services/BrokerService';
import AsyncStatus from '~/core/models/AsyncStatus';
import BrokerageUser from '~/core/models/submodels/BrokerageUser';

/**
 * The Broker Store
 */
export default class BrokerUserStore {
	//region Properties

	/**
	 * The Brokerage id
	 * @type {null}
	 */
	@observable brokerageId = null;

	/**
	 * The User Brokerage id
	 * @type {null}
	 */
	@observable identityId = null;

	/**
	 * The Brokerage Name
	 * @type {null}
	 */
	@observable brokerageName = null;

	/**
	 * Brokerage User
	 * @type {Array}
	 */
	@observable brokerageUser = null;

	/**
	 * List of brokerage users
	 * @type {Array}
	 */
	@observable brokerageUsers = null;

	/**
	 * Sync status of retrieving the User History via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	/**
	 * CRUD Status for brokers
	 * @type {AsyncStatus}
	 */
	@observable crudStatus = new AsyncStatus();

	//endregion

	//region Actions

	/**
	 * Set Brokerage ID
	 */
	@action
	setBrokerageId(value) {
		this.brokerageId = value || null;
	}

	/**
	 * Set User Brokerage/Identity ID
	 */
	@action
	setIdentityId(value) {
		this.identityId = value || null;
	}

	/**
	 * Set Brokerage name
	 */
	@action
	setBrokerageName(value) {
		this.brokerageName = value || null;
	}

	/**
	 * Set Brokerage User
	 */
	@action
	setBrokerageUser(value) {
		if (!value) {
			this.brokerageUser = null;
		} else if (value instanceof BrokerageUser) {
			this.brokerageUser = value;
		} else {
			throw new TypeError('value must be a BrokerageUser instance');
		}
	}

	/**
	 * Set Brokerage users list
	 */
	@action
	setBrokerageUsers(value) {
		if (!value || !value.length) {
			this.brokerageUsers = null;
		} else if (value.length) {
			this.brokerageUsers = value;
		} else {
			throw new Error();
		}
	}

	/**
	 * Get the Brokerage List
	 * if the Role is BROKER set Brokerage List
	 */
	@action
	syncBrokerageUsers = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const brokerageUsers = await BrokerService.searchBrokerageUsers(this.brokerageName);
			runInAction(() => {
				this.setBrokerageUsers(brokerageUsers);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	/**
	 * Add users to the list of selected brokers
	 */
	@action
	addUsersToBrokerages = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			await BrokerService.addUsersToBrokerages(this.brokerageUser);
			runInAction(() => {
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	/**
	 * Edit users in Brokerage User
	 */
	@action
	editUsersToBrokerage = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			await BrokerService.editUsersToBrokerage(this.brokerageId, this.brokerageUser, this.identityId);
			runInAction(() => {
				this.syncBrokerageUsers();
				this.brokerageUser = null;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	/**
	 * Get user in Brokerage User
	 */
	@action
	getUserToBrokerageUsers = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const brokerageUser = await BrokerService.getUserToBrokerageUsers(this.identityId);
			runInAction(() => {
				this.setBrokerageUser(brokerageUser);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	/**
	 * Reset password in Brokerage user
	 */
	@action
	resetPassword = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			await BrokerService.resetPassword(this.brokerageUser, this.identityId);
			runInAction(() => {
				this.brokerageUser = null;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	//endregion
}
