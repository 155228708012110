import {observable, action} from 'mobx';
import WageDeclarationService from '~/core/services/WageDeclarationService';
import AsyncStatus from '~/core/models/AsyncStatus';
import {parseJSON, parseErrorJSONToString} from '~/core/utils/JsonUtility';
import {deleteArrayById} from '~/core/utils/ArrayUtils';

export default class WageDeclarationStore {
	//region Properties

	/**
	 * Data item
	 * @type {?type}
	 */
	@observable preliminaryInfo = null;
	@observable businessDetails = null;
	@observable wageBasedActivities = [];
	@observable nonWageBasedActivities = [];
	@observable areYouBroker = null;
	@observable referenceNumber = null;
	@observable userAction = {
		ADD: 0,
		EDIT: 0,
	};

	/**
	 * Sync status of retrieving the reference data via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();
	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = WageDeclarationService) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * Action
	 */
	@action
	submitRequest({selectedBusinessTypeName = '', selectedTrusteeTypeName = ''}) {
		this.asyncStatus.isBusy = true;
		const {preliminaryInfo, businessDetails, wageBasedActivities, nonWageBasedActivities} = this;

		/* The below two variables are hardcoded as required by the final API endpoint. They are no longer needed after
		removal of estimated wages from this form */
		preliminaryInfo.declarationOf = 'Actual Wages';
		const doEstimateNextYear = 'No';

		const mappedBusinessDetails = {
			...businessDetails,
			businessTypeName: selectedBusinessTypeName,
			trusteeTypeName: selectedTrusteeTypeName,
		};

		return WageDeclarationService.submitRequest({
			preliminaryInfo,
			businessDetails: mappedBusinessDetails,
			doEstimateNextYear,
			wageBasedActivities,
			nonWageBasedActivities,
		})
			.then(response => {
				// Convert response to JSON
				const resultsJSON = parseJSON(response);

				// Valid JSON will always have status. If no status than parsing failed
				// Throw error to above layers
				if (!resultsJSON.status) {
					this._setErrorMessage('Invalid response format. Failed to understand response from server.');
					return;
				}

				// All good
				this._setSuccessMessage(resultsJSON);
			})
			.catch(error => {
				this._setErrorMessage(parseErrorJSONToString(error));
			});
	}

	@action
	setPreliminaryInfo(preliminaryInfo = null) {
		this.preliminaryInfo = preliminaryInfo;
	}

	@action
	getPreliminaryInfo() {
		return this.preliminaryInfo;
	}

	@action
	setBusinessDetail(businessDetails = null) {
		this.businessDetails = businessDetails;
	}

	@action
	getBusinessDetail() {
		return this.businessDetails;
	}

	@action
	setAreYouBroker(flag = null) {
		this.areYouBroker = flag;
	}

	@action
	getAreYouBroker() {
		return this.areYouBroker;
	}

	@action
	setWageFlag(wageFlag = null) {
		this.wageFlag = wageFlag;
	}

	@action
	getWageFlag() {
		return this.wageFlag;
	}

	@action
	getWorkerWages() {
		return this.wageBasedActivities || [];
	}

	@action
	setWorkerWages(wageBasedActivities = []) {
		this.wageBasedActivities = wageBasedActivities;
	}

	@action
	getNonWageBasedActivities() {
		return this.nonWageBasedActivities || [];
	}

	@action
	setNonWageBasedActivities(nonWageBasedActivities = []) {
		this.nonWageBasedActivities = nonWageBasedActivities;
	}

	@action
	deleteWageBasedActivities(wage) {
		this.wageBasedActivities = deleteArrayById(this.wageBasedActivities, wage.id);
		return this.wageBasedActivities;
	}

	@action
	deleteNonWageBasedActivities(wage) {
		this.nonWageBasedActivities = deleteArrayById(this.nonWageBasedActivities, wage.id);
		return this.nonWageBasedActivities;
	}

	@action
	_setErrorMessage = msg => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = false;
		this.asyncStatus.error = typeof msg === 'string' ? msg : msg.toString();
		console.error(msg);
	};

	@action
	_setSuccessMessage = jsonResponse => {
		this.asyncStatus.isBusy = false;
		this.asyncStatus.isSuccess = true;
		this.asyncStatus.error = '';
		this.referenceNumber = jsonResponse.id;
	};
	//endregion
}
