import {observable, computed, action} from 'mobx';
import {AuthorisationFactory, authorisationType} from '~/core/models/submodels/Authorisation';
import AccessLevel from '~/core/models/enums/AccessLevel';

class EmployeeUser {
	/** @type {?string} */
	@observable id = null;

	/** @type {?string} */
	@observable type = null;

	/** @type {?string} */
	@observable firstName = null;

	/** @type {?string} */
	@observable lastName = null;

	/** @type {?string} */
	@observable email = null;

	/** @type {?string} */
	@observable contactNumber = null;

	/** @type {?string} */
	@observable contactId = null;

	/** @type {?string} */
	@observable phoneType = null;

	/** @type {?[import('../submodels/Authorisation').default]} */
	@observable authorisations = []; /** @property {?[Authorisation]} */

	/**
	 * Sets the id
	 *
	 * @param {?string} value
	 */
	@action
	setId(value) {
		this.id = value;
	}

	/**
	 * Sets the type
	 *
	 * @param {?string} value
	 */
	@action
	setType(value) {
		this.type = value;
	}

	/**
	 * Sets the first name
	 *
	 * @param {?string} value
	 */
	@action
	setFirstName(value) {
		this.firstName = value;
	}

	/**
	 * Sets the last name
	 *
	 * @param {?string} value
	 */
	@action
	setLastName(value) {
		this.lastName = value;
	}

	/**
	 * Sets the email
	 *
	 * @param {?string} value
	 */
	@action
	setEmail(value) {
		this.email = value;
	}

	/**
	 * Sets the contact number
	 *
	 * @param {?string} value
	 */
	@action
	setContactNumber(value) {
		this.contactNumber = value;
	}

	/**
	 * Sets the contact id
	 *
	 * @param {?string} value
	 */
	@action
	setContactId(value) {
		this.contactId = value;
	}

	/**
	 * Sets the phone type
	 *
	 * @param {?string} value
	 */
	@action
	setPhoneType(value) {
		this.phoneType = value;
	}

	/**
	 * Sets the authorisations
	 *
	 * @param {?import('../submodels/Authorisation').default} value
	 */
	@action
	setAuthorisations(value) {
		this.authorisations = value;
	}

	/**
	 * Adds new authorisation
	 *
	 * @param {?import('../submodels/Authorisation').default} value
	 */
	@action
	addAuthorisations(value) {
		this.authorisations.push(value);
	}

	@computed
	get accessLevels() {
		const authorisations = this.authorisations.map(item => {
			return item.role.replace(/^\w/, c => c.toUpperCase());
		});

		// Remove duplicates
		const setOfAuths = new Set(authorisations);

		return [...setOfAuths].join(', ');
	}

	/**
	 * Employee user has active authorisations
	 * IF
	 * for any authorisation
	 * newRole is not null and new role is not NO_ACCESS
	 * OR
	 * newRole is null and role is not NO_ACCESS
	 */
	@computed
	get hasActiveAuthorisations() {
		return (
			this.authorisations.filter(authority =>
				authority.newRole ? authority.newRole !== AccessLevel.NO_ACCESS : authority.role !== AccessLevel.NO_ACCESS,
			).length > 0
		);
	}

	/**
	 * Employee user has inactive authorisations
	 * IF
	 * for any authorisation
	 * newRole is not null and newRole is NO_ACCESS
	 * OR
	 * newRole is null and role is NO_ACCESS
	 */
	@computed
	get hasInactiveAuthorisations() {
		return (
			this.authorisations.filter(authority =>
				authority.newRole ? authority.newRole === AccessLevel.NO_ACCESS : authority.role === AccessLevel.NO_ACCESS,
			).length > 0
		);
	}

	@computed
	get toServiceLayerRegisterUser() {
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			mobilePhone: this.contactNumber,
			addDelegatedUser: true,
			addSelfToPolicy: true,
			userType: 'EMPLOYER',
			status: true,
			authorities: [
				{
					userId: null,
					access: this.authorisations
						.filter(authority => authority.newRole !== AccessLevel.NO_ACCESS)
						.map(authority => authority.toServiceLayerAdd),
				},
			],
		};
	}

	@computed
	get toServiceLayerUpdateUserAuthorisations() {
		return this.authorisations
			.filter(item => !!item.newRole)
			.reduce((result, item) => {
				if (item.role && (item.newRole === AccessLevel.NO_ACCESS || item.role !== item.newRole)) {
					result.push(item.toServiceLayerRemoveAuthorisation);
				}
				if (item.newRole !== AccessLevel.NO_ACCESS) {
					result.push(item.toServiceLayerAddAuthorisation);
				}
				return result;
			}, []);
	}

	@computed
	get toServiceLayerResetPassword() {
		return {
			email: this.email,
			firstName: this.firstName,
			lastName: this.lastName,
			delegatedUserId: this.id,
		};
	}

	// end region
}

/**
 * The Authorisation Factory
 */
export class EmployeeUserFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const u = new EmployeeUser();

		u.setId(o.id);
		u.setType(o.type);

		const {authorisations, contactInfo} = o.attributes;

		if (contactInfo) {
			u.setFirstName(contactInfo.firstName);
			u.setLastName(contactInfo.lastName);
			u.setEmail(contactInfo.email);
			u.setContactId(contactInfo.contactId);

			if (contactInfo.phone) {
				u.setContactNumber(contactInfo.phone.contactNumber);
				u.setPhoneType(contactInfo.phone.phoneType);
			}
		}

		u.setAuthorisations(
			(() => {
				const assertions = [];

				if (authorisations && authorisations.length) {
					authorisations
						.filter(item => item.type === authorisationType)
						.forEach(item => {
							item.assertions.forEach(assertion => {
								const policy = AuthorisationFactory.createFromObject(assertion, o.id);
								if (policy) {
									assertions.push(policy);
								}
							});
						});
				}

				return assertions;
			})(),
		);

		return u;
	}

	static createFromFormValues(o) {
		if (!o) {
			return null;
		}
		const u = new EmployeeUser();
		u.setFirstName(o.firstName);
		u.setLastName(o.lastName);
		u.setEmail(o.email);
		u.setContactNumber(o.contactNumber);

		return u;
	}
}
export default EmployeeUser;
