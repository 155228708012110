import {observable, computed} from 'mobx';
import AccessLevel from '~/core/models/enums/AccessLevel';

class Brokerage {
	/**
	 * generic name for group name
	 * @type {?string}
	 */
	@observable groupName = null;
	/**
	 * generic name for broker code
	 * @type {?string}
	 */
	@observable groupId = null;
	/**
	 * generic name for producer code
	 * @type {?string}
	 */
	@observable activeProducerCodePublicId = null;
	/**
	 * @type {?string}
	 */
	@observable accessLevel = null;

	@computed
	get toServiceLayer() {
		return {
			groupName: this.groupName,
			groupId: this.groupId,
			activeProducerCodePublicId: this.activeProducerCodePublicId,
			accessLevel: this.accessLevel.toLowerCase(),
		};
	}
}

/**
 * The Authorisation Factory
 */
export class BrokerageFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}
		const b = new Brokerage();

		b.groupName = o.groupName;
		b.groupId = o.groupId;
		b.activeProducerCodePublicId = o.activeProducerCodePublicId;

		let accessLevel = '';
		switch (o.accessLevel.toLowerCase()) {
			case AccessLevel.ADMIN:
			case AccessLevel.ADMINISTRATOR:
				accessLevel = AccessLevel.ADMIN;
				break;
			case AccessLevel.NO_ACCESS:
				accessLevel = AccessLevel.NO_ACCESS;
				break;
			default:
				accessLevel = AccessLevel.GENERAL;
		}
		b.accessLevel = accessLevel;

		return b;
	}
}
export default Brokerage;
