import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import {EmployeeUserFactory} from '~/core/models/submodels/EmployeeUser';
import EmployeeUserService from '~/core/services/EmployeeUserService';

/**
 * The Employee User Store
 */
export default class EmployeeUserStore {
	//region Properties

	/**
	 * Policy user
	 * @type {Array}
	 */
	@observable users = null;

	/**
	 * Sync status of retrieving the User History via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Actions

	/**
	 * Set users list
	 */
	@action
	setUsers(value) {
		this.users = value;
	}

	/**
	 * Get the Employee user list
	 *
	 */
	@action
	syncUsers = async () => {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const usersResponse = await EmployeeUserService.searchUsers();
			const users = usersResponse
				.filter(user => !!user.attributes.contactInfo)
				.map(EmployeeUserFactory.createFromObject);
			runInAction(() => {
				this.setUsers(users);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
				console.error(e);
			});
		}
	};

	//endregion
}
