import colour from 'color';

export const BRAND_PRIMARY_COLOUR_1 = '#D0006F';
export const BRAND_PRIMARY_COLOUR_1_ACCENT = '#F00080';
export const BRAND_PRIMARY_COLOUR_2 = '#007B83';
export const BRAND_PRIMARY_COLOUR_3 = '#00A3AD';
export const BRAND_PRIMARY_COLOUR_3_ACCENT = '#01BBC6';
export const BRAND_PRIMARY_MAGENTA = '#D0006F';
export const BRAND_PRIMARY_MAGENTA_HOVER = '#F00080';
export const BRAND_PRIMARY_TEAL = '#00A3AD';
export const BRAND_PRIMARY_TEAL_HOVER = '#01BBC6';
export const BRAND_PRIMARY_TEAL_ACCESSIBLE = '#007B83';

export const BRAND_SECONDARY_COLOUR_1 = '#6C2B85';
export const BRAND_SECONDARY_COLOUR_1_ACCENT = '#842AA7';
export const BRAND_SECONDARY_COLOUR_2 = '#D22630';
export const BRAND_SECONDARY_COLOUR_2_ACCENT = '#E81A26';
export const BRAND_SECONDARY_PURPLE = '#6C2B85';
export const BRAND_SECONDARY_PURPLE_HOVER = '#842AA7';
export const BRAND_SECONDARY_RED = '#D22630';
export const BRAND_SECONDARY_RED_HOVER = '#E81A26';
export const BRAND_ERROR_RED = '#CF0000';

export const BRAND_TERTIARY_COLOUR = '#00A9E0';
export const BRAND_TERTIARY_BLUE = '#00A9E0';

export const BRAND_HEADING_COLOUR = '#111111';
export const BRAND_BODY_COPY_COLOUR = '#555';

export const BRAND_LINE_COLOUR = '#CCC';

export const BRAND_ALTERNATE_BACKGROUND_COLOUR = '#F5F5F5';
export const BRAND_MESSAGE_BACKGROUND_COLOUR = '#F2FAFF';

export const BRAND_GREY = '#9CA7AD';
export const BRAND_GREY_BORDER = '#CCC';
export const BRAND_GREY_SLATE = '#111111';
export const BRAND_GREY_DARK = '#555555';
export const BRAND_GREY_MID = '#999999';
export const BRAND_GREY_ACCENT = '#CCCCCC';
export const BRAND_GREY_LIGHT = '#F5F5F5';
export const BRAND_BLACK = '#000';
export const BRAND_WHITE = '#FFF';

export const ICARE_BLUE = BRAND_TERTIARY_COLOUR;
export const ICARE_MAGENTA = BRAND_PRIMARY_COLOUR_1;
export const ICARE_MAGENTA_DARK = '#9E0356';
export const ICARE_MAGENTA_DARKER = '#8A034B';
export const ICARE_PURPLE = BRAND_SECONDARY_COLOUR_1;
export const ICARE_PURPLE_TRANSPARENT = colour(BRAND_SECONDARY_COLOUR_1).fade(0.1).string();
export const ICARE_RED = BRAND_SECONDARY_COLOUR_2;
export const ICARE_RED_ACCESSIBLE = '#DC391D';
export const ICARE_TEAL = BRAND_PRIMARY_COLOUR_3;
export const ICARE_YELLOW = '#FEDB00';
export const ICARE_ORANGE = '#ED8B00';
export const ICARE_ORANGE_LIGHT = '#F78C5C';
export const ICARE_LIME_GREEN = '#78BE20';
export const ICARE_BLACK = '#000';
export const ICARE_WHITE = '#FFF';
export const ICARE_MAGENTA_LIGHT = BRAND_PRIMARY_COLOUR_1_ACCENT;
export const ICARE_PURPLE_LIGHT = BRAND_PRIMARY_COLOUR_1_ACCENT;
export const ICARE_PURPLE_LIGHT_TRANSPARENT = colour(BRAND_SECONDARY_COLOUR_1_ACCENT).fade(0.1).string();
export const ICARE_RED_LIGHT = BRAND_SECONDARY_COLOUR_2_ACCENT;
export const ICARE_TEAL_LIGHT = BRAND_PRIMARY_COLOUR_3_ACCENT;
export const ICARE_TEAL_DARK = BRAND_PRIMARY_COLOUR_2;
export const ICARE_BLUE_LIGHT = '#46ADD8';
export const ICARE_GREY_LIGHTER = BRAND_ALTERNATE_BACKGROUND_COLOUR;
export const ICARE_GREY_DIM = '#656565';
export const ICARE_GREY_LIGHT = '#898989';
export const ICARE_GREY_LIGHTER_HC = colour(BRAND_ALTERNATE_BACKGROUND_COLOUR).darken(0.05).hex();
export const ICARE_GREY_MEDIUM = '#979797';
export const ICARE_GREY_SEMI_DARK = '#4E4E51';
export const ICARE_GREY_DARK = '#212121';
export const ICARE_GREY_DARKER = '#111111';
export const ICARE_GREY_HIGHLIGHT = '#262626';

export const ICARE_POLICY_BLUE = '#2D53AC';
export const ICARE_POLICY_LIGHT_GREY = '#F0F0F0';
export const ICARE_POLICY_BUTTON_HOVER = '#497FB7';
export const ICARE_POLICY_TERTIARY_FOCUS = '#004C97';

export const ICARE_HELP_TEXT_GREY = '#F2F2F2';
export const ICARE_HELP_TEXT_LIGHT_GREY = '#0000001C';

export const ICARE_POLICY_LIGHT_RED = '#F8F8F8';
export const ICARE_SB_LIGHT_GREY = '#b3b3b3';
export const ICARE_BOX_SHADOW_COLOR = '#00000029';
