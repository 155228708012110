import {observable, computed, action} from 'mobx';

/**
 * Contractor
 */
export default class Contractor {
	// region Properties

	/**
	 * Labour component
	 * @type {?number}
	 */
	@observable labourComponent = null;

	/**
	 * Percentage 0-100
	 * @type {?number}
	 */
	@observable percent = null;

	/**
	 * Contractor Type
	 * @type {?string}
	 */
	@observable contractType = null;

	/**
	 * The total contact value
	 * @type {?number}
	 */
	@observable totalContractValue = null;

	/**
	 * The total number of contractors
	 * @type {?number}
	 */
	@observable numberOfContractors = null;

	/**
	 * The description
	 * @type {?string}
	 */
	@observable description = null; /** @property {?string} */

	// endregion

	// region Actions

	/**
	 * Set the labour component
	 * @param {?number} value - The labour component or null
	 */
	@action setLabourComponent(value) {
		if (typeof value !== 'number' && value !== null) {
			throw new TypeError();
		}
		this.labourComponent = value;
	}

	/**
	 * Set the percentage
	 * @param {?number} value - The percentage between 0 - 100 or null
	 */
	@action setPercent(value) {
		if (typeof value === 'undefined' || value === null) {
			this.percent = null;
		} else if (typeof value !== 'number') {
			throw new TypeError();
		} else if (value !== null && (value < 0 || value > 100)) {
			throw new RangeError('Value must be either null or between 0 and 100');
		} else {
			this.percent = value;
		}
	}

	/**
	 * Set the contractor type
	 * @param {?string} value - The contractor work type or null
	 */
	@action setContractorType(value) {
		if (typeof value !== 'string' && value !== null) {
			throw new TypeError();
		} else {
			this.contractType = value;
		}
	}

	/**
	 * Set the total contract value
	 * @param {?number} value - The total value or null
	 */
	@action setTotalContractValue(value) {
		this.totalContractValue = value;
	}

	/**
	 * Set the number of contractors
	 * @param {?number} value - The number of contractors or null
	 */
	@action setNumberOfContractors(value) {
		if (typeof value !== 'number' && value !== null) {
			throw new TypeError();
		}
		this.numberOfContractors = value;
	}

	/**
	 * Set the description
	 * @param {?string} value - The description or null
	 */
	@action setDescription(value) {
		this.description = value || null;
	}

	// endregion

	// region Computed properties

	@computed
	get toServiceLayer() {
		return {
			labourComponent: this.labourComponent,
			percent: this.percent,
			contractType: this.contractType,
			totalValueOfContract: this.totalContractValue, // FQ
			totalContractValue: this.totalContractValue, // QQ
			numberOfContractWorkers: this.numberOfContractors, // FQ
			numberOfContractors: this.numberOfContractors, // QQ
			description: this.description,
		};
	}

	// endregion
}

/**
 * The Contractor Factory
 */
export class ContractorFactory {
	/**
	 * Creates a Contractor from a server response object
	 * @param {object} o - The object
	 * @returns {?Contractor}
	 */
	static createFromResponseObject(o) {
		if (!o) {
			return null;
		}

		const c = new Contractor();
		c.setLabourComponent(o.labourComponent);
		c.setPercent(o.percent);
		c.setContractorType(o.contractType);
		c.setNumberOfContractors(o.numberOfContractWorkers);
		c.setTotalContractValue(o.totalContractValue || o.totalValueOfContract);
		c.setDescription(o.description);
		return c;
	}

	/**
	 * Creates a Contractor from a form object
	 * @param {object} o - The form object
	 * @returns {?Contractor}
	 */
	static createFromFormObject(o) {
		if (!o) {
			return null;
		}

		const c = new Contractor();
		c.setLabourComponent(parseInt(o.labourCost));
		c.setTotalContractValue(parseFloat(o.totalValue));
		c.setNumberOfContractors(parseInt(o.numberOfContractors));
		c.setContractorType(o.contractType);
		return c;
	}
}
