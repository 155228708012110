import React from 'react';
import T from 'prop-types';
import {inject, observer} from 'mobx-react';
import Spinner from '@icare/components/lib/Spinner';

/**
 * Ensures reference data is available and injects prop `referenceData` into wrapped component
 * @param ComponentToWrap
 * @returns {<T extends IReactComponent>(target: T) => (T & IWrappedComponent<T>)}
 * @example
 *
 * @injectReferenceData
 * @observable
 * class MyClass extends React.Component {
 *    constructor(props) {
 *       super(props);
 *       console.log(props.referenceData)
 *    }
 * }
 */
const injectReferenceData = ComponentToWrap => {
	return @inject('referenceDataStore')
	@observer
	class ComponentWithReferenceData extends React.Component {
		//region Properties

		static propTypes = {
			referenceDataStore: T.object.isRequired,
		};

		//endregion

		//region Lifecycle
		componentDidMount() {
			// If there is no reference data in the store, trigger a new sync
			const {referenceDataStore} = this.props;

			if (!referenceDataStore.referenceData) {
				referenceDataStore.sync();
			}
		}

		componentDidUpdate() {
			// If there is no reference data in the store, trigger a new sync
			const {referenceDataStore} = this.props;

			if (!referenceDataStore.referenceData) {
				referenceDataStore.sync();
			}
		}

		//endregion

		//region Render Methods

		render() {
			if (this.props.referenceDataStore.asyncStatus.isBusy) {
				return (
					<div style={{paddingTop: '60px'}}>
						<Spinner active isStatic />
					</div>
				);
			}

			if (this.props.referenceDataStore.asyncStatus.error) {
				// Throw error so error boundary will pick it up
				throw this.props.referenceDataStore.asyncStatus.error;
			}

			// Don't render unless we have data (avoid's any weird race conditions)
			if (!this.props.referenceDataStore.referenceData) {
				return null;
			}

			return <ComponentToWrap referenceData={this.props.referenceDataStore.referenceData} {...this.props} />;
		}

		//endregion
	};
};

export default injectReferenceData;
