import ReactDOMServer from 'react-dom/server';
import ObjectUtils from '@icare/core/lib/utils/ObjectUtils';
import {OKTA_ROLE} from '~/config';
import {PaymentOptions} from '~/core/constants/ApplicationConstants';
import PaymentMethodOptions from '~/core/models/enums/PaymentMethodOptions';
import LabourHireType from '~/core/models/enums/LabourHireType';
import AnalyticsUtils from '~/core/utils/AnalyticsUtils';
import fieldTypes from '@icare/forms/lib/constants/ConfigFormTypes';
import policyTransactionType from '../../pods/policy-wage-declaration/constants/policyTransactionType';
import { estimatedWagesPolicyType } from '../../pods/policy-wage-declaration/constants/policyConstants';

const UserTypeFormats = Object.freeze({
	AUTHBROKER: 'Authenticated Broker',
	AUTHEMPLOYER: 'Authenticated Employer',
	UNAUTH: 'Unauthenticated',
	AUTH: 'Authenticated',
	BROKER: 'Broker',
	EMPLOYER: 'Employer',
});
const LOGIN_SUCCESS_FLAG = 'login-success-flag';

export default class AnalyticsHandler {
	static GA_EVENT = {
		formFieldsAbandonment: 'formFieldsAbandonment',
		abandonLastFieldKey: 'abandonment_field',
		abandonDataLayerKey: 'pageAndFormFieldFlow',
		abandonUserRoleKey: 'user_role_abandoned',
		POLICY_FORM_FIELDS_COMPLETED_DATA_LAYER: 'policyformfieldscompleted',
		POLICY_FORM_FIELD_ABANDONMENT_DATA_LAYER: 'policyformfieldabandonment',
		POLICY_FORM_FIELDS_COMPLETED_LABELS: 'completedpolicyformfieldlabels',
		POLICY_FORM_PAGE: 'policyformpage',
		POLICY_ABANDON_FIELD: 'policyabandonment_field',
	};

	// For storing the details of the currently logged in user.
	// Note that this refreshes whenever the AnalyticsHandler class is referenced.
	static User = null;

	// For storing the paymentPlans retrieved in the Premium page as it is from this point
	// where we can get the premium amount options the user could have chosen from.
	// Note that this refreshes whenever the AnalyticsHandler class is referenced.
	static paymentPlans = null;

	static submitFieldSequenceToGa(
		fieldSequenceDetails,
		pageUrl,
		dataLayerNameSuffix = 'DataLayerNameMissing',
		analyticsStore,
	) {
		if (!fieldSequenceDetails || fieldSequenceDetails.size === 0) {
			return false;
		}
		const fieldSequenceDetailsArray = Array.from(fieldSequenceDetails.values());
		const fieldSequenceOneLineValue = fieldSequenceDetailsArray
			.map(field => {
				return field.label;
			})
			.join(' > ');
		const data = {
			event: AnalyticsHandler.GA_EVENT.POLICY_FORM_FIELDS_COMPLETED_DATA_LAYER + dataLayerNameSuffix,
			[AnalyticsHandler.GA_EVENT.POLICY_FORM_PAGE]: pageUrl,
			[AnalyticsHandler.GA_EVENT.POLICY_FORM_FIELDS_COMPLETED_LABELS]: fieldSequenceOneLineValue,
		};
		ObjectUtils.gaTrackingEvent(data);
		// add to master field sequence of workflow
		analyticsStore.appendToMasterFieldSeq(fieldSequenceOneLineValue);
		return true;
	}

	static submitMasterFieldSequenceToGa(
		submitMasterFieldSequenceToGa,
		pageUrl,
		dataLayerNameSuffix = 'MasterFieldSequenceDataLayerNameMissing',
	) {
		if (!submitMasterFieldSequenceToGa || submitMasterFieldSequenceToGa.length === 0) {
			return false;
		}
		const fieldSequenceOneLineValue = submitMasterFieldSequenceToGa.join(' > ');

		const data = {
			event: AnalyticsHandler.GA_EVENT.POLICY_FORM_FIELD_ABANDONMENT_DATA_LAYER + dataLayerNameSuffix,
			[AnalyticsHandler.GA_EVENT.POLICY_FORM_PAGE]: pageUrl,
			[AnalyticsHandler.GA_EVENT.POLICY_FORM_FIELDS_COMPLETED_LABELS]: fieldSequenceOneLineValue,
		};
		ObjectUtils.gaTrackingEvent(data);
		return true;
	}

	/**
	 * Takes a Map of {@link FieldInfo} that contains field data in sequence of the Map.
	 * It then converts it to a single GA object and pushes it to the GA dataLayer.
	 *
	 * @param {Map<string, FieldInfo} fieldSequenceDetails
	 * @returns {*}
	 */
	static abandonFieldSequenceMapToGa(fieldSequenceDetails, dataLayerNameSuffix = 'DataLayerNameMissing') {
		if (!fieldSequenceDetails || fieldSequenceDetails.size === 0) {
			return false;
		}
		const fieldSequenceDetailsArray = Array.from(fieldSequenceDetails.values());
		const lastAbandonmentFieldValue = fieldSequenceDetailsArray[fieldSequenceDetailsArray.length - 1].label;
		let abandonUserRoleValue = null;
		const fieldSequenceOneLineValue = fieldSequenceDetailsArray
			.map(field => {
				if (field.type === fieldTypes.TILED_RADIO) {
					abandonUserRoleValue = field.value;
				}
				return field.label;
			})
			.join(' > ');

		const data = {
			event: AnalyticsHandler.GA_EVENT.formFieldsAbandonment + dataLayerNameSuffix,
			[AnalyticsHandler.GA_EVENT.abandonDataLayerKey]: fieldSequenceOneLineValue,
			[AnalyticsHandler.GA_EVENT.abandonUserRoleKey]: abandonUserRoleValue,
			[AnalyticsHandler.GA_EVENT.abandonLastFieldKey]: lastAbandonmentFieldValue,
		};
		ObjectUtils.gaTrackingEvent(data);
		return true;
	}

	// TRACK USER ID
	static logUserId(user) {
		if (this.User) {
			// If the User object of the AnalyticsHandler class is not null, userID was already set
			return;
		}

		// Exit if not logged-in
		if (!user || !user.crmContactId) {
			return;
		}

		// Pass User ID data to dataLayer
		AnalyticsUtils.callGaTrackingEvent({
			userID: user.crmContactId,
		});

		// User ID is available in dataLayer, now load GA tag
		AnalyticsUtils.initiateLoadOfGATag();

		// Save user info in the AnalyticsHandler's User object
		const isBroker = user.groups && !!user.groups.find(r => r === OKTA_ROLE.BROKER);
		AnalyticsHandler.setUser({
			usertype: this.userTypeFormatBrokerEmployer(true, isBroker),
			isBroker: isBroker,
			user: user,
		});
	}

	// TRACKING EVENT: Login Error Messages
	static loginError(userName, oktaErrorMessage) {
		const data = {
			event: 'Login Error Messages',
			loginerrormessage: oktaErrorMessage,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: login_success
	static loginSuccess() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'login_success',
				uid: u.user.crmContactId,
				loggedin_usertype: this.userTypeFormat(true, u.isBroker),
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: selectedmaintab
	static tabEvent(tabValue, tabDropDown) {
		const analyticsData = {
			event: 'selectedmaintab',
			maintab: tabValue,
			maintabdropdownsearch: tabDropDown,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: Accessibility Settings
	static selectAccessibilitySettings(option, font) {
		const data = {
			event: 'Accessibility Settings',
			options: option,
			selectedfont: font,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: End Session Now Inactivity
	static inactivityModalEnd() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'End Session Now Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
			AnalyticsHandler.removeUserLoginSuccessFlag();
		}
	}

	// TRACKING EVENT: fifteen min Inactivity
	static inactivityModal() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'fifteen min Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: Continue Session Inactivity
	static inactivityModalContinue() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'Continue Session Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: Inactivity Session Time Out
	static inactivityModalTimeout() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'Inactivity Session Time Out',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
			AnalyticsHandler.removeUserLoginSuccessFlag();
		}
	}

	// TRACKING EVENT: quickquote
	static saveQuote(isAuthenticated, isBroker, pageLocation) {
		const analyticsData = {
			event: 'quickquote',
			usertypequickquote: this.userTypeFormat(isAuthenticated, isBroker),
			pageurlsave: pageLocation,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: takeoutpolicypaymentmethod
	static takeoutPayment(isAuthenticated, isBroker, form) {
		const paymentMethod = form.payment && PaymentMethodOptions.find(m => m.id === form.payment.paymentMethod);
		const analyticsData = {
			event: 'takeoutpolicypaymentmethod',
			takeoutpolicy_payment_method: paymentMethod ? paymentMethod.label : 'Direct debit not setup',
			// With 'paid' in property name
			usertypetakeoutpolicypaid: this.userTypeFormatAuth(isAuthenticated),
			takeoutpolicy_userpaid: this.userTypeFormatBrokerEmployer(isAuthenticated, isBroker),
			takeoutpolicy_typepaid: 'New Policy',
			policystartdatepaid: form.periodStartDate.format('YYYYMMDD'),
			policyenddatepaid: form.periodEndDate.format('YYYYMMDD'),
			businesstypepaid: form.account.businessType,
			abnnumberpaid: form.account.abn ? true : false,
			entitynamepaid: form.account.entityName ? true : false,
			tradingnamepaid: form.account.tradingName ? true : false,
			gstpaid: form.account.gstRegistration,
			businesspremisepaid: form.primaryPremise ? true : false,
			businessstarttradingpaid: form.account.businessStartTrading ? true : false,
			groupofcompaniespaid: form.account.groupNumber ? true : false,
			labourhirepaid: form.labourHireCode !== LabourHireType.NOT_A_LABOUR_HIRE_FIRM ? true : false,
			doyouhaveabrokerpaid: form.isBrokerPresent,
			wiccodepaid: form.primaryPremise.wics.map(e => e.wicActivity.code).join(','),
			wiccodepaid2: form.primaryPremise.wics.map(e => e.wicActivity.wicCode).join(','),
			businessprimaryactivitypaid: form.primaryPremise.wics[0].wicActivity.wicDescription,
			numberofapprenticespaid: form.primaryPremise.wics.map(e => e.numberOfApprentices).reduce((a, b) => a + b, 0),
			numberofcontractorspaid: form.primaryPremise.wics
				.map(e => e.contractorList.map(f => f.numberOfContractors).reduce((c, d) => c + d, 0))
				.reduce((a, b) => a + b, 0),
			numberofworkersexposedtoasbestospaid: form.primaryPremise.wics
				.map(e => e.asbestoses.map(f => f.numberOfWorkersExposed).reduce((c, d) => c + d, 0))
				.reduce((a, b) => a + b, 0),
			totalemployeespaid: form.primaryPremise.totalNumberOfEmployees,
			totalwagespaid: this.currencyCleanUp(form.primaryPremise.totalWages),
			firstnamepaid: form.primaryContact.details.firstName ? true : false,
			lastnamepaid: form.primaryContact.details.lastName ? true : false,
			receivedocumentspreferencepaid: 'Email', //Requirement by business always send to email
			smsnotificationpaid: form.primaryContact.details.smsNotifications ? true : false,
			quotenumberpaid: form.quoteId ? form.quoteId : '',
			annualpremiumpaid: AnalyticsHandler.getPremiumAmount(PaymentOptions.annual),
			quarterlypremiumpaid: AnalyticsHandler.getPremiumAmount(PaymentOptions.quarterly),
			monthlypremiumpaid: AnalyticsHandler.getPremiumAmount(PaymentOptions.monthly),
			selectedpaymentperiodpaid: this.selectedPaymentPlan(form.premium.selectedPaymentPlan),
			paymentannualpaid: this.currencyCleanUp(form.premium.annual),
			paymentquarterlypaid: this.currencyCleanUp(form.premium.quarterly),
			paymentmonthlypaid: this.currencyCleanUp(form.premium.monthly),
			policynumberpaid: form.policyNumber,
			confirmedpremiumpaid: this.getPaymentPremium(form.premium),
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
		AnalyticsHandler.clearPaymentPlans();
	}

	// TRACKING EVENT: renewpolicy
	static renewPolicy(isAuthenticated, isBroker, form) {
		const paymentMethod = form.payment && PaymentMethodOptions.find(m => m.id === form.payment.paymentMethod);
		const analyticsData = {
			event: 'renewpolicy',
			usertypetakeoutpolicy: this.userTypeFormatAuth(isAuthenticated),
			takeoutpolicy_user: this.userTypeFormatBrokerEmployer(isAuthenticated, isBroker),
			takeoutpolicy_payment_method: paymentMethod ? paymentMethod.label : 'Direct debit not setup',
			takeoutpolicy_type: 'Renew Policy',
			policystartdate: form.periodStartDate.format('YYYYMMDD'),
			policyenddate: form.periodEndDate.format('YYYYMMDD'),
			businesstype: form.account.businessType,
			abnnumber: form.account.abn ? true : false,
			entityname: form.account.entityName ? true : false,
			tradingname: form.account.tradingName ? true : false,
			gst: form.account.gstRegistration,
			businesspremise: form.primaryPremise ? true : false,
			businessstarttrading: form.account.businessStartTrading ? true : false,
			groupofcompanies: form.account.groupNumber ? true : false,
			labourhire: form.labourHireCode !== LabourHireType.NOT_A_LABOUR_HIRE_FIRM ? true : false,
			doyouhaveabroker: form.isBrokerPresent,
			wiccode: form.primaryPremise.wics.map(e => e.wicActivity.code).join(','),
			wiccode2: form.primaryPremise.wics.map(e => e.wicActivity.wicCode).join(','),
			businessprimaryactivity: form.primaryPremise.wics[0].wicActivity.wicDescription,
			numberofapprentices: form.primaryPremise.wics.map(e => e.numberOfApprentices).reduce((a, b) => a + b, 0),
			numberofcontractors: form.primaryPremise.wics
				.map(e => e.contractorList.map(f => f.numberOfContractors).reduce((c, d) => c + d, 0))
				.reduce((a, b) => a + b, 0),
			numberofworkersexposedtoasbestos: form.primaryPremise.wics
				.map(e => e.asbestoses.map(f => f.numberOfWorkersExposed).reduce((c, d) => c + d, 0))
				.reduce((a, b) => a + b, 0),
			totalemployees: form.primaryPremise.totalNumberOfEmployees,
			totalwages: this.currencyCleanUp(form.primaryPremise.totalWages),
			firstname: form.primaryContact.details.firstName ? true : false,
			lastname: form.primaryContact.details.lastName ? true : false,
			receivedocumentspreference: 'Email', //Requirement by business always send to email
			smsnotification: form.primaryContact.details.smsNotifications ? true : false,
			quotenumber: form.quoteId ? form.quoteId : '',
			annualpremium: AnalyticsHandler.getPremiumAmount(PaymentOptions.annual),
			quarterlypremium: AnalyticsHandler.getPremiumAmount(PaymentOptions.quarterly),
			monthlypremium: AnalyticsHandler.getPremiumAmount(PaymentOptions.monthly),
			selectedpaymentperiod: this.selectedPaymentPlan(form.premium.selectedPaymentPlan),
			paymentannual: this.currencyCleanUp(form.premium.annual),
			paymentquarterly: this.currencyCleanUp(form.premium.quarterly),
			paymentmonthly: this.currencyCleanUp(form.premium.monthly),
			policynumber: form.policyNumber,
			confirmedpremium: this.getPaymentPremium(form.premium),
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
		AnalyticsHandler.clearPaymentPlans();
	}

	// TRACKING EVENT: editpolicy
	static editPolicy(isBroker, policyId, prePrem, revPrem, changeCost) {
		const analyticsData = {
			event: 'editpolicy',
			usertypeeditpolicy: this.userTypeFormat(true, isBroker),
			policynumber: policyId,
			previouspremium: prePrem,
			revisedpremium: revPrem,
			changeincost: changeCost,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: cancelpolicy
	static cancelPolicy(cancelReason, isAuthenticated, isBroker) {
		const analyticsData = {
			event: 'cancelpolicy',
			usertypecancelpolicy: this.userTypeFormat(isAuthenticated, isBroker),
			cancellationreason: cancelReason,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT:proceedManageYourPolicy - Landing Page
	static proceedManageYourPolicy(prelimInfo) {
		const {policyNumber, policyUniqueCode} = prelimInfo;
		const data = {
			event: 'proceed_manage_policy',
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: managePolicyFormErrors - Landing Page
	static managePolicyFormErrors(form_error_message = null, policy = null) {
		const {policyNumber, policyUniqueCode} = policy || {};
		const data = {
			event: 'manage_policy_form_errors',
			form_error_message,
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	/* Manage Your Policy START */

	// TRACKING EVENT:proceedPolicyCancellation - Manage Your Policy Page
	static proceedPolicyCancellation(prelimInfo) {
		const {policyNumber, policyUniqueCode} = prelimInfo;
		const data = {
			event: 'proceed_policy_cancellation',
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: Chnage CSP Submit Action - Manage Your Policy Page
	static proceedChangeCSP(prelimInfo) {
		const {policyNumber, policyUniqueCode} = prelimInfo;
		const data = {
			event: 'proceed_change_my_provider',
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT:proceedDeclarationWages - Manage Your Policy Page
	static proceedDeclarationWages(prelimInfo) {
		const {policyNumber, policyUniqueCode, policyTransactionTypeTitle, policyTransactionType: transactionType, isRenewalPolicy} = prelimInfo;
		const data = {
			event: 'proceed_declaration_wages',
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
			clicked_tile_message: policyTransactionTypeTitle || null,
		};
		
		if (transactionType === policyTransactionType.ESTIMATED_WAGES) {
			const {renew, update} = estimatedWagesPolicyType;
			data.estimated_wages = isRenewalPolicy ? renew : update;
		}
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: cancelPolicyFormErrors - Manage Your Policy Page
	static cancelPolicyFormErrors(form_error_message = null, policy = null) {
		const {policyNumber, policyUniqueCode} = policy || {};
		const data = {
			event: 'policy_cancellation_form_errors',
			form_error_message,
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: changeServiceFormErrors - Manage Your Policy Page
	static changeServiceFormErrors(form_error_message = null, policy = null) {
		const {policyNumber, policyUniqueCode} = policy || {};
		const data = {
			event: 'policy_csp_form_errors',
			form_error_message,
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: declarationWagesFormErrors - Manage Your Policy Page
	static declarationWagesFormErrors(form_error_message = null, clicked_tile_message_form_error = null, policy = null) {
		const {transactionType, isRenewalPolicy, policyNumber, policyUniqueCode} = policy || {};
		const data = {
			event: 'declaration_wages_form_errors',
			form_error_message,
			clicked_tile_message_form_error,
			policy_number: policyNumber || null,
			customer_reference_code: policyUniqueCode || null,
		};

		if (transactionType === policyTransactionType.ESTIMATED_WAGES) {
			const {renew, update} = estimatedWagesPolicyType;
			data.estimated_wages = isRenewalPolicy ? renew : update;
		}
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	/* Manage Your Policy END */

	/* Policy Submission Page START */

	//TRACKING EVENT: submitCancelPolicyForm - Cancel Policy Submission Page
	static submitCancelPolicyForm(
		who_is_submitting_cancellation = null,
		reason_for_cancellation = null,
		policyNumber = null,
		submission_type = null,
	) {
		const data = {
			event: 'submit_policy_cancellation_success',
			who_is_submitting_cancellation,
			reason_for_cancellation,
			policy_number: policyNumber,
			submission_type,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: submitCSPPolicyForm - CSP Submission Page
	static submitCSPPolicyForm(
		who_is_submitting_csp = null,
		policyNumber = null,
		cspValue = null,
	) {
		const data = {
			event: 'submit_csp_success',
			who_is_submitting_csp,
			policy_number: policyNumber,
			who_is_selecting_csp: cspValue,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: addNewDeclarationWages - Wage Declaration Page
	static addNewDeclarationWages(
		user_action = null,
		who_is_declaring_wages = null,
		submission_type = null,
		reason_for_change = null,
		policy_number = null,
		transactionType = null,
	) {
		const data = {
			event: 'add_new_edit_declaration_wages',
			user_action, // '<"Add Wages" or "Edit Wages" or "Both">',
			who_is_declaring_wages, //'<"Employed by the Business" or "On behalf of the Business">',
			submission_type, //'<"Returned Premium" or "Under Review - UW Issue">',
			reason_for_change, //'<Selected reason for change in the dropdown menu>'
			policy_number,
			clicked_tile_message: transactionType,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: submitCancelPolicyFormErrors - Cancel Policy Submission Page
	static submitCancelPolicyFormErrors(form_error_message = null, policy = null) {
		const {policyNumber} = policy || {};
		const data = {
			event: 'submit_policy_cancellation_form_errors',
			form_error_message,
			policy_number: policyNumber || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: submitCSPPolicyFormErrors - CSP Submission Page
	static submitCSPPolicyFormErrors(form_error_message = null, policy = null) {
		const {policyNumber} = policy || {};
		const data = {
			event: 'submit_csp_form_errors',
			form_error_message,
			policy_number: policyNumber || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	//TRACKING EVENT: addEditDeclarationWagesFormErrors - Wage Declaration Page
	static addEditDeclarationWagesFormErrors(
		add_edit_form_error_message = null,
		which_form_did_error_happen = null,
		reason_for_change_form_error = null,
		policyNumber = null,
	) {
		const data = {
			event: 'add_edit_declaration_wages_form_errors',
			add_edit_form_error_message, //'<Error Message>'
			which_form_did_error_happen, // '<"Add Wages Form" or "Edit Wages Form">'
			reason_for_change_form_error, // '<Selected reason for change in the dropdown menu>'
			policy_number: policyNumber,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}


	/* Policy Submission Page END */

	// TRACKING EVENT: policypaymentdue
	static policyPaymentDue(details, uid = null, isAuth = false, isBroker = false) {
		const data = {
			event: 'policypaymentdue',
			usertypepaymentdue: AnalyticsHandler.userTypeFormatAuth(isAuth),
			userrolepaymentdue: AnalyticsHandler.userTypeFormatBrokerEmployer(isAuth, isBroker),
			policynumberpaymentdue: details.customerReferenceNumber,
			paymentamountpaymentdue: details.paymentAmount,
			invoicenumberpaymentdue: details.paymentReference,
			'&uid': uid,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: addbrokerbyuser
	static addBrokerByUser() {
		const data = {
			event: 'addbrokerbyuser',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: click industry insights
	static insightsTag(insightLinkText, isAuthenticated, isBroker) {
		const analyticsData = {
			event: 'click industry insights',
			industry_insights: insightLinkText,
			usertypeclickindustryinsights: this.userTypeFormat(isAuthenticated, isBroker),
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: errorsummary
	static formErrors(isAuthenticated, isBroker, pagePath, stateForm) {
		const data = {
			event: 'errorsummary',
			action: `${pagePath} - ${this.userTypeFormat(isAuthenticated, isBroker)}`,
			pageandformerrors: AnalyticsHandler.getFormErrors(stateForm),
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: filter results
	static filterPolicyListing(filter, userId) {
		const data = {
			event: 'filter results',
			filter_by: filter,
			uid: userId,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: download coc
	static downloadCoc() {
		const data = {
			event: 'download coc',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: error download coc
	static downloadCocError(error) {
		const data = {
			event: 'error download coc',
			cocerrormessage: error, // format: heading - message
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: selectedmaintab
	static multiPolicyTabTileSelection(tabValue, selections) {
		const analyticsData = {
			event: 'selectedmaintab',
			maintab: tabValue,
			selections: selections,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: add new broker
	static addNewBroker(brokerCode, registrationCode) {
		const data = {
			event: 'add new broker',
			broker_code: brokerCode,
			registration_code: registrationCode,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: cancellation form submission
	static policyCancellationFormSubmitted({details = null, workerWages = null, referenceNumber = null}) {
		const data = {
			event: 'cancel_policy',
			cancel_policy_number: details && details.policyDetailSection ? details.policyDetailSection.policyNumber : null,
			cancel_policy_total_number_of_workers: AnalyticsHandler.policyCancellationTotalWorkers(workerWages),
			cancel_policy_does_your_business_employ: AnalyticsHandler.policyCancellationWorkerTypes(workerWages),
			cancel_policy_reference_number: referenceNumber || null,
			cancellation_reason:
				details && details.policyDetailSection ? details.policyDetailSection.reasonForCancellation : null,
			cancellation_new_insurer:
				details && details.policyDetailSection ? details.policyDetailSection.newInsurer : null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	static wageDeclarationFormSubmitted({referenceNumber = null}) {
		const data = {
			event: 'wage_declaration',
			/* The below two variables are hardcoded. They are no longer needed after removal of estimated wages from wage declaration form */
			declaration: null,
			do_estimate_next_year: null,
			wage_declaration_reference_number: referenceNumber || null,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	static policyCancellationTotalWorkers(workerWages) {
		if (workerWages && workerWages.length > 0) {
			let total = 0;
			workerWages.forEach(({totalNumber}) => {
				total += parseInt(totalNumber, 10);
			});
			return total;
		}
		return null;
	}
	static policyCancellationWorkerTypes(workerWages) {
		return workerWages && workerWages.length && workerWages[0].typeOfWorkers && workerWages[0].typeOfWorkers.length
			? workerWages[0].typeOfWorkers.join('/')
			: null;
	}

	// TRACKING EVENT: wages declaration refund
	static wagesDeclarationRefund() {
		const data = {
			event: 'wages declaration refund',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: wages declaration guidewire
	static wagesDeclarationGuidewire() {
		const data = {
			event: 'wages declaration guidewire',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: wages declaration premise wic paylater
	static wagesDeclarationPaylater() {
		const data = {
			event: 'wages declaration premise wic paylater',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: wages declaration premise wic paynow
	static wagesDeclarationPaynow() {
		const data = {
			event: 'wages declaration premise wic paynow',
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: cost_of_claim_report
	static cocReporting(policyDetails) {
		const analyticsData = {
			event: 'cost_of_claim_report',
			policy_number: policyDetails.cocFormPage.policies.map(e => e.policyNumber).join(','),
			coc_by: policyDetails.cocFormPage.userType || null,
			request_type: policyDetails.cocFormPage.requestType || null,
		};
		AnalyticsUtils.callGaTrackingEvent(analyticsData);
	}

	/**
	 * Helper Methods
	 */

	// Flag that the user has successfully logged in
	static setUserLoginSuccessFlag() {
		const flag = localStorage.getItem(LOGIN_SUCCESS_FLAG);
		const flagParsed = parseInt(flag);
		const flagValue = isNaN(flagParsed) ? 0 : flagParsed;

		// Increment flag and store in localStorage
		localStorage.setItem(LOGIN_SUCCESS_FLAG, flagValue + 1);
	}

	// Clear flag that the user has successfully logged in
	static removeUserLoginSuccessFlag() {
		// Remove item from localStorage
		localStorage.removeItem(LOGIN_SUCCESS_FLAG);
	}

	// Read flag that the user has successfully logged in
	static readUserLoginSuccessFlag() {
		const flag = localStorage.getItem(LOGIN_SUCCESS_FLAG);
		const flagParsed = parseInt(flag);
		const flagValue = isNaN(flagParsed) ? 0 : flagParsed;
		return flagValue;
	}

	// Store current user object
	static setUser(user) {
		this.User = user;
	}

	// Retrieve current user object
	static getUser() {
		return this.User;
	}

	// Get user type (Authenticated or Unauthenticated)
	static userTypeFormatAuth(isAuthenticated) {
		if (isAuthenticated) {
			return UserTypeFormats.AUTH;
		}
		return UserTypeFormats.UNAUTH;
	}

	// Get user type (Authenticated Broker or Authenticated Employer or Unauthenticated)
	static userTypeFormat(isAuthenticated, isBroker) {
		if (isAuthenticated) {
			if (isBroker) {
				return UserTypeFormats.AUTHBROKER;
			}
			return UserTypeFormats.AUTHEMPLOYER;
		}
		return UserTypeFormats.UNAUTH;
	}

	// Get user type (Broker or Employer)
	static userTypeFormatBrokerEmployer(isAuthenticated, isBroker) {
		if (isAuthenticated) {
			if (isBroker) {
				return UserTypeFormats.BROKER;
			}
			return UserTypeFormats.EMPLOYER;
		}
		return '';
	}

	// Selected payment plan value
	static selectedPaymentPlan(paymentId) {
		switch (paymentId) {
			case PaymentOptions.annual:
				return 'annual';
			case PaymentOptions.quarterly:
				return 'quarterly';
			case PaymentOptions.monthly:
				return 'monthly';
		}
		return '';
	}

	// Set the paymentPlans object
	static setPaymentPlans(delegate) {
		if (delegate && delegate.premium && delegate.premium.paymentPlans && delegate.premium.paymentPlans.length) {
			this.paymentPlans = delegate.premium.paymentPlans.map(plan => {
				return {
					id: plan.id,
					amount: plan.amount,
				};
			});
		}
	}

	// Get the paymentPlans object
	static getPaymentPlans() {
		return this.paymentPlans;
	}

	// Clear the paymentPlans object
	static clearPaymentPlans() {
		this.paymentPlans = null;
	}

	// Get premium amount
	static getPremiumAmount(plan) {
		const paymentPlans = AnalyticsHandler.getPaymentPlans();
		if (paymentPlans && paymentPlans.length) {
			const targetPlan = paymentPlans.find(p => p.id === plan);
			if (targetPlan) {
				return targetPlan.amount;
			}
		}
		return '';
	}

	// Get formatted selected premium payment amount
	static getPaymentPremium(premium) {
		switch (premium.selectedPaymentPlan) {
			case PaymentOptions.annual:
				return this.currencyCleanUp(premium.annual);
			case PaymentOptions.monthly:
				return this.currencyCleanUp(premium.monthly);
			case PaymentOptions.quarterly:
				return this.currencyCleanUp(premium.quarterly);
		}
		return '';
	}

	// Remove currency symbol
	static currencyCleanUp(value) {
		if (value) {
			if (typeof value === 'string') {
				return value.replace(/\$/g, '').replace(/,/g, '');
			}
			return value;
		}
		return '';
	}

	// Get the form errors
	static getFormErrors(formData) {
		// Get Errors from each field
		const errors = Object.keys(formData.errors() || []).filter(fieldName => {
			return formData.$(fieldName).error;
		});

		// If there are errors, return formatted
		if (errors.length) {
			const errorsPretty = errors.map(fieldName => {
				if (formData.$(fieldName).label.length) {
					return `${formData.$(fieldName).label} (${formData.$(fieldName).error})`;
				}
				const div = window.document.createElement('div');
				div.innerHTML = ReactDOMServer.renderToString(formData.$(fieldName).label);
				const text = div.innerText;
				return `${text} (${formData.$(fieldName).error})`;
			});

			return errorsPretty.join(', ');
		}

		return '';
	}
}
