const YesNoUnknown = Object.freeze({
	YES: {
		value: 'yes',
		label: 'Yes',
	},
	NO: {
		value: 'no',
		label: 'No',
	},
	UNKNOWN: {
		value: 'unknown',
		label: 'Unknown',
	},
});

export default YesNoUnknown;
