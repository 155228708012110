import React from 'react';
import T from 'prop-types';

import injectContent from '@icare/sitecore-content/lib/injectContent';

import Layout from '@icare/layouts/lib/Layout';
import RichText from '@icare/components/lib/RichText';
import ContentHeader from '@icare/components/lib/ContentHeader';

@injectContent(['maintenancePage'])
export default class MaintenanceDashboard extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
	};

	render() {
		const content = this.props.content.maintenancePage;

		return (
			<Layout type={Layout.Type.ONE_COLUMN}>
				<ContentHeader heading={content.title} />

				<RichText content={content.body} />
			</Layout>
		);
	}
}
