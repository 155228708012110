import {css} from '@emotion/core';

import listStyle from '~/core/styles/lists';

const actionList = css`
	${listStyle.listReset}

	.tag {
		margin-left: 8px;
	}
`;

const actionListItem = css`
	display: inline;

	span {
		vertical-align: middle;
	}
`;

export default {
	actionList,
	actionListItem,
};
