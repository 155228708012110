import React from 'react';
import {useStrict} from 'mobx';
import ConfigProvider from '~/core/utils/ConfigProvider';

useStrict(true);
export default class PolicyOverviewWidgetLoader extends React.Component {
	render() {
		return <ConfigProvider {...this.props}>{() => import('./PolicyOverviewWidgetProvider')}</ConfigProvider>;
	}
}
