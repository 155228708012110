import React from 'react';
import {useStrict} from 'mobx';
import {Provider as MobxProvider} from 'mobx-react';

import AuthProvider from '@icare/auth-okta/lib/AuthProvider';

import SinglePolicyDashboard from '~/pods/dashboard/widgets/PolicyOverviewWidget';
import {OKTA_CONFIG, INACTIVITY_TIMEOUT, INACTIVITY_WARNING} from '~/config';

import stores from '../stores';

useStrict(true);
export default class SinglePolicyDashboardWidget extends React.Component {
	render() {
		return (
			<MobxProvider {...stores}>
				<AuthProvider
					config={OKTA_CONFIG}
					onSessionResume={stores.authStore.updateSession}
					inactivityTimeout={INACTIVITY_TIMEOUT}
					inactivityWarning={INACTIVITY_WARNING}
				>
					<SinglePolicyDashboard />
				</AuthProvider>
			</MobxProvider>
		);
	}
}
