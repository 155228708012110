import { observable, action, computed } from 'mobx';
import { submitterTypes } from '../forms/ChangeClaimsProviderForm'

export default class CSPModel {

    @observable newGeneralistCSP = null;

    @observable newSpecialistCSP = null;

    @observable isCSPEditable = false;

    @observable submitterTypeCSP = null;

    @observable firstName = null;

    @observable lastName = null;

    @observable position = null;

    @observable companyName = null;

    @observable contactNumber = null;

    @observable emailAddress = null;

    /**
     * Set new specialist CSP
     * @param {string} -- New specialist CSP
     */
    @action setNewSpecialistCSP(value) {
        this.newSpecialistCSP = value || null;
    }

    /**
     * Set new generalist CSP
    * @param {string} -- New specialist CSP
     */
    @action setNewGeneralistCSP(value) {
        this.newGeneralistCSP = value || null;
    }

    /**
     * Set CSP editable
     * @param {Boolean} -- CSP Editale
     */
    @action setIsCSPEditable(value) {
        this.isCSPEditable = value || false;
    }

    /**
     * Set Submitter Type
    * @param {string} -- Submitter type
     */
    @action setSubmitterType(value) {
        this.submitterTypeCSP = value || null;
    }

    /**
     * Set Fist name
    * @param {string} -- first Name
     */
    @action setFirstName(value) {
        this.firstName = value || null;
    }

    /**
     * Set last name
    * @param {string} -- last Name
     */
    @action setLastName(value) {
        this.lastName = value || null;
    }

    /**
     * Set position
    * @param {string} -- position
     */
    @action setPosition(value) {
        this.position = value || null;
    }

    /**
     * Set Company name
    * @param {string} -- company Name
     */
    @action setCompanyName(value) {
        this.companyName = value || null;
    }

    /**
     * Set contact number
    * @param {string} -- contact Number
     */
    @action setContactNumber(value) {
        this.contactNumber = value || null;
    }

    /**
     * Set email address
    * @param {string} -- email Address
     */
    @action setEmailAddress(value) {
        this.emailAddress = value || null;
    }

    @computed
    get toServiceLayerNewCSP() {
        const payload = {
            data: {
                attributes: {
                    criteria: {
                        newGeneralistCSP: this.newGeneralistCSP,
                        newSpecialistCSP: this.isCSPEditable ? this.newSpecialistCSP : this.newGeneralistCSP,
                    },
                    submitter: {
                        isEmployee: this.submitterTypeCSP === submitterTypes.EMPLOYED ? true : false,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        position: this.position,
                        company: this.companyName,
                        email: this.emailAddress,
                        contactNumber: this.contactNumber
                    }
                }
            }
        }
        return payload;
    }
}

export class CSPFactory {
    /**
     * Create an Wage from a plain old object
     * @param prelimInfo
     * @returns {?newCsps}
     */
    static createFromFormObject(newCSPs) {
        if (!newCSPs) {
            return null;
        }
        const cspModel = new CSPModel();

        cspModel.setNewGeneralistCSP(newCSPs.policyGeneralCSPs || newCSPs.policyCSPlist);
        cspModel.setNewSpecialistCSP(newCSPs.policySpecialistCSPs);
        cspModel.setIsCSPEditable(newCSPs.isCSPEditable);
        cspModel.setSubmitterType(newCSPs.submitterTypeCSP);
        cspModel.setFirstName(newCSPs.firstName);
        cspModel.setLastName(newCSPs.lastName);
        cspModel.setPosition(newCSPs.position);
        cspModel.setCompanyName(newCSPs.companyName);
        cspModel.setContactNumber(newCSPs.contactNumber);
        cspModel.setEmailAddress(newCSPs.emailAddress);
        return cspModel;
    }
}
