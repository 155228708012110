import {observable, computed, action} from 'mobx';
import moment from 'moment';
import YesNoUnknown from '../enums/YesNoUnknown';
import BusinessType from '../enums/BusinessType';

export default class Account {
	// region Properties

	/**
	 * Account id
	 * @type {?string}
	 */
	@observable id = null;

	/**
	 * Business Type
	 * @type {?string}
	 */
	@observable businessType = null;

	/**
	 * The Australian business number (abn)
	 * @type {?number}
	 */
	@observable abn = null;

	/**
	 * The Australian company number (acn)
	 * @type {?number}
	 */
	@observable acn = null;

	/**
	 * The business/trust name
	 * @type {?string}
	 */
	@observable entityName = null;

	/**
	 * The Trading name
	 * @type {?string}
	 */
	@observable tradingName = null;

	/**
	 * The trust Australian business number (abn)
	 * @type {?string}
	 */
	@observable trustAbn = null;

	/**
	 * The trust name
	 * @type {?string}
	 */
	@observable trustName = null;

	/**
	 * The trustee type
	 * @type {?TrusteeType}
	 */
	@observable trusteeType = null; /** @property {?string} */

	/**
	 * The trusts Australian business number
	 * @type {?string}
	 */
	@observable trusteeABN = null;

	/**
	 * The trustee name
	 * @type {?string}
	 */
	@observable trusteeName = null;

	/**
	 * True if the business/company is registered for GST
	 * @type {boolean}
	 */
	@observable gstRegistration = false;

	/**
	 * The ITC Entitlement
	 * @type {string}
	 */
	@observable itcEntitlement = '0';

	/**
	 * The date the business started trading
	 * @type {?moment}
	 */
	@observable businessStartTrading = null;

	/**
	 * The group number
	 * @type {?string}
	 */
	@observable groupNumber = null; //TODO: are we missing the broker fields here?

	/**
	 * The group name
	 * @type {?string}
	 */
	@observable groupName = null;

	/**
	 *
	 * @type {?boolean}
	 */
	@observable isVedaValidated = null;

	/**
	 * Public Id
	 * @type {string}
	 */
	@observable publicId = null;

	/**
	 * Account Id
	 * @type {?string}
	 */
	@observable accountId = null;

	/**
	 * Account Holder
	 * @type {?string}
	 */
	@observable accountHolder = null;

	/**
	 * Primary Language
	 */
	@observable primaryLanguage = 'en_US';

	/** @type {boolean} */
	@observable isMemberGroupOfCompanies = null;

	// endregion

	// region Actions

	/**
	 * Set the account id
	 * @param {?string} value - The account id
	 */
	@action setId(value) {
		this.id = value || null;
	}

	/**
	 * Set the business type
	 * @param {BusinessType} value - The business type
	 */
	@action setBusinessType(value) {
		this.businessType = value || null;
	}

	/**
	 * Set the abn
	 * @param {?string} value - The abn
	 */
	@action setAbn(value) {
		this.abn = value || null;
	}

	/**
	 * Set the acn
	 * @param {?string} value - The acn
	 */
	@action setAcn(value) {
		this.acn = value || null;
	}

	/**
	 * Set the entity name
	 * @param {?string} value - The entity name
	 */
	@action setEntityName(value) {
		this.entityName = value || null;
	}

	/**
	 * Set the trading name
	 * @param {?string} value - The trading name
	 */
	@action setTradingName(value) {
		this.tradingName = value || null;
	}

	/**
	 * Set the trust abn
	 * @param {?string} value - The trust abn
	 */
	@action setTrustAbn(value) {
		this.trustAbn = value || null;
	}

	/**
	 * Set the trust name
	 * @param {?string} value - The trust name
	 */
	@action setTrustName(value) {
		this.trustName = value || null;
	}

	/**
	 * Set the ITC entitlement
	 * @param {?string} value - The itc entitlement
	 */
	@action setItcEntitlement(value) {
		this.itcEntitlement = value;
	}

	/**
	 * Set the GST Registration
	 * @param {boolean} value - The registration status
	 */
	@action setGstRegistration(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError();
		} else {
			this.gstRegistration = value;
		}
	}

	/**
	 * Set the trustee type
	 * @param {?TrusteeType} value - The trustee type
	 */
	@action setTrusteeType(value) {
		this.trusteeType = value || null;
	}

	/**
	 * Set the trustee acn
	 * @param {?string} value - The trustee acn
	 */
	@action setTrusteeAcn(value) {
		this.trusteeACN = value || null;
	}

	/**
	 * Set the trustee abn
	 * @param {?string} value - The trustee abn
	 */
	@action setTrusteeAbn(value) {
		this.trusteeABN = value || null;
	}

	/**
	 * Set the trustee name
	 * @param {?string} value - The trustee name
	 */
	@action setTrusteeName(value) {
		this.trusteeName = value || null;
	}

	/**
	 * Set the date the business started trading
	 * @param {?moment} value - The date the business started trading
	 */
	@action setBusinessStartTrading(value) {
		if (!value) {
			this.businessStartTrading = value;
		} else if (value instanceof moment) {
			this.businessStartTrading = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Set the group number
	 * @param {?string} value - The group number
	 */
	@action setGroupNumber(value) {
		if (!value) {
			this.groupNumber = null;
			this.isMemberGroupOfCompanies = false;
		} else {
			this.groupNumber = value;
			this.isMemberGroupOfCompanies = true;
		}
	}

	/**
	 * Set the group name
	 * @param {?string} value - The group name
	 */
	@action setGroupName(value) {
		this.groupName = value || null;
	}

	/**
	 * Set the isVedaValidated
	 * @param {?boolean} value
	 */
	@action setVedaValidation(value) {
		if (typeof value !== 'boolean') {
			this.isVedaValidated = null;
		} else {
			this.isVedaValidated = value;
		}
	}

	/**
	 * Set the public id
	 * @param {?string} value - The public id
	 */
	@action setPublicId(value) {
		this.publicId = value;
	}

	/**
	 * Set the account Id
	 * @param {?string} value - The account Id
	 */
	@action setAccountId(value) {
		this.accountId = value;
	}

	/**
	 * Set account holder
	 * @param {?string} value
	 */
	@action setAccountHolder(value) {
		this.accountHolder = value || null;
	}

	/**
	 * Set Primary Language
	 * @param {?string} value
	 */
	@action setPrimaryLanguage(value) {
		this.primaryLanguage = value;
	}

	/**
	 * Set isMemberGroupOfCompanies
	 * @param {?boolean} value
	 */
	@action setIsMemberGroupOfCompanies(value) {
		if (typeof value !== 'boolean') {
			throw new TypeError();
		} else {
			this.isMemberGroupOfCompanies = value;
		}
	}

	// endregion

	// region Computed properties

	/**
	 * True if a member of group companies
	 * @returns {boolean}
	 */
	@computed get memberOfGroupCompanies() {
		return this.groupNumber !== null;
	}

	/**
	 * Returns an account service layer object
	 * @returns {object}
	 */
	@computed get toServiceLayer() {
		let _abn = this.abn ? this.abn.replace(/\s/g, '') : null;
		if (!_abn) {
			_abn = this.trusteeABN ? this.trusteeABN.replace(/\s/g, '') : null;
		}

		let _acn = this.acn ? this.acn.replace(/\s/g, '') : null;
		if (!_acn) {
			_acn = this.trusteeACN ? this.trusteeACN.replace(/\s/g, '') : null;
		}

		return {
			displayName: this.entityName,
			companyName: this.entityName,
			entityName: this.entityName,
			accountOrgType: this.businessType,
			abn: _abn,
			acn: _acn,
			trusteeType: this.trusteeType,
			trustABN: this.trustAbn ? this.trustAbn.replace(/\s/g, '') : null,
			trustName: this.trustName,
			trusteeName: this.trusteeName,
			tradingBusinessName: this.tradingName,
			isVedaValidated: this.isVedaValidated,
			gstRegistration: this.gstRegistration,
			itcEntitlement: this.gstRegistration ? `${this.itcEntitlement}` : '0',
			commencementDate: this.businessStartTrading ? this.businessStartTrading.toISOString() : null,
			primaryLanguage: this.primaryLanguage,
		};
	}

	/**
	 * Returns an account service layer object - Renewal Policy
	 * @returns {object}
	 */
	@computed get toServiceLayerPolicyEdit() {
		let _abn = this.abn ? this.abn.replace(/\s/g, '') : null;
		if (!_abn) {
			_abn = this.trusteeABN ? this.trusteeABN.replace(/\s/g, '') : null;
		}

		let _acn = this.acn ? this.acn.replace(/\s/g, '') : null;
		if (!_acn) {
			_acn = this.trusteeACN ? this.trusteeACN.replace(/\s/g, '') : null;
		}

		return {
			displayName: this.entityName,
			companyName: this.entityName,
			entityName: this.entityName,
			accountOrgType: this.businessType,
			abn: _abn,
			acn: _acn,
			trusteeType: this.trusteeType,
			trustABN: this.trustAbn ? this.trustAbn.replace(/\s/g, '') : null,
			trustName: this.trustName,
			trusteeName: this.trusteeName,
			tradingBusinessName: this.tradingName,
			gstRegistration: this.gstRegistration,
			itcEntitlement: this.gstRegistration ? `${this.itcEntitlement}` : '0',
			commencementDate: this.businessStartTrading ? this.businessStartTrading.toISOString() : null,
			primaryLanguage: this.primaryLanguage,
			isVedaValidated: this.isVedaValidated,
			publicId: this.publicId,
			accountId: this.accountId,
			accountHolder: this.accountHolder,
		};
	}

	// endregion
}

/**
 * The Account factory
 */
export class AccountFactory {
	/**
	 * Creates an account from a server response object
	 * @param {object} o - The object
	 * @returns {?Account}
	 */
	static createFromResponseObject(o) {
		if (!o) {
			return null;
		}

		const a = new Account();
		a.setId(o.id || o.accountId || o.accountID);
		a.setBusinessType(o.accountOrgType);

		const _abn = o.abn || o.Abn || o.trustABN;
		if (_abn) {
			a.setAbn(_abn);
			a.setTrusteeAbn(_abn);
		}

		const _acn = o.acn || o.Acn;
		if (_acn) {
			a.setAcn(_acn);
		}

		// Populate Trustee ACN
		if (o.accountOrgType && o.accountOrgType === BusinessType.TRUST && _acn) {
			a.setTrusteeAcn(_acn);
		}

		if (o.companyName) {
			a.setEntityName(o.companyName);
		}

		if (o.tradingBusinessName) {
			a.setTradingName(o.tradingBusinessName);
		}

		const _trustAbn = o.trustAbn || o.trustABN;
		if (_trustAbn) {
			a.setTrustAbn(_trustAbn);
		}

		if (o.trustName) {
			a.setTrustName(o.trustName);
		}

		if (o.trusteeType) {
			a.setTrusteeType(o.trusteeType);
		}

		if (o.trusteeName) {
			a.setTrusteeName(o.trusteeName);
		}

		if (typeof o.itcEntitlement === 'string') {
			a.setItcEntitlement(o.itcEntitlement);
		}

		if (o.groupNumber) {
			a.setGroupNumber(o.groupNumber);
		}

		if (typeof o.gstRegistration === 'boolean') {
			a.setGstRegistration(o.gstRegistration);
		}

		if (o.commencementDate) {
			a.setBusinessStartTrading(moment(o.commencementDate));
		}

		if (typeof o.isVedaValidated === 'boolean') {
			a.setVedaValidation(o.isVedaValidated);
		}

		if (o.publicId) {
			a.setPublicId(o.publicId);
		}

		if (o.accountId) {
			a.setAccountId(o.accountId);
		}

		if (o.accountHolder) {
			a.setAccountHolder(o.accountHolder);
		}

		if (o.primaryLanguage) {
			a.setPrimaryLanguage(o.primaryLanguage);
		}

		return a;
	}

	/**
	 * Create an Account from a plain old object
	 * @param o
	 * @returns {?Account}
	 */
	static createFromFormObject(o) {
		if (!o) {
			return null;
		}

		const a = new Account();
		a.setId(o.id);
		a.setBusinessType(o.businessType);
		a.setAbn(o.Abn || o.trusteeABN);
		a.setAcn(o.Acn || o.trusteeAcnArbn);
		a.setEntityName(o.entityName || o.companyName);
		a.setTradingName(o.tradingName);
		a.setTrustAbn(o.trustABN);
		a.setTrustName(o.trustName);
		a.setTrusteeType(o.trusteeType);
		a.setTrusteeName(o.trusteeName);
		a.setItcEntitlement(o.itcEntitlement);
		a.setGroupNumber(o.groupNumber);
		a.setGroupName(o.groupName);
		a.setPublicId(o.publicId);
		a.setAccountId(o.accountId);
		a.setAccountHolder(o.accountHolder);
		a.setPrimaryLanguage(o.primaryLanguage);
		if (typeof o.registeredForGST === 'string') {
			const registeredForGST = o.registeredForGST.toLowerCase();
			a.setGstRegistration(
				registeredForGST === YesNoUnknown.YES.value || registeredForGST === YesNoUnknown.UNKNOWN.value,
			);
		} else if (typeof o.registeredForGST === 'boolean') {
			a.setGstRegistration(o.registeredForGST);
		}

		if (o.businessStartTrading) {
			a.setBusinessStartTrading(moment(o.businessStartTrading, 'DD/MM/YYYY'));
		}

		return a;
	}
}
