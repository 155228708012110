import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import Policy from '~/core/models/Policy';
import PolicyServices from '~/core/services/PolicyServices';

/**
 * The Hindsight Store
 */
export default class PolicyHindsightStore {
	//region Properties

	/**
	 * Main Policy instance
	 * @type {Policy}
	 */
	@observable policy = null;

	/**
	 * Sync status of retrieving the Policy via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	/**
	 * The value of the original total wages, to be used
	 * in the wages page for the wage notification modal.
	 * Only set on getPolicy call (first page)
	 */
	@observable originalTotalWages = null;

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PolicyServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * New Quote Store object
	 */
	@action
	initPolicy(policy = null) {
		this.policy = policy || new Policy();
	}

	/**
	 * Set policy
	 */
	@action
	setPolicy(policy) {
		this.policy = policy;
	}

	/**
	 * Set policy
	 */
	@action
	clearPolicy() {
		this.policy = null;
		this.originalTotalWages = null;
	}

	/**
	 * Get Policy Hindsight Details
	 */
	@action
	async getPolicyHindsight(policyId, termNumber) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const policy = await this.api.getPolicy(policyId, termNumber);

			runInAction(() => {
				this.setPolicy(policy);
				this.originalTotalWages = policy.totalWagesAcrossPremises;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Update policy hindsight
	 */
	@action
	async updatePolicyHindsight(policy) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const p = await this.api.updatePolicyHindsight(policy);
			runInAction(() => {
				this.setPolicy(p);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Rebind policy hindsight
	 */
	@action
	async rebindPolicyHindsight(policy, callback) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const p = await this.api.rebindPolicyHindsight(policy);
			runInAction(async () => {
				this.setPolicy(p);
				await callback();
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	//endregion
}
