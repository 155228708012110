import {observable, computed, action} from 'mobx';
import {addressToString} from '~/core/utils/DataUtils';
import AddressType from '~/core/models/enums/AddressType';

export default class Address {
	// region Properties

	@observable id = null; /** @property {?string} */
	@observable type = null; /** @property {?string} */
	@observable addressLine1 = null; /** @property {?string} */
	@observable addressLine2 = null; /** @property {?string} */
	@observable addressLine3 = null; /** @property {?string} */
	@observable locality = null; /** @property {?string} */
	@observable postalCode = null; /** @property {?string} */
	@observable state = null; /** @property {?string} */
	@observable country = null; /** @property {?string} */
	@observable displayName = null; /** @property {?string} */

	// endregion

	// region Actions

	@action setId(value) {
		this.id = value || null;
	}
	@action setType(value) {
		this.type = value || null;
	}
	@action setAddressLine1(value) {
		this.addressLine1 = value || null;
	}
	@action setAddressLine2(value) {
		this.addressLine2 = value || null;
	}
	@action setAddressLine3(value) {
		this.addressLine3 = value || null;
	}
	@action setCity(value) {
		this.locality = value || null;
	}
	@action setPostalCode(value) {
		this.postalCode = value || null;
	}
	@action setCountry(value) {
		this.country = value || null;
	}
	@action setDisplayName(value) {
		this.displayName = value || null;
	}
	@action setState(value) {
		this.state = value || null;
	}

	// end region

	// region Computed Properties

	@computed
	get isAddressVerified() {
		return this.id !== null;
	}

	@computed
	get unstructured() {
		return addressToString(this);
	}

	@computed
	get value() {
		return {
			id: this.id,
			addressLine1: this.addressLine1,
			addressLine2: this.addressLine2,
			addressLine3: this.addressLine3,
			locality: this.locality,
			postalCode: this.postalCode,
			province: this.state,
			country: this.country,
			displayName: this.displayName,
			unstructured: addressToString(this),
		};
	}

	@computed
	get toServiceLayer() {
		return {
			publicId: this.id,
			addressType: this.type || AddressType.POSTAL,
			addressLine1: this.addressLine1,
			addressLine2: this.addressLine2,
			addressLine3: this.addressLine3,
			city: this.locality,
			postalCode: this.postalCode,
			state: this.state ? `AU_${this.state}` : null,
			country: this.country ? this.country.replace(/AUS/i, 'AU') : null,
			isAddressVerified: this.isAddressVerified,
			displayName: this.displayName || addressToString(this),
		};
	}

	// endregion
}

export class AddressFactory {
	static createAddressFromObject(o) {
		if (!o) {
			return null;
		}

		const a = new Address();
		a.setId(o.id || o.publicId);
		a.setType(o.type || o.addressType || AddressType.BUSINESS);
		a.setAddressLine1(o.addressLine1);
		a.setAddressLine2(o.addressLine2);
		a.setAddressLine3(o.addressLine3);
		a.setCity(o.locality || o.city);
		a.setPostalCode(o.postalCode || o.postCode);

		// if province is less than 3 characters, default to province else to state
		// we default to province because in manual mode, province is the one
		// updating and not state
		const state = o.province && o.province.length < 4 && o.province.toUpperCase() === o.province ? o.province : o.state;
		a.setState(typeof state === 'string' ? state.replace(/AU_/i, '') : state);
		const country = o.country;
		a.setCountry(country === 'AU' ? 'AUS' : country);
		a.setDisplayName(o.displayName || o.unstructuredAddress);

		return a;
	}
}
