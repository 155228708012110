import {observable, action} from 'mobx';
import moment from 'moment-timezone';
import uuidv4 from 'uuid/v4';

const DATE_FORMAT = 'DD/MM/YYYY';

export default class TaxiModel {

	@observable id = uuidv4();
	
	@observable taxiPlateFixedID = null;
	
	@observable taxiPlate = null;
	
	@observable taxiPlateStartDate = null;
	
	@observable taxiPlateEndDate = null;

	 
	/**
	 * Set the taxiPlateFixedID type
	 * @param {string} value - The taxiPlateFixedID type
	 */
	@action setTaxiPlateFixedId(value) {
		this.taxiPlateFixedID = value || null;
	}

	/**
	 *
	 * @param {string} value
	 */
	@action setTaxiPlate(value) {
		this.taxiPlate = value || '';
	}

	/**
	 *
	 * @param {Date} value
	 */
	@action setTaxiPlateStartDate(value) {
		const dateToService = moment.tz(moment(value).format('YYYY-MM-DD'), 'Australia/Sydney').add(1, 'minute');
		this.taxiPlateStartDate =
			dateToService || moment.tz(moment(new Date()).format('YYYY-MM-DD'), 'Australia/Sydney').add(12, 'months');
	}

	/**
	 *
	 * @param {Date} value
	 */
	@action setTaxiPlateEndDate(value) {
		const dateToService = moment.tz(moment(value).format('YYYY-MM-DD'), 'Australia/Sydney').add(1, 'minute');
		this.taxiPlateEndDate =
			dateToService || moment.tz(moment(new Date()).format('YYYY-MM-DD'), 'Australia/Sydney').add(12, 'months');
	}
}

export class TaxiFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param taxi
	 * @returns {?TaxiModel}
	 */
	static createFromServiceObject(taxi) {
		if (!taxi) {
			return null;
		}
		const taxiModel = new TaxiModel();
	 
		taxiModel.setTaxiPlateFixedId(taxi.taxiPlateFixedID);
		taxiModel.setTaxiPlate(taxi.taxiPlate);
		taxiModel.setTaxiPlateStartDate(taxi.taxiPlateStartDate);
		taxiModel.setTaxiPlateEndDate(taxi.taxiPlateEndDate);
		return taxiModel;
	}

	static createFromFormObject(taxi) {
		if (!taxi) {
			return null;
		}

		const taxiModel = new TaxiModel();
	 
		taxiModel.setTaxiPlateFixedId(taxi.taxiPlateFixedID);
		taxiModel.setTaxiPlate(taxi.taxiPlate);
		taxiModel.setTaxiPlateStartDate(taxi.taxiPlateStartDate ? moment(taxi.taxiPlateStartDate, DATE_FORMAT) : null);
		taxiModel.setTaxiPlateEndDate(taxi.taxiPlateEndDate ? moment(taxi.taxiPlateEndDate, DATE_FORMAT) : null);

		return taxiModel;
	}
}
