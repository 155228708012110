import {observable, action, runInAction} from 'mobx';
import Auth from '@icare/auth-okta/lib/Auth';
import AsyncStatus from '~/core/models/AsyncStatus';
import {Quote} from '~/core/models/Quote';
import QuoteServices from '~/core/services/QuoteServices';

/**
 * The Quote Store
 */
export default class QuoteStore {
	//region Properties

	/**
	 * Main Quote instance
	 * @type {Quote}
	 */
	@observable quote = null;

	/**
	 * Sync status of retrieving the Quote via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = QuoteServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * New Quote Store object
	 */
	@action
	initQuote(quote = null) {
		this.quote = quote || new Quote();
	}

	/**
	 * Set Quote Store object
	 */
	@action
	setQuote(quote) {
		this.quote = quote || null;
	}

	/**
	 * This will set the quote to null
	 * @returns {Promise<void>}
	 */
	@action
	destroyQuote() {
		this.quote = null;
	}

	/**
	 * Get Estimated Premium (Quick Quote)
	 */
	@action
	async quickQuote(isQuickQuoteFlow = false) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const auth = Auth.sharedInstance;

			const resp = await this.api.quickQuote(this.quote, auth && auth.isAuthenticated, isQuickQuoteFlow);
			runInAction(() => {
				this.setQuote(resp);
				this.asyncStatus.isBusy = false;
			});
			return this.quote;
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Update Quote (Full Quote)
	 */
	@action
	async updateQuote() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const auth = Auth.sharedInstance;

			const quote = await this.api.updateQuote(this.quote, auth && auth.isAuthenticated);
			runInAction(() => {
				this.setQuote(quote);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Save Quote
	 */
	@action
	async saveQuote() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const auth = Auth.sharedInstance;

			const resp = await this.api.saveQuote(this.quote, auth && auth.isAuthenticated);
			runInAction(() => {
				this.setQuote(resp);
				this.asyncStatus.isBusy = false;

				// Check if API call was succesful
				if (resp.id) {
					this.asyncStatus.isSuccess = true;
				}
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	/**
	 * Sync Quote with the server
	 * @returns {Promise<void>}
	 */
	@action
	async sync() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const resp = await this.api.getQuote();
			runInAction(() => {
				this.setQuote(resp);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	@action
	async getQuote(id, email) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const auth = Auth.sharedInstance;

			const quote = await QuoteServices.getQuote(id, auth && auth.isAuthenticated, email);
			runInAction(() => {
				this.setQuote(quote);
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	//endregion
}
