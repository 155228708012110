/* eslint-disable */
import {observable, computed, action} from 'mobx';

import {formatCurrency} from '@icare/core/lib/utils/FormattingUtils';

import PerCapitaWicUtils from '~/core/utils/PerCapitaWicUtils';
import WorkerTypes from '../enums/WorkerTypes';
import {AsbestosFactory} from './Asbestos';
import {ContractorFactory} from './Contractor';
import {TaxiFactory} from './Taxi';
import WicActivity, {WicActivityFactory} from './WicActivity';

/**
 * Wic
 */
export class Wic {
	// region Properties

	/** The business description
	 * @type {?string}
	 * */
	@observable businessDescription = null;

	/** Worker Types
	 * @type {Array<WorkerTypes>}
	 * */
	@observable typeOfWorkers = [];

	/**
	 * Gross Value
	 * @type {?number}
	 */
	@observable grossValue = null;

	/**
	 * Apprentice wages
	 * @type {number}
	 */
	@observable apprenticesWages = 0;

	/**
	 * Number of apprentices
	 * @type {?number}
	 */
	@observable numberOfApprentices = 0;

	/**
	 * Number of employee's
	 * @type {number}
	 */
	@observable numberOfEmployees = 0;

	/**
	 * Number of units
	 * @type {?number}
	 */
	@observable numberOfUnits = 0;

	/**
	 * Wic activity
	 * @type {WicActivity}
	 */
	@observable wicActivity = new WicActivity();

	/**
	 * Contractors
	 * @type {Array<import('../submodels/Contractor').default>}
	 */
	@observable contractors = [];

	/**
	 * Asbestoses exposed workers
	 * @type {Array<import('../submodels/Asbestos').default>}
	 */
	@observable asbestoses = [];

	/**
	 * Taxi plates
	 * @type {Array<import('../submodels/Taxi').default>}
	 */
	@observable taxis = [];

	/**
	 * Verified WIC
	 * @type {boolean}
	 */
	@observable isVerified = false;

	// endregion

	// region Actions

	/**
	 * Set the contractors
	 * @param {?Array<import('../submodels/Contractor').default>} value - The contractors
	 */
	@action setContractors(value) {
		if (!Array.isArray(value)) {
			throw new TypeError();
		}

		this.contractors = value;
	}

	/**
	 * Set the business description
	 * @param {string} value - The description
	 */
	@action setBusinessDescription(value) {
		this.businessDescription = value || null;
	}

	/**
	 * Set the total number of units
	 * @param {?number} value - The number of units
	 */
	@action setNumberOfUnits(value) {
		if (typeof value !== 'number') {
			throw new TypeError();
		}
		this.numberOfUnits = value;
	}

	/**
	 * Set the gross value
	 * @param {?number} value - The gross value or null
	 */
	@action setGrossValue(value) {
		this.grossValue = value;
	}

	/**
	 * Set the total number of employees
	 * @param {?number} value - The number of employees
	 */
	@action setNumberOfEmployees(value) {
		if (typeof value !== 'number') {
			throw new TypeError();
		}
		this.numberOfEmployees = value;
	}

	/**
	 * Set the total number of apprentices
	 * @param {number} value - The number of apprentices
	 */
	@action setNumberOfApprentices(value) {
		if (typeof value !== 'number') {
			throw new TypeError();
		}
		this.numberOfApprentices = value;
	}

	/**
	 * Set the apprentice wages amount
	 * @param {number} value - The apprentices wages amount
	 */
	@action setApprenticesWages(value) {
		this.apprenticesWages = value;
	}

	/**
	 * Set the absestos exposed workers
	 * @param {?Array<import('../submodels/Asbestos').default>} value - The asbestoses or null
	 */
	@action setAsbestoses(value) {
		if (!Array.isArray(value)) {
			throw new TypeError();
		}
		this.asbestoses = value;
	}

	/**
	 * Sets the taxi plates
	 * @param {?Array<import('../submodels/Taxi').default>} value - The array of taxi plates or null
	 */
	@action setTaxis(value) {
		if (!Array.isArray(value)) {
			throw new TypeError();
		}
		this.taxis = value;
	}

	/**
	 * Update wicActivity
	 * @param {?WicActivity|Object}
	 */
	@action
	setWicActivity(wicActivity) {
		if (!wicActivity) {
			this.wicActivity = null;
		} else if (wicActivity instanceof WicActivity) {
			this.wicActivity = wicActivity;
		} else if (typeof wicActivity === 'object') {
			this.wicActivity = WicActivityFactory.createFromResponseObject(wicActivity);
		} else {
			throw new TypeError('Unknown type', wicActivity);
		}
	}

	/**
	 * Sets the types of workers
	 * @param {Array<WorkerTypes>} value - The worker type
	 */
	@action setTypesOfWorkers(value) {
		if (!Array.isArray(value)) {
			throw new TypeError();
		}

		if (!value.find(n => n === WorkerTypes.ASBESTOS_WORKER)) {
			this.setAsbestoses([]);
		}

		if (!value.find(n => n === WorkerTypes.APPRENTICES)) {
			this.setNumberOfApprentices(0);
			this.setApprenticesWages(0);
		}

		if (!value.find(n => n === WorkerTypes.CONTRACTORS)) {
			this.setContractors([]);
		}

		this.typeOfWorkers = value;
	}

	/**
	 * Set verified status
	 * @param {boolean} isVerified Verified status
	 */
	@action
	setIsVerified(isVerified) {
		this.isVerified = isVerified;
	}

	/**
	 * Updates the Apprentices items
	 */
	@action
	updateApprentices(apprentices) {
		this.apprenticesWages = apprentices.apprenticesWages;
		this.numberOfApprentices = apprentices.numberOfApprentices;
	}

	// endregion

	// region Computed properties

	@computed
	get totalNumberOfEmployees() {
		if (!this.contractors) {
			return this.numberOfEmployees;
		} else {
			const totalContractors = this.contractors.reduce(
				(total, contractor) => total + contractor.numberOfContractors,
				0,
			);

			return parseInt(this.numberOfEmployees) + parseInt(totalContractors);
		}
	}

	@computed
	get totalEmployeeWages() {
		if (!this.contractors) {
			return this.grossValue;
		} else {
			const totalContractorWages = this.contractors.reduce(
				(sumWage, contractor) => sumWage + parseFloat(contractor.labourComponent),
				0,
			);
			return this.grossValue + totalContractorWages;
		}
	}

	@computed
	get taxiPlateList() {
		const taxiPlates = [];
		this.taxis.map(taxi => {
			taxiPlates.push(taxi.taxiPlate);
		});
		return taxiPlates;
	}

	@computed
	get toServiceLayer() {
		return {
			totalWages: this.totalEmployeeWages, // Calculated base on grossValue + SUM(contractorWages[].laboutComponent)
			taxiPlates: this.taxis.map(taxi => taxi.toServiceLayer),
			grossValue: this.grossValue, // Mapped to estimated wages from Business Activity Modal
			directWageID: this.wicActivity.toServiceLayer, // QQ
			directWageId: this.wicActivity.toServiceLayer, // FQ
			contactorWages: this.contractors.map(contractor => contractor.toServiceLayer),
			businessDescription: this.businessDescription || null,
			asbestoses: this.asbestoses.map(asbestos => asbestos.toServiceLayer),
			numberOfUnits: this.numberOfUnits,
			numberOfEmployees: this.numberOfEmployees,
			numberOfApprentices: this.numberOfApprentices,
			apprenticesWages: this.apprenticesWages,
		};
	}

	@computed
	get contractorList() {
		if (this.contractors && this.contractors.length > 0) {
			const contractorList = this.contractors.map(contractor => {
				return {
					contractType: contractor.contractType,
					numberOfContractors: contractor.numberOfContractors,
					totalValue: formatCurrency(contractor.totalContractValue),
					labourCost: formatCurrency(contractor.labourComponent),
				};
			});

			return contractorList;
		}

		return [];
	}

	@computed
	get taxiPlates() {
		if (this.taxis && this.taxis.length > 0) {
			const taxiPlates = this.taxis.map(taxi => {
				return {
					taxiPlate: taxi.taxiPlate,
				};
			});

			return taxiPlates;
		}

		return [TaxiFactory.createDefaultValues()];
	}

	@computed
	get numberOfPlates() {
		return this.taxis.length;
	}

	// endregion
}

/**
 * Wic factory
 */
export class WicFactory {
	/**
	 * Creates a wic from a response object
	 * @param {object}   o  - The response object
	 * @returns {?Wic}
	 */
	static createWicFromResponseObject(o, wic) {
		if (!o) {
			return null;
		}

		const w = wic || new Wic();
		w.setBusinessDescription(o.businessDescription);
		w.setWicActivity(WicActivityFactory.createFromResponseObject(o.directWageID || o.directWageId));

		if (typeof o.numberOfUnits === 'number') {
			w.setNumberOfUnits(o.numberOfUnits);
		}

		if (typeof o.numberOfEmployees === 'number') {
			w.setNumberOfEmployees(o.numberOfEmployees);
		}

		if (typeof o.numberOfApprentices === 'number') {
			w.setNumberOfApprentices(o.numberOfApprentices);
		}

		if (typeof o.apprenticesWages === 'number') {
			w.setApprenticesWages(o.apprenticesWages);
		}

		if (typeof o.grossValue === 'number' || o.grossValue === null) {
			w.setGrossValue(o.grossValue);
		}

		const typeofWorkers = [];

		const contractors = o.contractorWages || o.contactorWages; // Handle property typos
		if (Array.isArray(contractors) && contractors.length) {
			w.setContractors(contractors.map(n => ContractorFactory.createFromResponseObject(n)));
			typeofWorkers.push(WorkerTypes.CONTRACTORS);
		}

		if (o.numberOfApprentices || o.apprenticesWages) {
			typeofWorkers.push(WorkerTypes.APPRENTICES);
		}

		// Add asbestos workers only if it is not empty
		if (o.asbestoses && o.asbestoses.length > 0) {
			w.setAsbestoses(o.asbestoses.map(n => AsbestosFactory.createFromResponseObject(n)));
			typeofWorkers.push(WorkerTypes.ASBESTOS_WORKER);
		}

		w.setTypesOfWorkers(typeofWorkers);

		if (o.taxiPlates) {
			w.setTaxis(o.taxiPlates.map(n => TaxiFactory.createFromResponseObject(n)));
		}

		return w;
	}

	static createWicApprenticesSectionFromObject(o) {
		if (!o) {
			return null;
		}

		return {
			numberOfApprentices: o.numberOfApprentices,
			apprenticesWages: o.apprenticeWages,
		};
	}

	/**
	 * Returns a single WIC object with all primary activities nested
	 * @param currentValue {object}
	 * @returns wic {object}
	 */
	static createWicResponseObject(value) {
		return {
			industry: value.industry,
			code: value.code,
			wicCode: value.wicClassification,
			wicDescription: value.wicDetailedDescription,
			wicRate: value.wicRate,
			pac: [
				{
					pacDescription: value.primaryActivityDescription,
					pacCode: value.primaryActivityCode,
				},
			],
			ddlContribution: value.ddlContribution,
		};
	}

	/**
	 * Returns a formatted response data for the WIC search
	 * @param response {object}
	 * @return processedResults {object)
	 */
	static createWicsArrayFromResponse(response) {
		const processedResult = response.data.attributes.reduce((wics, currentValue) => {
			const index = wics.findIndex(wic => wic.code === currentValue.code);
			if (index !== -1) {
				const activity = wics[index].pac.find(activity => activity.pacCode === currentValue.primaryActivityCode);

				// only add to the pac array if the item is not the same pacCode
				if (!activity) {
					const updatedWic = Object.assign({}, wics[index], {
						pac: wics[index].pac.concat({
							pacDescription: currentValue.primaryActivityDescription,
							pacCode: currentValue.primaryActivityCode,
						}),
					});
					return Object.assign([], wics, {[index]: updatedWic});
				}

				// do not add if a duplicate
				return wics;
			}
			return wics.concat(this.createWicResponseObject(currentValue));
		}, []);
		return processedResult;
	}
}

export default Wic;
