import {css} from '@emotion/core';
import {BRAND_GREY, ICARE_GREY_LIGHTER} from '~/core/styles/colors';

const totalPane = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1 1 auto;
`;

const paneItem = css`
	border-left: 1px solid ${BRAND_GREY};
	padding: 5px 10px;

	.labeled-data__definition {
		font-size: 40px;
		font-weight: 100;
	}
`;

const descriptionPane = css`
	font-size: 26px;
`;

const statistics = css`
	background-color: ${ICARE_GREY_LIGHTER};

	.l-content-container {
		width: 100%;
	}
`;

export default {
	totalPane,
	paneItem,
	descriptionPane,
	statistics,
};
