import React from 'react';
import T from 'prop-types';

import Button from '@icare/components/lib/Button';
import ButtonGroup from '@icare/components/lib/ButtonGroup';
import RichText from '@icare/components/lib/RichText';
import Sublayouts from '@icare/layouts/lib/Sublayouts';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import PolicyLink from '~/core/components/PolicyLink';

import {quotePaths} from '~/paths';

@injectContent(['dp'])
export default class NoPolicyDashboard extends React.PureComponent {
	static propTypes = {
		content: T.object.isRequired,
	};

	render() {
		const {dp} = this.props.content;

		return (
			<Sublayouts
				type="has-feature-left"
				col1={
					<React.Fragment>
						<h3>{dp.c4A}</h3>
						<RichText content={dp.c4B} />
						<ButtonGroup>
							<PolicyLink parentNode={Button} variant={Button.Variant.PRIMARY} to={quotePaths.quoteLandingPage}>
								{dp.c4C}
							</PolicyLink>
						</ButtonGroup>
					</React.Fragment>
				}
			/>
		);
	}
}
