import {observable, action, computed} from 'mobx';
import uuidv4 from 'uuid/v4';
import CurrencyUtils from '@icare/core/lib/utils/CurrencyUtils';
import FieldUtils from '@icare/forms/lib/utils/FieldUtils';
import {ContractorWagesFactory} from './ContractorWages';
import {AsbestosFactory} from './Asbestos';
import {TaxiFactory} from './TaxiModel';

function mapFilter(data, f) {
	if (!data) {
		return null;
	}
	return data.map(f).filter(d => d);
}
export default class Wage {
 
	 
	@observable id = uuidv4();
 
	@observable businessDescription = null;
	 
	@observable classificationCode = null;
	 
	@observable wicDescription = null;
	 
	@observable pacDescription = null;
	 
	@observable policyStartDate = null;
	 
	@observable policyEndDate = null;
	 
	@observable employees = null;
	 
	@observable wages = null;

	@observable immutableWages = null;
	 
	@observable apprenticeNumber = null;
	 
	@observable apprenticeWages = null;
	 
	@observable asbestosWorkDescription = null;
 
	@observable asbestosWorkersNumber = null;
	 
	@observable asbestosGrossWages = null;
	 
	@observable contractType = null;
 
	@observable fixedID = null;
	
	@observable pac = null;
	
	@observable publicLocationID = null; 

	@observable costCenterPublicID = null; 

	@observable noOfContractWorkers = null; 

	@observable totalContractValue = null; 

	@observable contractorsLabourCost = null; 

	@observable unitCount = null;

	@observable isPercapita = false; 

	@observable isTaxi = false; 

	@observable isRemoved = false; 

	@observable isNew = false; 

	@observable nationalQualificationCode = null;

	@observable hasApprentices = false;

	@observable hasContractors = false; 

	@observable hasAsbestos = false; 

	@observable asbestosObject = []; 

	@observable ContractorWages = []; 

	@observable TaxiObject = []; 

	@observable FormObject = null;

	@observable isFormOpen = false;

	/**
	 *
	 * @param {?import('./PolicyLocation').default} value
	 */

	convertWageIntoNumber(value) {
		let newValue;
		if (value) {
			newValue = typeof value !== 'number' ? FieldUtils.getNumberFromField(value).toFixed(2) : value.toFixed(2); 
		} 
		return newValue;
	};

	@action
	setPolicyLocation(value) {
		this.policyLocation = value;
	}
	/**
	 *
	 * @param {?import('./Asbestos').default} value
	 */
	@action
	setAsbestosObject(value) {
		this.asbestosObject = value;
	}
	/**
	 *
	 * @param {?import('./ContractorWages').default} value
	 */
	@action
	setContractorWages(value) {
		this.ContractorWages = value;
	}

	/**
	 *
	 * @param {?import('./TaxiModel').default} value
	 */
	@action
	setTaxiModel(value) {
		this.TaxiObject = value;
	}

	/**
	 * Set the Fixed id
	 * @param {?string} value - The  Fixed id
	 */
	@action setFixedID(value) {
		this.fixedID = value || null;
	}

	/**
	 * Set the Business description
	 * @param {?string} value - The Business description
	 */
	@action setBusinessDescription(value) {
		this.businessDescription = value || null;
	}

	/**
	 * Set the classification code
	 * @param {?string} value - The  classification code
	 */
	@action setClassificationCode(value) {
		this.classificationCode = value || null;
	}

	/**
	 * Set the wic description
	 * @param {?string} value - The wic description
	 */
	@action setWicDescription(value) {
		this.wicDescription = value || null;
	}

	/**
	 * Set the pac description
	 * @param {?string} value - The pac description
	 */
	@action setPacDescription(value) {
		this.pacDescription = value || null;
	}

	/**
	 * Set the pac
	 * @param {?string} value - The pac
	 */
	@action setPac(value) {
		this.pac = value || null;
	}

	/**
	 * Set the policy start date
	 * @param {?string} value - The policy start date
	 */
	@action setPolicyStartDate(value) {
		this.policyStartDate = value || null;
	}

	/**
	 * Set the policy end date
	 * @param {?string} value - The policy end date
	 */
	@action setPolicyEndDate(value) {
		this.policyEndDate = value || null;
	}

	/**
	 * Set the employees
	 * @param {?number} value - The employees
	 */
	@action setEmployees(value) {
		this.employees = value || null;
	}

	/**
	 * Set the wages
	 * @param {?number} value - The wages
	 */
	@action setWages(value) {
		const wageValue = this.convertWageIntoNumber(value);
		this.wages = wageValue || null;
	}

	/**
	 * Set the wages
	 * @param {?number} value - The wages
	 */
	@action setImmutableWages(value) {
		const wageValue = this.convertWageIntoNumber(value);
		this.immutableWages = wageValue || null;
	}

	/**
	 * Set the apprentices number
	 * @param {?number} value - The apprentices number
	 */
	@action setApprenticeNumber(value) {
		this.apprenticeNumber = value || null;
	}

	/**
	 * Set the apprentices wages
	 * @param {?number} value - The apprentices wages
	 */
	@action setApprenticeWages(value) {
		const numberValue = this.convertWageIntoNumber(value);
		this.apprenticeWages = numberValue || null;
	}

	/**
	 * Set the asbestos work description
	 * @param {?string} value - The asbestos work description
	 */
	@action setAsbestosWorkDescription(value) {
		this.asbestosWorkDescription = value;
	}

	/**
	 * Set the asbestos workers number
	 * @param {number} value - The asbestos workers number
	 */
	@action setAsbestosWorkersNumber(value) {
		this.asbestosWorkersNumber = value;
	}

	/**
	 * Set the asbestos gross wages
	 * @param {?number} value - The asbestos gross wages
	 */
	@action setAsbestosGrossWages(value) {
		const asbestosValue = this.convertWageIntoNumber(value);
		this.asbestosGrossWages = asbestosValue || null;
	}

	/**
	 * Set the no of contract workers
	 * @param {?number} value - The no of contract workers
	 */
	@action setContractorsNumber(value) {
		this.noOfContractWorkers = value || null;
	}

	/**
	 * Set the contract Type
	 * @param {?number} value - The contract Type
	 */
	@action setContractType(value) {
		this.contractType = value || null;
	}

	/**
	 * Set the total Contract Value
	 * @param {?number} value - The total Contract Value
	 */
	@action setTotalContractValue(value) {
		const contractValue = this.convertWageIntoNumber(value);
		this.totalContractValue = contractValue || null;
	}

	/**
	 * Set the contractors Labour Cost
	 * @param {?number} value - The contractors Labour Cost
	 */
	@action setContractorsLabourCost(value) {
		const labourCostValue = this.convertWageIntoNumber(value);
		this.contractorsLabourCost = labourCostValue || null;
	}

	/**
	 * Set the unit Count
	 * @param {?number} value - The unit Count
	 */
	@action setUnits(value) {
		this.unitCount = value || null;
	}

	/**
	 * Set the  Percapita
	 * @param {?boolean} value - The Percapita
	 */

	@action setIsPercapita(value) {
		this.isPercapita = value || false;
	}

	/**
	 * Set the is Taxi
	 * @param {?boolean} value - The is Taxi
	 */

	@action setIsTaxi(value) {
		this.isTaxi = value || false;
	}

	/**
	 * Set the is wic removed
	 * @param {?boolean} value - The is wic removed
	 */

	@action setIsRemoved(value) {
		this.isRemoved = value || false;
	}

	/**
	 * Set the trustee name
	 * @param {?boolean} value - The trustee name
	 */

	@action setIsNew(value) {
		this.isNew = value || false;
	}

	/**
	 * Set the trustee name
	 * @param {?boolean} value - The trustee name
	 */

	@action setHasApprentices(value) {
		this.hasApprentices = value || false;
	}

	/**
	 * Set the trustee name
	 * @param {?boolean} value - The trustee name
	 */

	@action setHasContractors(value) {
		this.hasContractors = value || false;
	}

	/**
	 * Set the trustee name
	 * @param {?boolean} value - The trustee name
	 */

	@action setHasAsbestos(value) {
		this.hasAsbestos = value || false;
	}

	@action setFormObject(value) {
		this.FormObject = value || null;
	}

	@action setNationalQualificationCode(value) {
		this.nationalQualificationCode = value || '';
	}

	@action setIsFormOpen(value) {
		this.isFormOpen = value || false;
	}

	@action addNewTaxi(taxi) {
		this.TaxiObject.push(taxi);
	}

	@computed
	get platesCount() {
		if (this.isPercapita && this.isTaxi) {
			return this.TaxiObject.length;
		}

		return 0;
	}

	@computed
	get totalEmployees() {
		let count = 0;
		if (!this.isPercapita) {
			count += parseInt(this.employees, 10);
			if (this.hasContractors) {
				this.ContractorWages.forEach(wic => {
					if (wic.noOfContractWorkers) {
						count = count + parseInt(wic.noOfContractWorkers, 10);
					}
				});
			}
		}
		return count;
	}

	@computed
	get totalWages() {
		let sum = 0;
		if (!this.isPercapita) {
			sum += CurrencyUtils.currencyToFloat(this.wages);
			if (this.hasContractors) {
				this.ContractorWages.forEach(wic => {
					if (wic.labourCost) {
						sum = sum + CurrencyUtils.currencyToFloat(wic.labourCost);
					}
				});
			}
		}

		return sum;
	}

	@computed
	get wicCode() {
		return `${this.classificationCode} - ${this.wicDescription}`;
	}
}

/**
 * The Wage factory
 */
export class WageFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param wageModel
	 * @returns {?Wage}
	 */
	static createFromFormObject(wageModel) {
		if (!wageModel) {
			return null;
		}
		const objWage = new Wage();

		objWage.setClassificationCode(wageModel.wIC);
		objWage.setWicDescription(wageModel.wICDescription);
		objWage.setPolicyStartDate(wageModel.policyStartDate);
		objWage.setPolicyEndDate(wageModel.policyEndDate);
		objWage.setEmployees(wageModel.employees);
		objWage.setWages(wageModel.wages);
		objWage.setApprenticeNumber(wageModel.apprenticeNumber);
		objWage.setApprenticeWages(wageModel.apprenticeWages);
		objWage.setAsbestosWorkDescription(wageModel.asbestosWorkDescription);
		objWage.setAsbestosWorkersNumber(wageModel.asbestosWorkersNumber);
		objWage.setAsbestosGrossWages(wageModel.asbestosGrossWages);
		objWage.setContractType(wageModel.contractType);
		objWage.setContractorsNumber(wageModel.noOfContractWorkers);
		objWage.setTotalContractValue(wageModel.totalContractValue);
		objWage.setContractorsLabourCost(wageModel.contractorsLabourCost);
		objWage.setIsPercapita(wageModel.isPercapita);
		objWage.setIsTaxi(wageModel.isTaxi);
		objWage.setUnits(wageModel.unitCount);
		objWage.setIsRemoved(false);
		objWage.setHasApprentices(wageModel.hasApprentices);
		objWage.setHasContractors(wageModel.hasContractors);
		objWage.setHasAsbestos(wageModel.hasAsbestos);
		return objWage;
	}

	/**
	 * Create an Wage from a plain old object
	 * @param wageModel
	 * @returns {?Wage}
	 */
	static createFromServiceObject(wageModel) {
		if (!wageModel) {
			return null;
		}
		const objWage = new Wage();

		objWage.setFixedID(wageModel.fixedID);
		objWage.setPac(wageModel.pAC);
		objWage.setBusinessDescription(wageModel.businessDescription);
		objWage.setClassificationCode(wageModel.wIC);
		objWage.setWicDescription(wageModel.wICDescription);
		objWage.setPacDescription(wageModel.pACDescription);
		objWage.setEmployees(wageModel.noOfEmployees);
		objWage.setWages(wageModel.totalWages);
		objWage.setImmutableWages(wageModel.totalWages);
		objWage.setHasApprentices(wageModel.hasApprentices);
		objWage.setHasContractors(wageModel.hasContractors);
		objWage.setHasAsbestos(wageModel.hasAsbestos);
		objWage.setApprenticeNumber(wageModel.noOfApprentices);
		objWage.setApprenticeWages(wageModel.apprenticeWages);
		objWage.setContractorWages(
			(() => {
				const contractor = [];

				if (wageModel.contractorWages && wageModel.contractorWages.length) {
					wageModel.contractorWages.forEach(contractorWage => {
						const cntWage = ContractorWagesFactory.createFromServiceObject(contractorWage);
						if (cntWage) {
							contractor.push(cntWage);
						}
					});
				}

				return contractor;
			})(),
		);

		objWage.setAsbestosObject(mapFilter(wageModel.asbestoses, AsbestosFactory.createFromServiceObject));

		if (wageModel.isTaxi && wageModel.isPercapita && wageModel.taxiPlates) {
			objWage.setTaxiModel(wageModel.taxiPlates.map(tPlate => TaxiFactory.createFromServiceObject(tPlate)));
		}

		objWage.setIsPercapita(wageModel.isPercapita);
		objWage.setIsTaxi(wageModel.isTaxi);
		objWage.setUnits(wageModel.noOfUnits);
		return objWage;
	}

	/**
	 * Create an Wage from a plain old object
	 * @param wageModel
	 * @returns {?Wage}
	 */
	static createFromNewObject(wageModel) {
		if (!wageModel) {
			return null;
		}
		const objWage = new Wage();

		objWage.setFixedID(wageModel.fixedID);
		objWage.setPac(wageModel.pAC);
		objWage.setBusinessDescription(wageModel.businessDescription);
		objWage.setClassificationCode(wageModel.wIC);
		objWage.setWicDescription(wageModel.wICDescription);
		objWage.setPacDescription(wageModel.pACDescription);
		objWage.setEmployees(wageModel.noOfEmployees);
		objWage.setWages(wageModel.totalWages);
		objWage.setHasApprentices(wageModel.hasApprentices);
		objWage.setHasContractors(wageModel.hasContractors);
		objWage.setHasAsbestos(wageModel.hasAsbestos);
		objWage.setApprenticeNumber(wageModel.noOfApprentices);
		objWage.setApprenticeWages(wageModel.apprenticeWages);
		objWage.setIsNew(true);
		objWage.setContractorWages(wageModel.ContractorWages.map(ContractorWagesFactory.createFromServiceObject).filter(i=>i));	

		objWage.setAsbestosObject(mapFilter(wageModel.asbestosObject, AsbestosFactory.createFromServiceObject));
		objWage.setTaxiModel(mapFilter(wageModel.TaxiObject, TaxiFactory.createFromServiceObject));
		objWage.setIsPercapita(wageModel.isPercapita);
		objWage.setIsTaxi(wageModel.isTaxi);
		objWage.setUnits(wageModel.noOfUnits);
		return objWage;
	}
}