import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import authBearer from '~/core/utils/authBearer';
import requestBearer from '~/core/utils/requestBearer';
import {addCharacterAt} from '~/core/utils/DataUtils';

export default class PaymentServices {
	static async getInvoiceHistory(policyNumber, isAuth = false, hasDisountedPremium = false) {
		const url = isAuth
			? `/v2/portal/workersInsurance/policies/me/${policyNumber}/invoices`
			: `/v2/portal/workersInsurance/policies/${policyNumber}/invoices`;
		const response = await request.get(`${BASE_URL.invoices}${url}`).use(requestBearer).use(authBearer);
		if (hasDisountedPremium) {
			return {
				invoiceDetails: response.body.data.attributes.invoiceDetails,
				discountedPremium: response.body.data.attributes.discountedPremium,
			};
		}
		return response.body.data.attributes.invoiceDetails;
	}

	static async retrievePaymentDetails(policyNumber) {
		const response = await request
			.get(`${BASE_URL.payments}/v2/portal/workersInsurance/me/policies/${policyNumber}/payments`)
			.use(requestBearer)
			.use(authBearer);
		return response.body.data.attributes;
	}

	static async setupDirectDebit(payload, isAuth = false) {
		const url = isAuth
			? '/v2/portal/workersInsurance/payments/me/directDebit'
			: '/v2/portal/workersInsurance/payments/directDebit';
		const response = await request
			.post(`${BASE_URL.setupDirectDebit}${url}`)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);

		return response.status === 200 || response.status === 201 || response.status === 202;
	}

	/**
	 * Validates BSB
	 * Returns bank name if BSB found, null if BSB not found (404), and throws error otherwise
	 * @param {*} bsbNumber
	 */
	static async validateBSB(bsbNumber) {
		const formattedBSB = addCharacterAt(bsbNumber, '-', 3);
		try {
			const response = await request
				.get(`${BASE_URL.validateBSB}/v2/portal/payments/financialInstitutions?BSB=${formattedBSB}`)
				.use(requestBearer)
				.ok(response => response.status === 200 || response.status === 404);

			if (response.status === 404) {
				return null;
			} else {
				return response.body.data.attributes.name;
			}
		} catch (err) {
			throw err;
		}
	}

	static async updateDirectDebit(
		payment,
		policyNumber,
		isDirectDebitMethodFromBank = true,
		recaptchaToken,
		clientid,
		clientSecret,
	) {
		const payload = payment.toServiceLayerSetupDirectDebit(policyNumber, isDirectDebitMethodFromBank);

		const response = await request
			.post(`${BASE_URL.updateDirectDebit}/v2/portal/workersInsurance/payments/directDebit`)
			.set({
				'X-Client-ID': `${clientid}`,
				'X-Client-Secret': `${clientSecret}`,
			})
			.set('X-Token', recaptchaToken)
			.send(payload)
			.use(requestBearer);
		return response.body.data.attributes;
	}

	static async updatePaymentFrequency(policyNumber, name, paymentPlanObj) {
		const payload = {
			data: {
				type: 'PaymentPlan',
				id: policyNumber,
				attributes: {
					billingId: name,
					name: name,
					amount: {
						...paymentPlanObj,
					},
				},
			},
		};

		const response = await request
			.patch(
				`${BASE_URL.updatePaymentFrequency}/v2/portal/workersInsurance/me/policies/${policyNumber}/payments/paymentPlans`,
			)
			.send(payload)
			.use(requestBearer)
			.use(authBearer);
		return response.body.data.attributes;
	}
}
