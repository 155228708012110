// eslint-disable-next-line import/prefer-default-export
export const ASYNC_ERROR_422 = {
	response: {
		body: {
			errors: [
				{
					code: 'INVALID_PARAMETER',
					detail: 'INVALID_PARAMETER used with PolicyStore.getPolicy',
					status: '422',
					title: 'INVALID_PARAMETER',
				},
			],
		},
	},
};
