import React from 'react';
import PropTypes from 'prop-types';
import {inject} from 'mobx-react';
import withAuth from '@icare/auth-okta/lib/withAuth';

const injectAuth = ComponentToWrap => {
	return @inject('authStore')
	class ComponentWithAuth extends React.Component {
		static propTypes = {
			authStore: PropTypes.object.isRequired,
			auth: PropTypes.object.isRequired,
		};

		render() {
			return (
				<ComponentToWrap
					isAuthenticated={this.props.auth.isAuthenticated}
					user={this.props.authStore.user}
					{...this.props}
				/>
			);
		}
	};
};

export default component => withAuth(injectAuth(component));
