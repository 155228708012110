import {observable, action} from 'mobx';
import uuidv4 from 'uuid/v4';

export default class Asbestos {
	
	@observable id = uuidv4();

	@observable asbestosWorkDescription = null;

	@observable noOfExposedWorkers = null;

	@observable asbestosGrossWages = null;

	/**
	 * Set the asbestosWorkDescription
	 * @param {string} value - The asbestosWorkDescription
	 */
	@action setAsbestosWorkDescription(value) {
		this.asbestosWorkDescription = value || '';
	}

	/**
	 * Set the noOfExposedWorkers
	 * @param {string} value - The noOfExposedWorkers
	 */
	@action setNoOfExposedWorkers(value) {
		this.noOfExposedWorkers = value || 0;
	}

	/**
	 * Set the asbestosGrossWages
	 * @param {string} value - The asbestosGrossWages
	 */
	@action setAsbestosGrossWages(value) {
		this.asbestosGrossWages = value || 0;
	}
}

export class AsbestosFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param o
	 * @returns {?Asbestos}
	 */
	static createFromServiceObject(o) {
		if (!o) {
			return null;
		}
		const asbestos = new Asbestos();

		asbestos.setAsbestosWorkDescription(o.asbestosWorkDescription);
		asbestos.setNoOfExposedWorkers(o.noOfExposedWorkers);
		asbestos.setAsbestosGrossWages(o.asbestosGrossWages);
		return asbestos;
	}
}
