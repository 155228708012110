import {observable, action} from 'mobx';
import moment from 'moment';

/**
 * Policy History Model
 *
 * Setting the default data and
 * mapping the data from the
 * response of the Policy history
 */
export default class PolicyHistory {
	@observable termNumber = null; /**@property {?number} */
	@observable periodStartDate = null; /** @property {?date} */
	@observable periodEndDate = null; /** @property {?date} */
	@observable totalEmployees = null; /** @property {?number} */
	@observable totalUnits = null; /** @property {?number} */
	@observable transactionType = null; /** @property {?string} */
	@observable totalWages = null; /** @property {?number} */
	@observable totalPremium = null; /** @property {?object} */
	@observable docID = null; /** @property {?object} */
	@observable documentUrl = null; /** @property {?string} */
	@observable hindsightsDue = null; /** @property {?boolean} */

	@action
	setTermNumber(value) {
		this.termNumber = value || null;
	}

	@action
	setPeriodStartDate(value) {
		if (!value) {
			this.periodStartDate = null;
		} else if (value instanceof moment) {
			this.periodStartDate = value;
		} else {
			throw new TypeError();
		}
	}

	@action
	setPeriodEndDate(value) {
		if (!value) {
			this.periodEndDate = null;
		} else if (value instanceof moment) {
			this.periodEndDate = value;
		} else {
			throw new TypeError();
		}
	}

	@action
	setTotalEmployees(value) {
		this.totalEmployees = value || null;
	}
	@action
	setTotalUnits(value) {
		this.totalUnits = value || null;
	}
	@action
	setTransactionType(value) {
		this.transactionType = value || null;
	}
	@action
	setTotalWages(value) {
		this.totalWages = value || null;
	}
	@action
	setTotalPremium(value) {
		this.totalPremium = value || null;
	}
	@action
	setDocID(value) {
		this.docID = value || null;
	}

	@action
	setDocumentUrl(value) {
		this.documentUrl = value || null;
	}

	@action
	setHindsightsDue(value) {
		if (typeof value !== 'boolean') {
			this.hindsightsDue = null;
		} else {
			this.hindsightsDue = value;
		}
	}
}

export class PolicyHistoryFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const h = new PolicyHistory();
		h.setTermNumber(o.termNumber);
		h.setPeriodStartDate(moment(o.periodStartDate));
		h.setPeriodEndDate(moment(o.periodEndDate));
		h.setTotalEmployees(o.totalEmployees);
		h.setTotalUnits(o.totalUnits);
		h.setTransactionType(o.transactionType);
		h.setTotalWages(o.totalWages);
		h.setTotalPremium(o.totalPremium.amount);
		h.setDocID(o.docId);
		h.setDocumentUrl(o.documentUrl);
		h.setHindsightsDue(o.hindsightsDue);

		return h;
	}
}
