export const PaymentOptions = {
	annual: 'icare_bcpp:1',
	monthly: 'icare_bcpp:2',
	quarterly: 'icare_bcpp:3',
};

export const PaymentPlanConstants = [
	{code: 'icare_bcpp:1', label: 'Annual'},
	{code: 'icare_bcpp:2', label: 'Monthly'},
	{code: 'icare_bcpp:3', label: 'Quarterly'},
];

export const WestpacConstants = {
	QuickVault: 'QUICKVAULT',
};

export const LavyNameMap = {
	rtwi: 'Return to Work Incentive',
	esr: 'ESR',
	pd: 'Performance Discount',
	ad: 'Apprentice Discount',
	ddl: 'Dust Disease Levy',
	msc: 'Mine Safety Levy',
	aw: 'Asbestos wages',
};

export const PaymentStatus = {
	success: 'success',
	cancelled: 'cancelled',
};

const PolicyConstants = {
	DraftStatus: 'Draft',
	QuotedStatus: 'Quoted',
	BoundStatus: 'Bound',
};

export default PolicyConstants;
