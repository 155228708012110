import {observable, action} from 'mobx';
import policyTransactionType from '../constants/policyTransactionType';

export default class SubmitterModel {
	
	@observable isEmployee = false;
	
	@observable firstName = null;

	@observable lastName = null;

	@observable position = null;

	@observable company = null;

	@observable email = null;

	@observable contactNumber = null;

	@observable reasonForChange = null;
	
	@observable transactionType = null;

	@observable policyStartDate = null;

	@observable policyEndDate = null;

	@observable totalNoOfEmployees = null;

	@observable totalWages = null;

	@observable totalNoOfUnits = null;

	@observable employerName = null;



	/**
	 * Set the isEmployee id
	 * @param {?boolean} value - The isEmployee id
	 */
	@action setisEmployee(value) {
		this.isEmployee = value || false;
	}

	/**
	 * Set the firstName type
	 * @param {string} value - The firstName type
	 */
	@action setfirstNamer(value) {
		this.firstName = value || null;
	}

	/**
	 * Set the lastName
	 * @param {?string} value - The lastName
	 */
	@action setlastName(value) {
		this.lastName = value || null;
	}

	/**
	 * Set the position
	 * @param {?string} value - The position
	 */
	@action setposition(value) {
		this.position = value || null;
	}

	/**
	 * Set the company name
	 * @param {?string} value - The company name
	 */
	@action setcompany(value) {
		this.company = value || null;
	}

	/**
	 * Set the email
	 * @param {?string} value - The email
	 */
	@action setemail(value) {
		this.email = value || null;
	}

	/**
	 * Set the contactNumber
	 * @param {?string} value - The contactNumber
	 */
	@action setcontactNumber(value) {
		this.contactNumber = value || null;
	}

	/**
	 * Set the reasonForChange
	 * @param {?string} value - The reasonForChange
	 */
	@action setReasonForChange(value) {
		this.reasonForChange = value || null;
	}
	
	/**
	 * Set the transactionType
	 * @param {?string} value - The transactionType
	 */
	@action setTransactionType(value) {
		this.transactionType = value || null;
	}

	/**
	 * Set the isRenewalPolicy
	 * @param {?boolean} value - The Renewal policy flag - Estimated wages. For Actual wages it will be NULL  
	 */
	 @action setIsRenewalPolicy(value) {
		this.isRenewalPolicy = !!value;
	}
	/**
	 * Set the policyStartDate
	 * @param {?boolean} value - policyStartDate value from submitter 
	 */
	 @action setSubmitterPolicyStartDate(value) {
		this.policyStartDate = value ;
	}

	/**
	 * Set the policyEndDate
	 * @param {?boolean} value - policyEndDate value from submitter 
	 */
	@action setSubmitterPolicyEndDate(value) {
		this.policyEndDate = value ;
	}

	/**
	 * Set the totalNoOfEmployees
	 * @param {?boolean} value - totalNoOfEmployees value from submitter 
	 */
	@action setSubmitterTotalNoOfEmployees(value) {
		this.totalNoOfEmployees = value ;
	}

	/**
	 * Set the totalWages
	 * @param {?boolean} value - totalWages value from submitter 
	 */
	 @action setSubmitterTotalWages(value) {
		this.totalWages = value ;
	}

	/**
	 * Set the totalNoOfUnits
	 * @param {?boolean} value - totalNoOfUnits value from submitter 
	 */
	 @action setSubmitterTotalNoOfUnits(value) {
		this.totalNoOfUnits = value ;
	}


	/**
	 * Set the employerName
	 * @param {?boolean} value - employerName value from submitter 
	 */
	 @action setSubmitterEmployerName(value) {
		this.employerName = value ;
	}
}

export class SubmitterFactory {
	/**
	 * Create an Wage from a plain old object
	 * @param submitter
	 * @returns {?SubmitterModel}
	 */
	static createFromService(submitter) {
		if (!submitter) {
			return null;
		}
		const submitterModel = new SubmitterModel();
		submitterModel.setisEmployee(submitter.submitterType);
		submitterModel.setfirstNamer(submitter.firstName);
		submitterModel.setlastName(submitter.lastName);
		submitterModel.setposition(submitter.position);
		submitterModel.setcompany(submitter.companyName);
		submitterModel.setemail(submitter.emailAddress);
		submitterModel.setcontactNumber(submitter.contactNumber);
		submitterModel.setReasonForChange(submitter.reasonForChange);
		submitterModel.setTransactionType(submitter.transactionType);

		submitterModel.setSubmitterPolicyStartDate(submitter.policyStartDate);
		submitterModel.setSubmitterPolicyEndDate(submitter.policyEndDate);
		submitterModel.setSubmitterTotalNoOfEmployees(submitter.totalNoOfEmployees);
		submitterModel.setSubmitterTotalWages(submitter.totalWages);
		submitterModel.setSubmitterTotalNoOfUnits(submitter.totalNoOfUnits);
		submitterModel.setSubmitterEmployerName(submitter.employerName);

		if (submitter.transactionType === policyTransactionType.ESTIMATED_WAGES) {
			submitterModel.setIsRenewalPolicy(submitter.isRenewalPolicy);
		}
		return submitterModel;
	}
}
