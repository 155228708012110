import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import ReferenceDataService from '../services/ReferenceDataService';

/**
 * The Reference Data Store
 */
export default class ReferenceDataStore {
	//region Properties

	/**
	 * ReferenceData instance
	 * @type {ReferenceData}
	 */
	@observable referenceData = null;

	/**
	 * Sync status of retrieving the reference data via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = ReferenceDataService) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * Sync reference data with the server
	 * @returns {Promise<void>}
	 */
	@action
	async sync() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			const referenceData = await this.api.getReferenceData();
			runInAction(() => {
				this.referenceData = referenceData;
				this.asyncStatus.isBusy = false;
			});
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}
	//endregion
}
