/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import T from 'prop-types';

import injectContent from '@icare/sitecore-content/lib/injectContent';

import LabeledDataCollection from '@icare/components/lib/LabeledDataCollection';
import {Sublayouts} from '@icare/layouts';

import Button from '@icare/components/lib/Button';
import ButtonGroup from '@icare/components/lib/ButtonGroup';
import LabeledData from '@icare/components/lib/LabeledData';
import {alert} from '@icare/components/lib/Modal';
import QuickLinks from '@icare/components/lib/QuickLinks/QuickLinks';
import Spinner from '@icare/components/lib/Spinner';
import {formatDateFromISO, formatCurrency} from '@icare/core/lib/utils/FormattingUtils';
import injectAuth from '~/core/decorators/injectAuth';
import injectReferenceData from '~/core/decorators/injectReferenceData';

import DocumentServices from '~/core/services/DocumentServices';
import {POLICY_BASE_NAME, MAIN_SITE_ROOT} from '~/config';
import {policyPaths, quotePaths} from '~/paths';
import {replacePolicyPathParams} from '~/core/utils/PolicyUtils';
import PolicyActionTag from '~/core/components/PolicyActionTag';
import PolicyActionList from '~/core/components/PolicyActionList';
import {ActionFactory} from '~/core/models/submodels/Action';
import PolicyLink from '~/core/components/PolicyLink';
import documentTemplates from '~/core/models/enums/DocumentTemplates';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';

import style from './SinglePolicyDashboard.style';

@injectReferenceData
@injectAuth
@injectContent(['singlePolicyDashboard', 'policyDetails'])
export default class SinglePolicyDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			certificateOfCurrencyUrl: null,
			asyncStatus: {
				isBusy: false,
				error: null,
			},
		};
	}
	static propTypes = {
		policy: T.object.isRequired,
		content: T.object.isRequired,
		authStore: T.object.isRequired,
		referenceData: T.object.isRequired,
	};

	async componentDidMount() {
		const docTemplate = this.props.referenceData.downloadDocTemplates.find(
			doc => doc.classification === documentTemplates.CERTIFICATE_OF_CURRENCY,
		);

		const {policyNumber} = this.props.policy;
		try {
			const response = await DocumentServices.getDocuments(policyNumber, null, null, 1, 10, docTemplate.value);
			this.setState({
				certificateOfCurrencyUrl: response.documents.length > 0 && response.documents[0].documentUrl,
			});
		} catch (e) {}
	}

	_handleNoDocumentUrl = () => {
		alert({
			message: this.props.content.policyDetails.e1,
		});

		// Analytics
		AnalyticsHandler.downloadCocError(this.props.content.policyDetails.e1);
	};

	_handleDownloadCoc = () => {
		// Analytics
		AnalyticsHandler.downloadCoc();
	};

	_renderAside() {
		const {policy, content} = this.props;
		const {singlePolicyDashboard} = content;
		const links = [
			{
				label: singlePolicyDashboard.c12,
				to: replacePolicyPathParams(policyPaths.policyPaymentsPagePattern, policy.policyNumber, policy.termNumber),
			},
			{
				label: singlePolicyDashboard.c13,
				to: replacePolicyPathParams(policyPaths.documentsPagePattern, policy.policyNumber, policy.termNumber),
			},
			{
				label: singlePolicyDashboard.c14,
				to: quotePaths.quoteLandingPage,
			},
			{
				label: singlePolicyDashboard.c16,
				to: replacePolicyPathParams(policyPaths.policyHistoryPagePattern, policy.policyNumber, policy.termNumber),
			},
			{
				label: singlePolicyDashboard.c17,
				to: replacePolicyPathParams(policyPaths.cancelPolicyPagePattern, policy.policyNumber, policy.termNumber),
			},
		];

		// Remove the cancelled link if the policy is cancelled
		const isCancelledPolicy = !!policy.actions.map(ActionFactory.createFromObj).find(action => action.isCancelled);
		if (isCancelledPolicy) {
			links.splice(4, 4);
		}

		const doHide = policy.actions
			.map(ActionFactory.createFromObj)
			.find(action => action.isRenewalDue || action.isPremiumPending);
		if (!doHide && !isCancelledPolicy) {
			links.splice(3, 0, {
				label: singlePolicyDashboard.c15,
				to: replacePolicyPathParams(policyPaths.policyEditBusinessPagePattern, policy.policyNumber, policy.termNumber),
			});
		}

		const linksWithAbsoluteUrls = links.map(link => ({...link, to: `${MAIN_SITE_ROOT}/${POLICY_BASE_NAME}${link.to}`}));

		return <QuickLinks heading="I want to" links={linksWithAbsoluteUrls} linkTag={PolicyLink} />;
	}

	render() {
		const {policy, content, authStore} = this.props;
		const {singlePolicyDashboard} = content;
		const {certificateOfCurrencyUrl, asyncStatus} = this.state;
		return (
			<Spinner active={asyncStatus.isBusy} isStatic>
				<Sublayouts
					type="has-feature-left"
					col1={
						<React.Fragment>
							<h3 css={style.tagsContainer}>
								<span className="m-r-s">
									{singlePolicyDashboard.c3.replace('${POLICY_NUMBER}', policy.policyNumber)}
								</span>
								{policy && (
									<span css={style.actionTags}>
										<PolicyActionTag
											periodEndDate={policy.policyEndDate}
											actions={policy.actions.map(ActionFactory.createFromObj)}
											css={style.actionTags}
										/>
									</span>
								)}
							</h3>
							<div className="single-dashboard-client-info">
								<h5>{policy.entityName}</h5>
							</div>
							<div css={style.topLeft}>
								<LabeledDataCollection type={LabeledDataCollection.HORIZONTAL} rows={LabeledDataCollection.Rows.TWO}>
									<LabeledData
										term={singlePolicyDashboard.c6}
										definition={`${formatDateFromISO(policy.policyStartDate)} - ${formatDateFromISO(
											policy.policyEndDate,
										)}`}
									/>
									<LabeledData term={singlePolicyDashboard.c7} definition={formatCurrency(policy.totalPremium)} />
								</LabeledDataCollection>
								<ButtonGroup>
									<Button
										tag="a"
										href={`${POLICY_BASE_NAME}${replacePolicyPathParams(
											policyPaths.policyDetailsPagePattern,
											policy.policyNumber,
											policy.termNumber,
										)}`}
									>
										{singlePolicyDashboard.c10}
									</Button>
									{certificateOfCurrencyUrl ? (
										<Button
											tag="a"
											variant={Button.Variant.SECONDARY}
											iconName="file-pdf"
											iconPosition="after"
											target="_blank"
											href={certificateOfCurrencyUrl}
											onClick={this._handleDownloadCoc}
										>
											{singlePolicyDashboard.c11}
										</Button>
									) : (
										<Button
											tag="a"
											variant={Button.Variant.SECONDARY}
											iconName="file-pdf"
											iconPosition="after"
											onClick={this._handleNoDocumentUrl}
										>
											{singlePolicyDashboard.c11}
										</Button>
									)}
								</ButtonGroup>
							</div>
							<hr />
							{policy && (
								<PolicyActionList
									content={content}
									policyNumber={policy.policyNumber}
									termNumber={policy.termNumber}
									periodEndDate={policy.policyEndDate}
									actions={policy.actions.map(ActionFactory.createFromObj)}
									isBroker={authStore.user.isBroker}
								/>
							)}
						</React.Fragment>
					}
					col2={this._renderAside()}
				/>
			</Spinner>
		);
	}
}
