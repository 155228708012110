import {observable, action, runInAction} from 'mobx';
import AsyncStatus from '~/core/models/AsyncStatus';
import {PaymentFactory} from '~/core/models/submodels/Payment';
import PaymentServices from '~/core/services/PaymentServices';
import Auth from '@icare/auth-okta/lib/Auth';

/**
 * The Reference Data Store
 */
export default class PaymentStore {
	//region Properties

	/**
	 * The Policy id
	 * @type {null}
	 */
	@observable policyId = null;

	/**
	 * Payment instance
	 * @type {Payment}
	 */
	@observable payment = null;

	/**
	 * Sync status of retrieving the reference data via network
	 * @type {AsyncStatus}
	 */
	@observable asyncStatus = new AsyncStatus();

	//endregion

	//region Lifecycle

	/**
	 * Constructor
	 * @param rootStore
	 * @param api
	 */
	constructor(rootStore = null, api = PaymentServices) {
		this.rootStore = rootStore;
		this.api = api;
	}

	//endregion

	//region Actions

	/**
	 * Set Policy ID
	 */
	@action
	setPolicyId(value) {
		this.policyId = value || null;
	}

	/**
	 * Sync reference data with the server
	 * @returns {Promise<void>}
	 */
	@action
	async sync() {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;
			// Retrieve Payment Details API call
			if (this.policyId) {
				const response = await PaymentServices.retrievePaymentDetails(this.policyId);
				const payment = PaymentFactory.createFromPaymentDetails(response);
				runInAction(() => {
					this.payment = payment;
					this.asyncStatus.isBusy = false;
				});
			} else {
				throw new Error('no policy id provided');
			}
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}

	@action
	async setupDirectDebit(policyNumber, inputs) {
		try {
			this.asyncStatus.isBusy = true;
			this.asyncStatus.error = null;

			const auth = Auth.sharedInstance;

			const payment = PaymentFactory.createPaymentFromDebitForm(inputs);
			const isSuccess = await PaymentServices.setupDirectDebit(
				payment.toServiceLayerSetupDirectDebit(policyNumber),
				auth && auth.isAuthenticated,
			);

			runInAction(() => {
				this.sync();
				this.asyncStatus.isBusy = false;
			});
			if (isSuccess) {
				return true;
			}
			throw new Error('setup direct debit failed');
		} catch (e) {
			runInAction(() => {
				this.asyncStatus.error = e;
				this.asyncStatus.isBusy = false;
			});
		}
	}
	//endregion
}
