import request from 'superagent';
import {BASE_URL} from '~/config/endpoints';
import requestBearer from '~/core/utils/requestBearer';
import {ReferenceDataFactory} from '~/core/models/ReferenceData';
import {WicFactory} from '~/core/models/submodels/Wic';

const REF_STORAGE_KEY = '1X_REFERENCE_DATA';

/**
 * The Reference Data Service is responsible for retrieving reference data from the
 * referenceData api.
 */
export default class ReferenceDataService {
	/**
	 * Gets reference data from the server
	 * @returns {Promise<ReferenceData>}
	 */
	static async getReferenceData() {
		if (sessionStorage) {
			const cache = sessionStorage.getItem(REF_STORAGE_KEY);
			if (cache) {
				return ReferenceDataFactory.createFromObject(JSON.parse(cache));
			}
		}

		const response = await request
			.get(`${BASE_URL.referenceData}/v2/portal/workersInsurance/policies/referenceData`)
			.use(requestBearer);

		if (!response.body || !response.body.data || !response.body.data.attributes) {
			return null;
		}

		if (sessionStorage) {
			sessionStorage.setItem(REF_STORAGE_KEY, JSON.stringify(response.body.data.attributes));
		}

		return ReferenceDataFactory.createFromObject(response.body.data.attributes);
	}

	static async searchWic(term) {
		const response = await request.get(`${BASE_URL.wic}/v1/referenceData/wics?searchText=${term}`).use(requestBearer);

		return WicFactory.createWicsArrayFromResponse(response.body);
	}
}
