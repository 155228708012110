import { toJS } from 'mobx';
import moment from 'moment';
import Policy from '~/core/models/Policy';
import { PremiseFactory } from '~/core/models/submodels/Premise';
import { ActionFactory } from '~/core/models/submodels/Action';
import { ContactFactory } from '~/core/models/submodels/Contact';
import { getTypeOfPremium } from '~/core/utils/QuoteUtils';
import { HIDE_IS_BUSINESS_INSURED } from '~/config';

export default class PolicyFactory {
	static createFromQuote(q) {
		if (!q) {
			return null;
		}

		const policy = new Policy();

		//TODO: Finished "cloning" values, important we don't copy by reference
		policy.setAccount(toJS(q.account));
		//policy.setPrimaryPremise(q.primaryPremise.toJS());
		//policy.setPremium(q.premium.toJS());
		//policy.setPrimaryContact(q.primaryContact.toJS());
		//policy.setContacts(q.contacts.map(c => ContactFactory.createContactFromObject(c.toJS())));
		policy.setPeriodStartDate(moment(q.periodStartDate));
		policy.setPeriodEndDate(moment(q.periodEndDate));
		policy.setLabourHireCode(q.labourHireCode);

		return policy;
	}

	static createPolicyFromObject(o, policy) {
		try {
			if (!o || !o.data || !o.data.attributes) {
				return null;
			}

			const { bindData, quoteData, draftData } = o.data.attributes;

			const p = policy || new Policy();

			// Policy Details
			p.setId(o.data.id);
			p.setQuoteId(o.data.attributes.quoteId);
			p.setAccountId(o.data.attributes.accountId);

			if (o.data.attributes.srpTermNumber) {
				p.setSrpTermNumber(o.data.attributes.srpTermNumber);
			}

			if (o.data.attributes.hadPolicylast12Months) {
				p.setHadPolicyLast12Months(o.data.attributes.hadPolicylast12Months);
			}

			p.setPolicyNumber(o.data.id);
			p.setPolicyPending(o.data.attributes.policyPending);
			p.setStatus(o.data.attributes.status);
			p.setUwIssueAvailable(bindData.isUwIssueAvailable);
			p.setPolicyChangeReason(o.data.attributes.policyChangeReason);
			p.setPolicyChangeType(o.data.attributes.policyChangeType);

			// Don't override existing policy store values when draft data is not returned
			if (draftData && draftData.productCode && draftData.periodStartDate && draftData.periodEndDate) {
				p.setProductCode(draftData.productCode);
				p.setPreQualQuestionSets(draftData.preQualQuestionSets);
				p.setOffering(draftData.offering);
				p.setPeriodStartDate(moment(draftData.periodStartDate));
				p.setPeriodEndDate(moment(draftData.periodEndDate));

				if (draftData.account) {
					p.setAccount(draftData.account);
				}

				// Mapping Contacts
				draftData.accountContact.map((contact, index) => {
					if (index === 0) {
						p.setPrimaryContact(ContactFactory.createFromObject(contact));
					} else {
						p.addContact(contact);
					}
				});

				const lobs = draftData.lobs;

				if (lobs) {
					// Group and Broker Details
					if (lobs.wcLine.policyPeriod) {
						if (lobs.wcLine.policyPeriod.wcLabourHireCode) {
							p.setLabourHireCode(lobs.wcLine.policyPeriod.wcLabourHireCode);
						}
						p.setBrokerOrganization(lobs.wcLine.policyPeriod.brokerOrganisation);
						p.setBranch(lobs.wcLine.policyPeriod.brokerName);
						p.setBrokerGroupId(lobs.wcLine.policyPeriod.brokerId);
						if (p.account) {
							p.account.setGroupNumber(lobs.wcLine.policyPeriod.groupNumber);
						}
						p.setGroupName(lobs.wcLine.policyPeriod.groupName);
						p.setSchemeAgent(lobs.wcLine.policyPeriod.schemeAgent);
						p.setPolicyLobs(lobs.wcLine.policyPeriod.policy);
						if (lobs.wcLine.policyPeriod.reasonLowWages) {
							p.setReasonForLowWages(lobs.wcLine.policyPeriod.reasonLowWages);
						}
						p.setTermNumber(lobs.wcLine.policyPeriod.termNumber);

						// Premise Locations
						if (lobs.wcLine.policyPeriod.policyLocation && lobs.wcLine.policyPeriod.policyLocation.length) {
							// Primary location
							const primaryLocation = lobs.wcLine.policyPeriod.policyLocation.find(n => n.primaryLocation);

							p.setPrimaryPremise(
								PremiseFactory.createFromResponseObject({
									locationRetired: primaryLocation.locationRetired,
									isRatedLocation: primaryLocation.isRatedLocation,
									address: primaryLocation.accountLocation.address,
									wics: primaryLocation.costCenters[0].directWages,
									publicId: primaryLocation.publicId,
									costCenterPublicId: primaryLocation.costCenters[0].publicId,
									fullName: primaryLocation.costCenters[0].fullName,
									name: primaryLocation.costCenters[0].name,
								}),
							);

							// Other locations
							const otherLocations = lobs.wcLine.policyPeriod.policyLocation.filter(n => !n.primaryLocation);

							if (otherLocations && otherLocations.length) {
								p.setPremises(
									otherLocations.map(n =>
										PremiseFactory.createFromResponseObject({
											locationRetired: n.locationRetired,
											isRatedLocation: n.isRatedLocation,
											address: n.accountLocation.address,
											wics: n?.costCenters[0]?.directWages || undefined,
											publicId: primaryLocation.publicId,
											costCenterPublicId: primaryLocation.costCenters[0].publicId,
											fullName: primaryLocation.costCenters[0].fullName,
											name: primaryLocation.costCenters[0].name,
										}),
									),
								);
							}
						}
					}

					const policyPeriod = lobs.wcLine.policyPeriod;
					if (
						policyPeriod &&
						policyPeriod.policy &&
						policyPeriod.policy.priorPolicy &&
						policyPeriod.policy.priorPolicy.length > 0
					) {
						p.setPreviousSchemeAgent(policyPeriod.policy.priorPolicy[0].wcCarrier);
						p.setPreviousPolicyNumber(policyPeriod.policy.priorPolicy[0].policyNumber);
						if (p.previousPolicyNumber !== null && p.previousSchemeAgent !== null) {
							p.setIsBusinessInsured(HIDE_IS_BUSINESS_INSURED ? false : true);
						} else {
							p.setIsBusinessInsured(false);
						}
					} else {
						p.setIsBusinessInsured(false);
					}
				}
			}

			// Premium
			if (bindData.paymentPlans && bindData.paymentPlans.length) {
				// Checking if draft data exist here and if not passing draft data from toServiceLayer method as policy update, bind policy and renewal calls don't return draft data
				const locations =
					draftData && draftData.lobs && draftData.lobs.wcLine && draftData.lobs.wcLine.policyPeriod
						? draftData.lobs.wcLine.policyPeriod.policyLocation
						: p.toServiceLayerPolicy.draftData.lobs.wcLine.policyPeriod.policyLocation;
				p.setPremium({
					policyLocation: locations,
					bindData: {
						paymentPlans: bindData.paymentPlans,
						fullPayDiscountPercentage: bindData.fullPayDiscountPercentage || bindData.fullPayDisPercentage,
						selectedPaymentPlan: bindData.selectedPaymentPlan,
					},
					// Checking if quote data exist here and if not passing quote data from toServiceLayer method as bind policy and renewal calls don't return quote data
					quoteData: quoteData || p.toServiceLayerPolicy.quoteData,
				});
			}

			p.setPayment({
				bindData: bindData,
				quoteData: quoteData,
				draftData: draftData,
			});

			// Type of Premium
			if (p.premium && p.account) {
				p.premium.setTypeOfPremium(getTypeOfPremium(p));
			}

			// Registration Code
			p.setRegistrationCode(bindData.registrationCode);

			return p;
		} catch (e) {
			console.error('Missing required data');
			throw e;
		}
	}

	static createPolicyActionFromResponse(o) {
		return ActionFactory.createFromResponseObject(o);
	}
}
