import { globalConfig } from '@icare/forms';
import { getConfig } from '~/core/utils/ConfigProvider';

let APP_CONFIG;
if (process.env.NODE_ENV === 'test') {
	APP_CONFIG = require('../../_api_mocks_/system/v1/policyconfig.json');
} else {
	APP_CONFIG = getConfig();
}

const stripTrailingSlash = url => {
	if (!url || typeof url !== 'string') {
		return url;
	}
	return url.replace(/\/$/, '');
};

// VALIDATION

if (!APP_CONFIG) {
	throw new Error('APP_CONFIG is null or undefined');
}

// Required Config Props Sanity Check
['baseApiUrl', 'contentApiBaseUrl', 'contentApiBaseUrl', 'baseMockUrl', 'mainSiteRoot', 'faqUrl', 'appTitle'].forEach(
	required => {
		if (!APP_CONFIG[required]) {
			throw new Error(`APP_CONFIG missing required property "${required}"`);
		}
	},
);

// APP SETTINGS

// Site APIs
export const PORTAL_API_BASE = stripTrailingSlash(APP_CONFIG.portalApiBaseUrl);
export const POLICY_BASE_NAME = stripTrailingSlash(APP_CONFIG.policyBaseName);
export const CLAIMS_BASE_NAME = stripTrailingSlash(APP_CONFIG.claimsBaseName);
export const BASE_API_URL = stripTrailingSlash(APP_CONFIG.baseApiUrl);
export const BASE_MOCK_URL = stripTrailingSlash(APP_CONFIG.baseMockUrl);
export const IDENTITY_API_BASE = stripTrailingSlash(APP_CONFIG.identityApiBaseUrl);
export const REFERENCE_DATA_API_URL = stripTrailingSlash(APP_CONFIG.referenceDataApiBaseUrl);
export const CONTENT_API_BASE = stripTrailingSlash(APP_CONFIG.contentApiBaseUrl);
globalConfig.addressService.baseApiUrl = BASE_API_URL;
globalConfig.addressService.referenceDataApiBaseUrl = REFERENCE_DATA_API_URL;

export const VIRTUAL_PAGE_API_BASE = stripTrailingSlash(APP_CONFIG.virtualPageBaseUrl);
export const MAINTENANCE_MODE_PAGE_API_BASE = stripTrailingSlash(APP_CONFIG.maintenanceModeApiBaseUrl);
export const SITE_ALERT_API_BASE = stripTrailingSlash(APP_CONFIG.siteAlertApiBaseUrl);
export const PORTAL_LOGOUT_ENDPOINT = APP_CONFIG.logoutEndpoint;

export const API_SANDBOX_MODE = APP_CONFIG.apiSandboxMode === 'true';

export const OKTA_ROLE = {
	EMPLOYER: APP_CONFIG.oktaPortalRoleEmployer,
	BROKER: APP_CONFIG.oktaPortalRoleBroker,
};
export const G_SITE_ID = APP_CONFIG.googleAppId;

// OKTA Config
export const OKTA_CONFIG = {
	url: APP_CONFIG.oktaUrl,
	clientId: APP_CONFIG.oktaClientId,
	issuer: APP_CONFIG.oktaIssuer,
	redirectUri: APP_CONFIG.oktaRedirectUri,
	loggingInUri: APP_CONFIG.oktaLogginInUri,
};
export const OKTA_ENV = APP_CONFIG.oktaEnvironment;

export const INACTIVITY_TIMEOUT = parseInt(APP_CONFIG.inactivityTimeout);
export const INACTIVITY_WARNING = parseInt(APP_CONFIG.inactivityWarning);

// Site Links
export const MAIN_SITE_ROOT = stripTrailingSlash(APP_CONFIG.mainSiteRoot);
export const FAQ_URL = stripTrailingSlash(APP_CONFIG.faqUrl);
export const CLAIMS_URL = stripTrailingSlash(APP_CONFIG.claimsUrl);
export const CONTACT_US_TEL = stripTrailingSlash(APP_CONFIG.contactUsTel);
export const CONTACT_US_URL = stripTrailingSlash(APP_CONFIG.contactUs);
export const APP_TITLE = stripTrailingSlash(APP_CONFIG.appTitle);
export const ICARE_HOMEPAGE = stripTrailingSlash(APP_CONFIG.icareHomePage);
export const BASE_API_URL_CANCEL_POLICY = stripTrailingSlash(APP_CONFIG.baseApiUrlCancelPolicy);
export const BASE_API_URL_SEND_EMAIL = stripTrailingSlash(APP_CONFIG.baseApiUrlSendEmail);
export const BASE_API_URL_WAGE_DECLARATION = stripTrailingSlash(APP_CONFIG.baseApiUrlWageDeclaration);

export const FOOTER_LINKS = {
	contactUs: APP_CONFIG.contactUs,
	termsAndCondition: APP_CONFIG.termsAndCondition,
	privacy: APP_CONFIG.privacy,
};

// Westpac QuickWeb
export const WESTPAC_CONFIG = {
	gateway: stripTrailingSlash(APP_CONFIG.paymentGateway),
	handoff: stripTrailingSlash(APP_CONFIG.paymentHandoff),
	communityCode: stripTrailingSlash(APP_CONFIG.paymentCommunityCode),
	businessCode: stripTrailingSlash(APP_CONFIG.paymentBusinessCode),
};

// SPA Config
export const CERTIFICATE_OF_CURRENCY_ID = APP_CONFIG.certificateOfCurrencyId;
export const POLICY_THRESHOLD_DATE = APP_CONFIG.policyThresholdDate;
export const SEARCH_PAGE_SIZE = APP_CONFIG.searchPageSize;
export const DOCUMENTS_PAGE_SIZE = APP_CONFIG.documentsPageSize;
export const PAYMENT_PAGE_GET_INVOICE_INTERVAL = parseInt(APP_CONFIG.paymentPageGetInvoiceInterval) || 5;
export const HINDSIGHT_PREMIUM_PAGE_GET_INVOICE_INTERVAL =
	parseInt(APP_CONFIG.hindsightPremiumPageGetInvoiceInterval) || 5;
export const WESTPAC_AMOUNT_LIMIT = parseInt(APP_CONFIG.westpacAmountLimit) || 1000000;

export const SHOW_PAYMENT_METHOD_BPAY = APP_CONFIG.paymentMethodBPAY === 'true';
export const SHOW_PAYMENT_METHOD_DIRECTDEBIT = APP_CONFIG.paymentMethodDirectDebit === 'true';
export const SHOW_PAYMENT_METHOD_DEBITCREDITCARD = APP_CONFIG.paymentMethodDebitCreditCard === 'true';

// SPA Feature Toggles
export const HIDE_ADDITIONAL_CONTACT = APP_CONFIG.hideAdditionalContact === 'true';
export const HIDE_RENEWALS_FUNCTIONALITY = APP_CONFIG.hideRenewalsFunctionality === 'true';
export const HIDE_INSIGHTS = APP_CONFIG.hideInsights === 'true';
export const HIDE_CONFIRMATION_ACCOUNT_REGISTRATION = APP_CONFIG.hideConfirmationAccountRegistration === 'true';
export const HIDE_EMPLOYER_POLICY_SEARCH_SORT = APP_CONFIG.hideEmployerPolicySearchSort === 'true';
export const HIDE_EMPLOYER_POLICY_SEARCH_FILTER = APP_CONFIG.hideEmployerPolicySearchFilter === 'true';
export const HIDE_BROKER_POLICY_SEARCH_SORT = APP_CONFIG.hideBrokerPolicySearchSort === 'true';
export const HIDE_BROKER_POLICY_SEARCH_FILTER = APP_CONFIG.hideBrokerPolicySearchFilter === 'true';
export const HIDE_BROKER_QUOTE_SEARCH_SORT = APP_CONFIG.hideBrokerQuoteSearchSort === 'true';
// HIDE_PAYMENT_CHANGE_FREQUENCY_BUTTON is currently not supported by GW (FLPAU-8020). Do not set to false.
export const HIDE_PAYMENT_CHANGE_FREQUENCY_BUTTON = APP_CONFIG.hidePaymentChangeFrequencyButton === 'true';
export const HIDE_BROKER_ALERT = APP_CONFIG.hideBrokerAlert === 'true';
export const HIDE_HINDSIGHTS = APP_CONFIG.hideHindsights === 'true';
export const HIDE_EDIT_POLICY_CONTACT_DETAILS = APP_CONFIG.hideEditPolicyContactDetails === 'true';
export const HIDE_INVOICE_TABLE_IF_PREMIUM_PENDING = APP_CONFIG.hideInvoiceTableIfPremiumPending === 'true';
export const DISABLE_AUTHENTICATED_PAGES = APP_CONFIG.disableAuthenticatedPages === 'true';
export const ENABLE_POLICY_WAGE_DECLARATION = APP_CONFIG.isPolicyWageDeclaratiionEnabled === 'true';
export const SHOW_ADDITIONAL_PASSWORD_HEADER = APP_CONFIG.showAdditionalPwHeader === 'true';
export const SHOW_NEW_BUSINESS_HEALTH_QUESTION = APP_CONFIG.showNewBusinessHealthQuestion === 'true';
export const RECAPTCHA_SITE_KEY = APP_CONFIG.recaptchaSiteKey;
export const RECAPTCHA_WEBSITE_SITE_KEY = APP_CONFIG.recaptchaWebsiteSiteKey;
export const RECAPTCHA_WAGE_DECLARATION_SITE_KEY = APP_CONFIG.recaptchaWageDeclarationSiteKey;
export const CLIENT_APP_ID = APP_CONFIG.clientId;
export const CLIENT_APP_SECRET = APP_CONFIG.clientSecret;
export const SHOW_PREMIUM_PROCEED_ERRORS = APP_CONFIG.showPremiumProceedErrors === 'true';

export const USE_VEDA_FOR_TRUSTEE_ABN_ACN_ITC_GST = APP_CONFIG.useVedaForTrusteeAbnAcnItcGst === 'true';
export const USE_VEDA_FOR_ACN_ITC_GST = APP_CONFIG.useVedaForAcnItcGst === 'true';
export const SHOW_GA_ABANDON_TRACKING = APP_CONFIG.showGaAbandonTracking === 'true';

export const HIDE_ACTUAL_WAGES = APP_CONFIG.hideActualWage === 'true';
export const HIDE_MORE_INFO = APP_CONFIG.hideMoreInfo === 'true';
export const ALLOW_ZERO_CONTRACTOR = APP_CONFIG.allowZeroContractor === 'true';

export const HIDE_IS_BUSINESS_INSURED = APP_CONFIG.hideIsBusinessInsured === 'true';
export const DISABLE_CLICK_TWICE = APP_CONFIG.disableClickTwice === 'true';
export default null;
