import StickySessionService from '@icare/core/lib/services/StickySessionService';
import {PORTAL_API_BASE, BASE_API_URL, BASE_MOCK_URL, REFERENCE_DATA_API_URL} from '~/config';

export const IS_MOCK = {
	// Quote Endpoint status
	quoteGet: false,
	quoteCreate: false,
	quoteUpdate: false,
	quoteSave: false,
	quoteEstimate: true,
	quoteSearch: false,
	quoteDelete: false,

	// Policy Endpoint status
	policyGet: false,
	policyBind: false,
	policyUpdate: false,
	policyCancel: false,
	policySearch: false,
	policyRebind: false,
	policyStatisticsGet: false,

	// Policy Renewal
	policyRenewalGet: false,
	policyRenewalUpdate: false,
	policyBindRenewal: false,
	policyBindSRP: false,

	// Various Endpoint status
	referenceData: false,
	wic: false,
	broker: false,
	businesses: false,
	businessValidate: false,

	// Document Endpoint status
	documents: false,
	archive: false,
	stagingLocations: false,

	// User Endpoint status
	user: false,
	register: false,
	forgotRegistrationCode: false,
	addPolicyForUser: false,
	addPolicyForgotRegistrationCode: false,

	// Broker Endpoint status
	brokerageGet: false,
	brokerageAdd: false,
	brokerageUserGet: false,
	brokerageUserSearch: false,
	brokerageUserAdd: false,
	brokerageUserUpdate: false,
	brokerageUserResetPassword: false,

	// Employee User Endpoint status
	employerUserSearch: false,
	employerUserGet: false,
	employerUserRegister: false,
	employerUserResetPassword: false,
	employerUserUpdate: false,

	// Payments Endpoint
	invoices: false,
	payments: false,

	// Direct Debit
	setupDirectDebit: false,
	validateBSB: false,
	updateDirectDebit: false,
	updatePaymentFrequency: false,

	// Error Codes Messages Endpoint
	getErrorMessage: false,

	// Hindsight
	hindsightUpdate: false,
	hindsightRebind: false,
};

export const BASE_URL = {
	// Quote Endpoints
	quoteGet: IS_MOCK.quoteGet ? BASE_MOCK_URL : BASE_API_URL,
	quoteCreate: IS_MOCK.quoteCreate ? BASE_MOCK_URL : BASE_API_URL,
	quoteUpdate: IS_MOCK.quoteUpdate ? BASE_MOCK_URL : BASE_API_URL,
	quoteSave: IS_MOCK.quoteSave ? BASE_MOCK_URL : BASE_API_URL,
	quoteDelete: IS_MOCK.quoteDelete ? BASE_MOCK_URL : BASE_API_URL,
	quoteEstimate: IS_MOCK.quoteEstimate ? BASE_MOCK_URL : BASE_API_URL,
	quoteSearch: IS_MOCK.quoteSearch ? BASE_MOCK_URL : BASE_API_URL,

	// Policy
	policyGet: IS_MOCK.policyGet ? BASE_MOCK_URL : BASE_API_URL,
	policyBind: IS_MOCK.policyBind ? BASE_MOCK_URL : BASE_API_URL,
	policyUpdate: IS_MOCK.policyUpdate ? BASE_MOCK_URL : BASE_API_URL,
	policyCancel: IS_MOCK.policyCancel ? BASE_MOCK_URL : BASE_API_URL,
	policySearch: IS_MOCK.policySearch ? BASE_MOCK_URL : BASE_API_URL,
	policyRebind: IS_MOCK.policyRebind ? BASE_MOCK_URL : BASE_API_URL,
	policyStatistics: IS_MOCK.policyStatisticsGet ? BASE_MOCK_URL : BASE_API_URL,

	// Policy Renewal
	policyRenewalGet: IS_MOCK.policyRenewalGet ? BASE_MOCK_URL : BASE_API_URL,
	policyRenewalUpdate: IS_MOCK.policyRenewalUpdate ? BASE_MOCK_URL : BASE_API_URL,
	policyBindRenewal: IS_MOCK.policyBindRenewal ? BASE_MOCK_URL : BASE_API_URL,
	policyBindSRP: IS_MOCK.policyBindSRP ? BASE_MOCK_URL : BASE_API_URL,

	// Policy Hindsight
	hindsightUpdate: IS_MOCK.hindsightUpdate ? BASE_MOCK_URL : BASE_API_URL,
	hindsightRebind: IS_MOCK.hindsightRebind ? BASE_MOCK_URL : BASE_API_URL,

	// Document Endpoints
	documents: IS_MOCK.documents ? BASE_MOCK_URL : BASE_API_URL,
	archive: IS_MOCK.archive ? BASE_MOCK_URL : BASE_API_URL,
	stagingLocations: IS_MOCK.stagingLocations ? BASE_MOCK_URL : BASE_API_URL,

	// Various
	referenceData: IS_MOCK.referenceData ? BASE_MOCK_URL : BASE_API_URL,
	wic: IS_MOCK.wic ? BASE_MOCK_URL : REFERENCE_DATA_API_URL,
	businesses: IS_MOCK.businesses ? BASE_MOCK_URL : BASE_API_URL,
	businessValidate: IS_MOCK.businessValidate ? BASE_MOCK_URL : BASE_API_URL,

	// User Endpoints
	user: IS_MOCK.user ? BASE_MOCK_URL : BASE_API_URL,
	register: IS_MOCK.register ? BASE_MOCK_URL : BASE_API_URL,
	forgotRegistrationCode: IS_MOCK.forgotRegistrationCode ? BASE_MOCK_URL : BASE_API_URL,
	addPolicyForUser: IS_MOCK.addPolicyForUser ? BASE_MOCK_URL : BASE_API_URL,
	addPolicyForgotRegistrationCode: IS_MOCK.addPolicyForgotRegistrationCode ? BASE_MOCK_URL : BASE_API_URL,

	// Brokers Endpoints
	broker: IS_MOCK.broker ? BASE_MOCK_URL : BASE_API_URL,
	brokerageGet: IS_MOCK.brokerageGet ? BASE_MOCK_URL : BASE_API_URL,
	brokerageAdd: IS_MOCK.brokerageAdd ? BASE_MOCK_URL : BASE_API_URL,
	brokerageUserGet: IS_MOCK.brokerageUserGet ? BASE_MOCK_URL : BASE_API_URL,
	brokerageUserSearch: IS_MOCK.brokerageUserSearch ? BASE_MOCK_URL : BASE_API_URL,
	brokerageUserAdd: IS_MOCK.brokerageUserAdd ? BASE_MOCK_URL : BASE_API_URL,
	brokerageUserUpdate: IS_MOCK.brokerageUserUpdate ? BASE_MOCK_URL : BASE_API_URL,
	brokerageUserResetPassword: IS_MOCK.brokerageUserResetPassword ? BASE_MOCK_URL : BASE_API_URL,

	// Employee User Endpoints
	employerUserSearch: IS_MOCK.employerUserSearch ? BASE_MOCK_URL : BASE_API_URL,
	employerUserGet: IS_MOCK.employerUserGet ? BASE_MOCK_URL : BASE_API_URL,
	employerUserRegister: IS_MOCK.employerUserRegister ? BASE_MOCK_URL : BASE_API_URL,
	employerUserResetPassword: IS_MOCK.employerUserResetPassword ? BASE_MOCK_URL : BASE_API_URL,
	employerUserUpdate: IS_MOCK.employerUserUpdate ? BASE_MOCK_URL : BASE_API_URL,

	// Payments Endpoints
	invoices: IS_MOCK.invoices ? BASE_MOCK_URL : BASE_API_URL,
	payments: IS_MOCK.payments ? BASE_MOCK_URL : BASE_API_URL,
	setupDirectDebit: IS_MOCK.setupDirectDebit ? BASE_MOCK_URL : BASE_API_URL,
	validateBSB: IS_MOCK.validateBSB ? BASE_MOCK_URL : BASE_API_URL,
	updateDirectDebit: IS_MOCK.updateDirectDebit ? BASE_MOCK_URL : BASE_API_URL,
	updatePaymentFrequency: IS_MOCK.updatePaymentFrequency ? BASE_MOCK_URL : BASE_API_URL,

	// Error Codes Messages Endpoint
	getErrorMessage: IS_MOCK.getErrorMessage ? BASE_MOCK_URL : PORTAL_API_BASE,
};

export const BASE_REQ_HEADERS = Object.freeze({
	'Content-Type': 'application/vnd.api+json; charset=utf-8',
	'Cache-Control': 'no-cache',
	'X-InitialSystem': 'WIPolicy-Portal',
	[StickySessionService.sessionHeaderKey]: StickySessionService.getSessionId(),
});
