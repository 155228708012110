import React from 'react';
import T from 'prop-types';
import moment from 'moment';

import RichText from '@icare/components/lib/RichText';
import DateList from '@icare/components/lib/DateList/DateList';

import {replacePolicyPathParams} from '~/core/utils/PolicyUtils';
import {HIDE_RENEWALS_FUNCTIONALITY, HIDE_HINDSIGHTS, POLICY_BASE_NAME} from '~/config';
import {policyPaths} from '~/paths';

export default class PolicyActionList extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
		policyNumber: T.string.isRequired,
		termNumber: T.oneOfType([T.string, T.number]).isRequired,
		actions: T.array.isRequired,
		isBroker: T.bool.isRequired,
		dateToFormat: T.string,
		srpTermNumber: T.number,
	};

	static defaultProps = {
		dateToFormat: 'DD MMMM YYYY',
		srpTermNumber: null,
	};

	_replacePolicyPathParams(path, policyNumber, termNumber) {
		const updatedPath = `/${POLICY_BASE_NAME}${path}`;
		return replacePolicyPathParams(updatedPath, policyNumber, termNumber);
	}

	render() {
		const {actions, policyNumber, termNumber, isBroker, dateToFormat, content, srpTermNumber} = this.props;
		const {policyDetails} = content;
		const actionItems = actions
			.filter(item => !item.isCancelled && !item.isPaymentDue && !item.isPremiumPending)
			.filter(action => !(HIDE_HINDSIGHTS && action.isHindsightsDue))
			.map(action => {
				let content = null;
				if (action.isPaymentOverdue) {
					const link = this._replacePolicyPathParams(policyPaths.policyPaymentsPagePattern, policyNumber, termNumber);
					content = policyDetails.c10A.replace('${MAKE_A_PAYMENT}', `<a href=${link}>${policyDetails.c10B}</a>`);
				} else if (action.isRenewalDue) {
					content = policyDetails.c10C2;
					if (!HIDE_RENEWALS_FUNCTIONALITY) {
						const link = this._replacePolicyPathParams(
							srpTermNumber ? policyPaths.policySRPPagePattern : policyPaths.policyRenewPagePattern,
							policyNumber,
							srpTermNumber ? srpTermNumber : termNumber,
						);
						content = policyDetails.c10C.replace('${MAKE_A_RENEWAL}', `<a href=${link}>${policyDetails.c10D}</a>`);
					}
				} else if (action.isCardExpiring) {
					const link = this._replacePolicyPathParams(policyPaths.policyPaymentsPagePattern, policyNumber, termNumber);

					if (isBroker) {
						content = policyDetails.c10G;
					} else {
						content = policyDetails.c10E.replace(
							'${UPDATE_CREDIT_CARD_DETAILS}',
							`<a href=${link}>${policyDetails.c10F}</a>`,
						);
					}
				} else if (action.isHindsightsDue) {
					const link = this._replacePolicyPathParams(policyPaths.policyHistoryPagePattern, policyNumber, termNumber);
					content = policyDetails.c10H.replace('${DECLARE_WAGES}', `<a href=${link}>${policyDetails.c10I}</a>`);
				}

				return {
					term: moment(action.actionDueDate).format(dateToFormat),
					definition: (
						<React.Fragment>
							<p>
								<strong>{action.actionLabel}</strong>
							</p>
							<RichText content={content} />
						</React.Fragment>
					),
				};
			});

		return (
			<React.Fragment>
				<h3 className="m-t-o">{policyDetails.c9}</h3>

				{!actionItems || !actionItems.length ? (
					<RichText className="m-t-xxxs" content={policyDetails.c10} />
				) : (
					<DateList items={actionItems} />
				)}
			</React.Fragment>
		);
	}
}
